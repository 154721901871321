import React from "react";
import PieChart from "../../components/Graphs/PieChart";
import BarChart from "../../components/Graphs/BarChart";
import WordCloud from "components/Graphs/WordCloud";
import ChartContainer from "./ChartContainer";
import { Grid } from "@material-ui/core";

export default function ResultsChart({
  title,
  loading,
  loadingColor,
  backgroundColor,
  borderColor,
  conditions,
  chartDataFn,
  chartType,
  question,
  openReportsPopup,
  additionalChartProps,
  hideMenu,
}) {
  {
    if (!conditions) return null;
    const chartData = chartDataFn();
    let chart = null;
    switch (chartType) {
      case "BAR":
        chart = (
          <BarChart
            chartData={chartData}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            {...additionalChartProps}
          />
        );
        break;
      case "PIE":
        chart = (
          <PieChart
            chartData={chartData}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            {...additionalChartProps}
          />
        );
        break;
      case "WORD_CLOUD":
        chart = <WordCloud words={chartData} />;
    }
    return (
      <Grid item xs={12} sm={4}>
        <ChartContainer
          title={title}
          loading={loading}
          loadingColor={loadingColor}
          action={() => {
            openReportsPopup({
              type: "GRAPH",
              graphData: chartData,
              graphType: chartType,
              title: title,
              questionText: question ? question.text : "",
            });
          }}
          hideMenu={hideMenu}
        >
          {chart}
        </ChartContainer>
      </Grid>
    );
  }
}

import React from "react";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";

const highlightWithLineNumbers = (input, language) =>
  highlight(input, language)
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

class CodeEditor extends React.Component {
  render() {
    return (
      <Editor
        value={this.props.value ? this.props.value : ""}
        onValueChange={this.props.onValueChange}
        highlight={(code) =>
          highlightWithLineNumbers(code, languages[this.props.language])
        }
        textareaId="codeArea"
        className="editor"
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 18,
          outline: 0,
          minHeight: 500,
        }}
      />
    );
  }
}

export default CodeEditor;

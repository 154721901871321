export const firebaseConfig = {
  apiKey: "AIzaSyBlkiz_aSnR2ikCBxXP5KK6c_8JCac0Nrc",
  authDomain: "auth.phonic.ai",
  databaseURL: "https://phonic-2.firebaseio.com",
  projectId: "phonic-2",
  storageBucket: "phonic-2.appspot.com",
  messagingSenderId: "825819054942",
  appId: "1:825819054942:web:4a289c9e2c60a860f445cf",
  measurementId: "G-VN1YLVBEBG",
};

import React from "react";
import clsx from "clsx";
import { Grid, Button, CircularProgress, Popover } from "@material-ui/core";
import ImageUploadPopup from "components/Popups/ImageUploadPopup";
import { connect } from "react-redux";
import { uploadSurveyImage } from "redux/actions";
import { useDefaultIfUndef, getContrastYIQ } from "utils/utils";
import {
  faTimes,
  faCog,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SketchPicker } from "react-color";
import { notification } from "utils/utils";

const backgrounds = [
  "light",
  "dark",
  "tangerine",
  "lavender",
  "mint",
  "ocean",
  "coffee",
  "lime",
  "turquoise",
  "passionfruit",
  "midnight",
];
const fonts = ["plain", "serif", "opensans", "mulish"];
const alignments = ["left", "center", "right"];
const defaultCustom = "#4A4A4A";

const mapDispatchToProps = (dispatch) => {
  return {
    uploadSurveyImage: (surveyId, file, action) =>
      dispatch(uploadSurveyImage(surveyId, file, action)),
  };
};

class SurveyAppearance extends React.Component {
  state = {
    colorPickerEl: undefined,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.survey !== this.props.survey) {
      if (
        prevProps.survey.welcomeImageUrl !== this.props.survey.welcomeImageUrl
      ) {
        this.setState({ welcomeImageLoading: false });
      }
      if (
        prevProps.survey.topLeftImageUrl !== this.props.survey.topLeftImageUrl
      ) {
        this.setState({ topLeftImageLoading: false });
      }
    }
  }
  imagePopup = (val) => {
    this.setState({
      imagePopup: val,
    });
  };
  topLeftImagePopup = (val) => {
    if (
      this.props.user.tier !== "PREMIUM" &&
      this.props.user.tier !== "ENTERPRISE"
    ) {
      alert("Please upgrade to Premium to use this feature.");
      return;
    }
    this.setState({
      topLeftImagePopup: val,
    });
  };
  submitImage = (file) => {
    this.props.pushSurveyUpdates();
    this.props.uploadSurveyImage(
      this.props.surveyId,
      file,
      "WELCOME_IMAGE_UPLOAD"
    );
  };
  submitTopLeftImage = (file) => {
    this.props.pushSurveyUpdates();
    this.props.uploadSurveyImage(
      this.props.surveyId,
      file,
      "TOP_LEFT_IMAGE_UPLOAD"
    );
  };

  setLoading = (val) => {
    if (val === "welcome")
      this.setState({
        welcomeImageLoading: true,
      });
    if (val === "top-left")
      this.setState({
        topLeftImageLoading: true,
      });
  };
  render() {
    const buttonClass =
      this.props.user &&
      (this.props.user.tier === "PREMIUM" ||
        this.props.user.tier === "ENTERPRISE")
        ? "secondary-button-color"
        : "gray-button";
    const background = this.props.survey.background;
    return (
      <div id="builder-appearance-tab">
        <ImageUploadPopup
          open={this.state.imagePopup}
          onClose={() => this.imagePopup(false)}
          onSubmit={this.submitImage}
          setLoading={() => this.setLoading("welcome")}
        />
        <ImageUploadPopup
          open={this.state.topLeftImagePopup}
          onClose={() => this.topLeftImagePopup(false)}
          onSubmit={this.submitTopLeftImage}
          setLoading={() => this.setLoading("top-left")}
        />
        <p>Background</p>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          {backgrounds.map((b) => {
            const selectedClass = b === background ? "tile-selected" : "";
            return (
              <Grid item key={b}>
                <div
                  className={clsx("background-preview-tile", b, selectedClass)}
                  onClick={() => {
                    this.props.changeInfo(
                      {
                        background: b,
                      },
                      true
                    );
                  }}
                />
              </Grid>
            );
          })}
          <Grid item>
            <div
              className={`background-preview-tile custom ${
                "custom" === this.props.survey.background ? "tile-selected" : ""
              }`}
              style={{
                background: useDefaultIfUndef(
                  this.props.survey.customBackground,
                  defaultCustom
                ),
                color: this.props.survey.customBackground
                  ? getContrastYIQ(this.props.survey.customBackground)
                  : "white",
              }}
              onClick={(e) => {
                if (
                  this.props.user.tier !== "PREMIUM" &&
                  this.props.user.tier !== "ENTERPRISE"
                ) {
                  notification(
                    "",
                    "Please upgrade to Premium to use this feature.",
                    "warning"
                  );
                  return;
                }
                this.setState(
                  {
                    colorPickerEl: e.target,
                  },
                  () => {
                    this.props.changeInfo(
                      {
                        background: "custom",
                      },
                      true
                    );
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </div>
          </Grid>
        </Grid>
        <Popover
          open={Boolean(this.state.colorPickerEl)}
          anchorEl={this.state.colorPickerEl}
          onClose={() => {
            this.setState({ colorPickerEl: undefined });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={
              this.props.survey.customBackground
                ? this.props.survey.customBackground
                : defaultCustom
            }
            onChangeComplete={(color) => {
              this.props.changeInfo(
                {
                  customBackground: color.hex,
                },
                true
              );
            }}
          />
        </Popover>
        <p>Font</p>
        <Grid container spacing={1} style={{ marginBottom: 4 }}>
          {fonts.map((f) => {
            const selectedClass =
              f === this.props.survey.font ? "tile-selected" : "";
            return (
              <Grid item key={f}>
                <div
                  className={`font-preview-tile ${f} ${selectedClass}`}
                  onClick={() =>
                    this.props.changeInfo(
                      {
                        font: f,
                      },
                      true
                    )
                  }
                >
                  Aa
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={1} style={{ marginBottom: 20 }}>
          {alignments.map((a, i) => {
            const selectedClass =
              a === this.props.survey.align ? "tile-selected" : "";
            return (
              <Grid item key={a}>
                <div
                  className={`font-preview-tile ${a} ${selectedClass}`}
                  onClick={() => {
                    this.props.changeInfo(
                      {
                        align: a,
                      },
                      true
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={[faAlignLeft, faAlignCenter, faAlignRight][i]}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
        <p>Custom Branding</p>
        <Grid container spacing={2}>
          <Grid item>
            <p className="image-upload-header">Welcome Image</p>
            <div className="image-preview">
              {this.props.survey.welcomeImageUrl && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="image-preview-x"
                  onClick={() =>
                    this.props.changeInfo(
                      {
                        welcomeImageUrl: undefined,
                      },
                      true
                    )
                  }
                />
              )}
              {this.props.survey.welcomeImageUrl ? (
                <img
                  src={this.props.survey.welcomeImageUrl}
                  className="custom-image-preview"
                  alt="welcome preview"
                />
              ) : (
                <span>No Image</span>
              )}
            </div>
            <Button
              onClick={() => this.imagePopup(true)}
              variant="contained"
              style={{ width: 150 }}
              className={`bottom-button secondary-button-color`}
            >
              {this.state.welcomeImageLoading ? (
                <CircularProgress size={22} style={{ color: "white" }} />
              ) : (
                "Upload Image"
              )}
            </Button>
          </Grid>
          <Grid item>
            <p className="image-upload-header">Top Left</p>
            <div className="image-preview">
              {this.props.survey.topLeftImageUrl && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="image-preview-x"
                  onClick={() =>
                    this.props.changeInfo(
                      {
                        topLeftImageUrl: undefined,
                      },
                      true
                    )
                  }
                />
              )}
              {this.props.survey.topLeftImageUrl ? (
                <img
                  src={this.props.survey.topLeftImageUrl}
                  className="custom-image-preview"
                  alt="top left preview"
                />
              ) : (
                <span>No Image</span>
              )}
            </div>
            <Button
              onClick={() => this.topLeftImagePopup(true)}
              variant="contained"
              style={{ width: 150 }}
              className={`bottom-button ${buttonClass}`}
            >
              {this.state.topLeftImageLoading ? (
                <CircularProgress size={22} style={{ color: "white" }} />
              ) : (
                "Upload Image"
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(SurveyAppearance);

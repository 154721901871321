import { countryList } from "utils/countries";
import {
  maritalStatuses,
  numChildren,
  employmentStatus,
  educationStatus,
  ethnicity,
  careers,
  ageRanges,
  genderOptions,
  hHincomes,
} from "./recruitOptions";

export const selectionDemographics = [
  { title: "Age", optionType: "age", optionList: ageRanges },
  { title: "Gender", optionType: "gender", optionList: genderOptions },
];

export const autocompleteDemographics = [
  { title: "Countries", optionType: "country", optionList: countryList },
  {
    title: "Number of Children",
    optionType: "numChildren",
    optionList: numChildren,
  },
  {
    title: "Marital Status",
    optionType: "maritalStatus",
    optionList: maritalStatuses,
  },
  {
    title: "Employment Status",
    optionType: "employmentStatus",
    optionList: employmentStatus,
  },
  {
    title: "Education",
    optionType: "education",
    optionList: educationStatus,
  },
  {
    title: "Ethnicity",
    optionType: "ethnicity",
    optionList: ethnicity,
  },
  {
    title: "Career",
    optionType: "career",
    optionList: careers,
  },
  {
    title: "Household Income",
    optionType: "hHincome",
    optionList: hHincomes,
  },
];

import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import DeletePopup from "./Popups/DeletePopup";
import { withRouter } from "react-router";
import { deleteSession } from "redux/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSession: (surveyId, sessionId) =>
      dispatch(deleteSession(surveyId, sessionId)),
  };
};

function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const openDeleteSessionPopup = (event) => {
    event.stopPropagation();
    setPopupOpen(true);
    setAnchorEl(null);
  };
  const deleteSession = () => {
    props.closeSession();
    props.deleteSession(props.surveyId, props.sessionObjectId);
    setAnchorEl(null);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const popupClose = () => {
    setPopupOpen(false);
  };
  return (
    <div>
      <DeletePopup
        open={popupOpen}
        deleteFunction={deleteSession}
        onClose={popupClose}
        title={"Are you sure you'd like to delete this respondent?"}
        subTitle={"This action is not recoverable."}
        buttonText={"Delete Respondent"}
      />
      <div onClick={handleClick} className="survey-menu-container">
        <FontAwesomeIcon
          icon={faEllipsisV}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="survey-menu-icon"
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openDeleteSessionPopup}>Delete Respondent</MenuItem>
      </Menu>
    </div>
  );
}

export default withRouter(connect(null, mapDispatchToProps)(SimpleMenu));

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class ConsentPopup extends React.Component {
  state = {
    file: null,
  };
  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    if (!this.state.file) {
      alert("You have not uploaded a file.");
      return;
    }
    this.props.onSubmit(this.state.file);
    this.props.setLoading();
    this.handleClose();
  };

  fileInputChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div className="image-upload-container">
            <DialogTitle id="form-dialog-title">
              Upload Consent Document
            </DialogTitle>
            <DialogContent>
              <form method="post" action="#" id="#">
                <div className="form-group files">
                  <input
                    type="file"
                    className="form-control"
                    multiple=""
                    accept="application/pdf"
                    onChange={this.fileInputChange}
                  />
                </div>
              </form>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={this.handleSubmit}
                >
                  Upload to Phonic
                </Button>
              </DialogActions>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default ConsentPopup;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { CircularProgress, Table, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { shareSurvey, unshareSurvey } from "redux/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    shareSurvey: (surveyId, userEmail, callback) =>
      dispatch(shareSurvey(surveyId, userEmail, callback)),
    unshareSurvey: (surveyId, userId, callback) =>
      dispatch(unshareSurvey(surveyId, userId, callback)),
  };
};

class SharingPopup extends React.Component {
  state = {
    email: undefined,
    unshareWaiting: undefined,
    shareWaiting: undefined,
  };
  handleClose = () => {
    this.props.onClose();
  };

  shareSurvey = () => {
    this.props.shareSurvey(this.props.surveyId, this.state.email, () => {
      this.setState({
        shareWaiting: false,
        email: "",
      });
    });
    this.setState({
      shareWaiting: true,
    });
  };
  unshareSurvey = (user, idx) => {
    this.props.unshareSurvey(this.props.surveyId, user.userId, () => {
      this.setState({ unshareWaiting: undefined });
    });
    this.setState({ unshareWaiting: idx });
  };
  getUserTable = () => {
    if (
      !this.props.user ||
      !this.props.user.shared ||
      !this.props.user.shared[this.props.surveyId] ||
      !this.props.user.shared[this.props.surveyId].length === 0
    )
      return undefined;
    return this.props.user.shared[this.props.surveyId].map((user, idx) => {
      return (
        <tr key={`SHARE-ROW-${idx}`}>
          <td>{user.email}</td>
          <td>
            {this.state.unshareWaiting === idx ? (
              <CircularProgress size={22} style={{ color: "#595c62" }} />
            ) : (
              <FontAwesomeIcon
                icon={faTrash}
                style={{ color: "#595c62" }}
                onClick={() => this.unshareSurvey(user, idx)}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="sharing-popup-container">
            <h2 className="dialog-title-small">Edit Sharing Preferences</h2>
            <h3 className="sharing-title">Share with a new user</h3>
            <div className="textfield-button-row">
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Button
                  margin="dense"
                  variant="contained"
                  color="primary"
                  onClick={this.shareSurvey}
                >
                  {this.state.shareWaiting ? (
                    <CircularProgress size={22} style={{ color: "white" }} />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faShare} />
                      &nbsp;Share
                    </>
                  )}
                </Button>
              </div>
            </div>
            <h3 className="sharing-title">Change who can see this survey</h3>
            <div className="sharing-table">
              <Table className="align-middle mb-0 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>{this.getUserTable()}</tbody>
              </Table>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SharingPopup);

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

class ToolTipPopup extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  getTitle = (type) => {
    switch (type) {
      case "AUDIO":
        return "Audio";
      case "SELECTION":
        return "Selection";
      case "TEXT":
        return "Open Text";
      case "AUDIO_SELECTION":
        return "Audio + Selection";
      case "THINKALOUD":
        return "Think Aloud";
      case "AUDIO_TEXT":
        return "Audio + Text";
      case "VIDEO":
        return "Video";
      case "SCREEN":
        return "Screen";
      case "NUMBER":
        return "Number";
      case "DISPLAY":
        return "Display";
      default:
        return "Unknown";
    }
  };

  getSurveyUrl = (type) => {
    switch (type) {
      case "AUDIO":
        return "https://survey.phonic.ai/5f3364394f355457323fa47f?preview=true";
      case "SELECTION":
        return "https://survey.phonic.ai/5f336452be1212efbad7df2a?preview=true";
      case "TEXT":
        return "https://survey.phonic.ai/5f33644ebe1212efbad7df28?preview=true";
      case "AUDIO_SELECTION":
        return "https://survey.phonic.ai/5f336493be1212efbad7df31?preview=true";
      case "THINKALOUD":
        return "https://survey.phonic.ai/5f3367dbf708e4b9b894d5e9?preview=true";
      case "AUDIO_TEXT":
        return "https://survey.phonic.ai/5f33653bf708e4b9b894d5dd?preview=true";
      case "VIDEO":
        return "https://survey.phonic.ai/5f3365a5f708e4b9b894d5e4?preview=true";
      case "SCREEN":
        return "https://survey.phonic.ai/5f3365a1f708e4b9b894d5e2?preview=true";
      case "NUMBER":
        return "https://survey.phonic.ai/5f336827f708e4b9b894d5eb?preview=true";
      case "DISPLAY":
        return "https://survey.phonic.ai/5f336496be1212efbad7df33?preview=true";
      default:
        return "https://survey.phonic.ai/5f3364394f355457323fa47f?preview=true";
    }
  };

  getDescription = (type) => {
    switch (type) {
      case "AUDIO":
        return "Let survey participants respond with their voice. Optional transcription and sentiment analysis available.";
      case "SELECTION":
        return "Standard multiple choice compponent. Can be configured with skip logic, randomization, and multiple selections.";
      case "TEXT":
        return "In a text response, results are given in a freeform text format.";
      case "AUDIO_SELECTION":
        return "Simple combination of an audio recording component with a selection one. Optional transcription and sentiment analysis available along with selection configurations.";
      case "THINKALOUD":
        return "Start recording audio immediately. Optional transcription and sentiment analysis available.";
      case "AUDIO_TEXT":
        return "Simple combination of an audio recording component with a text one. Optional transcription and sentiment analysis available.";
      case "VIDEO":
        return "Record your participants via the webcam. Optional transcription and sentiment analysis available.";
      case "SCREEN":
        return "Have your survey participants record themselves interacting with an online experience. Audio is recorded and optionally transcribed alongside the screen capture. A redirect link can also be provided to bring your participants to a desired website.";
      case "NUMBER":
        return "Similar to a text question, but restricted to numeric characters only.";
      case "DISPLAY":
        return "No use input whatsoever. Great for breaking surveys up into sections.";
      default:
        return "Unknown question type. Please check back.";
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {this.getTitle(this.props.questionType)} Component
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="dialog-text">
              {this.getDescription(this.props.questionType)}
            </DialogContentText>
            <div id="wrap">
              <iframe
                id="frame"
                title="sample-survey"
                src={this.getSurveyUrl(this.props.questionType)}
              />
            </div>
          </DialogContent>
          <DialogContent>
            <DialogActions style={{ justifyContent: "left" }}>
              <Button
                variant="contained"
                component="label"
                onClick={this.handleClose}
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default ToolTipPopup;

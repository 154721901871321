import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& label.Mui-focused": {
      color: "#507b82",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#507b82",
    },
    "& .MuiInputLabel-shrink, .MuiOutlinedInput-root": {
      fontSize: "1.25rem",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "unset",
    },
    "& .MuiChip-root": {
      backgroundColor: "#EAEAEA",
    },
  },
}));

const PickTopic = ({
  options,
  disabledOptions,
  item,
  handleChange,
  label,
  name,
}) => {
  const classes = useStyles();

  if (!Array.isArray(options)) {
    return null;
  }

  const disabledTopics = Array.isArray(disabledOptions)
    ? new Set(disabledOptions.map((o) => o._id))
    : new Set();

  const isDisabled = (topicId) => {
    return disabledTopics.has(topicId);
  };

  return (
    <Autocomplete
      id="topics"
      options={options}
      getOptionLabel={(item) => (item ? item.Text : "")}
      getOptionSelected={(item) => item.Text}
      getOptionDisabled={(item) => isDisabled(item._id)}
      onChange={handleChange}
      value={item}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          className={classes.inputRoot}
          name={name}
        />
      )}
    />
  );
};

export default PickTopic;

import React from "react";
export default function PoweredBy() {
  return (
    <div id="powered-by" className="flex-container">
      powered by{" "}
      <img
        src={require("../assets/img/logo-light.png")}
        alt="phonic logo light."
      />
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
  label: {
    color: "#507b82",
    "&.Mui-focused": {
      color: "#507b82",
    },
  },
  select: {
    "&:after": {
      borderColor: "#507b82",
    },
  },
}));

const SelectSort = ({ handleChange }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="theme-sort-label" classes={{ root: classes.label }}>
        Sort by
      </InputLabel>
      <Select
        id="theme-sort"
        labelId="theme-sort-label"
        label="Sort by"
        margin="dense"
        defaultValue="DEFAULT"
        onChange={handleChange}
        className={classes.select}
      >
        <MenuItem value="DEFAULT">default</MenuItem>
        <MenuItem value="POSITIVE">positive</MenuItem>
        <MenuItem value="NEGATIVE">negative</MenuItem>
        <MenuItem value="CONTROVERSIAL">controversial</MenuItem>
        <MenuItem value="MENTIONS">mentions</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectSort;

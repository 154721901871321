import React from "react";
import { withRouter } from "react-router-dom";
import TypeTile from "components/TypeTile";
import { connect } from "react-redux";
import { removeHtmlTags } from "utils/utils";
import {
  getSurveys,
  getResponses,
  getTopics,
  getUser,
  getSurveyExport,
  setPageTitle,
} from "redux/actions";
import { parseQuery } from "utils/utils";
import { stringifyQuery, isTextTypeStrict } from "utils/utils";
import QuestionAnalytics from "./QuestionAnalytics";
import ResponseTable from "./ResponseTable";
import { Tooltip, Menu, MenuItem } from "@material-ui/core";
import { isAudioType, isVideoType, getStimuliJSX } from "utils/utils";
import EditSentimentPopup from "components/Popups/EditSentimentPopup";
import AddToShowreelPopup from "components/Popups/AddToShowreelPopup";
import AddToReportPopup from "components/Popups/AddToReportPopup";
import { isAudioOrVideoType } from "utils/utils";
import TopicsView from "./TopicsView";
import { isEqual } from "lodash";

const getResponseMap = (state, ownProps) => {
  var s = {};
  if (state.responses) {
    s.responses = state.responses[ownProps.match.params.questionId];
  }
  if (state.topics) {
    s.topics = state.topics[ownProps.match.params.questionId];
  }
  if (state.urlParams) {
    s.urlParams = state.urlParams[ownProps.match.params.questionId];
  }
  if (state.topics) {
    s.topics = state.topics[ownProps.match.params.questionId];
  }
  return s;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...getResponseMap(state, ownProps),
    searchText: state.searchText,
    loading: state.loading["Question"],
    exportLoading: state.loading["SurveyExport"],
    topicsDirtied: state.topicsDirtied,
  };
};
const mapDispatchToProps = {
  getSurveys,
  getResponses,
  getTopics,
  getUser,
  getSurveyExport,
  setPageTitle,
};

class Question extends React.Component {
  state = {
    filters: {
      processedMedia: false,
      emotions: new Set([]),
      bookmarked: false,
      word: "",
      sentiment: undefined,
    },
    displayOptions: {
      demographics: true,
      externalId: false,
      public: false,
    },
    showTopics: false,
  };

  componentDidMount() {
    this.fetchResponses();
    this.props.getTopics(this.props.match.params.questionId);
  }

  componentDidUpdate(prevProps, prevState) {
    // If query parameters are changed (with the exception of "page") refresh responses
    const prevQuery = parseQuery(prevProps.location.search);
    delete prevQuery.page;
    const query = parseQuery(this.props.location.search);
    delete query.page;
    if (
      prevProps.match.params.questionId !==
        this.props.match.params.questionId ||
      !isEqual(prevQuery, query) ||
      (!prevProps.topicsDirtied && this.props.topicsDirtied)
    ) {
      this.fetchResponses();
      this.props.getTopics(this.props.match.params.questionId);
    }
  }

  fetchResponses = () => {
    const query = parseQuery(this.props.location.search);
    delete query.page;
    query.include_metadata = "true";
    this.props.getResponses(
      this.props.match.params.surveyId,
      this.props.match.params.questionId,
      `?${stringifyQuery(query)}`
    );
  };

  filterAndSort = (responses) => {
    if (responses === undefined) return responses;
    // Search Text
    if (this.props.filters.searchText)
      responses = responses.filter(
        (r) =>
          (r.features &&
            r.features.transcription &&
            r.features.transcription
              .toLowerCase()
              .includes(this.props.filters.searchText.toLowerCase())) ||
          (isTextTypeStrict(r.type) &&
            r.data &&
            r.data
              .toLowerCase()
              .includes(this.props.filters.searchText.toLowerCase()))
      );

    if (this.props.filters.emotions.size > 0) {
      // Emotion Stuff
      responses = responses.filter((r) => {
        if (!r.features || !r.features.emotions) return false;
        let collision = 0;
        r.features.emotions.emojis.split(" ").forEach((e) => {
          if (this.props.filters.emotions.has(e)) collision += 1;
        });
        return collision === this.props.filters.emotions.size;
      });
    }
    // Processed Media
    if (
      this.props.filters.processedMedia &&
      this.props.question.doTranscription &&
      (isAudioType(this.props.question.type) ||
        isVideoType(this.props.question.type))
    ) {
      responses = responses.filter((r) => {
        return (
          (r.features &&
            r.features.transcription &&
            r.features.transcription !== "Processing...") ||
          r.backupText
        );
      });
    }
    // Bookmarked Responses
    if (this.props.filters.bookmarked) {
      responses = responses.filter((r) => {
        return r.bookmarked === true;
      });
    }
    // Filter Sentiment via pie chart
    if (this.props.filters.sentiment) {
      responses = responses.filter((r) => {
        return (
          (r.features && r.features.sentiment) ===
          this.props.filters.sentiment.toUpperCase()
        );
      });
    }
    // Filter Word via word cloud
    if (this.props.filters.word) {
      responses = responses.filter((r) => {
        const text = (r.features && r.features.transcription) || r.backupText;
        if (!text) return false;
        return text.indexOf(this.props.filters.word) !== -1;
      });
    }

    return responses;
  };

  toggleTopics = () => {
    this.setState({ showTopics: !this.state.showTopics });
  };

  /**
   * Utility functions for response popups
   */
  closeResponsePopup = () => {
    this.setState({
      sentimentPopupOpen: false,
      showreelsPopupOpen: false,
      reportPopupOpen: false,
      modelPickerOpen: false,
      responseMenuResponse: undefined,
      responseMenuAnchorEl: undefined,
    });
  };

  openReportsPopup = (blockInfo) => {
    this.setState({
      reportPopupOpen: true,
      reportPopupBlockInfo: blockInfo,
    });
  };
  openShowreelsPopup = (response) => {
    this.setState({
      showreelsPopupOpen: true,
      showreelsPopupResponse: response,
    });
  };
  openResponseMenu = (e, response) => {
    this.setState({
      responseMenuAnchorEl: e.currentTarget,
      responseMenuResponse: response,
    });
  };
  getRandomResponse = () => {
    if (!this.props.responses || this.props.responses.length == 0) return;

    // Keep rerolling until we find some real data. Only retry a few times
    let retries = 10;
    let text = "";
    while (text == "" && retries > 0) {
      const response =
        this.props.responses[
          Math.floor(Math.random() * this.props.responses.length)
        ];
      if (response.features && response.features.transcription) {
        text = response.features.transcription;
      } else if (response.backupText) {
        text = response.backupText;
      } else if (response.data && typeof response.data == "string") {
        text = response.data;
      }
      retries--;
    }
    return text;
  };

  render() {
    if (!this.props.survey || !this.props.question) return null;
    const responses = this.filterAndSort(this.props.responses);
    return (
      <div id="question-pane">
        {/* Popups for adding to showreels, reports, and editing sentiment */}
        <EditSentimentPopup
          open={this.state.sentimentPopupOpen}
          onClose={this.closeResponsePopup}
          response={this.state.responseMenuResponse}
        />
        <AddToReportPopup
          open={this.state.reportPopupOpen}
          onClose={this.closeResponsePopup}
          blockInfo={this.state.reportPopupBlockInfo}
        />
        <AddToShowreelPopup
          open={this.state.showreelsPopupOpen}
          onClose={this.closeResponsePopup}
          response={this.state.showreelsPopupResponse}
        />

        {/* Popover showing response options */}
        <Menu
          anchorEl={this.state.responseMenuAnchorEl}
          open={Boolean(this.state.responseMenuAnchorEl)}
          onClose={this.closeResponsePopup}
        >
          <MenuItem
            onClick={() => {
              this.openReportsPopup({
                type: "RESPONSE",
                responseId: this.state.responseMenuResponse._id,
              });
            }}
          >
            Add to Report
          </MenuItem>
          {this.state.responseMenuResponse &&
            (this.state.responseMenuResponse.audioPath ||
              this.state.responseMenuResponse.videoPath) && (
              <MenuItem
                onClick={() => {
                  this.openShowreelsPopup(this.state.responseMenuResponse);
                }}
              >
                Add to Showreel
              </MenuItem>
            )}
          {this.props.question &&
            isAudioOrVideoType(this.props.question.type) &&
            this.props.question.doSentiment && (
              <MenuItem
                onClick={() => this.setState({ sentimentPopupOpen: true })}
              >
                Edit Sentiment
              </MenuItem>
            )}
        </Menu>
        <div id="title-block">
          <div className="mr-10">
            <TypeTile type={this.props.question.type} shape="square" />
          </div>
          <div className="title-block-text">
            <Tooltip
              title={removeHtmlTags(this.props.question.text)}
              placement="bottom-start"
            >
              <div className="question-title overflow-ellipsis">
                {removeHtmlTags(this.props.question.text)}
              </div>
            </Tooltip>
            <Tooltip
              title={removeHtmlTags(this.props.question.subText)}
              placement="bottom-start"
            >
              <div className="question-subtitle overflow-ellipsis">
                {removeHtmlTags(this.props.question.subText)}
              </div>
            </Tooltip>
            {getStimuliJSX(this.props.question)}
          </div>
        </div>
        <QuestionAnalytics
          survey={this.props.survey}
          question={this.props.question}
          responses={responses}
          topics={this.props.topics}
          updateFilters={this.props.updateFilters}
          closeResponsePopup={this.closeResponsePopup}
          openReportsPopup={this.openReportsPopup}
          entities={this.props.topics}
        />
        {this.state.showTopics ? (
          <TopicsView
            entities={this.props.topics}
            question={this.props.question}
            toggleTopics={this.toggleTopics}
            getRandomResponse={this.getRandomResponse}
          />
        ) : (
          <ResponseTable
            responses={responses}
            question={this.props.question}
            survey={this.props.survey}
            displayOptions={this.props.displayOptions}
            filters={this.props.filters}
            closeResponsePopup={this.closeResponsePopup}
            entities={this.props.topics}
            openReportsPopup={this.openReportsPopup}
            openShowreelsPopup={this.openShowreelsPopup}
            openResponseMenu={this.openResponseMenu}
            toggleTopics={this.toggleTopics}
            updateFilters={this.props.updateFilters}
            loading={this.props.loading}
            searchParams={this.props.searchParams}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Question)
);

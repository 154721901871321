import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import ErrorOutlineOutlined from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  otherText: { margin: theme.spacing(2, 0, 0) },
}));

let otherUsageSelected = false;
let otherReferralSelected = false;

function LoadingAdornment(props) {
  if (props.loading) return <CircularProgress size={20} />;
  else if (props.success === true)
    return <VerifiedUserOutlined style={{ color: "#2EA591" }} />;
  else if (props.success === false)
    return <ErrorOutlineOutlined style={{ color: "red" }} />;
  else return "";
}

export default function SignUpFormPart2(props) {
  const classes = useStyles();

  return (
    <Grid id="auth-screen" className="flex-in-center-fullscreen-90" container>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {props.organization && props.organization.logoUri && (
            <img
              src={props.organization.logoUri}
              className="login-logo"
              alt="Login logo"
            />
          )}
          {!props.organizationId && (
            <img
              src={require("../assets/img/Phonic_Logo_4x.png")}
              className="login-logo"
              alt="Login logo"
            />
          )}
          <h2>You're Almost There</h2>
          {props.shouldVerifyCode && (
            <p style={{ textAlign: "center" }}>
              We just sent a login code to <b>{props.email}</b>. Please check
              your inbox.
              <br />
              <a onClick={props.signOutAndGoToPage1}>
                Use another email
              </a> or{" "}
              {!props.sendVerificationCodeLoading ? (
                <a onClick={props.sendVerificationEmail}>resend code</a>
              ) : (
                <span>
                  <CircularProgress size={10} />
                </span>
              )}
              .
            </p>
          )}
          {!props.shouldVerifyCode && <p>Just a few more questions!</p>}
          <form className={classes.form} onSubmit={props.addUser}>
            <Grid container spacing={2}>
              {props.shouldVerifyCode && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      className="email-verification"
                      defaultValue={props.verificationCode}
                      variant="outlined"
                      required
                      fullWidth
                      label="Code"
                      id="verification-code"
                      name="verification-code"
                      autoComplete="verification-code"
                      onChange={props.formChange.bind(this, "verificationCode")}
                      error={props.verificationSuccessful === false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LoadingAdornment
                              loading={props.emailVerificationLoading}
                              success={props.verificationSuccessful}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                  What were you hoping to use Phonic for?
                </Typography>
                <Select
                  variant="outlined"
                  aria-label="use case"
                  name="use case"
                  fullWidth
                  required
                  onChange={(e) => {
                    // Changing the use case text shouldn't override
                    // another selection unless Other is selected.
                    if (e.target.value === "Other") {
                      otherUsageSelected = true;
                    } else {
                      otherUsageSelected = false;
                    }
                    props.formChange("useCase", e);
                  }}
                >
                  <MenuItem value="Academic Research">
                    Academic Research
                  </MenuItem>
                  <MenuItem value="Consumer Insights">
                    Consumer Insights
                  </MenuItem>
                  <MenuItem value="AI Research (building datasets)">
                    AI Research (Building datasets)
                  </MenuItem>
                  <MenuItem value="HR (Hiring/Training)">
                    HR (Hiring/Training)
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {otherUsageSelected && (
                  <TextField
                    className={classes.otherText}
                    noValidate
                    autoComplete="off"
                    id="discovery-method-other"
                    placeholder="I'm hoping to use Phonic for..."
                    variant="outlined"
                    fullWidth
                    onChange={props.formChange.bind(this, "useCase")}
                  />
                )}
              </Grid>
              <Grid item xs={12} id="discovery-dropdown">
                <Typography variant={"body1"}>
                  Where did you hear about Phonic?
                </Typography>
                <Select
                  variant="outlined"
                  aria-label="discovery method"
                  name="discovery method"
                  fullWidth
                  required
                  onChange={(e) => {
                    // Changing the discovery method text shouldn't override
                    // another selection unless Other is selected.
                    if (e.target.value === "Other") {
                      otherReferralSelected = true;
                    } else {
                      otherReferralSelected = false;
                    }
                    props.formChange("discoveryMethod", e);
                  }}
                >
                  <MenuItem value="Referred by a friend">
                    Referred by a friend
                  </MenuItem>
                  <MenuItem value="Product Hunt">Product Hunt</MenuItem>
                  <MenuItem value="Google">Google</MenuItem>
                  <MenuItem value="Facebook">Facebook</MenuItem>
                  <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                  <MenuItem value="ResearchGate">ResearchGate</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {otherReferralSelected && (
                  <TextField
                    className={classes.otherText}
                    noValidate
                    autoComplete="off"
                    id="discovery-method-other"
                    placeholder="I heard about Phonic..."
                    variant="outlined"
                    fullWidth
                    onChange={props.formChange.bind(this, "discoveryMethod")}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                  How big is your organization?
                </Typography>
                <Select
                  variant="outlined"
                  aria-label="org size"
                  name="org size"
                  fullWidth
                  required
                  onChange={(e) => {
                    props.formChange("orgSize", e);
                  }}
                >
                  <MenuItem value="1 to 10 people">1 to 10 people</MenuItem>
                  <MenuItem value="11 to 100 people">11 to 100 people</MenuItem>
                  <MenuItem value="101 to 1000 people">
                    101 to 1000 people
                  </MenuItem>
                  <MenuItem value="More than 1000 people">
                    More than 1000 people
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={true}
                      color="primary"
                      onChange={props.formChange.bind(this, "subscribed")}
                    />
                  }
                  label="Receive discounts and product updates via email."
                />
              </Grid>
            </Grid>
            <div id="section-header">
              <div style={{ marginTop: 10, color: "gray" }}></div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-button"
              disabled={
                props.verificationSuccessful !== true && props.shouldVerifyCode
              }
            >
              {props.addUserLoading ? (
                <CircularProgress size={20} className="signup" />
              ) : (
                "Access Phonic"
              )}
            </Button>
          </form>
        </div>
      </Container>
    </Grid>
  );
}

export const ageRanges = [
  {
    name: "17-18",
    maxAge: 18,
    minAge: 17,
    include: true,
  },
  {
    name: "18-24",
    maxAge: 24,
    minAge: 18,
    include: true,
  },
  {
    name: "25-34",
    maxAge: 34,
    minAge: 25,
    include: true,
  },
  {
    name: "35-44",
    maxAge: 35,
    minAge: 44,
    include: true,
  },
  {
    name: "45-54",
    maxAge: 54,
    minAge: 45,
    include: true,
  },
  {
    name: "> 54",
    minAge: 54,
    include: true,
  },
];

export const genderOptions = [
  {
    name: "Male",
    include: true,
    quota: undefined,
  },
  { name: "Female", include: true, quota: undefined },
  { name: "Other", include: true, quota: undefined },
];

export const maritalStatuses = [
  { name: "Divorced" },
  { name: "Living with partner" },
  { name: "Separated" },
  { name: "Single" },
  { name: "Widowed" },
  { name: "Married" },
  { name: "Prefer not to say" },
];

export const numChildren = [
  { name: "1" },
  { name: "2" },
  { name: "3" },
  { name: "4" },
  { name: "5" },
  { name: "6" },
  { name: "Prefer not to say" },
];

export const education = [
  { name: "Middle school" },
  { name: "High school" },
  { name: "Vocational/Technical college" },
  { name: "University" },
  { name: "Post-graduate" },
];

export const employmentStatus = [
  { name: "Employed for wages" },
  { name: "self-employed" },
  { name: "Out of work looking for work" },
  { name: "Out of work not looking for work" },
  { name: "Student" },
  { name: "Military" },
  { name: "Retired" },
];
export const ethnicity = [
  { name: "Arab" },
  { name: "Asian" },
  { name: "Black" },
  { name: "White" },
  { name: "Hispanic" },
  { name: "Latino" },
  { name: "Multiracial" },
  { name: "Other" },
  { name: "Prefer not to say" },
];

export const careers = [
  { name: "Agriculture" },
  { name: "Arts" },
  { name: "Broadcasting" },
  { name: "Construction" },
  { name: "Education" },
  { name: "Finance and Insurance" },
  { name: "Government and Public Administration" },
  { name: "Healthcare and Social Assistance" },
  { name: "Homemaker" },
  { name: "Hotel and Food Service" },
  { name: "Information" },
  { name: "Legal Services" },
  { name: "Manufacturing" },
  { name: "Military" },
  { name: "Real Estate" },
  { name: "Retail" },
  { name: "Religious" },
  { name: "Software" },
  { name: "Telecommunications" },
  { name: "Transportation" },
  { name: "Energy/Utilities/Gas & Oil" },
  { name: "Advertising" },
  { name: "Wholesale" },
  { name: "Consulting" },
  { name: "Automotive" },
  { name: "Fashion/Apparel" },
  { name: "Human Resources" },
  { name: "Market Research" },
  { name: "Marketing/Sales" },
  { name: "Shipping/Distribution" },
  { name: "Personal Services" },
  { name: "Security" },
];

export const educationStatus = [
  { name: "Middle School" },
  { name: "High School" },
  { name: "Vocational/Technical College" },
  { name: "University" },
  { name: "Post-Graduate" },
];

export const hHincomes = [
  { 
    name: "0-10k",
    minIncome: 0,
    maxIncome: 9999
  },
  { 
    name: "10-25k", 
    minIncome: 10000, 
    maxIncome: 24999
  },
  { 
    name: "25-50k", 
    minIncome: 25000, 
    maxIncome: 49999
  },
  { 
    name: "50-75k", 
    minIncome: 50000, 
    maxIncome: 74999
  },
  { 
    name: "75-100k", 
    minIncome: 75000, 
    maxIncome: 99999
  },
  { 
    name: "100-125k", 
    minIncome: 100000, 
    maxIncome: 124999
  },
  { 
    name: "125-150k", 
    minIncome: 125000, 
    maxIncome: 149999
  },
  { 
    name: "150-175k", 
    minIncome: 150000, 
    maxIncome: 174999
  },
  { 
    name: "175-200k", 
    minIncome: 175000, 
    maxIncome: 199999
  },
  { 
    name: "200k+", 
    minIncome: 200000
  }
]

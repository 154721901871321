import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import backendReducer from "./reducers/backend";

const preloadedState = {
  demographics: [
    {
      id: 0,
      activeScreen: 0,
      countries: ["United States of America (USA)"],
      malePercent: 50,
      minAge: 18,
      maxAge: 100,
      customQuestion: "",
    },
  ],
  loading: {},
  fileUploadProgress: {},
  files: [],
};

const store = createStore(
  backendReducer,
  preloadedState,
  applyMiddleware(thunk)
);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  window.store = store;
}

export default store;

import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/core/styles";
import { getFeatures, getPrice } from "utils/tiers";
import clsx from "clsx";

function getName(tierName, discount) {
  // Always show Premium, unless Academic discount.
  if (tierName === "Premium" && discount === "ACADEMIC") return "Academic";
  return tierName;
}

function getButtonText(tier, selectedTier, loading) {
  if (loading)
    return <ClipLoader size={14} color={"black"} loading={loading} />;

  if (tier === selectedTier) return "Current Plan";

  switch (tier) {
    case "FREE":
      return "Downgrade";
    case "PREMIUM":
      if (selectedTier === "FREE") return "Upgrade";
      if (selectedTier === "ENTERPRISE") return "Downgrade";
      break;
    case "ENTERPRISE":
      return "Contact Us";
    default:
      return null;
  }
}

function getSubText(tier, discount) {
  if (tier === "FREE")
    return "Kick off a 14 day trial without providing any payment details.";
  else if (tier === "PREMIUM" && discount === "ACADEMIC")
    return "Supporting students and academics. $284/yr paid annually or $38/mo.";
  else if (tier === "PREMIUM")
    return "Best for startups and smaller teams. $948/yr paid annually or $129/mo.";
  else if (tier === "ENTERPRISE")
    return "Flexible pricing for teams and businesses.";
}

function getColor(tier) {
  if (tier === "FREE") return "#12b361";
  else if (tier === "PREMIUM") return "#5772ff";
  else if (tier === "ENTERPRISE") return "#ff6501";
  return "#000000";
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: 12,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "black",
    borderRadius: 16,
    width: 290,
    margin: 16,
  },
  hoverable: {
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 1px 6px 0 rgba(32, 33, 36, 0.4);",
    },
  },
  tier: {
    fontSize: 20,
    fontWeight: 700,
  },
  priceContainer: {
    display: "inline-block",
    fontWeight: "bold",
  },
  price: {
    display: "inline-block",
    fontSize: 36,
  },
  perMonth: {
    fontSize: 20,
    color: "gray",
    display: "inline-block",
    position: "relative",
    top: -5,
    margin: 4,
  },
  subText: { marginTop: 12, marginLeft: 12, marginRight: 12 },
  features: {
    textAlign: "left",
  },
  feature: { width: 200, marginTop: 5, marginBottom: 5 },
  free: { color: getColor("FREE") },
  premium: { color: getColor("PREMIUM") },
  enterprise: { color: getColor("ENTERPRISE") },
}));

export default function PricingCard(props) {
  const classes = useStyles();
  const features = getFeatures(props.tier);
  var disabled = props.tier === props.selectedTier;
  var elevation = 2;
  // If plan is selected
  if (props.tier === props.selectedTier) elevation = 10;

  var action = null;
  if (!disabled) {
    if (props.tier === "FREE") action = props.downgrade;
    else if (props.tier === "PREMIUM") action = props.upgrade;
    else if (props.tier === "ENTERPRISE") action = props.contact;
  }

  var tierClass;
  if (props.tier === "FREE") tierClass = classes.free;
  else if (props.tier === "PREMIUM") tierClass = classes.premium;
  else if (props.tier === "ENTERPRISE") tierClass = classes.enterprise;
  return (
    <Paper
      className={clsx(classes.paper, !disabled ? classes.hoverable : null)}
      elevation={elevation}
      onClick={action}
    >
      <h3 className={clsx(classes.tier, tierClass)}>
        {getName(features.name, props.discount)}
      </h3>
      <div className={classes.priceContainer}>
        {features.showTime && <div className={classes.perMonth}>$</div>}
        <div className={classes.price}>
          {getPrice(props.tier, props.billingPeriod, props.discount)}{" "}
        </div>
        {features.showTime && <div className={classes.perMonth}>/mo</div>}
      </div>
      <p className={classes.subText}>
        {getSubText(props.tier, props.discount)}
      </p>
      <div className="grid-check-plan">
        {features.featureList.map((feature, idx) => {
          return (
            <div
              className="check-block-plan"
              key={`PricingCardFeature-${props.tier}-${idx}`}
            >
              <div className={clsx("check-plan", props.tier.toLowerCase())}>
                <svg
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 21 17"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="ui-icons"
                      transform="translate(-109.000000, -67.000000)"
                      fill={getColor(props.tier)}
                    >
                      <polygon
                        id="check"
                        points="108.994099 76.4000626 115.987848 83.419577 129.407253 69.9978283 126.587674 67.1592372 115.987848 77.7490936 111.827057 73.5894775"
                      ></polygon>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-check-plan">{feature}</div>
            </div>
          );
        })}
      </div>
      <Button
        className="upgrade-button"
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={action}
      >
        {getButtonText(props.tier, props.selectedTier, props.loading)}
      </Button>
    </Paper>
  );
}

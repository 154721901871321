import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

export default function FolderTile({ ...props }) {
  return (
    <div
      className="folder-tile"
      onClick={props.onClick}
      tabIndex={0}
      role="button"
    >
      <div className="date">
        <FontAwesomeIcon icon={faCalendar} /> &nbsp; {props.date}
      </div>
      <div className="title">{props.title}</div>
      <div className="comment">{props.comment}</div>
      <div>
        <hr />
        <div className="stat">
          <div className="num">{props.numFolders}</div>
          <div className="unit">Folders</div>
        </div>
        <div className="stat">
          <div className="num">{props.numFiles}</div>
          <div className="unit">Files</div>
        </div>
      </div>
    </div>
  );
}

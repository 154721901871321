import React, { Component } from "react";
import SurveyQuestions from "components/SurveyQuestions";
import { connect } from "react-redux";
import { track, trackPage } from "analytics";
import { Helmet } from "react-helmet";
import {
  updateSurveyQuestions,
  clearCurrentSurveyId,
  updateSurveyRedux,
  getUser,
  setPageTitle,
  deploySurvey,
  startLoading,
  clearSurveyDurationEstimate,
} from "redux/actions";
import CreateSurveyPopupContent from "components/Popups/CreateSurveyPopupContent";
import TemplateSurveyPopup from "components/Popups/TemplateSurveyPopup";
import Templates from "components/SurveyCreation/Templates";
import { getSurvey } from "redux/actions";
import SurveySettings from "views/CreateSurvey/SurveySettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faCog } from "@fortawesome/free-solid-svg-icons";
import {
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Grid,
  Button,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import SurveyAppearance from "views/CreateSurvey/SurveyAppearance";
import { getBlankSurvey, getBlankQuestion } from "utils/defaults";
import { sleep, notification } from "utils/utils";
import { ClipLoader, ScaleLoader } from "react-spinners";
import _ from "lodash";
import clsx from "clsx";
import QuestionConfiguration from "views/CreateSurvey/QuestionConfiguration";
import PreviewSettings from "components/SurveyCreation/PreviewSettings";
import ConfirmationPopup from "components/Popups/ConfirmationPopup";
import EmbedPreview from "components/EmbedPreview";
import { FocusStates } from "utils/data";
import { getObjectId } from "utils/utils";
import { truncateStringWithEllipsis } from "utils/utils";

const mapStateToProps = (state) => {
  return {
    createSurveyId: state.createSurveyId,
    currentSurvey: state.currentSurvey,
    user: state.user,
    loading: state.loading["CreateSurvey"],
    estimatedCompletionTimeMinutes: state.estimatedCompletionTimeMinutes,
  };
};

const mapDispatchToProps = {
  updateSurveyQuestions,
  getSurvey,
  clearCurrentSurveyId,
  updateSurveyRedux,
  getUser,
  setPageTitle,
  deploySurvey,
  startLoading,
  clearSurveyDurationEstimate,
};

const tabOptions = ["questions", "appearance", "admin"];
const previewOptions = ["default", "popover", "sidedrawer", "inline"];

class CreateSurvey extends Component {
  constructor(props) {
    super(props);
    trackPage("Survey Builder [V2]");

    this.state = {
      info: { ...getBlankSurvey() },
      showPopup: true,
      confirmationPopup: false,
      tab: "questions",
      previewId: 0,
      previewQuestionIdx: undefined,
      isQuestionConfigOpen: false,
      publishPending: undefined,
      isTemplate: true,
      selectedQuestionId: undefined,
      questionFocusOverride: FocusStates.NONE,
      popupOptions: {
        mainView: true,
        isTemplates: true,
        createSurveyFocus: null,
      },
      autoRefresh: true,
      preview: "default",
    };

    if (this.props.match.params.surveyId) {
      this.props.getSurvey(this.props.match.params.surveyId, { staging: true });
      this.state = {
        ...this.state,
        showPopup: false,
        surveyId: this.props.match.params.surveyId,
      };
    } else {
      this.state = {
        ...this.state,
        showPopup: true,
        surveyId: this.props.match.params.surveyId,
      };
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentSurveyId();
    this.props.getUser();
    this.props.clearSurveyDurationEstimate();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentSurvey !== this.props.currentSurvey &&
      this.props.currentSurvey
    ) {
      var questions = this.props.currentSurvey.questions;
      // Merging Fixed and Options into objects
      // TODO: database migration + survey app changes
      if (
        questions.length > 0 &&
        questions[0].options &&
        questions[0].options.length > 0 &&
        typeof questions[0].options[0] === "string"
      ) {
        if (questions[0].fixed) {
          questions.map((q) => {
            return (q.options = q.options.map((o, oidx) => {
              return {
                text: o,
                fixed: q.fixed[oidx],
                skip: q.optionProps ? q.optionProps[oidx].skip : 0,
              };
            }));
          });
        } else {
          questions.map((q) => {
            q.options = q.options.map((o) => {
              return { text: o, fixed: false };
            });
          });
        }
        // If new props have been added to the question prototype, merge them
        // into the existing question object with their default values
        this.props.currentSurvey.questions = questions.map((q) => {
          return { ...getBlankQuestion(), ...q };
        });
      }
      this.setState(
        {
          ...this.state,
          info: { ...this.state.info, ...this.props.currentSurvey },
        },
        () => {
          // Save new changes to mongo if not new survey
          if (prevProps.currentSurvey) {
            this.onSaveStay(() =>
              this.setState({ previewId: this.state.previewId + 1 })
            );
          }
        }
      );
    }
    this.props.setPageTitle(
      <div className="breadcrumbs-title">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit">Survey Builder</Link>
          {this.state.info.name && (
            <Link color="inherit">
              {truncateStringWithEllipsis(this.state.info.name, 36)}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  getQuestionIdx = (id) => {
    /* The welcome screen uses a questionId of undefined */
    if (id === undefined) return undefined;

    const index = this.state.info.questions.findIndex(
      (question) => question.questionId === id
    );
    return index === -1 ? undefined : index;
  };

  changeQInfo = (qinfo, id, refreshPreview) => {
    var questions = _.cloneDeep(this.state.info.questions);
    const idx = this.getQuestionIdx(id);
    if (idx === undefined) return;
    questions[idx] = qinfo;
    this.setState(
      {
        info: {
          ...this.state.info,
          questions: questions,
        },
      },
      () => {
        this.onSaveStay(() => {
          this.previewQuestion(id, refreshPreview);
        });
      }
    );
  };

  checkQuestionLimit = (length) => {
    // Free tier restricts at 10 questions
    if (!this.props.user) return;
    if (
      this.props.user.tier !== "PREMIUM" &&
      this.props.user.tier !== "ENTERPRISE" &&
      length >= 10
    ) {
      notification(
        "Too many questions.",
        "Please upgrade to Premium to add more than ten questions to your survey.",
        "info"
      );
      return false;
    }
    return true;
  };
  addQuestion = (idx = undefined, type = undefined) => {
    track(`[Builder V2] Adding Question: ${type}`, {
      surveyId: this.state.info._id,
    });
    var questions = this.state.info.questions;
    if (!this.checkQuestionLimit(questions.length)) return;
    idx = idx ? idx : questions.length;
    var newQuestion = { ...getBlankQuestion(), newQuestion: true };
    if (type !== undefined) newQuestion.type = type;
    questions.splice(idx, 0, newQuestion);
    this.setState(
      {
        info: {
          ...this.state.info,
          questions: questions,
        },
        /* Focus the input field of the new question */
        selectedQuestionId: newQuestion.questionId,
        questionFocusOverride: FocusStates.SET_FOCUS,
      },
      () => {
        this.onSaveStay(() => {
          this.previewQuestion(newQuestion.questionId, true);
        });
      }
    );
  };

  duplicateQuestion = (idx) => {
    var questions = this.state.info.questions;
    if (!this.checkQuestionLimit(questions.length)) return;

    /* Duplicate question is inserted into the array at the index immediately
     * following the source question */
    const newQuestionId = getObjectId();
    questions.splice(idx + 1, 0, {
      ..._.cloneDeep(questions[idx]),
      questionId: newQuestionId,
    });

    this.setState(
      {
        info: {
          ...this.state.info,
          questions: questions,
        },
        /* Focus the input field of the new question */
        selectedQuestionId: newQuestionId,
        questionFocusOverride: FocusStates.SET_FOCUS,
      },
      /* Push the survey to the backend and render the new question */
      () => {
        this.onSaveStay(() => {
          this.previewQuestion(newQuestionId, true);
        });
      }
    );
  };
  removeQuestion = (idx) => {
    /* After removing the question, render the actively selected question (if
     * available) or the question immediately before the removed question */
    const removeId = this.state.info.questions[idx].questionId;
    let renderId = this.state.selectedQuestionId;

    if (renderId === removeId || renderId === undefined) {
      const renderIdx = idx !== 0 ? idx - 1 : 1;
      renderId =
        renderIdx < this.state.info.questions.length
          ? this.state.info.questions[renderIdx].questionId
          : undefined;
    }

    this.setState(
      (prevState) => {
        var questions = prevState.info.questions;
        questions.splice(idx, 1);
        return {
          ...prevState,
          info: {
            ...prevState.info,
            questions: questions,
          },
          /* Remove focus from all components */
          selectedQuestionId: renderId,
          questionFocusOverride: FocusStates.BLUR_ALL,
        };
      },
      () => {
        this.onSaveStay(() => {
          this.previewQuestion(renderId, true);
        });
      }
    );
  };
  onSubmit = () => {
    this.setState({ publishPending: true });
    this.props.deploySurvey(this.state.info._id);
    // Quick timeout to make sure survey changes propagate
    sleep(100).then(() => {
      this.setState(
        { publishPending: false },
        this.props.history.push(`/surveys`)
      );
    });
  };
  onSaveStay = (callback) => {
    const questions = this.state.info.questions.map((q) => {
      var options = [],
        fixed = [];
      q.options.forEach((o) => {
        options.push(o.text);
        fixed.push(o.fixed);
      });
      var newQ = { ...q, newQuestion: undefined };
      newQ.options = options;
      newQ.fixed = fixed;
      newQ.optionProps = q.options;
      return newQ;
    });
    const surveyQuestions = {
      ...this.state.info,
      questions: questions,
    };
    this.props.startLoading(
      () =>
        this.props.updateSurveyQuestions(
          this.state.surveyId,
          surveyQuestions,
          callback
        ),
      "CreateSurvey"
    );
  };

  previewQuestion = (id, forceRender = false) => {
    this.setState((state) => {
      /* Preview window requires indices not identifiers */
      const previewIdx = this.getQuestionIdx(id);

      return {
        selectedQuestionId: id,
        previewQuestionIdx: previewIdx,
        questionFocusOverride: FocusStates.NONE,
        ...(forceRender &&
          this.state.autoRefresh && { previewId: state.previewId + 1 }),
        /* Close question config panel if welcome screen is selected */
        ...(!id && { isQuestionConfigOpen: false }),
      };
    });
  };

  addQuestionOption = (q_idx, idx, numOptions = 1) => {
    let question = _.cloneDeep(this.state.info.questions[q_idx]);
    let blankOptions = [];
    for (let i = 0; i < numOptions; i++) {
      blankOptions.push({
        text: "",
        fixed: false,
        skip: 0,
      });
    }
    if (idx + 1 === question.options.length)
      question.options = [...question.options, ...blankOptions];
    else
      question.options = [
        ...question.options.slice(0, idx + 1),
        ...blankOptions,
        ...question.options.slice(idx + 1),
      ];
    this.setState({
      info: {
        ...this.state.info,
        questions: [
          ...this.state.info.questions.slice(0, q_idx),
          question,
          ...this.state.info.questions.slice(q_idx + 1),
        ],
      },
    });
  };
  removeQuestionOption = (q_idx, o_idx) => {
    var questions = this.state.info.questions;
    questions[q_idx].options.splice(o_idx, 1);
    this.setState({
      info: {
        ...this.state.info,
        questions: questions,
      },
    });
  };
  moveQuestionOptionUp = (q_idx, idx) => {
    if (idx === 0) return;
    var questions = this.state.info.questions;
    const option = questions[q_idx].options[idx];
    questions[q_idx].options.splice(idx, 1);
    questions[q_idx].options.splice(idx - 1, 0, option);
    this.setState({
      info: {
        ...this.state.info,
        questions: questions,
      },
    });
  };
  moveQuestionOptionDown = (q_idx, idx) => {
    if (idx === this.state.info.questions[q_idx].options.length - 1) return;
    var questions = this.state.info.questions;
    const option = questions[q_idx].options[idx + 1];
    questions[q_idx].options.splice(idx + 1, 1);
    questions[q_idx].options.splice(idx, 0, option);
    this.setState({
      info: {
        ...this.state.info,
        questions: questions,
      },
    });
  };

  changeDemo = (e, demo) => {
    this.setState(
      {
        info: {
          ...this.state.info,
          demographics: {
            ...this.state.info.demographics,
            [demo]: e.target.checked,
          },
        },
      },
      () => {
        this.onSaveStay(() => {
          this.setState({ previewId: this.state.previewId + 1 });
        });
      }
    );
  };
  nextTab = () => {
    this.setState({
      tab: tabOptions[
        (tabOptions.indexOf(this.state.tab) + 1) % tabOptions.length
      ],
    });
  };
  changeInfo = (info, refreshPreview) => {
    this.setState(
      {
        info: { ...this.state.info, ...info },
      },
      () => {
        this.onSaveStay(() => {
          if (refreshPreview) {
            this.setState({
              previewId: this.state.previewId + 1,
              previewQuestionIdx: this.getQuestionIdx(
                this.state.selectedQuestionId
              ),
            });
          }
        });
      }
    );
  };

  openQuestionConfig = () => {
    this.setState({ isQuestionConfigOpen: true });
  };

  closeQuestionConfig = () => {
    this.setState({ isQuestionConfigOpen: false });
  };

  showPreview = () => {
    track("[Builder V2] Opening Preview");
    var url = new URL(`https://survey.phonic.ai/${this.state.surveyId}`);
    if (this.state.info.state !== "DRAFT")
      url.searchParams.set("preview", true);
    url.searchParams.set("staging", true);
    window.open(url, "_blank");
  };

  changeTabs = (e, v) => {
    track(`[Builder V2] Changing Tabs: ${v}`);
    this.setState({
      tab: v,
    });
  };
  pushSurveyUpdates = () => {
    this.props.updateSurveyRedux(this.state.info);
  };

  templateSurveySwitch = (createNewOrContinue) => {
    this.setState({
      ...this.state,
      popupOptions: {
        isTemplate: false,
        mainView: false,
        createSurveyFocus: createNewOrContinue,
      },
    });
  };

  turnOffMainView = () => {
    this.setState({
      popupOptions: { ...this.state.popupOptions, mainView: false },
    });
  };

  formatDurationString = () => {
    const times = this.props.estimatedCompletionTimeMinutes;

    if (times.upperBound !== times.lowerBound) {
      return `${times.lowerBound} - ${times.upperBound} min`;
    } else {
      return `${times.lowerBound} min`;
    }
  };

  toggleAutoRefresh = (autoRefreshOn) => {
    this.setState({
      previewId: this.state.previewId + 1,
      autoRefresh: autoRefreshOn,
    });
  };

  setPreview = (option) => {
    this.setState({ preview: option });
  };

  render() {
    const settingsClass = this.state.tab === "admin" ? "" : "display-none";
    const questionsClass = this.state.tab === "questions" ? "" : "display-none";
    const appearanceClass =
      this.state.tab === "appearance" ? "" : "display-none";
    const editorClass =
      this.state.tab === "appearance" || this.state.tab === "questions"
        ? ""
        : "display-none";

    const selectedQuestion = this.state.info.questions.find((question) => {
      return question.questionId === this.state.selectedQuestionId;
    });
    const showConfigPane = selectedQuestion && this.state.isQuestionConfigOpen;

    return (
      <>
        <Helmet>
          <title>Create Survey</title>
        </Helmet>
        <div id="survey-builder">
          {this.state.showPopup && (
            <TemplateSurveyPopup
              open={this.state.showPopup}
              handleClose={() => this.props.history.push("/surveys")}
              templateSurveySwitch={() =>
                this.setState({ isTemplate: !this.state.isTemplate })
              }
              isTemplate={this.state.isTemplate}
              backToMainView={() => {
                this.setState({
                  popupOptions: {
                    mainView: true,
                    isTemplates: true,
                    createSurveyFocus: null,
                  },
                });
              }}
              isMainView={this.state.popupOptions.mainView}
              filterTemplates={(key) => {
                this.setState({ searchKey: key });
              }}
            >
              {this.state.popupOptions.isTemplates ? (
                <Templates
                  isMainPage={this.state.popupOptions.mainView}
                  templateSurveySwitch={this.templateSurveySwitch}
                  turnOffMainView={this.turnOffMainView}
                  searchKey={this.state.searchKey}
                />
              ) : (
                <CreateSurveyPopupContent
                  focus={this.state.popupOptions.createSurveyFocus}
                />
              )}
            </TemplateSurveyPopup>
          )}

          <ConfirmationPopup
            open={this.state.confirmationPopup}
            onClose={() => this.setState({ confirmationPopup: false })}
            title="Ready to publish your changes?"
            subtext="These changes will be reflected in your live Phonic survey."
            action={this.onSubmit}
          />
          <div>
            <div id="header">
              <Tabs
                id="tabs"
                className="create-survey-tabs nomargin"
                value={this.state.tab}
                onChange={this.changeTabs}
                TabIndicatorProps={{ style: { background: "#51a871" } }}
              >
                <Tab label="Questions" value="questions" />
                <Tab label="Appearance" value="appearance" />
                <Tab label="Settings" value="admin" />
              </Tabs>
              <div className="action-buttons">
                {this.props.loading && (
                  <div id="saving-spinner">
                    <ClipLoader size={16} style={{ color: "black" }} />
                    &nbsp;Saving
                  </div>
                )}
                {this.props.estimatedCompletionTimeMinutes &&
                  this.props.estimatedCompletionTimeMinutes.lowerBound > 0 && (
                    <p id="duration-estimate">{this.formatDurationString()}</p>
                  )}
                <PreviewSettings
                  icon={<FontAwesomeIcon icon={faCog} />}
                  autoRefresh={this.state.autoRefresh}
                  toggleAutoRefresh={this.toggleAutoRefresh}
                  preview={this.state.preview}
                  setPreview={this.setPreview}
                  previewOptions={previewOptions}
                />
                <Button
                  className="preview-outlined-button"
                  variant="outlined"
                  color="primary"
                  onClick={this.showPreview}
                >
                  <FontAwesomeIcon icon={faEye} />
                  &nbsp;Preview
                </Button>
                <Button
                  id="publish-button"
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ confirmationPopup: true })}
                >
                  {!this.state.publishPending ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />
                      &nbsp;Publish{" "}
                    </>
                  ) : (
                    <CircularProgress size={22} style={{ color: "white" }} />
                  )}
                </Button>
              </div>
            </div>

            {!this.props.currentSurvey ? (
              <div className="loader-container">
                {this.props.match.params.surveyId && (
                  <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
                )}
              </div>
            ) : (
              <div>
                <div className={settingsClass}>
                  <SurveySettings
                    info={this.state.info}
                    surveyId={this.state.surveyId}
                    tier={this.props.user ? this.props.user.tier : "FREE"}
                    changeDemo={this.changeDemo}
                    changeInfo={this.changeInfo}
                    pushSurveyUpdates={this.pushSurveyUpdates}
                  />
                </div>
                <div className={editorClass}>
                  <Grid container>
                    <Grid item xs={12} sm={5} id="editor">
                      <div className={questionsClass}>
                        <SurveyQuestions
                          submit={this.submit}
                          questions={this.state.info.questions}
                          changeInfo={this.changeInfo}
                          selectedQuestionId={this.state.selectedQuestionId}
                          questionFocusOverride={
                            this.state.questionFocusOverride
                          }
                          surveyProps={{
                            id: this.state.surveyId,
                            randomGroups: this.state.info.randomGroups,
                            tier: this.props.user
                              ? this.props.user.tier
                              : "FREE",
                            state: this.state.info.state,
                            title: this.state.info.title,
                            subTitle: this.state.info.subTitle,
                          }}
                          info={this.state.info}
                          modFunctions={{
                            changeQInfo: this.changeQInfo,
                            changeInfo: this.changeInfo,
                            addQuestion: this.addQuestion,
                            removeQuestion: this.removeQuestion,
                            addQuestionOption: this.addQuestionOption,
                            removeQuestionOption: this.removeQuestionOption,
                            moveQuestionOptionUp: this.moveQuestionOptionUp,
                            moveQuestionOptionDown: this.moveQuestionOptionDown,
                            pushSurveyUpdates: this.pushSurveyUpdates,
                            duplicateQuestion: this.duplicateQuestion,
                            openQuestionConfig: this.openQuestionConfig,
                            closeQuestionConfig: this.closeQuestionConfig,
                            previewQuestion: this.previewQuestion,
                          }}
                        />
                      </div>
                      <div className={appearanceClass}>
                        <SurveyAppearance
                          survey={this.state.info}
                          surveyId={this.state.surveyId}
                          changeInfo={this.changeInfo}
                          pushSurveyUpdates={this.pushSurveyUpdates}
                          user={this.props.user}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm={showConfigPane ? 4 : 7}
                      xl={showConfigPane ? 5 : 7}
                      id="viewer"
                    >
                      {this.state.info._id &&
                        this.state.preview === "default" && (
                          <iframe
                            key={this.state.previewId}
                            id="preview"
                            src={`https://survey.phonic.ai/${
                              this.state.info._id
                            }?preview=true&staging=true${
                              this.state.previewQuestionIdx !== undefined
                                ? `&page=${this.state.previewQuestionIdx}`
                                : ""
                            }`}
                            allow="microphone;camera"
                            title="survey preview"
                          ></iframe>
                        )}
                      <Paper
                        className={clsx(
                          "share-preview",
                          "builder",
                          this.state.preview === "default" && "hidden"
                        )}
                      >
                        <EmbedPreview
                          surveyId={this.state.surveyId}
                          embed={this.state.preview}
                          previewId={this.state.previewId}
                          page={this.state.previewQuestionIdx}
                        />
                      </Paper>
                    </Grid>
                    {showConfigPane && (
                      <Grid item xs={12} sm={3} xl={2}>
                        <QuestionConfiguration
                          surveyInfo={this.state.info}
                          changeQInfo={this.changeQInfo}
                          changeInfo={this.changeInfo}
                          id={this.state.selectedQuestionId}
                          qinfo={selectedQuestion}
                          closeQuestionConfig={() => this.closeQuestionConfig()}
                          tier={this.props.user ? this.props.user.tier : "FREE"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);

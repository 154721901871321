import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

// We can inject some CSS into the DOM.
const styles = {
  root: {
    borderRadius: 5,
    border: 0,
    height: 60,
    width: 200,
    padding: "0 20px",
    marginBottom: 4,
    fontSize: 20,
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 800,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      boxShadow: "none",
    },
  },
};

function ClassNames(props) {
  const { classes, children, className, ...other } = props;

  return (
    <Button
      className={clsx(classes.root, className, "record-button")}
      {...other}
    >
      {children || "class names"}
    </Button>
  );
}

ClassNames.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(ClassNames);

import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

export default class FreeTrialAlert extends React.Component {
  render() {
    return (
      <Alert
        severity={this.props.user.trialExpired ? "warning" : "info"}
        style={{
          border: this.props.user.trialExpired
            ? "1px #ff9800 solid"
            : "1px #2196f3 solid",
        }}
        action={
          <CircularProgress
            variant="determinate"
            style={{
              marginRight: "3vw",
            }}
            size={50}
            value={(100 * this.props.user.trialDaysLeft) / 14}
          />
        }
      >
        <AlertTitle>
          {`Your have ${this.props.user.trialDaysLeft} day${
            this.props.user.trialDaysLeft !== 1 ? "s" : ""
          } left on your free
          trial.`}
        </AlertTitle>
        <div style={{ marginBottom: 12 }}>
          Whether you're a individual researcher, student, or enterprise looking
          to scale, Phonic has a plan for you.
        </div>
        <Button
          className="secondary-button-color"
          margin="normal"
          variant="contained"
          style={{ marginRight: 12 }}
          onClick={this.props.openUpgradePopup}
        >
          {"Upgrade Plan"}
        </Button>
        <Button
          target="_blank"
          margin="normal"
          variant="outlined"
          className="outlined-button-color"
          href="https://phonic.ai/pricing"
        >
          {"View Pricing"}
        </Button>
      </Alert>
    );
  }
}

import { Grid, Breadcrumbs, Link } from "@material-ui/core";
import ConfirmationPopup from "components/Popups/ConfirmationPopup";
import CreateShowreelPopup from "components/Popups/CreateShowreelPopup";
import ShowreelsSettingsPopup from "components/Popups/ShowreelsSettingsPopup";
import ShowreelsSubtitlesPopup from "components/Popups/ShowreelSubtitlesPopup";
import ContentViewer from "components/Showreels/ContentViewer";
import ShowreelPreview from "components/Showreels/ShowreelPreview";
import Timeline from "components/Showreels/Timeline";
import _ from "lodash";
import React, { Component } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { getBlankShowreel } from "utils/defaults";
import { reorder } from "utils/utils";
import { getFormattedTime } from "utils/utils";
import { truncateStringWithEllipsis } from "utils/utils";
import { getObjectId } from "utils/utils";
import {
  createShowreel,
  getShowreel,
  updateShowreel,
  setPageTitle,
  exportShowreel,
  updateShowreelRedux,
  clearCurrentShowreel,
  uploadShowreelFile,
} from "../redux/actions";

const mapStateToProps = (state) => {
  return {
    showreel: state.currentShowreel,
    user: state.user,
    loadingNewShowreel: state.loading ? state.loading.CreateShowreel : false,
    loadingShowreelImage: state.loading
      ? state.loading.ShowreelImageUpload
      : false,
  };
};

const mapDispatchToProps = {
  createShowreel,
  getShowreel,
  updateShowreel,
  setPageTitle,
  exportShowreel,
  updateShowreelRedux,
  clearCurrentShowreel,
  uploadShowreelFile,
};

class CreateShowreel extends Component {
  state = {
    showreelPopupOpen: false,
    showreel: getBlankShowreel(),
    clipIdx: 0,
    clipTime: 0,
    playing: false,
    trimming: false,
    durations: {},
  };

  constructor(props) {
    super(props);
    if (this.props.match.params.showreelId) {
      this.props.getShowreel(this.props.match.params.showreelId);
    } else {
      this.state.showreelPopupOpen = true;
    }
  }

  componentWillUnmount() {
    this.stopPollJobStatus();
    this.props.clearCurrentShowreel();
  }

  createShowreelCallback = (showreel) => {
    this.setState({
      showreelPopupOpen: false,
      showreel: { ...showreel },
    });
    this.props.history.push(`/showreels/${showreel._id}/create`);
    this.setPageTitle(showreel.name);
  };

  updateTopLeftImage = (data) => {
    this.setState({
      showreel: {
        ...this.state.showreel,
        topLeftImageUrl: data.public_url,
      },
    });
  };

  submitTopLeftImage = (file) => {
    this.props.uploadShowreelFile(
      this.props.showreel._id,
      file,
      this.updateTopLeftImage
    );
  };

  setPageTitle = (name) => {
    this.props.setPageTitle(
      <div className="breadcrumbs-title">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => this.props.history.push("/showreels")}
          >
            Showreels
          </Link>
          <Link color="inherit">{truncateStringWithEllipsis(name, 36)}</Link>
        </Breadcrumbs>
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showreel !== this.props.showreel) {
      this.setState({ showreel: this.props.showreel });
      this.setPageTitle(this.props.showreel.name);
      if (this.props.showreel.exportJobStatus === "PENDING") {
        this.pollJobStatus();
      } else if (
        this.props.showreel.exportJobStatus === "COMPLETED" &&
        this.jobStatusInterval
      ) {
        this.stopPollJobStatus();
      }
    }
  }

  pollJobStatus = () => {
    if (!this.jobStatusInterval) {
      this.jobStatusInterval = setInterval(
        () =>
          this.props.getShowreel(this.props.match.params.showreelId, [
            "exportJob",
            "path",
          ]),
        5000
      );
    }
  };
  stopPollJobStatus = () => {
    clearInterval(this.jobStatusInterval);
    this.jobStatusInterval = undefined;
  };

  contentDragEnd = (result) => {
    if (result.destination) {
      if (
        result.destination.droppableId === "TIMELINE" &&
        result.source.droppableId === "CONTENT"
      ) {
        const content = this.state.showreel.content
          ? Array.from(this.state.showreel.content)
          : [];
        // TODO: handle conversations, responses, and titles differently
        const newBlock = {
          blockId: getObjectId(),
          ...this.state.draggedResponse,
        };
        content.splice(result.destination.index, 0, newBlock);
        this.setState(
          {
            showreel: {
              ...this.state.showreel,
              content: content,
            },
          },
          this.saveShowreel
        );
      }
      if (
        result.destination.droppableId === "TIMELINE" &&
        result.source.droppableId === "TIMELINE"
      ) {
        const content = _.cloneDeep(
          reorder(
            this.state.showreel.content,
            result.source.index,
            result.destination.index
          )
        );
        this.setState(
          {
            showreel: {
              ...this.state.showreel,
              content: content,
            },
          },
          this.saveShowreel
        );
      }
    }
  };

  contentDragStart = (response) => {
    this.setState({ draggedResponse: response });
  };

  removeContent = (idx) => {
    this.setState((prevState) => {
      var content = prevState.showreel.content;
      content.splice(idx, 1);
      return {
        ...prevState,
        content: content,
        ...(prevState.clipIdx === idx && {
          clipIdx: prevState.clipIdx - 1,
          playing: false,
        }),
      };
    }, this.saveShowreel);
  };

  saveShowreel = () => {
    this.props.updateShowreelRedux(this.state.showreel);
    this.props.updateShowreel(
      this.props.match.params.showreelId,
      this.state.showreel
    );
  };
  exportShowreel = () => {
    this.props.exportShowreel(
      this.props.match.params.showreelId,
      this.pollJobStatus
    );
    this.setState({
      showreel: {
        ...this.state.showreel,
        exportJobStatus: "PENDING",
        exportJobProgress: 0,
      },
    });
  };

  routeToShowreels = () => {
    this.props.history.push("/showreels");
  };

  setClipIdx = (clipIdx, callback) => {
    this.setState({ trimming: false, clipIdx: clipIdx });
    if (callback) callback();
  };
  setPlaying = (playing) => {
    this.setState({ playing: playing });
  };
  setTrimming = (trimming, clipIdx) => {
    this.setState({
      trimming: trimming,
      ...(trimming && { playing: false }),
      ...(clipIdx && { clipIdx: clipIdx }),
    });
  };
  setShowreelInfo = (prop, value, save) => {
    this.setState(
      { showreel: { ...this.state.showreel, [prop]: value } },
      () => {
        if (save) this.saveShowreel();
      }
    );
  };
  setContent = (idx, data) => {
    this.setState(
      ({ showreel }) => ({
        showreel: {
          content: [
            ...showreel.content.slice(0, idx),
            {
              ...showreel.content[idx],
              ...data,
            },
            ...showreel.content.slice(idx + 1),
          ],
        },
      }),
      this.saveShowreel
    );
  };
  openSettingsPopup = (open) => {
    this.setState({ settingsPopupOpen: open });
  };
  openSubtitlesPopup = (open) => {
    this.setState({ subtitlesPopupOpen: open });
  };
  openPublicPopup = (open) => {
    this.setState({ publicPopupOpen: open });
  };

  setDuration = (clipId, duration) => {
    this.setState({
      durations: { ...this.state.durations, [clipId]: duration },
    });
  };

  setClipTime = (time, callback) => {
    this.setState({ clipTime: time });
    if (callback) callback();
  };

  getDurationSum(clipIdx) {
    let values = Object.values(this.state.durations);
    if (clipIdx !== undefined) {
      values = values.slice(0, this.state.clipIdx);
    }
    return values.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
  }
  getClipTime() {
    if (
      this.state.clipIdx === undefined ||
      !this.state.showreel ||
      !this.state.showreel.content ||
      !this.state.showreel.content[this.state.clipIdx]
    )
      return 0;
    const blockContent = this.state.showreel.content[this.state.clipIdx];
    let time = this.state.clipTime;
    if (blockContent.startTimeSec !== undefined)
      time -= blockContent.startTimeSec;
    return time;
  }

  render() {
    return (
      <div id="showreels">
        <ShowreelsSettingsPopup
          open={this.state.settingsPopupOpen}
          onClose={() => this.openSettingsPopup(false)}
          showreel={this.state.showreel}
          submitTopLeftImage={this.submitTopLeftImage}
          setShowreelInfo={this.setShowreelInfo}
          loadingShowreelImage={this.props.loadingShowreelImage}
          user={this.props.user}
          saveShowreel={this.saveShowreel}
        />
        <CreateShowreelPopup
          open={this.state.showreelPopupOpen}
          createShowreel={(name) => {
            this.props.createShowreel(
              { ...getBlankShowreel(), name: name },
              this.createShowreelCallback
            );
          }}
          onClose={this.routeToShowreels}
          loading={this.props.loadingNewShowreel}
        />
        <ShowreelsSubtitlesPopup
          open={this.state.subtitlesPopupOpen}
          onClose={() => this.openSubtitlesPopup(false)}
          showreel={this.state.showreel}
          setShowreelInfo={this.setShowreelInfo}
          clipIdx={this.state.clipIdx}
        />
        <ConfirmationPopup
          open={this.state.publicPopupOpen}
          onClose={() => {
            this.openPublicPopup(false);
          }}
          title="Allow sharing?"
          subtext="Enabling this will provide you with a public, sharable link to your showreel output."
          action={() => {
            this.setShowreelInfo("public", true, true);
            this.openPublicPopup(false);
            window.open(
              `/showreels/${this.props.showreel._id}/share`,
              "_blank"
            );
          }}
        />
        <DragDropContext onDragEnd={this.contentDragEnd}>
          <div className="showreels-top-container">
            <Grid container>
              <Grid item xs={12} sm={6} id="showreels-content">
                <ContentViewer contentDragStart={this.contentDragStart} />
              </Grid>
              <Grid item xs={12} sm={6} id="showreels-preview">
                <ShowreelPreview
                  showreel={this.state.showreel}
                  clipIdx={this.state.clipIdx}
                  playing={this.state.playing}
                  trimming={this.state.trimming}
                  setClipIdx={this.setClipIdx}
                  setClipTime={this.setClipTime}
                  setPlaying={this.setPlaying}
                  setContent={this.setContent}
                  setTrimming={this.setTrimming}
                  exportShowreel={this.exportShowreel}
                  openSettingsPopup={this.openSettingsPopup}
                  openSubtitlesPopup={this.openSubtitlesPopup}
                  openPublicPopup={this.openPublicPopup}
                  user={this.props.user}
                  durations={this.state.durations}
                />
              </Grid>
            </Grid>
          </div>
          <div className="showreels-time-container flex-container">
            {this.state.durations && (
              <div className="time-container">
                <b>
                  {getFormattedTime(
                    this.getDurationSum(this.state.clipIdx) + this.getClipTime()
                  )}
                </b>{" "}
                / <b>{getFormattedTime(this.getDurationSum())}</b>
              </div>
            )}
          </div>
          <Droppable droppableId={"TIMELINE"} direction="horizontal">
            {(provided, snapshot) => (
              <div
                id="showreels-timeline"
                className="showreels-bottom-container"
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <Timeline
                  showreel={this.state.showreel}
                  setClipIdx={this.setClipIdx}
                  clipIdx={this.state.clipIdx}
                  playing={this.state.playing}
                  removeContent={this.removeContent}
                  setTrimming={this.setTrimming}
                  setDuration={this.setDuration}
                  setContent={this.setContent}
                  uploadShowreelFile={this.props.uploadShowreelFile}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateShowreel);

import React, { Component } from "react";
import { Button } from "@material-ui/core";

class AddQuestionButton extends Component {
  render() {
    return (
      <div className="question-outer-container">
        <div className="dummy-question-outer">
          <Button
            variant="contained"
            className="dummy-question-container secondary-button-color"
            onClick={this.props.action}
          >
            {this.props.text}
          </Button>
        </div>
      </div>
    );
  }
}

export default AddQuestionButton;

import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HighlightedTranscription from "../HighlightedTranscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { updateTranscription } from "utils/editTranscription";
import { connect } from "react-redux";
import { updateResponse, getShowreel, updateFile } from "redux/actions";
import { isTitleType } from "utils/utils";
import ContentEditable from "react-contenteditable";
import { getTranscriptionItemsAsString } from "utils/utils";

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  updateResponse,
  updateFile,
  getShowreel,
};

class ShowreelsSubtitlesPopup extends Component {
  state = {};

  constructor() {
    super();
    this.contentEditable = React.createRef();
  }

  debounceAction = (action) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      updateTimeout: setTimeout(() => {
        action();
      }, 500),
    });
  };

  saveTranscription = () => {
    if (this.state.localTranscriptionItems) {
      if (this.props.showreel.content[this.props.clipIdx].responseId) {
        this.props.updateResponse(
          this.props.showreel.content[this.props.clipIdx].response._id,
          {
            transcription_items: this.state.localTranscriptionItems,
          }
        );
        this.props.getShowreel(this.props.showreel._id);
      }
      if (this.props.showreel.content[this.props.clipIdx].fileId) {
        this.props.updateFile(
          this.props.showreel.content[this.props.clipIdx].fileId,
          {
            transcription_items: this.state.localTranscriptionItems,
          }
        );
        this.props.getShowreel(this.props.showreel._id);
      }
    }
    this.setState({ editing: false });
  };

  updateTranscription = (transcription) => {
    var existingItems = this.state.localTranscriptionItems;
    const updatedTranscriptionItems = updateTranscription(
      transcription,
      existingItems
    );
    this.setState({ localTranscriptionItems: updatedTranscriptionItems });
  };

  render() {
    return (
      <Dialog
        id="showreels-subtitles-popup"
        open={this.props.open}
        onClose={this.props.onClose}
        fullWidth
      >
        <div className="x-button">
          <IconButton
            aria-label="close"
            onClick={this.props.onClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <h2>Edit Subtitles</h2>
          {this.state.editing ? (
            <>
              <ContentEditable
                innerRef={this.contentEditable}
                html={this.state.localEditableTranscriptionItems}
                onChange={(e) => {
                  this.setState({
                    localEditableTranscriptionItems: e.target.value,
                  });
                  this.debounceAction(() => {
                    this.updateTranscription(e.target.value);
                  });
                }}
              />
            </>
          ) : (
            <div className={"subtitles-container disabled"}>
              {this.props.showreel &&
                this.props.showreel.content &&
                this.props.clipIdx !== undefined &&
                this.props.showreel.content[this.props.clipIdx] &&
                !isTitleType(
                  this.props.showreel.content[this.props.clipIdx].type
                ) && (
                  <HighlightedTranscription
                    time={0}
                    onWordClick={() => {}}
                    transcription={
                      this.props.showreel.content[this.props.clipIdx].response
                        .features.transcription
                    }
                    transcriptionEdited={
                      this.props.showreel.content[this.props.clipIdx].response
                        .features.transcription_edited
                    }
                    transcriptionItems={
                      this.state.localTranscriptionItems
                        ? this.state.localTranscriptionItems
                        : this.props.showreel.content[this.props.clipIdx]
                            .response.features.transcription_items
                    }
                  />
                )}
            </div>
          )}

          <DialogActions>
            {!this.state.editing ? (
              <Button
                className="secondary-button-color"
                type="submit"
                variant="contained"
                onClick={() =>
                  this.setState({
                    editing: true,
                    localTranscriptionItems:
                      this.props.showreel.content[this.props.clipIdx].response
                        .features.transcription_items,
                    localEditableTranscriptionItems:
                      getTranscriptionItemsAsString(
                        this.props.showreel.content[this.props.clipIdx].response
                          .features.transcription_items
                      ),
                  })
                }
              >
                <FontAwesomeIcon icon={faUnlock} />
                &nbsp;Edit
              </Button>
            ) : (
              <Button
                className="secondary-button-color"
                type="submit"
                variant="contained"
                onClick={this.saveTranscription}
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowreelsSubtitlesPopup);

import React, { Component } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

export default class LineGraph extends Component {
  chartRef = React.createRef();
  chartInstance = null;

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chartData.data === null) {
      this.buildChart();
    }
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    if (this.chartInstance) this.chartInstance.destroy();
    this.chartInstance = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.chartData.labels,
        datasets: [
          {
            data: this.props.chartData.data,
            borderColor: this.props.backgroundColor,
            tension: 0.3,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: null,
            },
            type: "time",
            time: {
              displayFormats: {
                quarter: "MMM YYYY",
              },
              unit: "day",
              tooltipFormat: "ddd MMM D YYYY",
            },
            distribution: "series",
            scaleLabel: {
              display: this.props.xLabel ? true : false,
              labelString: this.props.xLabel,
            },
          },
          y: {
            grid: {
              display: null,
            },
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: this.props.yLabel ? true : false,
              labelString: this.props.yLabel,
            },
          },
        },
      },
    });
  };

  render() {
    return (
      <div>
        <canvas id="time-chart" className="basic-chart" ref={this.chartRef} />
      </div>
    );
  }
}

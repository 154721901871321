import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F7F7F8",
    padding: "8px 10px",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#E9E9EC",
    },
  },
  label: {
    color: "#575757",
  },
  header: {
    fontSize: "1.75rem",
  },
});

const Steps = ({ step, name, handleBack }) => {
  const classes = useStyles();

  return step === 0 ? null : (
    <Box display="flex" alignItems="center" pb={1} className={classes.header}>
      <Box pr={2}>
        <Button
          classes={{ root: classes.root, label: classes.label }}
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
        </Button>
      </Box>
      <span>{name}</span>
    </Box>
  );
};

export default Steps;

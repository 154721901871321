import React from "react";
import {
  DialogContent,
  DialogContentText,
  TextField,
  Button,
} from "@material-ui/core";
import { store } from "react-notifications-component";
import { withRouter } from "react-router-dom";
import {
  addSurvey,
  getDrafts,
  clearCurrentSurvey,
  getSurveys,
  getUser,
  startLoading,
} from "redux/actions";
import { connect } from "react-redux";
import { Table } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { getBlankSurvey } from "utils/defaults";

const mapStateToProps = (state) => {
  return {
    createSurveyId: state.createSurveyId,
    drafts: state.drafts,
    surveys: state.surveys,
    user: state.user,
    loading: state.loading["CreateSurvey"],
  };
};

const mapDispatchToProps = {
  addSurvey,
  getDrafts,
  clearCurrentSurvey,
  getSurveys,
  getUser,
  startLoading,
};

class CreateSurveyPopup extends React.Component {
  state = {
    name: "",
    tag: "",
    summary: "",
    open: true,
    tab: "NEW",
  };

  componentDidMount() {
    this.props.clearCurrentSurvey();
    if (!this.props.drafts) this.props.getDrafts();
    if (!this.props.user) this.props.getUser();
    if (!this.props.surveys) this.props.getSurveys();
  }

  handleClose = () => {
    this.props.history.push("/surveys");
  };

  checkSurveyCount = () => {
    if (
      this.props.user.tier === "FREE" &&
      Object.keys(this.props.surveys).length >= 3
    ) {
      store.addNotification({
        message:
          "You've already created three surveys. To create more, consider upgrading your account to premium.",
        title: "Too many surveys.",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      return false;
    }
    return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.checkSurveyCount()) {
      this.setState({ loading: false });
      return;
    }
    const survey = {
      ...getBlankSurvey(),
      name: this.state.name,
      tag: this.state.tag,
    };
    this.props.startLoading(() => this.props.addSurvey(survey), "CreateSurvey");
  };

  fileInputChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  draftsTableData = () => {
    if (this.props.drafts && Object.keys(this.props.drafts).length > 0)
      return Object.values(this.props.drafts).map((s) => (
        <tr
          key={`DRAFTS-TABLE-${s._id}`}
          onClick={() => this.routeToSurveyCreate(s)}
        >
          <td>{s.name}</td>
          <td>{s.tag}</td>
          <td>{s.createdAt}</td>
        </tr>
      ));
  };

  routeToSurveyCreate = (survey) => {
    if (!this.checkSurveyCount()) return;
    this.props.history.push({
      pathname: `/create_survey/${survey._id}`,
    });
  };
  changeTabs = (e, v) => {
    this.setState({
      tab: v,
    });
  };

  render() {
    if (this.props.createSurveyId)
      this.props.history.push(`/create_survey/${this.props.createSurveyId}`);
    return (
      <div>
        {this.props.focus === "NEW" && (
          <>
            <DialogContent>
              <h3>Create Survey</h3>
              <DialogContentText className="dialog-text">
                Give your survey a name for future reference.
              </DialogContentText>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  variant="outlined"
                  autoFocus
                  label="Survey Name"
                  required
                  fullWidth
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  style={{ marginBottom: 16, marginTop: 8, float: "right" }}
                >
                  {!this.props.loading ? (
                    "Create Survey"
                  ) : (
                    <ClipLoader size={21} color={"white"} />
                  )}
                </Button>
              </form>
            </DialogContent>
          </>
        )}
        {this.props.focus === "DRAFTS" && (
          <div>
            <DialogContent>
              <h3>Continue Draft</h3>
              <DialogContentText className="dialog-text">
                Continue editing one of your existing surveys.
              </DialogContentText>
              <div className="draft-table-create-survey">
                <Table className="align-middle mb-0 table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Tag</th>
                      <th>Date Created</th>
                    </tr>
                  </thead>
                  <tbody>{this.draftsTableData()}</tbody>
                </Table>
              </div>
            </DialogContent>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateSurveyPopup)
);

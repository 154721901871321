import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

const StyledCheckbox = withStyles({
  root: {
    "&&:hover": {
      backgroundColor: "transparent",
    },
    cursor: "default",
  },
})(Checkbox);

const StyledRadio = withStyles({
  root: {
    "&&:hover": {
      backgroundColor: "transparent",
    },
    cursor: "default",
  },
})(Radio);

const StyledTypography = withStyles({
  root: {
    cursor: "default",
  },
})(Typography);

const SelectedOptions = ({ responseData, options, exclusive }) => (
  <Grid container spacing={1}>
    {options.map((option, i) => {
      let selection = null;

      if (exclusive) {
        selection = (
          <StyledRadio
            checked={responseData[i] ? true : false}
            name={`${option}`}
            disableRipple
          />
        );
      } else {
        selection = (
          <StyledCheckbox
            checked={responseData[i] ? true : false}
            name={`${option}`}
            disableRipple
          />
        );
      }

      return (
        <Grid container item xs={6} md={3} key={option} alignItems="center">
          <FormControlLabel
            control={selection}
            label={
              <StyledTypography variant="body2">{`${option}`}</StyledTypography>
            }
          />
        </Grid>
      );
    })}
  </Grid>
);

export default SelectedOptions;

import React, { useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Grid, Paper, Button, TextField, Popover } from "@material-ui/core";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTimes, faCode } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import { SketchPicker } from "react-color";
import { ReactComponent as InlineIcon } from "assets/img/inline.svg";
import { ReactComponent as PopoverIcon } from "assets/img/popover.svg";
import { ReactComponent as SideDrawerIcon } from "assets/img/side-drawer.svg";
import ShareCard from "./ShareCard";
import LinkTextField from "components/Text/LinkTextField";
import EmbedPreview from "components/EmbedPreview";
import { copyToClipboard, getContrastYIQ, getEmbedJSX } from "utils/utils";

const EMBED_TYPES = [
  {
    icon: <InlineIcon />,
    type: "inline",
    label: "Inline",
    text: "Embed your survey into an existing web page.",
  },
  {
    icon: <PopoverIcon />,
    type: "popover",
    label: "Popover",
    text: "Launch your survey as a floating popover, triggered by a button.",
    color: true,
  },
  {
    icon: <SideDrawerIcon />,
    type: "sidedrawer",
    label: "Side Drawer",
    text: "Launch your survey as a floating drawer, triggered by a tab.",
    color: true,
    helperText: true,
  },
];

const Share = ({ surveyId, linkClick }) => {
  const [currentEmbed, setCurrentEmbed] = useState({});
  const [helperText, setHelperText] = useState("");
  const [color, setColor] = useState("#62cb88");
  const [colorPickerEl, setColorPickerEl] = useState(undefined);
  const [justCopied, setJustCopied] = useState(false);

  const debounceText = debounce((text) => setHelperText(text), 500);
  const handleChange = (e) => {
    debounceText(e.target.value);
  };

  const handlePreview = (embed) => (_, isExpanded) => {
    setCurrentEmbed(isExpanded ? embed : {});
    if (justCopied) {
      setJustCopied(false);
    }
  };

  const closePreview = () => {
    setCurrentEmbed({});
  };

  const getCodeStr = (embed) => {
    return renderToStaticMarkup(
      getEmbedJSX({
        phonicEmbed: embed,
        surveyId,
        color,
        helperText,
      })
    );
  };

  const copyCode = (embed) => {
    copyToClipboard(getCodeStr(embed));
    setJustCopied(true);
    setTimeout(() => {
      setJustCopied(false);
    }, 2000);
  };

  return (
    <Grid id="share-screen" container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <div className="text-title mb-2">Share Survey</div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ShareCard
              onClick={(e) => linkClick(e, surveyId)}
              icon={
                <FontAwesomeIcon
                  icon={faLink}
                  size="2x"
                  className="mr-2"
                  style={{ color: "#1d1c29" }}
                />
              }
              title="Copy Link"
            >
              <LinkTextField
                id={`${surveyId}-SHARE}`}
                label="Survey Link"
                link={`https://survey.phonic.ai/${surveyId}`}
                onClick={(e) => linkClick(e, surveyId)}
              />
            </ShareCard>
          </Grid>
        </Grid>
        <div className="text-title mb-2 mt-2">Embed</div>
        <Grid container spacing={3}>
          {EMBED_TYPES.map((embed, i) => (
            <Grid item xs={12} key={`${embed.type}-${i}`}>
              <ShareCard
                onChange={handlePreview(embed)}
                icon={embed.icon}
                title={embed.label}
                text={embed.text}
                embed
                className="share-card"
                expanded={embed.label === currentEmbed.label}
              >
                <div className="embed-options">
                  <pre className="code-block">
                    <code>{getCodeStr(embed.type)}</code>
                  </pre>
                  <Button
                    className="preview-outlined-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => copyCode(embed.type)}
                    style={{ minWidth: "170px" }}
                  >
                    <FontAwesomeIcon icon={faCode} className="mr-1" />
                    {justCopied ? "Copied!" : "Copy embed code"}
                  </Button>
                  {embed.color && (
                    <div className="color-preview-wrapper">
                      <div>Set button color</div>
                      <div
                        className="color-preview-tile"
                        style={{
                          backgroundColor: color,
                        }}
                        onClick={(e) => setColorPickerEl(e.target)}
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            backgroundColor: getContrastYIQ(color, 170),
                          }}
                          className="triangle"
                        />
                      </div>
                    </div>
                  )}
                  {embed.helperText && (
                    <TextField
                      variant="outlined"
                      label="helper text"
                      onChange={handleChange}
                    />
                  )}
                </div>
              </ShareCard>
            </Grid>
          ))}
          <Popover
            open={Boolean(colorPickerEl)}
            anchorEl={colorPickerEl}
            onClose={() => setColorPickerEl(undefined)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SketchPicker
              color={color}
              onChangeComplete={(color) => setColor(color.hex)}
              presetColors={[
                "#62cb88",
                "#D0021B",
                "#F5A623",
                "#F8E71C",
                "#8B572A",
                "#7ED321",
                "#417505",
                "#BD10E0",
                "#9013FE",
                "#4A90E2",
                "#50E3C2",
                "#B8E986",
                "#000000",
                "#4A4A4A",
                "#9B9B9B",
                "#FFFFFF",
              ]}
            />
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper
          className={clsx("share-preview", !currentEmbed.type && "hidden")}
        >
          <span className="x-button" onClick={closePreview}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <EmbedPreview
            surveyId={surveyId}
            embed={currentEmbed.type}
            showOptions
            helperText={helperText}
            color={color}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Share;

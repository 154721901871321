import React from "react";
import Grid from "@material-ui/core/Grid";

const NewTopicTile = ({ handleClick }) => {
  return (
    <Grid
      item
      onClick={handleClick}
      className="main-survey-tile main-survey-new new-topic-tile"
    >
      <div className="inline-center">
        <p
          style={{
            fontSize: 26,
            fontWeight: 700,
            marginBottom: -5,
            marginTop: -10,
          }}
        >
          +
        </p>
        <h3 style={{ fontSize: 22 }}>New Topic</h3>
      </div>
    </Grid>
  );
};

export default NewTopicTile;

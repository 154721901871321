import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Translation from "./../SurveyCreation/Translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

class TranslationQuestionPopup extends React.Component {
  state = {
    translations: [],
  };

  componentDidMount() {
    this.addTranslationsToState();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      this.addTranslationsToState();
    }
  };

  addTranslationsToState = () => {
    var t = [];
    if (this.props.question.text) {
      if (this.props.question.translations) {
        t.push({
          ...this.props.question.translations.text,
          text: this.props.question.text,
        });
      } else {
        t.push({
          text: this.props.question.text,
          "": "",
        });
      }
    }
    if (this.props.question.subText) {
      if (this.props.question.translations) {
        t.push({
          ...this.props.question.translations.subText,
          text: this.props.question.subText,
        });
      } else {
        t.push({
          text: this.props.question.subText,
          "": "",
        });
      }
    }
    this.setState({
      translations: t,
    });
  };

  handleClose = () => {
    this.setState({ translations: [] });
    this.props.onClose();
  };

  handleSave = () => {
    var t = {};
    if (this.state.translations.length > 0) {
      t["text"] = this.state.translations[0];
    }
    if (this.state.translations.length > 1) {
      t["subText"] = this.state.translations[1];
    }
    this.props.updateQuestion({ translations: t });
    this.handleClose();
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Translations</DialogTitle>
          <DialogContent>
            <Translation
              translationInfo={this.state.translations}
              changeTranslationInfo={(t) => {
                this.setState({ translations: t });
              }}
              textEditable={false}
            />
            <DialogActions>
              <Button
                className="secondary-button-color"
                variant="contained"
                component="label"
                onClick={this.handleSave}
              >
                <FontAwesomeIcon icon={faSave} />
                &nbsp;Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default TranslationQuestionPopup;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Slider, Grid } from "@material-ui/core";
import { updateResponse, updateResponseLocal } from "redux/actions";
import { connect } from "react-redux";
import _ from "lodash";

const SENTIMENT_VALUES = ["Positive", "Negative", "Mixed", "Neutral"];

const mapDispatchToProps = {
  updateResponse,
  updateResponseLocal,
};

class EditSentimentPopup extends React.Component {
  state = {
    sentiments: { Positive: 0.0, Negative: 0.0, Mixed: 0.0, Neutral: 0.0 },
  };

  onSave = () => {
    const maxSentiment = Object.keys(this.state.sentiments)
      .reduce((a, b) => {
        return this.state.sentiments[a] > this.state.sentiments[b] ? a : b;
      })
      .toUpperCase();

    // Update a copy of the response to store locally
    let responseCopy = _.cloneDeep(this.props.response);
    if (!responseCopy.features) responseCopy.features = {};
    responseCopy.features.sentiment = maxSentiment;
    responseCopy.features.sentiment_score = this.state.sentiments;
    // Update DB and locally
    this.props.updateResponse(this.props.response._id, {
      sentiment: maxSentiment,
      sentiment_score: this.state.sentiments,
      sentiment_edited: true,
    });
    this.props.updateResponseLocal(
      this.props.response._id,
      this.props.response.questionId,
      responseCopy
    );
    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response) {
      if (
        this.props.response &&
        this.props.response.features &&
        this.props.response.features.sentiment_score
      ) {
        try {
          this.setState({
            sentiments: {
              Positive: this.props.response.features.sentiment_score.Positive,
              Negative: this.props.response.features.sentiment_score.Negative,
              Mixed: this.props.response.features.sentiment_score.Mixed,
              Neutral: this.props.response.features.sentiment_score.Neutral,
            },
          });
        } catch (err) {
          this.setState({
            sentiments: {
              Positive: 0.0,
              Negative: 0.0,
              Mixed: 0.0,
              Neutral: 0.0,
            },
          });
        }
      }
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            Edit Response Sentiment
          </h2>

          {SENTIMENT_VALUES.map((sentiment) => {
            return (
              <Grid key={sentiment} container spacing={3}>
                <Grid item>
                  <div className={`sentiment-slider-box ${sentiment}`}>
                    {sentiment}
                  </div>
                </Grid>
                <Grid item className="sentiment-slider">
                  <Slider
                    value={this.state.sentiments[sentiment] * 100.0}
                    onChange={(e, val) => {
                      this.setState({
                        sentiments: {
                          ...this.state.sentiments,
                          [sentiment]: val / 100.0,
                        },
                      });
                    }}
                    step={1}
                  />
                </Grid>
                <Grid item>
                  <div className="sentiment-slider-box">
                    {(this.state.sentiments[sentiment] * 100).toFixed(1)}%
                  </div>
                </Grid>
              </Grid>
            );
          })}

          <DialogActions>
            <Button
              variant="outlined"
              component="label"
              onClick={(e) => {
                this.props.onClose(e);
              }}
              className="Mui-Overrides outlined-dark"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={this.onSave}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default connect(undefined, mapDispatchToProps)(EditSentimentPopup);

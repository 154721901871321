import MySurveys from "views/MySurveys";
import Questions from "views/Questions";
import CreateSurvey from "views/CreateSurvey";
import Settings from "views/Settings";
import Support from "views/Support";
import Drafts from "views/Drafts";
import MyReports from "views/MyReports";
import BuildReport from "views/BuildReport";
import PreBuildReport from "views/PreBuildReport";
import PlayAudio from "views/PlayAudio";
import MyShowreels from "views/MyShowreels";
import CreateShowreel from "views/CreateShowreel";
import CreatePanelRequest from "views/CreatePanelRequest";
import MyPanelRequests from "views/MyPanelRequests";
import Files from "views/Files";

import {
  faChartPie,
  faCog,
  faComment,
  faCommentAlt,
  faDraftingCompass,
  faPlayCircle,
  faScroll,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

const dashboardRoutes = [
  {
    path: "/create_survey",
    name: "Create",
    component: CreateSurvey,
    showInNav: false,
    key: 0,
  },
  {
    path: "/surveys",
    name: "Surveys",
    icon: faScroll,
    component: MySurveys,
    showInNav: true,
    authenticated: true,
    key: 1,
  },
  {
    path: "/surveys/:surveyId/respondents/:sessionObjectId",
    name: "Questions",
    component: Questions,
    key: 2,
  },
  {
    path: "/surveys/:surveyId/:tab",
    name: "Questions",
    component: Questions,
    key: 2,
  },
  {
    path: "/surveys/:surveyId",
    name: "Questions",
    component: Questions,
    key: 2,
  },
  {
    path: "/surveys/:surveyId/questions/:questionId",
    name: "Question",
    component: Questions,
    key: 3,
  },
  {
    path: "/drafts",
    name: "Drafts",
    component: Drafts,
    icon: faDraftingCompass,
    showInNav: true,
    authenticated: true,
    key: 4,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: faChartPie,
    component: MyReports,
    showInNav: true,
    authenticated: true,
    key: 5,
  },
  {
    path: "/create_survey/:surveyId",
    name: "Create Survey",
    component: CreateSurvey,
    showInNav: false,
    key: 6,
  },
  {
    path: "/showreels",
    name: "Reels",
    icon: faPlayCircle,
    component: MyShowreels,
    showInNav: true,
    key: 14,
  },
  {
    path: "/showreels/create",
    name: "Create Showreel",
    component: CreateShowreel,
    showInNav: false,
    key: 14,
  },
  {
    path: "/showreels/:showreelId/create",
    name: "Create Showreel",
    component: CreateShowreel,
    showInNav: false,
    key: 14,
  },
  {
    path: "/create_showreel/:showreelId",
    name: "Create Showreel",
    component: CreateShowreel,
    showInNav: false,
    key: 14,
  },
  {
    path: "/files",
    name: "Convos",
    icon: faComment,
    component: Files,
    hiddenUnlessUserHas: "showConversations",
    key: 15,
  },
  {
    path: "/files/:path+" /* The + indicates path can contain forward slashes */,
    name: "Convos",
    component: Files,
    showInNav: false,
    matchNonExactly: true,
    key: 15,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: faCog,
    component: Settings,
    showInNav: true,
    key: 7,
  },
  {
    path: "/support",
    name: "Support",
    icon: faCommentAlt,
    component: Support,
    showInNav: false,
    key: 8,
  },
  {
    path: "/surveys/:surveyId/questions/:questionId/responses/:responseId",
    name: "Play",
    component: PlayAudio,
    showInNav: false,
    authenticated: false,
    key: 10,
  },
  {
    path: "/play/:responseId",
    name: "Play",
    component: PlayAudio,
    showInNav: false,
    authenticated: false,
    key: 11,
  },
  {
    path: "/reports/:reportId/build",
    name: "Report Builder",
    component: BuildReport,
    showInNav: false,
    key: 12,
  },
  {
    path: "/new_report",
    name: "New Report",
    component: PreBuildReport,
    showInNav: false,
    key: 13,
  },
  {
    path: "/recruit",
    name: "Recruit",
    icon: faUserFriends,
    component: MyPanelRequests,
    showInNav: true,
    key: 15,
  },
  {
    path: "/recruit/create",
    name: "Recruit",
    icon: faUserFriends,
    component: CreatePanelRequest,
    key: 15,
  },
  {
    path: "/recruit/create/:panelRequestId",
    name: "Recruit",
    icon: faUserFriends,
    component: CreatePanelRequest,
    key: 15,
  },
];

export default dashboardRoutes;

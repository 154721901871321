import React from "react";
import BookmkarIcon from "@material-ui/icons/Bookmark";
import ToggleButton from "@material-ui/lab/ToggleButton";

export default function BookmarkToggleButton(props) {
  const [selected, setSelected] = React.useState(false);
  return (
    <ToggleButton
      value="check"
      selected={selected}
      onChange={() => {
        setSelected(!selected);
        props.toggleBookmarkFilter(!selected);
      }}
    >
      <BookmkarIcon />
    </ToggleButton>
  );
}

import React from "react";
import {
  Paper,
  Grid,
  CircularProgress,
  TextField,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Button,
  Chip,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  isTextType,
  isVideoType,
  isAudioType,
  parseQuery,
  stringifyQuery,
  isOpenEndType,
  isAudioOrVideoType,
} from "utils/utils";
import Response from "./Response";
import { ScaleLoader } from "react-spinners";
import { getQuestionType } from "utils/questionTypes";
import { withRouter } from "react-router";
import { track } from "analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import pickBy from "lodash/pickBy";
import { isArray } from "lodash";

const NUM_PER_PAGE = 20;

class ResponseTable extends React.Component {
  state = { page: 1 };

  // TODO: Stop filtering the results here. It's work that's being done twice.
  getPagination = (numResults) => {
    if (!numResults) return undefined;
    return (
      <div>
        {numResults / NUM_PER_PAGE > 1 && (
          <Pagination
            count={Math.ceil(numResults / NUM_PER_PAGE)}
            onChange={this.changePage}
            page={this.state.page}
            size="small"
          />
        )}
      </div>
    );
  };

  getHeaders = () => {
    var headers = [];
    // Default headers
    headers.push(<TableCell key="BOOKMARK"></TableCell>);
    // External ID
    if (this.props.displayOptions.externalId)
      headers.push(<TableCell key="EXT-ID">EXTERNAL ID</TableCell>);
    if (this.props.displayOptions.public)
      headers.push(<TableCell key="PUBLIC">PUBLIC</TableCell>);
    // Audio Headers and Data
    if (
      isVideoType(this.props.question.type) ||
      isAudioType(this.props.question.type)
    ) {
      if (this.props.question.type.includes("VIDEO")) {
        headers.push(<TableCell key="VIDEO">VIDEO</TableCell>);
      } else {
        headers.push(<TableCell key="AUDIO">AUDIO</TableCell>);
      }
      if (
        this.props.question.doTranscription ||
        this.props.question.allowBackupText
      ) {
        headers.push(
          <TableCell key="TRANSCRIPT OR BACKUP TEXT">CONTENT</TableCell>
        );
      }
    }
    // Selection Stuff
    if (
      this.props.question.type.includes("SELECTION") ||
      this.props.question.type.includes("DROPDOWN")
    ) {
      headers.push(<TableCell key="SELECTION">SELECTION</TableCell>);
    }
    // Text Stuff
    if (isTextType(this.props.question.type)) {
      headers.push(<TableCell key="TEXT">RESPONSE</TableCell>);
    }
    // Likert Stuff
    if (this.props.question.type.includes("LIKERT")) {
      headers.push(<TableCell key="LIKERT">SELECTION</TableCell>);
    }
    // Ranking Stuff
    if (this.props.question.type.includes("RANKING")) {
      headers.push(<TableCell key="RANKING">RANKING</TableCell>);
    }
    // File Stuff
    if (this.props.question.type.includes("FILE")) {
      headers.push(<TableCell key="FILE">FILE</TableCell>);
    }
    // Info (Demographics)
    if (Object.keys(this.props.survey.demographics).length > 0) {
      headers.push(<TableCell key="DEMOGRAPHICS">INFO</TableCell>);
    }

    if (
      isAudioOrVideoType(this.props.question.type) &&
      this.props.question.doSentiment
    )
      headers.push(<TableCell key="SENTIMENT">SENTIMENT</TableCell>);
    if (
      (this.props.question.type.includes("AUDIO") ||
        this.props.question.type.includes("THINKALOUD")) &&
      this.props.question.doEmotion
    )
      headers.push(<TableCell key="EMOTION">EMOTION</TableCell>);
    return headers;
  };

  changePage = (e, value) => {
    this.props.history.push({
      search: stringifyQuery({
        ...parseQuery(this.props.location.search),
        page: value,
      }),
    });
    this.setState({ page: value });
  };

  handleDeleteTopic = (name) => {
    let entities = this.props.searchParams.entity;
    if (entities === name) entities = undefined;
    if (isArray(entities)) {
      entities = entities.filter((e) => e !== name);
    }
    this.props.history.push({
      search: stringifyQuery({
        ...this.props.searchParams,
        entity: entities,
      }),
    });
  };

  /**
   * TODO: These are old updating functions which need to be deprecated.
   * @param {*} prop The property to be updated.
   * @param {*} value The value of the display or filter option.
   */
  updateDisplayOptions = (prop, value) =>
    this.setState({
      displayOptions: {
        ...this.props.displayOptions,
        [prop]: value,
      },
    });
  updateFilters = (prop, value) =>
    this.setState({
      filters: { ...this.props.filters, [prop]: value },
    });
  submitDates = () => {
    const query = parseQuery(this.props.location.search);
    delete query.page;
    this.props.history.push({
      search: stringifyQuery({ ...query, ...pickBy(this.state.dates) }),
    });
    this.setState({ page: 1 });
  };
  changeDate = (key, value) => {
    track("[Question Viewer] Changed Date");
    this.setState(
      (prevState) => ({
        ...prevState,
        dates: { ...prevState.dates, [key]: value },
      }),
      () => this.submitDates()
    );
  };

  render() {
    const loadingColor = getQuestionType(this.props.question.type).color;
    const { responses, question, survey } = this.props;
    const { page } = this.state;
    const pageResponses = !responses
      ? undefined
      : responses.slice(
          (this.state.page - 1) * NUM_PER_PAGE,
          this.state.page * NUM_PER_PAGE
        );
    const params = parseQuery(this.props.location.search);
    const numResults = responses ? responses.length : 0;
    return (
      <>
        <Grid container className="mb-10">
          <Grid item xs={6} className="flex-container justify-left">
            {this.getPagination(numResults)}
            {this.props.loading && (
              <span className="circle-loader">
                <CircularProgress size={14} style={{ color: "#595c62" }} />
              </span>
            )}
            {!this.props.loading && this.props.responses && (
              <span>
                Showing {pageResponses.length} of {responses.length} responses
              </span>
            )}
            <div className="chip-container" style={{ marginLeft: "12px" }}>
              {typeof this.props.searchParams.entity == "string" && (
                <Chip
                  label={this.props.searchParams.entity}
                  onDelete={() =>
                    this.handleDeleteTopic(this.props.searchParams.entity)
                  }
                />
              )}
              {isArray(this.props.searchParams.entity) &&
                this.props.searchParams.entity.map((entity, i) => (
                  <Chip
                    label={entity}
                    onDelete={() => this.handleDeleteTopic(entity)}
                    key={`${entity}-${i}`}
                  />
                ))}
              {this.props.filters.sentiment && (
                <Chip
                  label={`Sentiment: ${this.props.filters.sentiment}`}
                  onDelete={() =>
                    this.props.updateFilters({ sentiment: undefined })
                  }
                />
              )}
            </div>
          </Grid>
          {isOpenEndType(this.props.question.type) && (
            <Grid item xs={6} className="flex-container justify-right">
              <Button
                variant="outlined"
                className="phonic-outlined-button mr-10"
                onClick={this.props.toggleTopics}
              >
                <FontAwesomeIcon icon={faTags} />
                &nbsp;Show Topics
              </Button>
              <TextField
                id="response-searchbar"
                variant="outlined"
                margin="dense"
                type="text"
                placeholder="Search Responses..."
                onChange={(e) =>
                  this.props.updateFilters({ searchText: e.target.value })
                }
                value={this.props.filters.searchText}
                style={{ marginTop: 0, marginBottom: 0 }}
              />
            </Grid>
          )}
        </Grid>
        <Paper id="response-table">
          {responses ? (
            <TableContainer>
              <Table>
                <TableHead>{this.getHeaders()}</TableHead>
                <TableBody>
                  {pageResponses.map((r, idx) => {
                    return (
                      <Response
                        key={`RESPONSE-${r._id}`}
                        num={idx + (page - 1) * NUM_PER_PAGE}
                        response={r}
                        question={question}
                        survey={survey}
                        displayOptions={this.props.displayOptions}
                        filters={this.props.filters}
                        closeResponsePopup={this.props.closeResponsePopup}
                        openReportsPopup={this.props.openReportsPopup}
                        openShowreelsPopup={this.props.openShowreelsPopup}
                        openResponseMenu={this.props.openResponseMenu}
                        entities={this.props.entities}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="flex-container p-10">
              <ScaleLoader
                width={"2px"}
                height={"30"}
                color={loadingColor ? loadingColor : "#62cb88"}
                loading={true}
              />
            </div>
          )}
        </Paper>
        <div className="mt-10">{this.getPagination(numResults)}</div>
      </>
    );
  }
}

export default withRouter(ResponseTable);

import { NodeExtension, ExtensionTag } from "@remirror/core";

/**
 * <space contenteditable="false">
 * {
 *   type: "space",
 * }
 */
class SpaceExtension extends NodeExtension {
  get name() {
    return "space";
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra) {
    return {
      inline: true,
      atomic: true,
      attrs: { ...extra.defaults() },
      toDOM: () => ["space", " "],
      parseDOM: [{ tag: "space" }],
    };
  }
}

export default SpaceExtension;

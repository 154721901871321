import React, { useMemo } from "react";
import { Remirror, useRemirror } from "@remirror/react";
import {
  DocExtension,
  ParagraphExtension,
  AnnotationExtension,
} from "remirror/extensions";
import debounce from "lodash/debounce";
import ItemExtension from "./ItemExtension";
import SpaceExtension from "./SpaceExtension";
import PlainTextCopyExtension from "./PlainTextCopyExtension";
import EditingComponent from "./EditingComponent";
import Cursor from "./Cursor";
import "remirror/styles/all.css";

/**
 * @param {List} initialValue: initial value of editor as Remirror doc json
 * @param {float} time: current time of video
 * @param {Function} onWordClick: callback to handle word click
 * @param {Function} updateTranscription: callback to update transcription
 * @param {Boolean} readOnly: whether editor is read only or not
 */
const Editor = ({
  initialValue,
  time,
  onWordClick,
  updateTranscription,
  readOnly,
  className,
}) => {
  const useRemirrorProps =
    typeof initialValue === "string"
      ? {
          extensions: [
            new PlainTextCopyExtension(),
            new DocExtension({ content: "text*" }), // doc does not support paragraphs
          ],
          content: initialValue,
          stringHandler: "text",
        }
      : {
          extensions: [
            new ItemExtension(),
            new SpaceExtension(),
            new ParagraphExtension({
              extraAttributes: {
                class: { default: "transcription-paragraph" },
              },
              nodeOverride: { content: "(item | space)*" },
            }),
            new PlainTextCopyExtension(),
            new AnnotationExtension(),
          ],
          content: { type: "doc", content: initialValue },
        };

  const { manager, state, setState } = useRemirror(useRemirrorProps);

  const handleUpdate = (docState) => {
    if (updateTranscription) {
      updateTranscription(docState);
    }
  };

  const debouncedUpdate = useMemo(() => debounce(handleUpdate, 1000), []);

  const handleChange = ({ tr, state }) => {
    let nextState = state;
    // Check if document content changed
    if (tr && tr.docChanged) {
      debouncedUpdate(state.doc);
    }

    /**
     * Update state to latest value. Remirror usually does this automatically,
     * but do it manually here because we're intercepting the change callback
     */
    setState(nextState);
  };

  return (
    <div className="remirror-theme">
      <Remirror
        manager={manager}
        state={state}
        onChange={handleChange}
        editable={!readOnly}
        attributes={{ spellcheck: false }}
      >
        <EditingComponent className={className} />
        <Cursor time={time} onWordClick={onWordClick} />
      </Remirror>
    </div>
  );
};

export default Editor;

import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  otherText: { margin: theme.spacing(2, 0, 0) },
}));

export default function SignUpForm(props) {
  const classes = useStyles();

  return (
    <Grid id="auth-screen" className="flex-in-center-fullscreen-90" container>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {props.organization && props.organization.logoUri && (
            <img
              src={props.organization.logoUri}
              className="login-logo"
              alt="Login logo"
            />
          )}
          {!props.organizationId && (
            <img
              src={require("../assets/img/Phonic_Logo_4x.png")}
              className="login-logo"
              alt="Login logo"
            />
          )}
          <h2>
            {props.organizationId ? "Create an Account" : "Start a Free Trial"}
          </h2>
          <p>
            Or{" "}
            <a
              href={
                props.organizationId
                  ? `/${props.organizationId}/signin`
                  : "/signin"
              }
            >
              sign in
            </a>{" "}
            if you already have an account.
          </p>
          <form className={classes.form} onSubmit={props.signUp}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={props.formChange.bind(this, "firstName")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={props.formChange.bind(this, "lastName")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={props.formChange.bind(this, "email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={props.formChange.bind(this, "password")}
                />
              </Grid>
              <Grid item xs={12} />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-button"
            >
              {props.userLoading ? (
                <CircularProgress size={20} className="signup" />
              ) : (
                "Continue"
              )}
            </Button>
          </form>
          {/* <hr /> */}
          <div id="section-header">
            <div style={{ marginTop: 10, color: "gray" }}>─── Or ───</div>
          </div>
          <Button
            fullWidth
            variant="contained"
            className="google-signin-button"
            onClick={props.googleSignIn}
          >
            <img
              id="google-icon"
              src={require("../assets/img/icons8-google.svg")}
              alt="google logo"
            />
            &nbsp; Sign up With Google
          </Button>
        </div>
      </Container>
    </Grid>
  );
}

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

class MySurveysPopup extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };
  openSample = () => {
    window.open(`https://survey.phonic.ai/5ed54cf8674d1ecb1d12f0bd`, "_blank");
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div className="x-button">
            <IconButton
              aria-label="close"
              onClick={this.handleClose}
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent className="popup-container">
            <h2 className="dialog-title" style={{ marginTop: 30 }}>
              <span role="img" aria-label="Wave">
                🎉
              </span>{" "}
              Welcome aboard,{" "}
              {this.props.user.firstName
                ? this.props.user.firstName
                : "newcomer"}
              !
            </h2>
            <ul style={{ marginTop: 30 }}>
              <li style={{ marginBottom: 10 }}>
                We've added a demo survey to your account. Check it out to see
                what makes Phonic surveys unique.
              </li>
              <li style={{ marginBottom: 10 }}>
                Create your own project by selecting <b>Create Survey</b> main
                survey page.
              </li>
              <li style={{ marginBottom: 10 }}>
                Don't hesitate to reach out to our support if you need it. We'll
                always be here for you in the bottom left.
              </li>
            </ul>
          </DialogContent>
          <DialogContent style={{ paddingBottom: 24 }}>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                component="label"
                onClick={this.handleClose}
              >
                Let's go!
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default MySurveysPopup;

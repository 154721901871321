import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PickTopic from "components/PickTopic";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const ApplyTopicPopup = ({
  open,
  closePopup,
  topics,
  allTopics,
  applyTopicToResponse,
  createNewTopic,
  responseId,
}) => {
  const [type, setType] = useState("existing");
  const [topic, setTopic] = useState(null);

  const [newTopic, setNewTopic] = useState("");
  const [error, setError] = useState({ invalid: false, generic: false });

  const handleChange = (evt, value) => {
    if (evt.target.name === "new") {
      setNewTopic(evt.target.value);
      if (/[*{}[\]/\\()+^]/gi.test(evt.target.value)) {
        setError({ ...error, invalid: true });
      } else {
        setError({ ...error, invalid: false });
      }
    } else if (type === "existing") {
      setTopic(value);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (type === "existing") {
      applyTopicToResponse(topic._id);
      closePopup();
      setTopic(null);
    } else if (type === "new") {
      const topicName = newTopic.trim();
      if (allTopics.some((e) => e.Text === topicName)) {
        setError({ ...error, generic: true });
      } else if (!error.invalid && !error.generic) {
        createNewTopic(topicName, responseId);
        setNewTopic("");
        closePopup();
      }
    }
  };

  const handleToggle = (_, newType) => {
    if (newType !== null) {
      setType(newType);
    }
  };

  const isButtonDisabled = () => {
    if (type === "existing") {
      return topic === null;
    } else if (type === "new") {
      return newTopic.trim() === "" || error.invalid || error.generic;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      onClick={(evt) => evt.stopPropagation()}
      aria-labelledby="add-topic"
      fullWidth
    >
      <DialogContent className="popup-container">
        <div id="form-dialog-title" style={{ marginBottom: 20 }}>
          <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
            Add Topics
          </h2>
          <p>
            Read more about tagging responses with topics{" "}
            <a
              href="https://docs.phonic.ai/docs/themes"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={handleToggle}
          aria-label="topic-type"
          style={{ marginBottom: "16px" }}
        >
          <ToggleButton value="existing" aria-label="existing-topic">
            Add existing topic
          </ToggleButton>
          <ToggleButton value="new" aria-label="new-topic">
            Create new topic
          </ToggleButton>
        </ToggleButtonGroup>
        <form onSubmit={handleSubmit}>
          {type === "existing" && (
            <PickTopic
              options={allTopics}
              disabledOptions={topics}
              item={topic}
              handleChange={handleChange}
              label="Pick existing topic"
              name="existing"
            />
          )}
          {type === "new" && (
            <TextField
              variant="outlined"
              autoFocus
              id="title"
              label="Topic Name"
              required
              fullWidth
              onChange={handleChange}
              helperText={
                error.generic
                  ? "Topic name already exists"
                  : error.invalid
                  ? "Topic name cannot include the characters: * { } [ ] / \\ ( ) ^ +"
                  : ""
              }
              error={error.generic || error.invalid}
              onFocus={() => setError({ ...error, generic: false })}
              name="new"
            />
          )}
          <span className="create-report-button">
            <Button
              variant="contained"
              type="submit"
              disabled={isButtonDisabled()}
              color="primary"
            >
              Add Topic
            </Button>
          </span>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyTopicPopup;

import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getDrafts,
  deleteSurvey,
  removeDraftFromState,
  setPageTitle,
} from "redux/actions";
import DeletePopup from "../components/Popups/DeletePopup";
import { trackPage } from "../analytics";
import { Helmet } from "react-helmet";
import { Paper, Grid, Chip, Table } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => {
  return {
    drafts: state.drafts,
    user: state.user,
  };
};

const mapDispatchToProps = {
  getDrafts,
  deleteSurvey,
  removeDraftFromState,
  setPageTitle,
};

class Drafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePopup: false,
      deleteId: undefined,
    };
    this.props.getDrafts();
  }
  componentDidMount() {
    trackPage("Drafts");
    this.props.setPageTitle();
  }

  getStateChipJSX() {
    return <Chip size="small" label="Draft" className="survey-draft" />;
  }

  routeToSurveyCreate(survey) {
    this.props.history.push({
      pathname: `/create_survey/${survey._id}`,
    });
  }

  // Copy text to clipboard
  linkClick = (event, id) => {
    event.stopPropagation();
    var text = document.getElementById(id);
    text.select();
    document.execCommand("copy");
    alert("Survey link has been copied to clipboard.\n\n" + text.value);
  };

  deleteDraft = () => {
    this.props.deleteSurvey(this.state.deleteId);
    this.props.removeDraftFromState(this.state.deleteId);
    this.onCloseConfirmDelete();
  };

  confirmDelete = (event, surveyId) => {
    event.stopPropagation();
    this.setState({
      deletePopup: true,
      deleteId: surveyId,
    });
  };
  onCloseConfirmDelete = () => {
    this.setState({
      deletePopup: false,
      deleteId: undefined,
    });
  };

  editDraft = (event) => {
    event.stopPropagation();
  };

  noDrafts = () => {
    if (this.props.drafts && Object.keys(this.props.drafts).length === 0)
      return (
        <tr onClick={() => this.props.history.push("/create_survey")}>
          <td align="center" colSpan="7">
            <br />
            <img
              src={require("../assets/img/no_drafts.svg")}
              alt="New survey"
              style={{ height: 200 }}
            ></img>
            <br />
            <br />
            <span>No drafts yet.</span>
            {this.props.user && this.props.user.trialExpired === false && (
              <span>
                {" "}
                <b>Click here</b> to get started.
              </span>
            )}
          </td>
        </tr>
      );
  };

  draftsTableData = () => {
    if (this.props.drafts && Object.keys(this.props.drafts).length > 0)
      return Object.values(this.props.drafts).map((s) => (
        <tr key={s._id} onClick={this.routeToSurveyCreate.bind(this, s)}>
          <td>{s.name}</td>
          <td>{s.tag}</td>
          <td>{s.questions.length}</td>
          <td>{s.createdAt}</td>
          <td>{this.getStateChipJSX()}</td>
          <td>
            <FontAwesomeIcon
              icon={faTrash}
              className="edit-icon"
              onClick={(event) => {
                this.confirmDelete(event, s._id);
              }}
            />
          </td>
        </tr>
      ));
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Drafts</title>
        </Helmet>
        <div className="content">
          <DeletePopup
            open={this.state.deletePopup}
            deleteFunction={this.deleteDraft}
            onClose={this.onCloseConfirmDelete}
            title={"Are you sure you'd like to delete this survey?"}
            subTitle={"This action cannot be undone."}
            buttonText={"Delete Survey"}
          />
          <Grid container className="screen-margin">
            <Grid item xs={6}>
              <h1 className="page-title">Drafts</h1>
            </Grid>
          </Grid>
          <div className="screen-margin">
            <Paper>
              <Grid container>
                <Grid item md={12}>
                  <Table className="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Tag</th>
                        <th># Questions</th>
                        <th>Date Created</th>
                        <th>State</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.draftsTableData()}
                      {this.noDrafts()}
                    </tbody>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Drafts));

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Paper, Grid } from "@material-ui/core";
import TimeChart from "components/Graphs/TimeChart";
import StatsCard from "components/StatsCard/StatsCard";
import { getAverageSessionDuration, secondsToString } from "../../utils/utils";
import { getSurveyDateData, getDevicePieChartData } from "utils/graphData";
import ResultsChart from "components/Graphs/ResultsChart";
import { rainbow } from "utils/brandPalette";
import {
  getAgeData,
  getIncomeData,
  getLocationData,
  getGenderData,
} from "utils/graphData";
import { getChartColors } from "utils/utils";

class Completion extends React.PureComponent {
  getAverageResponseDuration = () => {
    if (!this.props.survey) return null;
    const labels = [];
    const data = [];
    for (let i = 0; i < this.props.survey.questions.length; i++) {
      labels.push(`Q${i + 1}`);
      const value = this.props.survey.questions[i].avgResponseDuration || 0;
      data.push((value / 1000).toFixed(1));
    }
    /**
     * toFixed returns a string, not a number.
     * It would be better to customize the bar chart's tooltip, but this works.
     */
    if (data.every((val) => val === "0.0")) return null;
    return { data, labels };
  };

  getDropoutData = () => {
    if (!this.props.sessions || !this.props.survey)
      return {
        data: null,
        labels: null,
      };

    var labels = [];
    var data = [];
    for (var i = 0; i < this.props.survey.questions.length; i++) {
      labels.push(`Q${i + 1}`.toString());
      data.push(this.props.survey.questions[i].numResponses);
    }
    return {
      data: data,
      labels: labels,
    };
  };

  render() {
    const averageResponseDurations = this.getAverageResponseDuration();
    const deviceData = getDevicePieChartData(this.props.sessions);
    const dropoutData = this.getDropoutData();
    const surveyDateData = getSurveyDateData(this.props.sessions);

    return (
      <div id="completion-screen">
        <Grid container spacing={3}>
          <ResultsChart
            title={"Survey Dropout"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={dropoutData}
            chartDataFn={() => {
              return dropoutData;
            }}
            backgroundColor={["#2B4257"]}
            hideMenu
          />

          {/* TODO: Convert time chart into a ResultsChart */}
          <Grid item xs={12} md={4}>
            <Paper className="graph-questions-container">
              <div className="graph-questions-title">
                <span>Surveys Started</span>
              </div>
              <TimeChart
                chartData={surveyDateData}
                backgroundColor={"#7ec6b2"}
                xLabel="Date Started"
                yLabel="# Responses"
              />
            </Paper>
          </Grid>

          <ResultsChart
            title={"Average Time Spent"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={averageResponseDurations}
            chartDataFn={() => {
              return averageResponseDurations;
            }}
            backgroundColor={["#c3726d"]}
            hideMenu
          />

          <ResultsChart
            title={"Device Used"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"PIE"}
            conditions={deviceData}
            chartDataFn={() => getDevicePieChartData(this.props.sessions)}
            backgroundColor={getChartColors(10)}
            hideMenu
          />

          {/* Demographic Charts */}
          <ResultsChart
            title={"Age"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={
              this.props.survey.demographics &&
              this.props.survey.demographics.age
            }
            chartDataFn={() => getAgeData(this.props.sessions)}
            backgroundColor={getChartColors(1)}
            hideMenu
          />
          <ResultsChart
            title={"Household Income"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={
              this.props.survey.demographics &&
              this.props.survey.demographics.hHIncome
            }
            chartDataFn={() => getIncomeData(this.props.sessions)}
            backgroundColor={getChartColors(1)}
            hideMenu
          />
          <ResultsChart
            title={"Location"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={
              this.props.survey.demographics &&
              this.props.survey.demographics.location
            }
            chartDataFn={() => getLocationData(this.props.sessions)}
            backgroundColor={getChartColors(1)}
            hideMenu
          />
          <ResultsChart
            title={"Gender"}
            loading={Boolean(!this.props.sessions)}
            loadingColor={rainbow.green}
            chartType={"BAR"}
            conditions={
              this.props.survey.demographics &&
              this.props.survey.demographics.gender
            }
            chartDataFn={() => getGenderData(this.props.sessions)}
            backgroundColor={getChartColors(2)}
            hideMenu
          />

          {/* Average Completion Time */}
          {getAverageSessionDuration(this.props.sessions) && (
            <Grid item xs={12} md={4}>
              <StatsCard
                bigIcon={<FontAwesomeIcon icon={faClock} />}
                statsText="Average Survey Completion Time"
                statsValue={`${secondsToString(
                  getAverageSessionDuration(this.props.sessions)
                )}`}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default Completion;

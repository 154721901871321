import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileMedical,
  faFolderMinus,
  faFolderPlus,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

export default function AddFileButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addFile = () => {
    props.addFile();
    handleClose();
  };
  const addFolder = () => {
    props.addFolder();
    handleClose();
  };
  const deleteFolder = () => {
    props.deleteFolder();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "add-file-menu" : undefined;

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="file-button-wrapper"
        onClick={handleClick}
      >
        <svg
          viewBox="0 0 62 62"
          className="file-button"
          style={{ enableBackground: "new 0 0 60.364 60.364" }}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </svg>
      </div>
      <Menu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.type === "FOLDER" && (
          <MenuItem onClick={addFile}>
            <FontAwesomeIcon icon={faFileMedical} />
            &nbsp;&nbsp;Add Files
          </MenuItem>
        )}
        {props.type === "FOLDER" && (
          <MenuItem onClick={addFolder}>
            <FontAwesomeIcon icon={faFolderPlus} />
            &nbsp;&nbsp;Add Folder
          </MenuItem>
        )}
        {props.type === "FOLDER" && (
          <MenuItem onClick={deleteFolder}>
            <FontAwesomeIcon icon={faFolderMinus} />
            &nbsp;&nbsp;Delete Folder
          </MenuItem>
        )}
        {props.type !== "FOLDER" && (
          <MenuItem onClick={deleteFolder}>
            <FontAwesomeIcon icon={faFolderMinus} />
            &nbsp;&nbsp;Remove Item
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

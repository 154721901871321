import React from "react";
import Typography from "@material-ui/core/Typography";

const ReportError = () => (
  <div style={{ marginTop: "30px" }}>
    <Typography variant="h2" component="h1" align="center" gutterBottom>
      There was an error retrieving this report.
    </Typography>
    <Typography variant="h3" component="h2" align="center">
      Are you sure you have the right URL?
    </Typography>
  </div>
);

export default ReportError;

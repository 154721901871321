import React from "react";
import { store } from "react-notifications-component";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import VideocamIcon from "@material-ui/icons/Videocam";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ImageIcon from "@material-ui/icons/Image";
import { Chip, CircularProgress, Tooltip } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { monthNames } from "./data";
import { getQuestionType } from "./questionTypes";
import { rainbow } from "./brandPalette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import kebabCase from "lodash/kebabCase";
var ObjectID = require("bson-objectid");
const queryString = require("query-string");

export function titleCase(word) {
  if (!word) return null;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function truncateStringWithEllipsis(title, trimLen) {
  let LENGTH = 28;
  if (trimLen) LENGTH = trimLen;
  if (typeof title !== "string" || title.length < LENGTH) return title;
  return title.substring(0, LENGTH - 3) + "...";
}

export function getBarColor(sentiment) {
  switch (sentiment) {
    case "POSITIVE":
      return "sentiment positive";
    case "NEGATIVE":
      return "sentiment negative";
    case "NEUTRAL":
      return "sentiment neutral";
    case "MIXED":
      return "sentiment mixed";
  }
}

export function getSentimentJSX(sentiment, response) {
  if (!sentiment) return <div></div>;
  const barColor = sentiment ? getBarColor(sentiment) : undefined;
  return (
    <div>
      <div>{sentiment.toUpperCase()}</div>
      <div
        className="progress-bar-xs progress"
        style={{ position: "relative" }}
      >
        <div
          className={barColor}
          style={{
            color: "black",
            paddingLeft: "10px",
            position: "absolute",
            top: "0",
            left: "0",
            fontSize: 10,
          }}
        >
          {Math.round(
            response.features.sentiment_score[titleCase(sentiment)] * 100
          )}
          %
        </div>
        <div
          className={`progress-bar ${barColor}`}
          role="progressbar"
          ria-valuenow="24"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${Math.abs(
              response.features.sentiment_score[titleCase(sentiment)] * 100
            )}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export function useDefaultIfUndef(value, def) {
  return value ? value : def;
}

export function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export const hHiLabels = [
  "0-10k",
  "10-25k",
  "25-50k",
  "50-75k",
  "75-100k",
  "100-125k",
  "125-150k",
  "150-175k",
  "175-200k",
  "200k +",
  "Prefer not to say",
];

export const chartColors = Object.values(rainbow);

export const conversationColors = [
  rainbow.blue,
  rainbow.green,
  rainbow.red,
  rainbow.violet,
  "#818c8e",
].map((color) => darken(color, 0.3));

export const getChartColors = (len) => {
  var colors = [];
  for (let i = 0; i < len; ++i)
    colors.push(chartColors[i % chartColors.length]);
  return colors;
};

export const getTransparentChartColors = (len, opacity) => {
  var colors = [];
  for (let i = 0; i < len; ++i)
    colors.push(changeOpacity(chartColors[i % chartColors.length], opacity));
  return colors;
};

/**
 * Return n elements from a list. If n > list length, add elements from the
 * beginning of the list. Mainly used with repeating colors.
 */
export const getNElements = (list, n) => {
  const result = [];
  for (let i = 0; i < n; ++i) result.push(list[i % list.length]);
  return result;
};

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function notification(title, text, status, duration = 2000) {
  store.addNotification({
    title: title,
    message: text,
    type: status,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration,
    },
  });
}

export function getTypeJSX(type) {
  if (!type) return null;
  const parts = type.split("_");
  var tags = [];
  for (var i = 0; i < parts.length; i++) {
    var stateClass;
    switch (parts[i]) {
      case "AUDIO":
        stateClass = "type-tile audio";
        break;
      case "VIDEO":
        stateClass = "type-tile video";
        break;
      case "SCREEN":
        stateClass = "type-tile screen";
        break;
      case "NUMBER":
      case "SLIDER":
      case "FILE":
      case "TEXT":
      case "LIKERT":
        stateClass = "type-tile text";
        break;
      case "SELECTION":
      case "DROPDOWN":
      case "RANKING":
        stateClass = "type-tile selection";
        break;
      case "DISPLAY":
        stateClass = "type-tile display";
        break;
      default:
        stateClass = "type-tile selection";
    }
    tags.push(
      <Chip
        size="small"
        key={`Question-${stateClass}-${parts.toString()}`}
        label={parts[i]}
        className={stateClass}
      />
    );
  }
  return tags;
}

export function toTitleCase(string) {
  return string.replace(/\S*/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
}

export function getTypeJSXIcon(type, num) {
  const questionType = getQuestionType(type);
  if (!questionType) {
    return null;
  }
  return (
    <Tooltip title={toTitleCase(type.replace("_", " "))} aria-label={type}>
      <div
        className="qtype-tile shape-rect"
        style={{ backgroundColor: questionType.color }}
      >
        {questionType.icons.map((icon, idx) => {
          return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
        })}{" "}
        &nbsp; {num}
      </div>
    </Tooltip>
  );
}

export function getTypeJSXIconSquare(type, num) {
  const questionType = getQuestionType(type);
  if (!questionType) {
    return null;
  }
  return (
    <div
      className="qtype-tile shape-square"
      style={{
        backgroundColor: `${questionType.color}`,
      }}
    >
      {questionType.icons.map((icon, idx) => {
        return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
      })}{" "}
      &nbsp; {num}
    </div>
  );
}

export function getStimuliJSX(question) {
  if (!question || !question.stimuli) return;
  var chipIcon;
  switch (question.stimuli.type) {
    case "AUDIO":
      chipIcon = <GraphicEqIcon />;
      break;
    case "YOUTUBE":
      chipIcon = <YouTubeIcon />;
      break;
    case "IMAGE":
      chipIcon = <ImageIcon />;
      break;
    case "VIDEO":
      chipIcon = <VideocamIcon />;
      break;
  }
  return (
    <Chip
      size="small"
      icon={chipIcon}
      label={question.stimuli.type.replace("_", " ")}
      onClick={() => {
        window.open(question.stimuli.url, "_blank");
      }}
    />
  );
}

export function getStateJSX(survey, size = "small") {
  var stateWord = "",
    stateClass = "";
  if (survey.state === "PENDING") {
    stateWord = "Collecting";
    stateClass = "survey-pending";
  } else if (survey.state === "COMPLETED") {
    stateWord = "Completed";
    stateClass = "survey-done";
  } else if (survey.state === "DRAFT") {
    stateWord = "Draft";
    stateClass = "survey-draft";
  }
  return <Chip size={size} label={stateWord} className={stateClass} />;
}

export function getTimeStringFromSeconds(time) {
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, 0);
  const seconds = Math.floor((time % 3600) % 60)
    .toString()
    .padStart(2, 0);
  return minutes + ":" + seconds;
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getObjectId() {
  return ObjectID().toString();
}

// Used for pagination when page is consumed from a query parameter.
// If it's an invalid number of non-existent, default to 1.
export function getPage(queryStr) {
  let q = queryString.parse(queryStr);
  if (q && q.page) return parseInt(q.page);
  return 1;
}

export function stringifyQuery(queryDict) {
  return queryString.stringify(queryDict);
}

export function parseQuery(query) {
  return queryString.parse(query);
}

export function getContrastYIQ(hexcolor, customThreshold) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= (customThreshold || 100) ? "black" : "white";
}

export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function findListOfListsIndex(listOfLists, value) {
  for (let i = 0; i < listOfLists.length; ++i) {
    if (listOfLists[i].includes(value)) return i;
  }
  return -1;
}

export function isAudioType(type) {
  if (!type) return false;
  return type.includes("AUDIO") || type.includes("THINKALOUD");
}

export function isVideoType(type) {
  if (!type) return false;
  return type.includes("VIDEO") || type.includes("SCREEN");
}

export function isFileType(type) {
  if (!type) return false;
  return type.includes("FILE");
}

export function isTextTypeStrict(type) {
  if (!type) return false;
  return type.includes("TEXT");
}

export function isAudioOrVideoType(type) {
  if (!type) return false;
  return isAudioType(type) || isVideoType(type);
}

export function isOpenEndType(type) {
  if (!type) return false;
  return isAudioOrVideoType(type) || isTextTypeStrict(type);
}

export function isTitleOrFolderType(type) {
  if (!type) return false;
  return type.includes("TITLE") || type.includes("FOLDER");
}

export function isTitleType(type) {
  if (!type) return false;
  return type.includes("TITLE");
}

export function isSelectionNature(type) {
  if (!type) return false;
  return (
    type.includes("SELECTION") ||
    type.includes("DROPDOWN") ||
    type.includes("LIKERT") ||
    type.includes("RANKING")
  );
}

export function isSelectionNatureStrict(type) {
  if (!type) return false;
  return type.includes("SELECTION") || type.includes("DROPDOWN");
}

export function isTextType(type) {
  if (!type) return false;
  return (
    type.includes("TEXT") ||
    type.includes("THINKALOUD") ||
    type.includes("NUMBER") ||
    type.includes("DATE") ||
    type.includes("SLIDER")
  );
}

export function isRankingType(type) {
  if (!type) return false;
  return type.includes("RANKING");
}

export function hasPlayScreen(response) {
  return (
    ((isAudioType(response.type) || isVideoType(response.type)) &&
      response.backupText === undefined) ||
    (response.type === "FILE" && response.uri !== undefined)
  );
}

export function hasImageExtension(ext) {
  return [
    "jpg",
    "png",
    "gif",
    "webp",
    "tiff",
    "psd",
    "raw",
    "bmp",
    "heif",
    "indd",
    "jpeg",
    "svg",
  ].includes(ext);
}

export function getDemographics(
  demographics,
  showOptionalLabels /* optional labels are name and email */,
  filter = true
) {
  if (!demographics) demographics = {};
  return [
    {
      label: showOptionalLabels ? "Name" : null,
      _key: "name",
      value: [demographics.firstName, demographics.lastName]
        .filter((d) => d)
        .join(" "),
    },
    {
      label: showOptionalLabels ? "Email" : null,
      _key: "email",
      value: demographics.email ? demographics.email : null,
    },
    {
      label: "Age ",
      _key: "age",
      value: demographics.age ? demographics.age : null,
    },
    {
      label: "Gender ",
      _key: "gender",
      value: demographics.gender ? demographics.gender : null,
    },
    {
      label: "Household Income ",
      _key: "hHIncome",
      value: demographics.hHIncome ? demographics.hHIncome : null,
    },
    {
      label: "Location ",
      _key: "location",
      value: demographics.location ? demographics.location.display : null,
    },
  ].filter(filter ? (d) => d.value : () => true);
}

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function readableDate(date) {
  try {
    let parts = date.split(" ")[0].split("-");
    if (parts.length !== 3) return date;
    return `${monthNames[parseInt(parts[1]) - 1]} ${ordinal_suffix_of(
      parseInt(parts[2])
    )}, ${parts[0]}`;
  } catch (error) {
    return date;
  }
}

export const wordCloudOptions = {
  colors: ["#8C8C8C", "#2C2C2C", "#8CC2B2", "#5F8BA8", "#394A61", "#2AC79F"],
  enableTooltip: true,
  deterministic: false,
  fontFamily: "Inter",
  fontSizes: [5, 60],
  fontStyle: "normal",
  fontWeight: "600",
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000,
};

export function getDemographicsBlock(response) {
  if (!response.demographics) return;
  return (
    <div className="demographics">
      {(response.demographics.firstName || response.demographics.lastName) && (
        <div className="font-size-s mb-5">
          <b>Name</b>:{" "}
          {response.demographics.firstName && (
            <span>{response.demographics.firstName}</span>
          )}{" "}
          {response.demographics.lastName && (
            <span>{response.demographics.lastName}</span>
          )}
        </div>
      )}
      {response.demographics.email && (
        <div className="font-size-s mb-5">
          <b>Email</b>: {response.demographics.email}
        </div>
      )}
      {response.demographics.age && (
        <div className="font-size-s mb-5">
          <b>Age</b>: {response.demographics.age}
        </div>
      )}
      {response.demographics.gender && (
        <div className="font-size-s mb-5">
          <b>Gender</b>: {response.demographics.gender}
        </div>
      )}
      {response.demographics.hHIncome && (
        <div className="font-size-s mb-5">
          <b>Household Income</b>: {response.demographics.hHIncome}
        </div>
      )}
      {response.demographics.location && (
        <div className="font-size-s mb-5">
          <b>Location</b>: {response.demographics.location.display}
        </div>
      )}
      <div className="font-size">
        <b>Date</b>: {readableDate(response.createdAt)}
      </div>
    </div>
  );
}

export function pushItemOntoArray(array, item) {
  if (!array) return [item];
  return [...array, item];
}

export function insertItemIntoArray(array, item, idx) {
  if (!array) return [item];
  array.splice(idx, 0, item);
  return array;
}

export function removeItemFromArray(array, idx) {
  if (!array) return [];
  return [...array.slice(0, idx), ...array.slice(idx + 1)];
}

export const getAverageSessionDuration = (sessions) => {
  if (!sessions || !Array.isArray(sessions)) return null;
  let sessionsWithDuration = sessions.length;

  const sum = sessions.reduce((sum, curr) => {
    const sessionDuration = curr.features.sessionDurationMs;
    if (sessionDuration) {
      return sum + sessionDuration;
    }
    sessionsWithDuration -= 1;
    return sum;
  }, 0);

  if (sessionsWithDuration === 0) return null;
  const value = sum / sessionsWithDuration;
  return (value / 1000).toFixed(1);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const strFromDate = (dateObj) => {
  // JS Date object => YYYY/mm/dd
  const Y = `${dateObj.getFullYear()}`;
  const m = `${dateObj.getMonth() + 1}`.padStart(2, "0");
  const d = `${dateObj.getDate()}`.padStart(2, "0");
  return `${Y}-${m}-${d}`;
};

export const secondsToString = (seconds) => {
  let clock =
    Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  // If there are no hours.
  if (clock.substring(0, 3) === "00:") return clock.slice(3);
  return clock;
};

export const initialParams = (parsedQuery) => {
  if (typeof parsedQuery === "string") {
    return [parsedQuery];
  } else if (Array.isArray(parsedQuery)) {
    return parsedQuery;
  }
  return [];
};

export const blockFreeTier = (user) => {
  if (!user || user.tier === "FREE") {
    blockFreeTierNotification();
    return true;
  }
  return false;
};

export function blockFreeTierNotification() {
  notification(
    "Please upgrade to premium.",
    "This feature is only available on premium accounts.",
    "warning"
  );
}

export function isPositiveInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

export function formatCurrency(num) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(num);
}

export function getJobStatusJSX(status) {
  if (status === "PENDING") return <CircularProgress size={12} />;
  if (status === "COMPLETED") return <FontAwesomeIcon icon={faCheck} />;
  if (status === "FAILED") return <FontAwesomeIcon icon={faCross} />;
  return status;
}

export function getSentenceAtTime(items, time) {
  if (!items || !time) return "";
  let sentence = "";
  for (let i = 0; i < items.length; ++i) {
    const item = items[i];
    if (item.end_time && parseInt(item.end_time) > time) {
      // Go back to previous punctuation
      let j = i;
      while (j >= 0) {
        if (
          items[j].type === "punctuation" ||
          (items[j].type === "generated" &&
            hasUtteranceEnd(itemContent(items[j])))
        )
          break;
        sentence = items[j].alternatives[0].content + " " + sentence;

        j--;
      }
      // Go forward to next punctuation
      j = i + 1;
      while (j < items.length) {
        if (items[j].type === "punctuation") break;
        sentence = sentence + " " + items[j].alternatives[0].content;
        if (
          items[j].type === "generated" &&
          hasUtteranceEnd(itemContent(items[j]))
        )
          break;
        j++;
      }
      break;
    }
  }
  return sentence;
}

export function launchHubspotWidget() {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();
      },
    ];
  }
}

export function surveyHasMedia(questions) {
  return (
    Array.isArray(questions) &&
    questions.some((q) => isAudioOrVideoType(q.type) && q.numResponses > 0)
  );
}

export function itemContent(item) {
  return item.alternatives[0].content;
}

export function isSpace(s) {
  return /\s/.test(s);
}

export function hasUtteranceEnd(s) {
  return /[;,.]/.test(s);
}

export function getSpaceItem() {
  return {
    type: "blankspace",
    alternatives: [{ content: " " }],
  };
}

export function getLastTimestamp(items) {
  for (let idx = items.length - 1; idx >= 0; --idx) {
    if (items[idx].end_time) {
      return items[idx].end_time;
    }
  }
}

export function getFirstTimestamp(items) {
  for (let idx = 0; idx < items.length; ++idx) {
    if (items[idx].start_time) {
      return items[idx].end_time;
    }
  }
}

function leftPad(number, targetLength) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function getFormattedTime(time) {
  if (!time) return "";
  return `${leftPad(String(Math.floor(time / 60)), 2)}:${String(
    leftPad(Math.floor(time % 60), 2)
  )}`;
}

export function mapFlatFilesToTree(flatFiles) {
  let result = {};
  let level = { result };
  flatFiles.forEach((file) => {
    file.path.split("/").reduce((root, name, idx, pathList) => {
      if (!root[name]) {
        root[name] = { result: {} };
        root.result[name] = {
          ...file,
          type:
            idx === pathList.length - 1 && file.type !== "FOLDER"
              ? file.type
              : "FOLDER",
          name,
          children: root[name].result,
        };
        /*
        On line 821, the spread operator copies all fields of file, including the pending upload status.
        This causes the encompassing folders to be updated with the pending status as well.
        To avoid this, we check if root.result[name] is a folder using the logical negation of the logic
        on line 823 (which is true if root.result[name] is the furthest nested file and not a folder).
        If so, the status field is deleted.
        */
        if (idx !== pathList.length - 1 || file.type === "FOLDER") {
          delete root.result[name].status;
        }
      }

      return root[name];
    }, level);
  });
  return result;
}

/*
Merges 2 trees with 'name' and 'children' props
t2 overwrites changes to t1, so usually t1 is the original tree and t2 are the new nodes

The root of t1 and t2 are arrays.
Children are always assumed to exist (although they may be empty).
*/
export function mergeTrees(t1, t2) {
  for (const j in t2) {
    var found = false;
    for (const i in t1) {
      if (t1[i].name === t2[j].name) {
        t1[i] = {
          ...t2[j],
          ...t1[i],
          children: mergeTrees(t1[i].children, t2[j].children),
        };

        found = true;
      }
    }
    if (!found) {
      t1[j] = t2[j];
    }
  }
  return { ...t1 };
}

export function getTranscriptionItemsAsString(transcriptionItems) {
  if (!transcriptionItems) return "";
  return transcriptionItems
    .map((item, idx) => {
      if (item.type === "punctuation") {
        return item.alternatives[0].content;
      } else {
        let innerResult = "";
        if (idx !== 0) {
          innerResult += " ";
        }
        innerResult += item.alternatives[0].content;
        return innerResult;
      }
    })
    .reduce((a, b) => a + b);
}

export function getTranscriptionHTML(items) {
  const transcription = getTranscriptionItemsAsString(items);
  return `${transcription}`;
}

export function printItems(items) {
  console.log(
    items.map((item) => {
      return itemContent(item);
    })
  );
}

export function notSpaceOrPunct(item) {
  if (!item) return false;
  return item.type !== "punctuation" && item.type !== "blankspace";
}

export function getDataset(params) {
  // Transform object: { surveyId, ... } => { "data-survey-id", ... }
  return Object.entries(params).reduce((obj, [k, v]) => {
    if (v) {
      const attr = `data-${kebabCase(k)}`;
      obj[attr] = v;
    }
    return obj;
  }, {});
}

export function getEmbedJSX(params) {
  const { phonicEmbed, surveyId, color, helperText } = params;
  switch (params.phonicEmbed) {
    case "inline": {
      return (
        <>
          <div
            {...getDataset({ phonicEmbed, surveyId })}
            style={{ width: "100%", height: 420, margin: "0 auto" }}
            id="launch-icon"
          />
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    case "popover": {
      return (
        <>
          <a
            {...getDataset({ phonicEmbed, surveyId, color })}
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              width: 48,
              height: 48,
              backgroundColor: `${params.color}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.15)",
              cursor: "pointer",
            }}
            tabIndex="0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="22"
              height="25"
              viewBox="0 0 512 512"
              aria-hidden="true"
              focusable="false"
              id="launch-icon"
            >
              <path
                fill={getContrastYIQ(params.color, 170)}
                d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
              />
            </svg>
          </a>
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    case "sidedrawer": {
      return (
        <>
          <a {...getDataset({ phonicEmbed, surveyId, color, helperText })}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="22"
              height="25"
              viewBox="0 0 512 512"
              aria-hidden="true"
              focusable="false"
              id="launch-icon"
              tabIndex="0"
            >
              <path
                fill={getContrastYIQ(params.color, 170)}
                d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
              />
            </svg>
          </a>
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    default:
      return null;
  }
}

export function getAvatarUrl(user, size) {
  let displayName = user.displayName;
  if ((!displayName && user.firstName) || user.lastName) {
    displayName = user.firstName + " " + user.lastName;
  }
  return `https://ui-avatars.com/api/?name=${displayName}&image_size=${size}`;
}

export function removeHtmlTags(html) {
  if (!html) return null;
  return html.replace(/<[^>]*>/g, "");
}

export function hexToRgbA(hex, opacity) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

export function changeOpacity(color, opacity) {
  return hexToRgbA(color, opacity);
}

export function getReadableSessionId(sid) {
  if (!sid) return "";
  return "#" + sid.substring(0, 6).toUpperCase();
}

export function getBestSessionTitles(s) {
  let result = ["", ""];
  if (s.createdAt) {
    result.push(readableDate(s.createdAt));
  }
  if (s.demographics) {
    if (s.demographics.email) {
      result.push(s.demographics.email);
    }
    if (s.demographics.firstName || s.demographics.lastName) {
      result.push(
        [s.demographics.firstName, s.demographics.lastName].join(" ")
      );
    }
  }
  return {
    text: result[result.length - 1],
    subText: result[result.length - 2],
  };
}

export function getFirstNameLastName(firstName, lastName) {
  let result = "";
  if (firstName) {
    result += firstName;
    if (lastName) result += " ";
  }
  if (lastName) result += lastName;
  return result;
}
/**
 * Add an item after every element in a array
 *
 * Example:
 * > interweave([1,2,3,4], 5)
 * [1,5,2,5,3,5,4]
 *
 * @param arr - Array to interweave
 * @param item - item to add
 * @returns a new array wih interweaved items
 */
export function interweave(arr, item) {
  return arr.reduce((a, b) => a.concat(item, b), []).slice(1);
}

export function debounceAction(
  updateTimeout,
  setUpdateTimeout,
  action,
  timeout = 1000
) {
  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }
  setUpdateTimeout(setTimeout(() => action(), timeout));
}

export function extractNumbersFromString(str) {
  const nums = str.match(/-?\d+/g);
  if (!nums) return [];
  return nums.map((n) => parseInt(n, 10));
}

import React from "react";
import { connect } from "react-redux";
import { getQuestionJobs } from "../redux/actions";
import { Table } from "reactstrap";
import { ScaleLoader } from "react-spinners";
import { getJobStatusJSX } from "utils/utils";
import { Chip } from "@material-ui/core";
import { readableDate } from "utils/utils";

const mapStateToProps = (state, ownProps) => {
  if (state.jobs)
    return {
      jobs: state.jobs[ownProps.questionId],
    };
};

const mapDispatchToProps = { getQuestionJobs };

class JobStatusTable extends React.Component {
  componentDidMount() {
    this.props.getQuestionJobs(this.props.surveyId, this.props.questionId);
    this.interval = setInterval(
      () =>
        this.props.getQuestionJobs(this.props.surveyId, this.props.questionId),
      5000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return (
      <div style={{ minHeight: 400 }}>
        {this.props.jobs ? (
          <Table>
            <thead>
              <tr>
                <td>Date</td>
                <td>Task</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {this.props.jobs.map((job) => {
                return (
                  <tr key={job._id}>
                    <td>{readableDate(job.createdAt)}</td>
                    <td>
                      <Chip size="small" color="default" label={job.task} />
                    </td>
                    <td>{getJobStatusJSX(job.status)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className="flex-container" style={{ height: 400 }}>
            <ScaleLoader size={"30px"} color={"#62cb88"} loading />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusTable);

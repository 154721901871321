import React from "react";
import { Grid } from "@material-ui/core";
import { ClipLoader } from "react-spinners";

export default class LoaderTile extends React.Component {
  render() {
    return (
      <Grid item className="main-survey-tile usage">
        <div className="loader-container">
          <ClipLoader
            style={{
              display: "flex",
              WebkitFlex: "0 1 auto",
              flex: "0 1 auto",
              WebkitFlexDirection: "column",
              flexDirection: "column",
              WebkitFlexGrow: 1,
              flexGrow: 1,
              WebkitFlexShrink: 0,
              flexShrink: 0,
              WebkitFlexBasis: "25%",
              flexBasis: "25%",
              maxWidth: "25%",
              height: "200px",
              WebkitAlignItems: "center",
              alignItems: "center",
              WebkitJustifyContent: "center",
              justifyContent: "center",
            }}
            color={"#9BA5BA"}
            loading={true}
          />
        </div>
      </Grid>
    );
  }
}

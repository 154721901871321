import React from "react";
import { Paper } from "@material-ui/core";
import BoundedCheckbox from "../BoundedCheckbox";

const SelectionPicker = ({
  title,
  audience,
  modifyAudience,
  optionType,
  optionList,
}) => {
  const updateOption = (idx, val) => {
    let options = audience[optionType];
    options[idx] = val;
    modifyAudience({ [optionType]: options });
  };
  return (
    <Paper className="recruit-paper">
      <h3>{title}</h3>
      <div className="flex-container justify-left wrap">
        {audience[optionType].map((option, idx) => {
          return (
            <BoundedCheckbox
              key={`${optionType}-${idx}`}
              text={option.name}
              checked={option.include}
              onChange={(v) => {
                updateOption(idx, {
                  ...option,
                  include: v,
                });
              }}
            />
          );
        })}
      </div>
      {/* TODO: Build quotas */}
      {/* <hr />
      <div className="flex-container justify-left">
        <Switch /> Quotas
      </div> */}
    </Paper>
  );
};

export default SelectionPicker;

import React, { useState } from "react";

const Editable = ({ text, placeholder, children, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleKeyDown = (evt) => {
    const exitKeys = { Escape: true, Tab: true, Enter: true };

    if (exitKeys[evt.key]) {
      setIsEditing(false);
    }
  };

  return (
    <div {...props}>
      {!isEditing ? (
        <div onClick={() => setIsEditing(true)}>
          <span className={text ? null : "empty"}>{text || placeholder}</span>
        </div>
      ) : (
        <div onBlur={() => setIsEditing(false)} onKeyDown={handleKeyDown}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Editable;

import './wdyr';

import React from "react";
import ReactDOM from "react-dom";

import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";
import { firebaseConfig } from "./auth";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/pe-icon-7-stroke.css";
import "./style.css";
import "./scss/style.scss";
import "./scss/basic.scss";
import "./scss/charts.scss";

import "./surveyapp.css";
import "react-notifications-component/dist/theme.css";

import AdminLayout from "layouts/Admin.jsx";
import SignIn from "layouts/SignIn";
import SignUp from "layouts/SignUp";
import UpgradeThankYou from "layouts/UpgradeThankYou";
import Report from "layouts/Report";

import store from "redux/store";
import Showreel from "layouts/Showreel";
import * as FullStory from "@fullstory/browser";

Sentry.init({
  dsn: "https://d617d17ba7824a2cad0f24a2e3ea6a20@o477774.ingest.sentry.io/5519302",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ["error", "warn"],
    }),
  ],
  tracesSampleRate: 1.0,
});
FullStory.init({ orgId: "16J4J7" });

ReactDOM.render(
  <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/:organization/signin" component={SignIn} />
          <Route path="/:organization/signup" component={SignUp} />
          <Route path="/thank-you-for-upgrading" component={UpgradeThankYou} />
          <Route exact path="/reports/:reportId" component={Report} />
          <Route
            exact
            path="/showreels/:showreelId/share"
            component={Showreel}
          />
          <FirebaseAuthConsumer>
            {({ isSignedIn, user }) => {
              return <AdminLayout isSignedIn={isSignedIn} user={user} />;
            }}
          </FirebaseAuthConsumer>
        </Switch>
      </BrowserRouter>
    </Provider>
  </FirebaseAuthProvider>,
  document.getElementById("root")
);

import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import React, { useState } from "react";
import { ClipLoader } from "react-spinners";

export default function PanelFeedback({ submitFeedback, loading }) {
  const [feedback, setFeedback] = useState({
    type: "PANEL",
    wantsPanel: undefined,
    strength: undefined,
    message: undefined,
  });

  return (
    <>
      <DialogContent>
        <h2>🤔 We'd Love Your Opinion!</h2>
        <div style={{ marginBottom: 10 }}>
          <Typography variant="body1">
            Would it be helpful if you could purchase survey responses directly
            within Phonic?
          </Typography>
          <ToggleButtonGroup
            exclusive={true}
            value={feedback.wantsPanel}
            onChange={(e, v) => {
              setFeedback({ ...feedback, wantsPanel: v });
            }}
          >
            <ToggleButton value={"Yes"}>Yes</ToggleButton>
            <ToggleButton value={"No"}>No</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div style={{ marginBottom: 10 }}>
          <Typography variant="body1">
            How strongly do you feel this way? (10 being the strongest)
          </Typography>
          <ToggleButtonGroup
            exclusive={true}
            value={feedback.strength}
            onChange={(e, v) => {
              setFeedback({ ...feedback, strength: v });
            }}
          >
            <ToggleButton value={1}>1</ToggleButton>
            <ToggleButton value={2}>2</ToggleButton>
            <ToggleButton value={3}>3</ToggleButton>
            <ToggleButton value={4}>4</ToggleButton>
            <ToggleButton value={5}>5</ToggleButton>
            <ToggleButton value={6}>6</ToggleButton>
            <ToggleButton value={7}>7</ToggleButton>
            <ToggleButton value={8}>8</ToggleButton>
            <ToggleButton value={9}>9</ToggleButton>
            <ToggleButton value={10}>10</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div style={{ marginBottom: 10 }}>
          <Typography variant="body1">
            If we added this ability to Phonic, is there anything we should keep
            in mind?
          </Typography>
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            value={feedback.message}
            onChange={(e) => {
              setFeedback({ ...feedback, message: e.target.value });
            }}
            placeholder="For my work I'd need a strong population under the following demographics..."
          />
        </div>

        <DialogActions>
          <Button
            className="secondary-button-color"
            variant="contained"
            component="label"
            onClick={() => {
              submitFeedback(feedback);
            }}
          >
            {loading ? <ClipLoader size={21} color={"white"} /> : "Submit"}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
}

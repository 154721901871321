import React, { Component } from "react";
import { secondsToString } from "../../utils/utils";
import Recorder from "react-video-recorder";
import VideoOverlay from "./VideoDefaults/VideoOverlay"
import DisconnectedView from "./VideoDefaults/DisconnectedView"

export default class VideoRecorder extends Component {
  getRecorderText = () => {
    if (
      this.props.audioParams.isRecording ||
      this.props.audioParams.somethingRecorded
    ) {
      return (
        <p className="record-time-text">
          {secondsToString(this.props.audioParams.timeElapsed)}
        </p>
      );
    } else {
      return <div className="record-time-spacer" />;
    }
  };

  render() {
    return (
      <div
        style={{
          padding: 8,
          maxWidth: 600,
          margin: "0 auto",
          overflow: "visible",
        }}
      >
        <Recorder
          onRecordingComplete={(videoBlob) => {
            // Turn videoblob into file.
            try {
              videoBlob.name = `stimuli_video.webm`;
            } catch (err) {
              // Vides from mobile camera app already have file names and throw exception.
            }
            this.props.onSubmit(videoBlob);
          }}
          renderDisconnectedView={DisconnectedView}
          renderActions={VideoOverlay}
          replayVideoAutoplayAndLoopOff
          showReplayControls
        />
      </div>
    );
  }
}

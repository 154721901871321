import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    return (
      <div
        id="sidebar"
        className="sidebar sidebar-shadow"
        data-color={this.props.color}
      >
        {this.props.hasImage ? <div className="sidebar-background" /> : null}
        <div className="logo">
          <img
            className="button-logo"
            src={require("../../assets/img/Phonic_Logo_4x.png")}
            alt="Phonic logo"
            onClick={() => this.props.history.push("/surveys")}
          ></img>
          <div className="logo-text">phonic</div>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.props.routes.map((prop, key) => {
              if (
                (!prop.redirect && prop.showInNav) ||
                (prop.hiddenUnlessUserHas &&
                  this.props.user &&
                  this.props.user[prop.hiddenUnlessUserHas])
              )
                return (
                  <React.Fragment key={key}>
                    <li
                      className={
                        prop.upgrade
                          ? "active active-pro"
                          : this.activeRoute(prop.path)
                      }
                    >
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => {
                          if (prop.externalLink) {
                            window.open(prop.externalLink, "_blank");
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={prop.icon} />
                      </NavLink>
                    </li>
                    <div className="nav-name">{prop.name}</div>
                  </React.Fragment>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  root: {
    border: "none",
    backgroundColor: "#FFE2D9",
    borderRadius: 0,
  },
  iconBox: {
    padding: "0 0 8px",
  },
  icon: {
    fontSize: "60px",
  },
  message: {
    textAlign: "center",
  },
  btnWrapper: {
    padding: "16px 16px 24px",
  },
}));

const ResponseNotFound = ({ deleteBlock }) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.root}>
      <CardHeader
        title={
          <Typography component="h4" variant="h4" align="center">
            Response Not Found
          </Typography>
        }
        disableTypography
      />
      <div className={`${classes.iconBox} absolute-center`}>
        <HighlightOffIcon aria-hidden="true" className={classes.icon} />
      </div>
      <p className={classes.message}>The response may have been deleted.</p>
      <div className={`${classes.btnWrapper} absolute-center`}>
        <Button
          className="gray-outlined-button"
          variant="outlined"
          size="large"
          startIcon={<DeleteIcon />}
          onClick={deleteBlock}
        >
          Remove from Report
        </Button>
      </div>
    </Card>
  );
};

export default ResponseNotFound;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  grouped: {
    margin: 4,
    border: "none",
    minWidth: 40,
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
});

const UnstyledToggleButtonGroup = (props) => {
  return <ToggleButtonGroup {...props} />;
};

export default withStyles(styles)(UnstyledToggleButtonGroup);

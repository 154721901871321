import React from "react";
import { Dialog, DialogContent, IconButton, Switch } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PricingCard from "../Pricing/PricingCard";
import { track } from "../../analytics";
import stripePromise from "../../stripe";
import {
  getUpgradeCheckoutId,
  startLoading,
  toggleUpgradePopup,
} from "../../redux/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    stripeCheckoutId: state.stripeCheckoutId,
    loading: state.loading["Upgrade"],
    upgradePopupOpen: state.upgradePopupOpen,
  };
};

const mapDispatchToProps = {
  getUpgradeCheckoutId,
  startLoading,
  toggleUpgradePopup,
};

class PremiumPopup extends React.Component {
  state = { billingPeriod: "annually" };
  upgradeToPremium = () => {
    this.props.startLoading(() => {
      this.props.getUpgradeCheckoutId(this.state.billingPeriod);
    }, "Upgrade");
  };

  handleClose = () => {
    this.props.toggleUpgradePopup(false);
  };

  billingPeriodChange = (e) => {
    this.setState({
      billingPeriod: e.target.checked ? "annually" : "monthly",
    });
  };

  async componentDidUpdate(prevProps) {
    if (this.props.stripeCheckoutId) {
      track("Tier Premium Clicked (Redirected to stripe)");
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId: this.props.stripeCheckoutId });
    }
    if (this.props.open !== prevProps.open && this.props.open === true) {
      track("Premium Popup Opened");
    }
  }

  getTier = () => {
    if (this.props.user) return this.props.user.tier;
  };

  getDiscount = () => {
    if (this.props.user) return this.props.user.discount;
  };

  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        open={Boolean(this.props.upgradePopupOpen)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <div className="premium-popup">
            <h1>
              Pricing designed for teams of{" "}
              <span className="green-text-accent">all sizes.</span>
            </h1>
            <p>
              Whether you're an enterprise looking to scale or individual
              academic, Phonic has a plan to support your research needs.
            </p>

            <span>
              <b>Monthly</b>
            </span>
            <Switch
              defaultChecked={true}
              color="primary"
              inputProps={{ "aria-label": "checkbox with default color" }}
              onChange={this.billingPeriodChange}
            />
            <span style={{ width: 110, textAlign: "left" }}>
              <b>Yearly</b> (save 40%)
            </span>
            <div className="plan-container">
              {["FREE", "PREMIUM", "ENTERPRISE"].map((tier) => {
                return (
                  <div
                    key={`PRICING-CARD-${tier}`}
                    className="plan-inner-container"
                  >
                    <PricingCard
                      upgrade={this.upgradeToPremium.bind(this)}
                      downgrade={() => {
                        track("Tier Downgrade Clicked");
                        this.props.navigate("/settings");
                        this.handleClose();
                      }}
                      contact={() => {
                        track("Tier Enterprise Clicked (Contact Us)");
                        document.location.href = "mailto:contact@phonic.ai";
                      }}
                      tier={tier}
                      // No tier implies free
                      selectedTier={this.getTier() ? this.getTier() : "FREE"}
                      discount={this.getDiscount()}
                      loading={tier === "PREMIUM" && this.props.loading}
                      billingPeriod={this.state.billingPeriod}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PremiumPopup);

import { itemContent, getSpaceItem } from "utils/utils";
import { isSpace } from "utils/utils";
import { getLastTimestamp } from "utils/utils";
import { getFirstTimestamp } from "utils/utils";
import { notSpaceOrPunct } from "./utils";

/** Algorithm to find changes in transcription items given an old transcript and a
 * new one. This approach denies multiple edits in multiple places. When leveraging
 * this function, you should make sure that this function is called repeatedly on
 * update.
 *
 * Step through the old and new transcriptions forwards and backwards character-wise
 * looking for conflicts. Upon encountering a conflict, create a new
 * "transcription_item" starting at the forward-traversing index and ending at the
 * backwards-traversing index.
 *
 * Timestamps for edited items will consume the "end_time" of the previous real item
 * and the "start_time" of the next real item. Adjacent generated items will merge
 * automatically and share timestamps.
 */
export function updateTranscription(transcription, existingItems) {
  var resultStart = [];
  var resultEnd = [];
  var conflictFound = false;

  // Add blankspace items
  var items = [];
  existingItems.forEach((item, idx) => {
    if (item.type !== "punctuation" && idx > 0) {
      items.push(getSpaceItem());
    }
    items.push(item);
  });

  // Word indices for items and character indices for the new transcript
  var startCharIdx = 0;
  var endCharIdx = transcription.length - 1;
  var startWordIdx = 0;
  var endWordIdx = items.length - 1;

  // Indices in the item words
  var startWordCharIdx = 0;
  var endWordCharIdx = itemContent(items[endWordIdx]).length - 1;

  // Need the *word* before and after the conflict, ignoring punctuation
  var itemBeforeConflict = undefined;
  var itemAfterConflict = undefined;

  ///////////////////////////
  // Search from beginning //
  ///////////////////////////
  while (startWordIdx < endWordIdx && startCharIdx < endCharIdx) {
    let item = itemContent(items[startWordIdx]);
    if (
      transcription[startCharIdx] == item[startWordCharIdx] ||
      (isSpace(transcription[startCharIdx]) && isSpace(item[startWordCharIdx]))
    ) {
      startCharIdx++;
      startWordCharIdx++;
      // If end of word, increment word index
      if (startWordCharIdx >= item.length) {
        // Add word to result
        resultStart.push(items[startWordIdx]);
        // Keep track of last pronunciation
        if (notSpaceOrPunct(items[startWordIdx]))
          itemBeforeConflict = items[startWordIdx];
        startWordIdx++;
        startWordCharIdx = 0;
      }
    } else {
      // Found a mismatch. Expand out to include any enclosed words
      startCharIdx -= startWordCharIdx;
      conflictFound = true;
      break;
    }
  }

  ////////////////////////
  // Search from ending //
  ////////////////////////
  while (startWordIdx <= endWordIdx && startCharIdx <= endCharIdx) {
    const item = itemContent(items[endWordIdx]);
    if (
      transcription[endCharIdx] == item[endWordCharIdx] ||
      (isSpace(transcription[endCharIdx]) && isSpace(item[endWordCharIdx]))
    ) {
      endCharIdx--;
      endWordCharIdx--;
      // If end of word, increment word index
      if (endWordCharIdx < 0) {
        // Add word to result
        resultEnd.splice(0, 0, items[endWordIdx]);
        // Keep track of last pronunciation
        if (notSpaceOrPunct(items[endWordIdx]))
          itemAfterConflict = items[endWordIdx];
        endWordIdx--;
        if (endWordIdx > 0)
          endWordCharIdx = itemContent(items[endWordIdx]).length - 1;
      }
    } else {
      // Found a mismatch. Expand out to include any enclosed words.
      endCharIdx += itemContent(items[endWordIdx]).length - 1 - endWordCharIdx;
      conflictFound = true;
      break;
    }
  }

  if (conflictFound) {
    var result = undefined;
    const newText = transcription.substring(startCharIdx, endCharIdx + 1);

    // Assemble the new item given preceding and proceeding pronunciations
    if (notSpaceOrPunct(resultStart[resultStart.length - 1])) {
      resultStart[resultStart.length - 1].alternatives[0].content += newText;
      result = [...resultStart, ...resultEnd];
    } else if (notSpaceOrPunct(resultEnd[0])) {
      resultEnd[0].alternatives[0].content =
        newText + itemContent(resultEnd[0]);
      result = [...resultStart, ...resultEnd];
    } else {
      const newItem = {
        start_time: String(
          Math.min(
            Boolean(itemBeforeConflict)
              ? itemBeforeConflict.end_time
              : getFirstTimestamp(items),
            Boolean(itemAfterConflict)
              ? itemAfterConflict.start_time
              : getLastTimestamp(items)
          )
        ),
        end_time: String(
          Math.max(
            Boolean(itemBeforeConflict)
              ? itemBeforeConflict.end_time
              : getFirstTimestamp(items),
            Boolean(itemAfterConflict)
              ? itemAfterConflict.start_time
              : getLastTimestamp(items)
          )
        ),
        alternatives: [
          { content: transcription.substring(startCharIdx, endCharIdx + 1) },
        ],
        generated: true,
      };

      result = [...resultStart, newItem, ...resultEnd];
    }

    // Remove blankspace to match AWS transcribe schema
    return result.filter((item) => {
      return (
        item.type !== "blankspace" &&
        itemContent(item) !== " " &&
        itemContent(item) !== ""
      );
    });
  }
}

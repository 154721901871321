import _ from "lodash";
import {
  faTimes,
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, TextField, Button } from "@material-ui/core";
import React, { Component } from "react";
import { languageShortCodes } from "./../../utils/data";
import { insertItemIntoArray, removeItemFromArray } from "utils/utils";

class TranslationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.translation,
    };
  }

  updateTranslationLocal = (data) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      ...this.state,
      ...data,

      updateTimeout: setTimeout(() => {
        this.props.updateTranslation({
          text: this.state.text,
          translations: this.state.translations,
        });
      }, 150),
    });
  };

  render() {
    return (
      <div id="translation-container">
        {this.props.textEditable && (
          <div className="remove-button">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={this.props.deleteTranslation}
            />
          </div>
        )}
        <div className="translation-line">
          <TextField
            autoFocus
            value={this.state.text}
            variant="outlined"
            margin="dense"
            className="translation-long background-white"
            placeholder="Original text"
            multiline
            disabled={!this.props.textEditable}
            onChange={(e) =>
              this.updateTranslationLocal({ text: e.target.value })
            }
          />
        </div>

        {this.state.translations &&
          this.state.translations.map((t, idx) => {
            return (
              <div
                className="translation-line"
                key={`TRANSLATION_LINE_${this.state.text}_${t[0]}`}
              >
                <Select
                  value={t[0]}
                  variant="outlined"
                  className="translation-short"
                  margin="dense"
                  onChange={(e) => {
                    var newTransArray = this.state.translations;
                    newTransArray[idx][0] = e.target.value;
                    this.updateTranslationLocal({
                      translations: newTransArray,
                    });
                  }}
                  style={{ maxHeight: 38 }}
                >
                  {Object.keys(languageShortCodes).map((sc) => {
                    return (
                      <MenuItem
                        key={`TRANSLATION_MENU_ITEM_${this.state.text}_${sc}`}
                        value={sc}
                      >
                        {languageShortCodes[sc]}
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  value={t[1]}
                  variant="outlined"
                  margin="dense"
                  className="translation-flex background-white"
                  placeholder="Translated text"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    var newTransArray = this.state.translations;
                    newTransArray[idx][1] = e.target.value;
                    this.updateTranslationLocal({
                      translations: newTransArray,
                    });
                  }}
                />
                <div className="selection-options-updown add-translation-lang">
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    className="add-response"
                    onClick={() => {
                      this.updateTranslationLocal({
                        translations: insertItemIntoArray(
                          this.state.translations,
                          ["", ""],
                          idx + 1
                        ),
                      });
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMinusSquare}
                    className="add-response"
                    onClick={() => {
                      if (this.state.translations.length <= 1) return; // Need at least 1 translation
                      this.updateTranslationLocal({
                        translations: removeItemFromArray(
                          this.state.translations,
                          idx
                        ),
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}
class Translation extends Component {
  state = { translations: [] };

  convertTranslationDictIntoList(translationInfo) {
    // Converts from a dictionary (better for the DB) to a dict with list (better for the UI)
    var translationInfoList = new Array(translationInfo.length);
    for (let i = 0; i < translationInfo.length; i++) {
      let text = translationInfo[i].text;
      let arr = [];
      for (let key in translationInfo[i]) {
        if (key !== "text") arr.push([key, translationInfo[i][key]]);
      }
      translationInfoList[i] = { text: text, translations: arr };
    }
    return translationInfoList;
  }

  convertTranslationListIntoDict(translations) {
    let translationsCopy = _.cloneDeep(translations);
    for (let i = 0; i < translationsCopy.length; i++) {
      let dict = {};
      for (let t in translationsCopy[i].translations) {
        dict[translationsCopy[i].translations[t][0]] =
          translationsCopy[i].translations[t][1];
      }
      translationsCopy[i] = { text: translationsCopy[i].text, ...dict };
    }
    return translationsCopy;
  }

  componentDidMount() {
    this.setState({
      translations: this.convertTranslationDictIntoList(
        this.props.translationInfo
      ),
    });
  }
  updateTranslation = (idx, translation) => {
    let updatedTranslation = _.cloneDeep(this.state.translations);
    updatedTranslation[idx] = { ...updatedTranslation[idx], ...translation };
    this.setState({ translations: updatedTranslation });
    this.props.changeTranslationInfo(
      this.convertTranslationListIntoDict(updatedTranslation)
    );
  };
  propagateStateToParent = () => {
    this.props.changeTranslationInfo(
      this.convertTranslationListIntoDict(this.state.translations)
    );
  };
  deleteTranslation = (idx) => {
    this.setState(
      {
        translations: removeItemFromArray(this.state.translations, idx),
      },
      this.propagateStateToParent
    );
  };
  addTranslation = () => {
    this.setState(
      {
        translations: this.state.translations.concat({
          text: "",
          translations: [["", ""]],
        }),
      },
      this.propagateStateToParent
    );
  };
  render() {
    return (
      <>
        {this.state.translations &&
          this.state.translations.map((t, idx) => {
            return (
              <TranslationItem
                key={`TRANSLATION_${t.text}_ITEM_${idx}`}
                updateTranslation={(tr) => this.updateTranslation(idx, tr)}
                deleteTranslation={() => this.deleteTranslation(idx)}
                translation={t}
                textEditable={this.props.textEditable}
              />
            );
          })}
        {this.props.textEditable && (
          <Button
            className="gray-outlined-button"
            style={{ width: "100%" }}
            variant="outlined"
            onClick={this.addTranslation}
          >
            + Add Translation
          </Button>
        )}
      </>
    );
  }
}

export default Translation;

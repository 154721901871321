import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { truncateStringWithEllipsis } from "../../utils/utils";
const useStyles = makeStyles({
  root: {
    width: 210,
    height: 230,
  },
  templateText: {
    fontSize: "16px !important",
    fontWeight: "bold",
    color: "black",
    textAlign: "left",
  },
  bodyText: {
    textAlign: "left",
  },
});

export default function TemplateTile({
  action,
  name,
  image,
  template,
  description,
}) {
  const classes = useStyles();

  return (
    <Grid item align="center">
      <Card
        className={clsx(classes.root, "template-survey-tile")}
        onClick={() => action(template)}
      >
        <CardMedia component="img" alt={name} height="140" image={image} />
        <CardContent>
          <Typography className={classes.templateText}>{name}</Typography>
          <Typography className={classes.bodyText}>
            {truncateStringWithEllipsis(description, 50)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

import React from "react";
import ReactDOMServer from "react-dom/server";
import { getContrastYIQ } from "utils/utils";

const getIframeContent = (
  embed,
  surveyId,
  page,
  showOptions,
  helperText,
  color = "#62cb88"
) => {
  const popover = (
    <a
      data-phonic-embed="popover"
      data-survey-id={surveyId}
      data-page={page}
      data-start-open={!showOptions}
      data-staging={!showOptions}
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        width: 48,
        height: 48,
        backgroundColor: `${color}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        boxShadow: "0 0 8px rgba(0, 0, 0, 0.15)",
        cursor: "pointer",
      }}
      tabIndex="0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="22"
        height="25"
        viewBox="0 0 512 512"
        aria-hidden="true"
        focusable="false"
        id="launch-icon"
      >
        <path
          fill={getContrastYIQ(color, 170)}
          d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
        />
      </svg>
    </a>
  );

  const sideDrawer = (
    <a
      data-phonic-embed="sidedrawer"
      data-survey-id={surveyId}
      data-page={page}
      data-start-open={!showOptions}
      data-staging={!showOptions}
      data-helper-text={helperText}
      data-button-color={color}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="22"
        height="25"
        viewBox="0 0 512 512"
        aria-hidden="true"
        focusable="false"
        id="launch-icon"
        tabIndex="0"
      >
        <path
          fill={getContrastYIQ(color, 170)}
          d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
        />
      </svg>
    </a>
  );

  const inline = (
    <div
      data-phonic-embed="widget"
      data-survey-id={surveyId}
      data-page={page}
      data-staging={!showOptions}
      style={{ width: "100%", height: 420, margin: "0 auto" }}
      id="launch-icon"
    ></div>
  );

  const skeleton = ReactDOMServer.renderToString(
    <div className="content">
      <div className="skeleton half" />
      <div className="skeleton" />
      <div className="skeleton" />
      {embed !== "inline" && (
        <>
          <div className="skeleton spacer" />
          <div className="skeleton half" />
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
          <div className="skeleton" />
        </>
      )}
      {embed === "popover" && popover}
      {embed === "sidedrawer" && sideDrawer}
      {embed === "inline" && inline}
    </div>
  );

  const content = [`${skeleton}`];
  content.push(
    `<script defer src="https://api.phonic.ai/survey-embed.min.js"></script>`
  );
  content.push(
    `<style>
      html, body {
        height: 100%;
        margin: 0;
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .content {
        padding-bottom: 24px;
      }
      .skeleton {
        height: 20px;
        background-color: #D0CFCE;
        transform: scale(1, 0.60);
        border-radius: 4px;
        margin-top: 14px;
        margin-bottom: 14px;
      }
      .half {
        width: 50%;
      }
      .spacer {
        background-color: white;
      }
    </style>`
  );
  return content.join("");
};

const EmbedPreview = ({
  embed,
  surveyId,
  showOptions,
  previewId,
  page,
  helperText,
  color,
  ...props
}) => {
  return (
    <div {...props} className="preview-wrapper">
      <iframe
        title="preview"
        frameBorder="0"
        srcDoc={getIframeContent(
          embed,
          surveyId,
          page,
          showOptions,
          helperText,
          color
        )}
        key={previewId}
      />
    </div>
  );
};

export default EmbedPreview;

import React from "react";

import AddFileButton from "./AddFileButton";
import ConfirmEditButton from "./ConfirmEditButton";
import ClipLoader from "react-spinners/ClipLoader";

// File groups handle all their children recursively.
// They have 2 primary independant state variables:
//   - Selected means they are currenlty being viewed
//   - Open means the folder or file is open, but not necessarily selected

// Props:
// name is the file name
// path is the full file path, containing the file name
export default class FileGroup extends React.Component {
  state = { open: false, editingName: false, editedName: "" };

  componentDidMount() {
    // If the current path starts with the URL path, open the folder
    // If it exactly equals, treat it as a click event to render the file
    if (this.props.urlPath && this.props.urlPath.startsWith(this.props.path)) {
      this.setState({ open: true });
      if (this.props.urlPath === this.props.path) this.ref.click();
    }
  }

  handleSelectClick = (e) => {
    e.preventDefault();
    if (!this.state.open) this.setState({ open: true });
    this.props.handleSelectClick(this.props);
  };
  handleOpenClick = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };
  handleAddClick = (e) => {
    e.preventDefault();
  };

  addFile = () => this.props.addFile(this.props);
  addFolder = () => this.props.addFolder(this.props);
  deleteFolder = () => this.props.deleteFolder(this.props);

  getPadding = () => {
    if (!this.props.depth || this.props.depth === 1) return "2px 14px";
    return `2px 14px 2px ${this.props.depth * 14}px`;
  };
  isSelected = () => {
    return this.props.selectedFile === this.props.path;
  };
  isLoading = () => {
    return this.props.fileUploadProgress[[this.props.path]] === "IN_PROGRESS";
  };
  confirmNameEdit = () => {
    if (this.props.name !== this.state.editedName) {
      this.props.updateFiles(this.props.collection, {
        oldPath: this.props.path,
        newPath: [
          ...this.props.path.split("/").slice(0, -1),
          this.state.editedName,
        ].join("/"),
      });
    }
    this.setState({ editingName: false, editedName: "" });
  };

  getButton = () => {
    if (this.isLoading())
      return (
        <div className="file-button-wrapper">
          <ClipLoader size={12} color={"grey"} loading={true} />
        </div>
      );
    if (this.state.editingName)
      return <ConfirmEditButton handleClick={this.confirmNameEdit} />;
    return (
      <AddFileButton
        type={this.props.type}
        handleClick={this.handleAddClick}
        addFile={this.addFile}
        addFolder={this.addFolder}
        deleteFolder={this.deleteFolder}
      />
    );
  };

  render() {
    return (
      <>
        <a
          ref={(ref) => (this.ref = ref)}
          rel="noopener noreferrer"
          className="file-group"
          onClick={this.handleSelectClick}
        >
          <div role="button" tabIndex={0} className="file-group-item">
            <div
              className="file-group-outer"
              style={{
                padding: this.getPadding(),
                background: this.isSelected() ? "rgba(55, 53, 47, 0.08)" : null,
                color: this.isSelected() ? "rgb(55, 53, 47)" : null,
                fontWeight: this.isSelected() ? 600 : null,
              }}
            >
              <div className="file-group-inner" onClick={this.handleOpenClick}>
                {this.props.type === "FOLDER" && (
                  <div
                    role="button"
                    tabIndex={0}
                    className="file-button-wrapper"
                    onClick={this.handleOpenClick}
                  >
                    <svg
                      viewBox="0 0 100 100"
                      className="file-group-triangle"
                      style={{
                        transform: this.state.open
                          ? "rotateZ(180deg)"
                          : "rotateZ(90deg)",
                      }}
                    >
                      <polygon points="5.9,88.2 50,11.8 94.1,88.2 " />
                    </svg>
                  </div>
                )}
              </div>
              <div
                className="file-group-item-text"
                onDoubleClick={() => {
                  this.setState({
                    editingName: true,
                    editedName: this.props.name,
                  });
                }}
              >
                {!this.state.editingName ? (
                  <div className="notranslate">{this.props.name}</div>
                ) : (
                  <input
                    type="text"
                    value={this.state.editedName}
                    onChange={(event) => {
                      this.setState({
                        editedName: event.target.value,
                      });
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === "Escape") {
                        this.setState({ editingName: false });
                        event.preventDefault();
                        event.stopPropagation();
                      }
                    }}
                  />
                )}
              </div>
              {this.getButton()}
            </div>
          </div>
        </a>
        {this.state.open &&
          this.props.children &&
          Object.values(this.props.children).map((c) => (
            <FileGroup
              {...this.props}
              {...c}
              key={c.path + c.name}
              depth={this.props.depth + 1}
              path={[this.props.path, c.name].join("/")}
            />
          ))}
      </>
    );
  }
}

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@material-ui/core";

const MergeTopicPopup = ({
  open,
  closePopup,
  selectedTopics,
  mergeTopics,
  questionId,
}) => {
  const [topic, setTopic] = useState("");

  const handleChange = (evt) => {
    setTopic(evt.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const topicIds = selectedTopics.map((t) => t._id);

    if (topicIds.length > 2) {
      const otherTopics = selectedTopics.filter((t) => t._id !== topic);

      mergeTopics(topic, otherTopics, questionId);
    } else if (topicIds.length === 2) {
      if (topicIds.indexOf(topic) === 0) {
        mergeTopics(selectedTopics[0]._id, selectedTopics[1]._id, questionId);
      } else if (selectedTopics.indexOf(topic === 1)) {
        mergeTopics(selectedTopics[1]._id, selectedTopics[0]._id, questionId);
      }
    }

    closePopup();
  };

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      aria-labelledby="create-topic"
      fullWidth
    >
      <DialogContent>
        <div id="form-dialog-title" style={{ marginBottom: 20 }}>
          <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
            Merge Topics
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Select an existing topic to combine under.
            </FormLabel>
            <RadioGroup
              aria-label="selected-topic"
              name="selected-topic"
              value={topic}
              onChange={handleChange}
            >
              {selectedTopics.map((t) => (
                <FormControlLabel
                  value={t._id}
                  control={<Radio />}
                  label={t.Text}
                  key={t._id}
                />
              ))}
            </RadioGroup>
            <span className="create-report-button">
              <Button
                variant="contained"
                type="submit"
                color="primary"
              >
                Merge Topics
              </Button>
            </span>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MergeTopicPopup;

import React from "react";
import {
  faTimes,
  faPlusSquare,
  faMinusSquare,
  faCode,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Select,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  IconButton,
  Slider,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { questionTypes } from "utils/questionTypes";
import PropCheckbox from "../../components/SurveyCreation/PropCheckbox";
import {
  supportedLanguages,
  amazonComprehendSupportedLanguages,
  amazonComprehendPIIEntityTypes,
} from "utils/data";
import {
  isAudioType,
  isAudioOrVideoType,
  isRankingType,
  isSelectionNatureStrict,
  findListOfListsIndex,
  range,
  insertItemIntoArray,
  removeItemFromArray,
} from "utils/utils";
import QuestionJavascriptPopup from "components/Popups/QuestionJavascriptPopup";
import { notification } from "utils/utils";
import { getBlankQuestion } from "utils/defaults";

/* Number of options/settings visible when a section is collapsed */
const collapsedLength = {
  piiRedactionBasic: 3,
};

class QuestionConfiguration extends React.Component {
  state = { qinfo: {}, piiRedaction: { basic: { isCollapsed: true } } };

  /**
   * NOTE: This function is deprecated. Use `updateQInfo` to make question updates.
   * @param {*} prop The question property to update.
   * @param {*} value Value applied to question property.
   * @param {*} refreshPreview Whether or not the application should save once edits are made.
   */
  changeQInfo = (prop, value, refreshPreview = true) => {
    const qinfo = {
      ...this.props.qinfo,
      [prop]: value,
    };

    /* Amazon Comprehend (which performs sentiment and topics) only supports a
     * subset of the available transcription languages */
    if (!this.isLangSupportedByAmazonComprehend(qinfo.transcriptionLanguage)) {
      qinfo.doSentiment = false;
      qinfo.doTopics = false;
    }

    /* PII Redaction is only supported for a subset of available languages */
    // TODO: extend this to array
    if (!this.langSupportsPIIRedaction(qinfo.transcriptionLanguage)) {
      // TODO: old vs. new surveys
      qinfo.piiRedaction = getBlankQuestion().piiRedaction;
    }

    this.props.changeQInfo(qinfo, this.props.id, refreshPreview);
  };

  /**
   * Replacement for `changeQInfo` which allows for multiple edits at once.
   * TODO: Replace all occurrences of the above with this function.
   * @param {*} updates Dictionary containing the updates to be made.
   * @param {*} refreshPreview Whether or not the application should save once edits are made.
   */
  updateQInfo = (updates, refreshPreview = true) => {
    const qinfo = {
      ...this.props.qinfo,
      ...updates,
    };

    /* Amazon Comprehend (which performs sentiment and topics) only supports a
     * subset of the available transcription languages */
    if (!this.isLangSupportedByAmazonComprehend(qinfo.transcriptionLanguage)) {
      qinfo.doSentiment = false;
      qinfo.doTopics = false;
    }

    // TODO: add PII redaction unset here

    this.props.changeQInfo(qinfo, this.props.id, refreshPreview);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ qinfo: {} });
    }
  }

  updateQuestionPropWithTimedUpdate = (prop, value) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      qinfo: {
        ...this.state.qinfo,
        [prop]: value,
      },
      updateTimeout: setTimeout(() => {
        this.changeQInfo(prop, value);
      }, 500),
    });
  };
  getQInfoIfMissingState = (key) => {
    return this.state.qinfo[key]
      ? this.state.qinfo[key]
      : this.props.qinfo[key];
  };

  getQuestionSelection = () => {
    return (
      <Select
        variant="outlined"
        fullWidth
        margin="dense"
        value={this.props.qinfo.type}
        onChange={(e) => this.changeQInfo("type", e.target.value, true)}
        disabled={this.props.surveyInfo.state !== "DRAFT"}
      >
        {questionTypes.map((type) => {
          if (type.key === "WELCOME") return null;
          return (
            <MenuItem value={type.key} key={type.key}>
              <span className="type-selection-logos">
                {type.icons.map((icon, idx) => {
                  return (
                    <FontAwesomeIcon
                      key={`ICON-${idx}`}
                      style={{ marginLeft: 3, marginRight: 3 }}
                      icon={icon}
                    />
                  );
                })}
              </span>
              &nbsp;&nbsp;<b>{type.name}</b> : {type.description}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  removeFromRandomGroup = () => {
    const questionId = this.props.qinfo.questionId;
    // Convert the lists into sets for simplicity
    var randomGroups = this.props.surveyInfo.randomGroups.map((group) => {
      return new Set(group);
    });
    // Get the index of the current question random group & delete
    var sourceIdx = -1;
    randomGroups.forEach((group, idx) => {
      if (group.has(questionId)) sourceIdx = idx;
    });
    if (sourceIdx === -1) {
      return;
    }
    randomGroups[sourceIdx].delete(questionId);
    // Remove if the set is now empty
    if (randomGroups[sourceIdx].size === 0) {
      randomGroups.splice(sourceIdx, 1);
    }
    // Back to lists and update
    randomGroups = randomGroups.map((group) => {
      return Array.from(group);
    });
    this.props.changeInfo({ randomGroups: randomGroups });
  };
  changeRandomGroup = (e) => {
    const questionId = this.props.qinfo.questionId;
    // Convert the lists into sets for simplicity
    var randomGroups = this.props.surveyInfo.randomGroups.map((group) => {
      return new Set(group);
    });
    // Get the index of the current question random group
    var sourceIdx = -1;
    randomGroups.forEach((group, idx) => {
      if (group.has(questionId)) sourceIdx = idx;
    });
    if (sourceIdx === e.target.value) return;
    if (sourceIdx !== -1) {
      randomGroups[sourceIdx].delete(questionId);
    }
    if (e.target.value >= randomGroups.length) {
      randomGroups.push(new Set([questionId]));
    } else if (e.target.value !== -1) {
      randomGroups[e.target.value].add(questionId);
    }
    // Remove if the set is now empty
    if (sourceIdx !== -1 && randomGroups[sourceIdx].size === 0) {
      randomGroups.splice(sourceIdx, 1);
    }
    // Back to lists and update
    var randomGroups = randomGroups.map((group) => {
      return Array.from(group);
    });
    this.props.changeInfo({ randomGroups: randomGroups });
  };

  saveCustomScript = (code) => {
    this.updateQInfo(
      {
        customScript: code,
        enableCustomScript: code !== "" && code !== undefined,
      },
      true
    );
    this.setState({ javascriptPopupOpen: false });
  };

  /**
   * Returns whether a given language code is supported by Amazon Comprehend.
   *  Options to detect sentiment and entities within transcribed responses are
   *  disabled for languages which aren't supported by Comprehend.
   * @param {string} langCode Language code with region (e.g. en-US)
   * @returns {boolean} True if language is supported, False otherwise
   */
  isLangSupportedByAmazonComprehend = (langCode) => {
    const twoLetterCode = langCode.split("-")[0];
    return (
      amazonComprehendSupportedLanguages.includes(langCode) ||
      amazonComprehendSupportedLanguages.includes(twoLetterCode)
    );
  };

  /**
   * Returns whether a given language code is supported for PII Redaction
   * @param {string} langCode Language code with region (e.g. en-US)
   * @returns {boolean} True if language is supported, False otherwise
   */
  langSupportsPIIRedaction = (langCode) => {
    // Currently, only US English is supported
    return langCode === "en-US";
  };

  // TODO: docstring
  basicPIIEntitiesToRender = () => {
    /* Number of entity types shown when the menu is collapsed */
    const collapsedViewLength = 3;

    if (this.state.piiRedaction.basic.isCollapsed) {
      return amazonComprehendPIIEntityTypes.slice(0, collapsedViewLength);
    } else {
      return amazonComprehendPIIEntityTypes;
    }
  };

  /**
   * Event handler that is invoked when a PII entity checkbox is toggled
   * @param {string} code PII entity code as specified by Amazon Comprehend
   */
  toggleBasicPIIEntity = (code) => {
    let selected = this.props.qinfo.piiRedaction.basic;
    const index = selected.indexOf(code);

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(code);
    }

    this.changeQInfo("piiRedaction", {
      ...this.props.qinfo.piiRedaction,
      basic: selected,
    });
  };

  // TODO: docstring
  toggleAllBasicPIIEntities = () => {
    const numSelected = this.props.qinfo.piiRedaction.basic.length;
    const maxSelected = amazonComprehendPIIEntityTypes.length;

    if (numSelected == maxSelected) {
      // Unselect all
      this.changeQInfo("piiRedaction", {
        ...this.props.qinfo.piiRedaction,
        basic: [],
      });
    } else {
      // Select all
      const allEntityCodes = amazonComprehendPIIEntityTypes.map((x) => x.code);
      this.changeQInfo("piiRedaction", {
        ...this.props.qinfo.piiRedaction,
        basic: allEntityCodes,
      });
    }
  };

  render() {
    const isAudioVideo = isAudioOrVideoType(this.props.qinfo.type);
    const isEnterpriseTier = this.props.tier === "ENTERPRISE";

    /* Recording */
    const doTranscription = this.props.qinfo.doTranscription;
    const transcriptionLang = this.props.qinfo.transcriptionLanguage;

    /* Analysis */
    const langSupportedByComprehend = this.isLangSupportedByAmazonComprehend(
      this.props.qinfo.transcriptionLanguage
    );
    const disableAmazonComprehendProps =
      !this.props.qinfo.doTranscription || !langSupportedByComprehend;

    /* Content Redaction */
    const langSupportsPIIRedaction =
      this.langSupportsPIIRedaction(transcriptionLang);
    const piiRedaction = this.props.qinfo.piiRedaction;
    const numBasicPIIEntities = amazonComprehendPIIEntityTypes.length;
    const piiRedactionDisabled = !doTranscription || !langSupportsPIIRedaction;
    const numberRedactionEnabled =
      this.props.qinfo.piiRedaction.numbers.enabled;

    return (
      <div id="question-settings">
        <QuestionJavascriptPopup
          open={this.state.javascriptPopupOpen}
          onSave={this.saveCustomScript}
          onClose={() => this.setState({ javascriptPopupOpen: false })}
          customScript={this.props.qinfo.customScript}
        />
        <span className="x-button">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={this.props.closeQuestionConfig}
          />
        </span>
        <h3>General</h3>
        <div style={{ marginBottom: 10 }}>{this.getQuestionSelection()}</div>
        {this.props.surveyInfo.type !== "DISPLAY" && (
          <div className="transcription-options">
            <PropCheckbox
              label={{
                text: "Mark Optional",
                placement: "right",
              }}
              update={(e) =>
                this.changeQInfo("optional", e.target.checked, true)
              }
              value={this.props.qinfo.optional}
              disabled={false}
            />
          </div>
        )}
        <div className="transcription-options">
          <PropCheckbox
            label={{
              text: (
                <span className="documentation-help">
                  Assign{" "}
                  <a
                    href="https://docs.phonic.ai/docs/using-data-mapping"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Label
                  </a>
                </span>
              ),
              placement: "right",
            }}
            update={(e) => {
              var value = e.target.checked ? true : undefined;
              this.changeQInfo("assignDatamap", value, true);
            }}
            value={Boolean(this.props.qinfo.assignDatamap != undefined)}
          />
          {this.props.qinfo.assignDatamap && (
            <TextField
              key={`DATAMAP-${this.props.qinfo.questionId}`}
              className="timer-selector"
              variant="outlined"
              margin="dense"
              fullWidth
              value={this.getQInfoIfMissingState("datamap")}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate(
                  "datamap",
                  e.target.value
                )
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
          )}
        </div>
        <div className="transcription-options">
          <PropCheckbox
            label={{
              text: "Time Minimum (sec)",
              placement: "right",
            }}
            update={(e) =>
              this.changeQInfo("includeCountdownTimer", e.target.checked)
            }
            value={this.props.qinfo.includeCountdownTimer ? true : false}
            disabled={false}
          />
          {this.props.qinfo.includeCountdownTimer && (
            <TextField
              className="timer-selector"
              variant="outlined"
              margin="dense"
              placeholder="Seconds"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  this.updateQuestionPropWithTimedUpdate(
                    "countdownTimer",
                    e.target.value
                  );
                }
              }}
              value={this.getQInfoIfMissingState("countdownTimer")}
              disabled={!this.props.qinfo.includeCountdownTimer}
              inputProps={{ style: { textAlign: "right" } }}
            />
          )}
        </div>
        <hr />
        {isAudioVideo && (
          <>
            <h3>Recording</h3>
            <div>
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: "Computer Transcription",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo("doTranscription", e.target.checked)
                  }
                  value={this.props.qinfo.doTranscription}
                />
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={
                    this.props.qinfo.transcriptionLanguage
                      ? this.props.qinfo.transcriptionLanguage
                      : "en-US"
                  }
                  disabled={!this.props.qinfo.doTranscription}
                  onChange={(e) =>
                    this.changeQInfo("transcriptionLanguage", e.target.value)
                  }
                >
                  {supportedLanguages.map((l) => {
                    return (
                      <MenuItem key={l.code} value={l.code}>
                        {l.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: "Text Backup",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo("allowBackupText", e.target.checked)
                  }
                  value={this.props.qinfo.allowBackupText}
                />
              </div>
              {isAudioType(this.props.qinfo.type) && (
                <>
                  <div className="transcription-options">
                    <PropCheckbox
                      label={{
                        text: "Record Immediately",
                        placement: "right",
                      }}
                      update={(e) =>
                        this.changeQInfo("startRecording", e.target.checked)
                      }
                      value={this.props.qinfo.startRecording}
                    />
                  </div>
                  <div className="transcription-options">
                    <PropCheckbox
                      label={{
                        text: "Audio Playback",
                        placement: "right",
                      }}
                      update={(e) =>
                        this.changeQInfo("showAudioPlayback", e.target.checked)
                      }
                      value={this.props.qinfo.showAudioPlayback}
                    />
                  </div>
                </>
              )}
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: "Maximum Duration (sec)",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo("limitResponseLength", e.target.checked)
                  }
                  value={Boolean(this.props.qinfo.limitResponseLength)}
                />
                {this.props.qinfo.limitResponseLength === true && (
                  <TextField
                    className="timer-selector"
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.updateQuestionPropWithTimedUpdate(
                          "maxResponseLength",
                          e.target.value
                        );
                      }
                    }}
                    value={this.getQInfoIfMissingState("maxResponseLength")}
                    disabled={!this.props.qinfo.limitResponseLength}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                )}
              </div>
            </div>
            <hr />
          </>
        )}
        {isSelectionNatureStrict(this.props.qinfo.type) && (
          <>
            <h3>Selection</h3>
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Multiple Selections",
                  placement: "right",
                }}
                update={(e) => this.changeQInfo("exclusive", !e.target.checked)}
                value={!this.props.qinfo.exclusive}
              />
            </div>
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Randomize Ordering",
                  placement: "right",
                }}
                update={(e) => this.changeQInfo("randomize", e.target.checked)}
                value={this.props.qinfo.randomize}
              />
            </div>
            <hr />
          </>
        )}
        {isRankingType(this.props.qinfo.type) && (
          <>
            <h3>Ranking</h3>
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Randomize Ordering",
                  placement: "right",
                }}
                update={(e) => this.changeQInfo("randomize", e.target.checked)}
                value={this.props.qinfo.randomize}
              />
            </div>
          </>
        )}
        {/* TODO: Add doTopics to text questions */}
        {isAudioVideo && (
          <>
            <h3>Analysis</h3>
            {!langSupportedByComprehend && this.props.qinfo.doTranscription && (
              <Alert
                severity="warning"
                className="comprehend-lang-not-supported-alert"
              >
                Analysis features are not supported for the selected
                transcription language
              </Alert>
            )}
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Sentiment Analysis",
                  placement: "right",
                }}
                update={(e) =>
                  this.changeQInfo("doSentiment", e.target.checked)
                }
                value={this.props.qinfo.doSentiment}
                disabled={disableAmazonComprehendProps}
              />
            </div>
            <hr />
          </>
        )}
        {isAudioVideo && isEnterpriseTier && (
          <>
            <h3>Content Redaction</h3>
            <h4>Basic</h4>
            <div className="info-text">
              <p>
                Omit one or more types of sensitive personally identifiable
                information (PII) from your survey responses.{" "}
                <a href="https://docs.phonic.ai/docs/custom-javascript">
                  Learn more
                </a>
              </p>
            </div>
            {doTranscription && !langSupportsPIIRedaction && (
              <Alert
                severity="warning"
                className="pii-lang-not-supported-alert"
              >
                Change the transcription language to <b>US English</b> to use
                content redaction
              </Alert>
            )}
            <div className="transcription-options">
              <div className="checkbox-with-label">
                <Checkbox
                  checked={piiRedaction.basic.length == numBasicPIIEntities}
                  indeterminate={
                    piiRedaction.basic.length >= 1 &&
                    piiRedaction.basic.length < numBasicPIIEntities
                  }
                  onChange={() => this.toggleAllBasicPIIEntities()}
                  disabled={piiRedactionDisabled}
                />
                <p className="selection-header exclusive">{"Select All"}</p>
              </div>
            </div>
            {this.basicPIIEntitiesToRender().map((item) => {
              return (
                <div className="transcription-options transcription-options-indented">
                  <PropCheckbox
                    label={{
                      text: item.text,
                      placement: "right",
                    }}
                    update={() => this.toggleBasicPIIEntity(item.code)}
                    value={piiRedaction.basic.includes(item.code)}
                    disabled={piiRedactionDisabled}
                  />
                </div>
              );
            })}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                aria-label="toggle content redaction options"
                size="small"
                onClick={() => {
                  this.setState((prevState) => ({
                    piiRedaction: {
                      basic: {
                        isCollapsed: !prevState.piiRedaction.basic.isCollapsed,
                      },
                    },
                  }));
                }}
              >
                <FontAwesomeIcon
                  icon={
                    this.state.piiRedaction.basic.isCollapsed
                      ? faChevronDown
                      : faChevronUp
                  }
                />
              </IconButton>
            </div>
            <h4>Numbers</h4>
            <div className="info-text">
              <p>
                Omit numbers of specified length that don't belong to the
                selected categories above.{" "}
                <a href="https://docs.phonic.ai/docs/custom-javascript">
                  Learn more
                </a>
              </p>
            </div>
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Enable Number Redaction",
                  placement: "right",
                }}
                update={(e) => {
                  console.log(e.target.checked);
                  console.log({
                    ...this.props.qinfo.piiRedaction,
                    numbers: {
                      ...this.props.qinfo.piiRedaction.numbers,
                      enabled: e.target.checked,
                    },
                  });
                  this.changeQInfo("piiRedaction", {
                    ...this.props.qinfo.piiRedaction,
                    numbers: {
                      ...this.props.qinfo.piiRedaction.numbers,
                      enabled: e.target.checked,
                    },
                  });
                }}
                value={numberRedactionEnabled}
                disabled={piiRedactionDisabled}
              />
            </div>
            <TextField
              select
              variant="outlined"
              margin="dense"
              fullWidth
              label="Lower Bound"
              value={this.props.qinfo.piiRedaction.numbers.lower}
              disabled={!numberRedactionEnabled}
              onChange={(event) =>
                this.changeQInfo("piiRedaction", {
                  // TODO: update upper if lower is now bigger
                  ...this.props.qinfo.piiRedaction,
                  numbers: {
                    ...this.props.qinfo.piiRedaction.numbers,
                    lower: event.target.value,
                  },
                })
              }
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              {range(1, 10).map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              variant="outlined"
              margin="dense"
              fullWidth
              label="Upper Bound"
              value={this.props.qinfo.piiRedaction.numbers.upper}
              disabled={!numberRedactionEnabled}
              onChange={(event) =>
                this.changeQInfo("piiRedaction", {
                  ...this.props.qinfo.piiRedaction,
                  numbers: {
                    ...this.props.qinfo.piiRedaction.numbers,
                    upper: event.target.value,
                  },
                })
              }
            >
              {range(this.props.qinfo.piiRedaction.numbers.lower, 10).map(
                (i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                )
              )}
              <MenuItem key={-1} value={-1}>
                None
              </MenuItem>
            </TextField>
            <hr />
          </>
        )}
        {this.props.qinfo.type.includes("SLIDER") && (
          <>
            <h3>Bounds</h3>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Lower Bound"
              value={this.getQInfoIfMissingState("bounds").lower}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate("bounds", {
                  ...this.props.qinfo.bounds,
                  lower: e.target.value,
                })
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Lower Bound Label"
              value={this.getQInfoIfMissingState("bounds").lowerLabel}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate("bounds", {
                  ...this.props.qinfo.bounds,
                  lowerLabel: e.target.value,
                })
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Upper Bound"
              value={this.getQInfoIfMissingState("bounds").upper}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate("bounds", {
                  ...this.props.qinfo.bounds,
                  upper: e.target.value,
                })
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Upper Bound Label"
              value={this.getQInfoIfMissingState("bounds").upperLabel}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate("bounds", {
                  ...this.props.qinfo.bounds,
                  upperLabel: e.target.value,
                })
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="Interval"
              value={this.getQInfoIfMissingState("bounds").interval}
              onChange={(e) =>
                this.updateQuestionPropWithTimedUpdate("bounds", {
                  ...this.props.qinfo.bounds,
                  interval: e.target.value,
                })
              }
              style={{ marginBottom: 16, marginTop: 0 }}
            />
            <hr />
          </>
        )}
        {this.props.qinfo.type === "SCREEN" && (
          <>
            <h3>Screen Recording</h3>
            <div>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Redirect Link (Ex. https://phonic.ai)"
                value={this.state.redirectLink || this.props.redirectLink}
                onChange={(e) =>
                  this.updateQuestionPropWithTimedUpdate(
                    "redirectLink",
                    e.target.value
                  )
                }
                style={{ marginBottom: 16, marginTop: 0 }}
              />
            </div>
            <hr />
          </>
        )}
        {this.props.qinfo.type === "LIKERT" && (
          <>
            <h3>Custom Likert Labels</h3>
            <div className="info-text">
              <PropCheckbox
                label={{
                  text: "Enable Custom Likert Labels",
                  placement: "right",
                }}
                update={async (e) => {
                  const checked = e.target.checked;
                  await this.changeQInfo("useCustomLikertOptions", checked);
                  if (checked && !this.props.qinfo.customLikertOptions) {
                    await this.changeQInfo("customLikertOptions", [
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                    ]);
                  }
                }}
                value={this.props.qinfo.useCustomLikertOptions ? true : false}
                disabled={false}
              />
            </div>
            {this.props.qinfo.customLikertOptions &&
              this.props.qinfo.customLikertOptions.map((option, idx) => {
                return (
                  <div
                    className="translation-line"
                    style={{ display: "flex", alignItems: "center" }}
                    key={`LIKERT_LABEL_${idx}`}
                  >
                    <TextField
                      value={option}
                      variant="outlined"
                      margin="dense"
                      className="translation-flex custom-likert-label-input"
                      placeholder="Value"
                      onChange={(e) => {
                        const newCustomLikerOptions = [
                          ...this.props.qinfo.customLikertOptions,
                        ];
                        newCustomLikerOptions[idx] = e.target.value;
                        this.changeQInfo(
                          "customLikertOptions",
                          newCustomLikerOptions
                        );
                      }}
                    />
                    <div className="selection-options-updown add-translation-lang">
                      <FontAwesomeIcon
                        icon={faPlusSquare}
                        className="add-response"
                        onClick={() => {
                          this.changeQInfo(
                            "customLikertOptions",
                            insertItemIntoArray(
                              this.props.qinfo.customLikertOptions,
                              "",
                              idx + 1
                            )
                          );
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faMinusSquare}
                        className="add-response"
                        onClick={() => {
                          if (this.props.qinfo.customLikertOptions.length <= 1)
                            return;
                          this.changeQInfo(
                            "customLikertOptions",
                            removeItemFromArray(
                              this.props.qinfo.customLikertOptions,
                              idx
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            <hr />
          </>
        )}
        <h3>Randomization</h3>
        <div className="info-text">
          <p>
            Randomize questions in groups. Learn more about group randomization
            in our{" "}
            <a href="https://docs.phonic.ai/docs/randomization">
              documentation
            </a>
          </p>
        </div>
        <Select
          fullWidth
          variant="outlined"
          margin="dense"
          disabled={this.props.tier === "FREE"}
          value={findListOfListsIndex(
            this.props.surveyInfo.randomGroups,
            this.props.qinfo.questionId
          )}
          onChange={this.changeRandomGroup}
        >
          <MenuItem value={-1}>None</MenuItem>
          {range(0, this.props.surveyInfo.randomGroups.length - 1).map((i) => {
            return (
              <MenuItem key={`GROUP-${i}`} value={i}>
                Group {i + 1}
              </MenuItem>
            );
          })}
          <MenuItem value={this.props.surveyInfo.randomGroups.length}>
            New Group
          </MenuItem>
        </Select>
        <hr />
        <h3>Question Javascript</h3>
        <div className="info-text">
          <p>
            Run a script when this question is shown. Learn more about question
            javascript in our{" "}
            <a href="https://docs.phonic.ai/docs/custom-javascript">
              documentation
            </a>
          </p>
          <Button
            className="phonic-outlined-button"
            variant="outlined"
            color="primary"
            onClick={() => {
              if (this.props.tier !== "ENTERPRISE") {
                notification(
                  "This feature is not included on your current tier",
                  "Please upgrade to continue",
                  "warning"
                );
                return;
              }
              this.setState({ javascriptPopupOpen: true });
            }}
          >
            <FontAwesomeIcon icon={faCode} /> &nbsp;Javascript Editor
          </Button>
        </div>
      </div>
    );
  }
}

export default QuestionConfiguration;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

class MediaDownloadPopup extends React.Component {
  state = {
    email: "",
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            Download Media Responses
          </h2>
          <p>
            A zip file containing all media collected by this survey will be
            sent to your email. If no email is provided, media will be sent to
            your account email.
          </p>
          <TextField
            variant="outlined"
            placeholder={this.props.user ? this.props.user.email : "email"}
            autoFocus
            fullWidth
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
          />
          <DialogActions>
            <Button
              variant="outlined"
              component="label"
              onClick={(e) => {
                this.props.onClose(e);
              }}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                this.props.onClose();
                this.props.startSurveyMediaDownload(
                  this.props.surveyId,
                  this.state.email !== "" ? this.state.email : undefined
                );
              }}
              color="primary"
            >
              Download
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default MediaDownloadPopup;

import React from "react";
import clsx from "clsx";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScaleLoader } from "react-spinners";

export default function ChartContainer({
  title,
  className,
  children,
  loading,
  loadingColor,
  action,
  hideMenu,
}) {
  return (
    <div className="chart-container">
      {!hideMenu && (
        <span className="top-right">
          <div
            className="rounded-icon-container gray-text"
            onClick={() => {
              action();
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </span>
      )}
      <div className="graph-title">
        <span>{title}</span>
      </div>
      <div className={clsx("custom-chart-container", className)}>
        <div>
          <div className="dummy" />
          <div className={clsx("contents", loading && "flex-container")}>
            {loading ? (
              <div className="flex-container">
                <ScaleLoader
                  width={"2px"}
                  height={"30"}
                  color={loadingColor ? loadingColor : "#62cb88"}
                  loading={true}
                />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { getFormattedTime } from "utils/utils";

const StyledSlider = withStyles({
  root: {
    color: "#28bf8f",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 35,
    width: 16,
    borderRadius: 3,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -17,
    marginLeft: -8,
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

export default function ClipTrimmer(props) {
  return (
    <div>
      <StyledSlider
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={getFormattedTime}
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={props.handleChange}
        onChangeCommitted={props.commitTrimChange}
        step={0.01}
      />
    </div>
  );
}

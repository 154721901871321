import React, { Component } from "react";
import { withRouter } from "react-router";
import SurveyQuestion from "./SurveyQuestion";
import AddQuestionButton from "./SurveyCreation/AddQuestionButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../utils/utils";
import WelcomeScreens from "./SurveyCreation/WelcomeScreens";
import NewQuestionPopup from "./Popups/NewQuestionPopup";
import _ from "lodash";

class SurveyQuestions extends Component {
  state = {
    expanded: new Set(),
    welcomeExpanded: false,
    addQuestionPopupOpen: false,
  };
  expandQuestion = (idx) => {
    if (idx === "welcome") {
      this.setState({
        welcomeExpanded: true,
      });
      return;
    }
    var newSet = new Set(this.state.expanded);
    newSet.add(idx);
    this.setState({
      expanded: newSet,
    });
  };
  collapseQuestion = (idx) => {
    if (idx === "welcome") {
      this.setState({
        welcomeExpanded: false,
      });
      return;
    }
    var newSet = new Set(this.state.expanded);
    newSet.delete(idx);
    this.setState({
      expanded: newSet,
    });
  };
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let questionsClone = _.cloneDeep(this.props.questions);
    const questions = reorder(
      questionsClone,
      result.source.index,
      result.destination.index
    );
    this.setState({ expanded: new Set() });
    this.props.changeInfo({ questions: questions }, true);
  };
  addQuestion = (idx, type) => {
    this.props.modFunctions.addQuestion(idx, type);
  };
  addQuestionWithPopup = (idx) => {
    this.setState({ addQuestionPopupOpen: true, addQuestionIdx: idx });
  };
  render() {
    return (
      <>
        <NewQuestionPopup
          open={this.state.addQuestionPopupOpen}
          handleClose={() => this.setState({ addQuestionPopupOpen: false })}
          addQuestion={this.addQuestion}
          info={this.props.info}
          changeInfo={this.props.changeInfo}
          modFunctions={this.props.modFunctions}
          idx={this.state.addQuestionIdx}
        />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, droppableSnapshot) => (
              <div>
                <div className="question-outer-container">
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      flexGrow: 1,
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {!this.props.info.removeWelcomeScreen && (
                      <div
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        <WelcomeScreens
                          info={this.props.info}
                          expanded={this.state.welcomeExpanded}
                          expandQuestion={this.expandQuestion}
                          collapseQuestion={this.collapseQuestion}
                          changeInfo={this.props.changeInfo}
                          modFunctions={this.props.modFunctions}
                        />
                      </div>
                    )}
                    {this.props.questions.map((qinfo, idx) => {
                      const isQuestionSelected =
                        this.props.selectedQuestionId === qinfo.questionId;
                      return (
                        <Draggable
                          key={`DRAGGABLE-${qinfo.questionId}`}
                          draggableId={`DRAGGABLE-${qinfo.questionId}`}
                          index={idx}
                        >
                          {(provided) => {
                            const style = {
                              position: "relative",
                              zIndex: isQuestionSelected ? "1" : "10",
                              ...provided.draggableProps.style,
                            };

                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={style}
                              >
                                <SurveyQuestion
                                  id={qinfo.questionId}
                                  idx={idx}
                                  key={`QUESTION-${qinfo.questionId}`}
                                  qinfo={qinfo}
                                  modFunctions={this.props.modFunctions}
                                  borderBottom={
                                    idx !== this.props.questions.length - 1
                                  }
                                  surveyProps={this.props.surveyProps}
                                  expandQuestion={this.expandQuestion}
                                  questions={this.props.questions}
                                  collapseQuestion={this.collapseQuestion}
                                  addQuestionBelow={() =>
                                    this.addQuestionWithPopup(idx + 1)
                                  }
                                  selected={isQuestionSelected}
                                  questionFocusOverride={
                                    this.props.questionFocusOverride
                                  }
                                  // Hide the add question popup during drag
                                  // actions to prevent layering issues
                                  hideAddQuestionPopup={
                                    droppableSnapshot.isDraggingOver
                                  }
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ marginBottom: 20 }}>
          <AddQuestionButton
            action={() => this.addQuestionWithPopup(undefined)}
            text="+ New Question"
          />
        </div>
      </>
    );
  }
}

export default withRouter(SurveyQuestions);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import clsx from "clsx";

const iconMap = {
  NEW: "pe-7s-note",
  DRAFT: "pe-7s-file",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 230,
  },
}));

export default function GetStartedTile(props) {
  const classes = useStyles();

  return (
    <Grid
      item
      onClick={props.action}
      align="center"
      className={clsx(classes.root)}
    >
      <div
        className="template-survey-tile template-survey-new"
        style={{ display: "flex", textAlign: "center" }}
      >
        <p
          style={{
            fontSize: 28,
            fontWeight: 700,
            marginBottom: -5,
            marginTop: -10,
          }}
        >
          {<i className={iconMap[props.type]} />}
        </p>
        <h3 style={{ fontSize: 20 }}>{props.title}</h3>
      </div>
    </Grid>
  );
}

import React from "react";
import { Paper, TextField } from "@material-ui/core";
import SquareChip from "components/SquareChip";
import { Autocomplete } from "@material-ui/lab";

const AutocompletePicker = ({
  title,
  audience,
  modifyAudience,
  optionType,
  optionList,
}) => {
  const removeOption = (idx) => {
    let options = audience[optionType];
    options.splice(idx, 1);
    modifyAudience({ [optionType]: options });
  };
  const addOption = (option) => {
    if (!option) return;
    // If objects doesn't exist, create it.
    let options = [];
    if (audience[optionType]) {
      options = audience[optionType];
    }
    // If the object already exists, don't duplicate it.
    if (options.some((o) => o.name === option.name)) {
      return;
    }
    options.push({ ...option, quota: undefined });
    modifyAudience({ [optionType]: options });
  };

  return (
    <Paper className="recruit-paper">
      <h3>{title}</h3>

      {audience[optionType] && audience[optionType].length > 0 && (
        <div className="selected-options flex-container justify-left wrap">
          {audience[optionType].map((option, idx) => {
            return (
              <SquareChip
                key={`OPTION-${optionType}-${idx}`}
                text={option.name}
                onDelete={removeOption}
              />
            );
          })}
        </div>
      )}
      <div className="flex-container justify-left">
        <Autocomplete
          id="combo-box-demo"
          options={optionList}
          getOptionLabel={(option) => option.name}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              margin="dense"
              {...params}
              label={title}
              variant="outlined"
            />
          )}
          onChange={(e, v) => addOption(v)}
        />
      </div>
      {/* TODO: Build quotas */}
      {/* <hr />
      <div className="flex-container justify-left">
        <Switch /> Quotas
      </div> */}
    </Paper>
  );
};

export default AutocompletePicker;

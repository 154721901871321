import React from "react";
import _ from "lodash";
import { Grid, Breadcrumbs, Link } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getBlankAudience } from "utils/defaults";
import {
  setPageTitle,
  getSurveys,
  getPanelRequest,
  updatePanelRequest,
  requestPanelPaymentIntent,
} from "../redux/actions";
import PanelCheckoutPopup from "components/Popups/PanelCheckoutPopup";
import AudiencePane from "components/Recruit/AudiencePane";
import FilteringPane from "components/Recruit/FilteringPane";
import OptionsPane from "components/Recruit/OptionsPane";
import PricingPane from "components/Recruit/PricingPane";
import { getBlankPanelRequest } from "utils/defaults";
import { notification } from "utils/utils";
import StripeWrapper from "components/Payments/StripeWrapper";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    surveys: state.surveys,
    panelRequest: state.currentPanelRequest,
    saving: state.loading.UpdatePanelRequest,
    loadingSurveys: state.loading.MySurveys,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  getSurveys,
  getPanelRequest,
  updatePanelRequest,
  requestPanelPaymentIntent,
};

class CreatePanelRequest extends React.Component {
  state = {
    selectedAudience: 0,
    panelRequest: getBlankPanelRequest(),
    proceedPopup: false,
  };
  componentDidMount() {
    this.props.getSurveys();
    this.props.getPanelRequest(this.props.match.params.panelRequestId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panelRequest !== this.props.panelRequest) {
      this.setState({
        panelRequest: {
          ...this.state.panelRequest,
          ...this.props.panelRequest,
        },
      });
    }
    if (
      prevProps.panelRequest !== this.props.panelRequest &&
      this.props.panelRequest.name
    ) {
      this.props.setPageTitle(
        <div className="breadcrumbs-title">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => this.props.history.push("/recruit")}
            >
              Recruit
            </Link>
            <Link color="inherit">{this.props.panelRequest.name}</Link>
          </Breadcrumbs>
        </div>
      );
    }
  }

  modifyAudience = (idx, info, save) => {
    let audiences = _.cloneDeep(this.state.panelRequest.audiences);
    audiences[idx] = {
      ...audiences[idx],
      ...info,
    };
    this.setState(
      {
        panelRequest: { ...this.state.panelRequest, audiences: audiences },
      },
      this.savePanelRequest
    );
  };

  addAudience = () => {
    this.setState(
      {
        panelRequest: {
          ...this.state.panelRequest,
          audiences: [...this.state.panelRequest.audiences, getBlankAudience()],
        },
      },
      this.savePanelRequest
    );
  };
  removeAudience = (idx) => {
    if (this.state.panelRequest.audiences.length === 1) {
      return;
    }
    let audiences = _.cloneDeep(this.state.panelRequest.audiences);
    let selectedAudience = _.clone(this.state.selectedAudience);
    audiences.splice(idx, 1);
    if (idx <= selectedAudience && selectedAudience !== 0) {
      selectedAudience--;
    }
    this.setState(
      {
        selectedAudience: selectedAudience,
        panelRequest: { ...this.state.panelRequest, audiences: audiences },
      },
      this.savePanelRequest
    );
  };
  modifyPanelRequest = (info) => {
    this.setState(
      { panelRequest: { ...this.state.panelRequest, ...info } },
      this.savePanelRequest
    );
  };

  selectAudience = (idx) => {
    this.setState({ selectedAudience: idx });
  };
  savePanelRequest = () => {
    this.props.updatePanelRequest(
      this.state.panelRequest._id,
      this.state.panelRequest
    );
  };
  setProceedPopupOpen = () => {
    if (
      !this.state.panelRequest.surveyId ||
      !this.state.panelRequest.audiences
    ) {
      notification(
        "Cannot submit panel request.",
        "Make sure you've selected a survey and created an audience.",
        "warning"
      );
      return;
    }
    this.setState({ proceedPopup: true });
  };
  setProceedPopupClosed = () => {
    this.setState({ proceedPopup: false });
  };

  render() {
    return (
      <div id="recruit">
        <Helmet>
          <title>Recruit</title>
        </Helmet>
        <StripeWrapper>
          <PanelCheckoutPopup
            open={this.state.proceedPopup}
            onClose={this.setProceedPopupClosed}
            getPaymentIntent={this.props.requestPanelPaymentIntent}
            panelRequest={this.state.panelRequest}
            onCheckoutCompleted={() => this.props.history.push("/recruit")}
          />
        </StripeWrapper>
        <Grid container>
          <AudiencePane
            audiences={this.state.panelRequest.audiences}
            selectedAudience={this.state.selectedAudience}
            modifyAudience={this.modifyAudience}
            selectAudience={this.selectAudience}
            removeAudience={this.removeAudience}
            addAudience={this.addAudience}
          />
          <FilteringPane
            audience={
              this.state.panelRequest.audiences[this.state.selectedAudience]
            }
            modifyAudience={(info) =>
              this.modifyAudience(this.state.selectedAudience, info)
            }
          />
          <OptionsPane
            audience={
              this.state.panelRequest.audiences[this.state.selectedAudience]
            }
            modifyAudience={(info) =>
              this.modifyAudience(this.state.selectedAudience, info)
            }
          />
          <PricingPane
            surveys={this.props.surveys}
            panelRequest={this.state.panelRequest}
            modifyPanelRequest={this.modifyPanelRequest}
            loadingNewPrice={this.props.saving}
            loadingSurveys={this.props.loadingSurveys}
            setProceedPopupOpen={this.setProceedPopupOpen}
          />
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePanelRequest);

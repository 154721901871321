import React, { Component } from "react";
import clsx from "clsx";

class VideoPlayer extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.skipTime !== this.props.skipTime) {
      this.video.currentTime = this.props.skipTime;
    }
  }

  seek = (time) => {
    if (isFinite(time)) {
      this.video.currentTime = time;
    }
  };

  handleTimeUpdate = () => {
    const { onTimeUpdate } = this.props;
    if (onTimeUpdate) {
      onTimeUpdate(this.video.currentTime);
    }
  };

  render() {
    return (
      <video
        ref={(c) => {
          this.video = c;
        }}
        controls
        controlsList="nodownload"
        onTimeUpdate={this.handleTimeUpdate}
        className={clsx("video-player", this.props.size)}
      >
        <source src={this.props.uri} />
        Your browser does not support the video tag.
      </video>
    );
  }
}

export default VideoPlayer;

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faMinusSquare,
  faCaretSquareUp,
  faCaretSquareDown,
  faAnchor,
  faEllipsisV,
  faCodeBranch,
  faCog,
  faImage,
  faLanguage,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ToolTipPopup from "./Popups/ToolTipPopup";
import { Button, Chip, CircularProgress, Input, Menu } from "@material-ui/core";
import { connect } from "react-redux";
import { uploadQuestionStimuli } from "../redux/actions";
import StimuliPopup from "./Popups/StimuliPopup";
import TranslationQuestionPopup from "./Popups/TranslationQuestionPopup";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import VideocamIcon from "@material-ui/icons/Videocam";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ImageIcon from "@material-ui/icons/Image";
import { isSelectionNature } from "utils/utils";
import { getTypeJSXIcon } from "utils/utils";
import CreateLogicPopup from "./Popups/CreateLogicPopup";
import { isSelectionNatureStrict } from "utils/utils";
import { FocusStates } from "../utils/data";
import _ from "lodash";

const mapStateToProps = (state) => {
  return {
    loadingQuestionMedia: state.loadingQuestionMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadQuestionStimuli: (
      surveyId,
      surveyQuestions,
      stimuliType,
      file,
      mediaProps
    ) =>
      dispatch(
        uploadQuestionStimuli(
          surveyId,
          surveyQuestions,
          stimuliType,
          file,
          mediaProps
        )
      ),
  };
};

class SurveyQuestion extends Component {
  constructor(props) {
    super(props);

    /* A reference to the question title input field is used to manage focus */
    this.questionTitleField = React.createRef();
  }

  state = {
    tab: "basic",
    qinfo: {
      text: "",
      type: "",
      options: [],
      exclusive: true,
      randomize: false,
      countdownTimer: 0,
      redirectLink: "",
    },
    showSkipLogic: false,
    optionsAnchorEl: undefined,
    stimuliPopupOpen: false,
    translationPopupOpen: false,
    popupOpen: false,
    updateTimeout: 0,
    optionFocusRetryCount: 0,
  };

  movePropsToState = async () => {
    await this.setState({
      qinfo: this.props.qinfo,
    });
  };
  componentDidMount() {
    this.movePropsToState();
  }

  componentDidUpdate(prevProps) {
    /* Manually assign focus to title field if necessary */
    const focusOverride = this.props.questionFocusOverride;
    if (this.props.selected && focusOverride === FocusStates.SET_FOCUS) {
      this.questionTitleField.current.focus();
    } else if (focusOverride === FocusStates.BLUR_ALL) {
      this.questionTitleField.current.blur();
    }

    if (this.props.qinfo != prevProps.qinfo) {
      this.movePropsToState();
    }
  }

  updateParent = () => {
    this.props.modFunctions.changeQInfo(this.state.qinfo, this.props.id, true);
  };
  changeText = (e) => {
    this.setState({
      qinfo: {
        ...this.state.qinfo,
        text: e.target.value,
      },
    });
  };

  /**  Preferred method of updating question properties */
  updateQuestion = (qinfo) => {
    this.props.modFunctions.changeQInfo(
      { ...this.state.qinfo, ...qinfo },
      this.props.id,
      true
    );
  };

  // Update the local state and set a timeout to update the parent too
  updateQuestionPropWithTimedUpdate = (prop, value) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      qinfo: {
        ...this.state.qinfo,
        [prop]: value,
      },
      updateTimeout: setTimeout(() => {
        this.updateParent();
      }, 500),
    });
  };

  changeOption = (e, idx) => {
    var options = this.state.qinfo.options;
    options[idx].text = e.target.value;
    this.setState({
      options: options,
    });
  };
  removeQuestion = () => {
    this.setState({
      renderSelf: false,
      showSelection: false,
    });
  };

  onPopupClose = () => {
    this.setState({
      popupOpen: false,
    });
  };
  openPopup = () => {
    this.setState({
      popupOpen: true,
    });
  };

  stimuliPopupOpenClose = (val) => {
    this.setState({
      stimuliPopupOpen: val,
    });
  };
  translationPopupOpenClose = (val) => {
    this.setState({
      translationPopupOpen: val,
    });
  };
  changeQInfo = (prop, value) => {
    const qinfo = {
      ...this.props.qinfo,
      [prop]: value,
    };
    this.props.modFunctions.changeQInfo(qinfo, this.props.id);
  };
  changeTabs = (e, v) => {
    this.setState({
      tab: v,
    });
  };
  toggleOptionSkipLogic = () => {
    this.setState({
      showSkipLogic: !this.state.showSkipLogic,
    });
  };

  toggleTranscriptionParent = (e) => {
    const doSentiment = e.target.checked ? this.props.qinfo.doSentiment : false;
    const qinfo = {
      ...this.props.qinfo,
      doTranscription: e.target.checked,
      doSentiment: doSentiment,
    };
    this.props.modFunctions.changeQInfo(qinfo, this.props.id);
  };

  toggleMultipleSelectionsParent = (e) => {
    const qinfo = {
      ...this.props.qinfo,
      exclusive: !e.target.checked,
    };
    this.props.modFunctions.changeQInfo(qinfo, this.props.id);
  };

  changeQuestionOption = (idx, prop, value) => {
    var options = this.props.qinfo.options;
    options[idx][prop] = value;
    const qinfo = {
      ...this.props.qinfo,
      options: options,
    };
    this.props.modFunctions.changeQInfo(qinfo, this.props.id);
  };
  setOptionsAnchorEl = (event) => {
    event.stopPropagation();
    this.setState({
      optionsAnchorEl: event.currentTarget,
    });
  };

  submitMedia = (type, file, mediaProps) => {
    if (type === "AUDIO" || type === "IMAGE" || type === "VIDEO") {
      this.props.modFunctions.pushSurveyUpdates();
      this.props.uploadQuestionStimuli(
        this.props.surveyProps.id,
        this.props.idx,
        type,
        file,
        mediaProps
      );
    } else if (
      type === "YOUTUBE" ||
      type === "GOOGLE_DRIVE" ||
      type === "IFRAME"
    ) {
      const qinfo = {
        ...this.props.qinfo,
        stimuli: { type: type, url: file },
      };
      this.props.modFunctions.changeQInfo(qinfo, this.props.id, true);
    } else if (type === "HTML") {
      const qinfo = {
        ...this.props.qinfo,
        stimuli: { type: type, htmlContent: mediaProps.htmlContent },
      };
      this.props.modFunctions.changeQInfo(qinfo, this.props.id, true);
    }
  };

  focusOption = (idx) => {
    const RETRY_COUNT = 5;
    if (idx < 0 || idx > this.props.qinfo.options.length) return;
    const element = document.getElementById(
      `QUESTION-${this.props.idx}-OPTION-${idx}`
    );
    if (element) {
      element.focus();
      this.setState({ optionFocusRetryCount: 0 });
    } else {
      if (this.state.optionFocusRetryCount > RETRY_COUNT) {
        this.setState({ optionFocusRetryCount: 0 });
      } else {
        this.setState({
          optionFocusRetryCount: this.state.optionFocusRetryCount + 1,
        });
        setTimeout(() => this.focusOption(idx), 100);
      }
    }
  };

  handleOptionPaste = async (pastedData, optionIdx) => {
    const pastedOptions = pastedData.split("\n");
    await this.props.modFunctions.addQuestionOption(
      this.props.idx,
      optionIdx,
      pastedOptions.length - 1
    );
    await this.movePropsToState();
    let options = _.cloneDeep(this.state.qinfo.options);
    let idx = optionIdx;
    for (const optionText of pastedOptions) {
      options[idx].text += optionText;
      idx++;
    }
    this.focusOption(optionIdx);

    this.setState(
      {
        qinfo: {
          ...this.state.qinfo,
          options: options,
        },
      },
      this.updateParent
    );
  };

  getOptionsJSX = () => {
    if (!this.props.expanded) {
      return (
        <div className="bullets">
          {this.state.qinfo.options.map((option, idx) => {
            return (
              <div key={`BULLETS-${idx}`} className="option">
                {this.props.qinfo.randomize && (
                  <FontAwesomeIcon
                    icon={faAnchor}
                    className={`add-response anchor ${
                      option.fixed ? "selected" : ""
                    }
             ${!this.props.qinfo.randomize ? "displaynone" : ""}`}
                    onClick={() =>
                      this.changeQuestionOption(idx, "fixed", !option.fixed)
                    }
                  />
                )}
                <span>-&nbsp;</span>
                <input
                  id={`QUESTION-${this.props.idx}-OPTION-${idx}`}
                  type="text"
                  className="Mui-Overrides sub text bullets"
                  placeholder={
                    isSelectionNatureStrict(this.props.qinfo.type)
                      ? "choice..."
                      : "prompt..."
                  }
                  value={this.state.qinfo.options[idx].text}
                  onPaste={(e) => {
                    e.preventDefault();
                    this.handleOptionPaste(
                      e.clipboardData.getData("text/plain"),
                      idx
                    );
                  }}
                  onChange={(e) => {
                    var options = this.state.qinfo.options;
                    options[idx].text = e.target.value;
                    this.updateQuestionPropWithTimedUpdate("options", options);
                  }}
                  onKeyDown={(e) => {
                    // Enter
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      this.props.modFunctions.addQuestionOption(
                        this.props.idx,
                        idx
                      );
                      this.focusOption(idx + 1);
                    }
                    // Delete
                    if (e.keyCode === 8) {
                      if (
                        this.state.qinfo.options[idx].text === "" &&
                        this.state.qinfo.options.length > 1
                      ) {
                        e.preventDefault();
                        this.props.modFunctions.removeQuestionOption(
                          this.props.idx,
                          idx
                        );
                        // Focus previous input
                        if (idx > 0) {
                          this.focusOption(idx - 1);
                        }
                      }
                    }
                    // Up
                    if (e.keyCode === 38) {
                      this.focusOption(idx - 1);
                    }
                    // Down
                    if (e.keyCode === 40) {
                      this.focusOption(idx + 1);
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return this.state.qinfo.options.map((option, idx) => {
      return (
        <div key={"OPTION-" + idx} className="selection-option-container">
          {(this.props.surveyProps.state === "DRAFT" ||
            (this.props.qinfo &&
              this.props.qinfo.newQuestion === true &&
              this.state.showSkipLogic !== true)) && (
            <div className="selection-options-updown">
              <FontAwesomeIcon
                icon={faCaretSquareUp}
                className="add-response"
                onClick={() => {
                  this.props.expandQuestion(undefined);
                  this.props.modFunctions.moveQuestionOptionUp(
                    this.props.idx,
                    idx
                  );
                }}
              />{" "}
              <FontAwesomeIcon
                icon={faCaretSquareDown}
                className="add-response"
                onClick={() => {
                  this.props.expandQuestion(undefined);
                  this.props.modFunctions.moveQuestionOptionDown(
                    this.props.idx,
                    idx
                  );
                }}
              />
            </div>
          )}
          <TextField
            variant="outlined"
            margin="dense"
            label="Option"
            value={this.state.qinfo.options[idx].text}
            className="option-flexgrow double"
            onChange={(e) => this.changeOption(e, idx)}
            onBlur={(e) =>
              this.changeQuestionOption(idx, "text", e.target.value)
            }
          />
          {(this.props.surveyProps.state === "DRAFT" ||
            (this.props.qinfo &&
              this.props.qinfo.newQuestion === true &&
              this.state.showSkipLogic !== true)) && (
            <div className="selection-options-updown addremove">
              <FontAwesomeIcon
                icon={faPlusSquare}
                className="add-response"
                onClick={() =>
                  this.props.modFunctions.addQuestionOption(this.props.idx, idx)
                }
              />{" "}
              <FontAwesomeIcon
                icon={faMinusSquare}
                className="add-response"
                onClick={() => {
                  this.props.modFunctions.removeQuestionOption(
                    this.props.idx,
                    idx
                  );
                }}
              />
            </div>
          )}
          <FontAwesomeIcon
            icon={faAnchor}
            className={`add-response anchor ${option.fixed ? "selected" : ""}
             ${!this.props.qinfo.randomize ? "displaynone" : ""}`}
            onClick={() =>
              this.changeQuestionOption(idx, "fixed", !option.fixed)
            }
          />
        </div>
      );
    });
  };

  removeFromRandomGroup = () => {
    const questionId = this.props.qinfo.questionId;
    // Convert the lists into sets for simplicity
    var randomGroups = this.props.surveyProps.randomGroups.map((group) => {
      return new Set(group);
    });
    // Get the index of the current question random group & delete
    var sourceIdx = -1;
    randomGroups.forEach((group, idx) => {
      if (group.has(questionId)) sourceIdx = idx;
    });
    if (sourceIdx === -1) {
      return;
    }
    randomGroups[sourceIdx].delete(questionId);
    // Remove if the set is now empty
    if (randomGroups[sourceIdx].size === 0) {
      randomGroups.splice(sourceIdx, 1);
    }
    // Back to lists and update
    randomGroups = randomGroups.map((group) => {
      return Array.from(group);
    });
    this.props.modFunctions.changeInfo({ randomGroups: randomGroups });
  };

  getQuestionMenu = () => {
    return (
      <span className="x-button-bottom">
        <div
          className="icon-container"
          onClick={() => {
            this.setState({ translationPopupOpen: true });
          }}
        >
          <FontAwesomeIcon icon={faLanguage} />
        </div>
        <div
          className="icon-container"
          onClick={() => {
            this.setState({ stimuliPopupOpen: true });
          }}
        >
          <FontAwesomeIcon icon={faImage} />
        </div>
        <div
          className="icon-container"
          onClick={() => {
            this.setState({ logicPopupOpen: true });
          }}
        >
          <FontAwesomeIcon icon={faCodeBranch} />
        </div>
        <div
          className="icon-container"
          onClick={() => {
            this.props.modFunctions.openQuestionConfig();
          }}
        >
          <FontAwesomeIcon icon={faCog} />
        </div>
        <div className="icon-container" onClick={this.setOptionsAnchorEl}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={this.state.optionsAnchorEl}
          keepMounted
          open={Boolean(this.state.optionsAnchorEl)}
          onClose={() => this.setState({ optionsAnchorEl: undefined })}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              this.props.modFunctions.duplicateQuestion(this.props.idx);
              this.setState({
                optionsAnchorEl: undefined,
              });
            }}
          >
            Duplicate Question
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              this.props.modFunctions.closeQuestionConfig();
              this.removeFromRandomGroup();
              this.props.modFunctions.removeQuestion(this.props.idx);
              this.props.expandQuestion(undefined);
              this.setState({
                optionsAnchorEl: undefined,
              });
            }}
          >
            Delete Question
          </MenuItem>
        </Menu>
      </span>
    );
  };

  changeStimuliAutoplay = (checked) => {
    // for existing stimuli
    const qinfo = {
      ...this.props.qinfo,
      stimuli: { ...this.props.qinfo.stimuli, autoPlay: checked },
    };
    this.props.modFunctions.changeQInfo(qinfo, this.props.id, true);
    this.stimuliPopupOpenClose(false);
  };
  render() {
    const showOptions =
      isSelectionNature(this.state.qinfo.type) || this.state.showSkipLogic;
    var chipIcon;
    if (this.props.qinfo.stimuli) {
      switch (this.props.qinfo.stimuli.type) {
        case "AUDIO":
          chipIcon = <GraphicEqIcon />;
          break;
        case "YOUTUBE":
          chipIcon = <YouTubeIcon />;
          break;
        case "IMAGE":
          chipIcon = <ImageIcon />;
          break;
        case "VIDEO":
          chipIcon = <VideocamIcon />;
          break;
      }
    }
    const chipJSX = (
      <div className="icon-container">
        <Chip
          size="small"
          icon={chipIcon}
          label={
            <>
              {this.props.loadingQuestionMedia === this.props.idx ? (
                <CircularProgress size={16} style={{ color: "white" }} />
              ) : (
                <span>
                  {this.props.qinfo.stimuli && this.props.qinfo.stimuli.type}
                </span>
              )}
            </>
          }
          onDelete={() => this.changeQInfo("stimuli", undefined)}
          color="primary"
          style={{ backgroundColor: "#3f51b5" }}
          onClick={() => {
            window.open(this.props.qinfo.stimuli.url, "_blank");
          }}
        />
      </div>
    );
    return (
      <>
        <div
          className={`builder-question ${
            this.props.selected || this.state.showQuestionMenu ? "darken" : ""
          }`}
          onClick={() => this.props.modFunctions.previewQuestion(this.props.id)}
        >
          <TranslationQuestionPopup
            open={this.state.translationPopupOpen}
            question={this.state.qinfo}
            onClose={() => this.translationPopupOpenClose(false)}
            updateQuestion={this.updateQuestion}
          />
          <StimuliPopup
            open={this.state.stimuliPopupOpen}
            onClose={() => this.stimuliPopupOpenClose(false)}
            idx={this.props.idx}
            onSubmit={this.submitMedia}
            stimuli={this.props.qinfo.stimuli}
            changeStimuliAutoplay={this.changeStimuliAutoplay}
          >
            {chipJSX}
          </StimuliPopup>
          <ToolTipPopup
            open={this.state.popupOpen}
            onClose={this.onPopupClose}
            questionType={this.state.qinfo.type}
          />
          <CreateLogicPopup
            open={this.state.logicPopupOpen}
            handleClose={() => this.setState({ logicPopupOpen: false })}
            questions={this.props.questions}
            qinfo={this.props.qinfo}
            changeQInfo={this.changeQInfo}
            changeQuestionOption={this.changeQuestionOption}
            idx={this.props.idx}
          />
          {!this.props.expanded ? (
            <div
              className="condensed"
              onMouseEnter={() => this.setState({ showQuestionMenu: true })}
              onMouseLeave={() => this.setState({ showQuestionMenu: false })}
              onMouseOver={() => this.setState({ showQuestionMenu: true })}
            >
              {this.state.showQuestionMenu && this.getQuestionMenu()}
              <div className="type-section">
                {getTypeJSXIcon(this.state.qinfo.type, this.props.idx + 1)}
              </div>
              <div className="info-section">
                <Input
                  multiline
                  autoFocus
                  disableUnderline
                  className="Mui-Overrides text"
                  placeholder="Question Text..."
                  value={this.state.qinfo.text}
                  inputRef={this.questionTitleField}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate(
                      "text",
                      e.target.value
                    )
                  }
                />
                <Input
                  multiline
                  autoFocus
                  disableUnderline
                  className="Mui-Overrides sub text"
                  placeholder="Description..."
                  value={
                    !this.state.qinfo.subText ? "" : this.state.qinfo.subText
                  }
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate(
                      "subText",
                      e.target.value
                    )
                  }
                />
                {(this.props.qinfo.stimuli ||
                  this.props.loadingQuestionMedia === this.props.idx) &&
                  chipJSX}
                {isSelectionNature(this.state.qinfo.type) &&
                  this.getOptionsJSX()}
              </div>
            </div>
          ) : (
            <div style={{ display: "grid" }}>
              <div className="question-container">
                {this.state.tab === "basic" && (
                  <>
                    {showOptions && (
                      <div>
                        <hr />
                        <div className="question-inner-container">
                          <h3 className="survey-info-titles">
                            Selection Options
                          </h3>
                        </div>
                        {this.getOptionsJSX()}
                        <div style={{ float: "right", marginTop: 16 }}>
                          <Button
                            variant="contained"
                            className={
                              this.props.surveyProps.tier === "FREE"
                                ? "button-grayed"
                                : "secondary-button-color"
                            }
                            onClick={() => {
                              this.setState({
                                tab: "logic",
                                showSkipLogic: true,
                              });
                            }}
                            disabled={
                              !this.props.surveyProps.tier ||
                              this.props.surveyProps.tier === "FREE"
                            }
                          >
                            <FontAwesomeIcon icon={faCodeBranch} /> &nbsp;Skip
                            Logic
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.tab === "recording" && <></>}
              </div>
            </div>
          )}
        </div>
        {this.state.showQuestionMenu && !this.props.hideAddQuestionPopup && (
          <div
            className="flex-container"
            onMouseEnter={() => this.setState({ showQuestionMenu: true })}
            onMouseLeave={() => this.setState({ showQuestionMenu: false })}
            onMouseOver={() => this.setState({ showQuestionMenu: true })}
          >
            <div
              className="circle-container"
              onClick={(e) => {
                e.stopPropagation();
                this.props.addQuestionBelow();
                this.setState({
                  optionsAnchorEl: undefined,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestion);

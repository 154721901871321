import React from "react";
import { useDispatch } from "react-redux";
import { updateResponseBuilder } from "redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import MediaPlayer from "components/MediaPlayer";
import SelectedOptions from "components/SelectedOptions";
import { getDemographics, isVideoType } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    backgroundColor: "#f9f9f9",
    borderRadius: 0,
  },
  pad1: {
    paddingTop: "25px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  pad2: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "75px",
      paddingRight: "75px",
    },
  },
  result: {
    paddingBottom: "16px",
  },
  transcript: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
  },
  demographicsPane: {
    textAlign: "right",
    fontStyle: "italic",
    fontSize: "0.875rem",
  },
  descriptor: {
    fontWeight: "bold",
    textTransform: "capitalize",
    letterSpacing: "0.1em",
  },
  demographicsText: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "0.875rem",
  },
  private: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 60px 0",
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

const ResponseCard = ({ question, response, hidden, changeReport }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let result = null;

  if (response.data) {
    if (Array.isArray(response.data)) {
      result = (
        <SelectedOptions
          responseData={response.data}
          options={question.options}
          exclusive={question.exclusive}
        />
      );
    } else {
      result = <Typography variant="body2">{response.data}</Typography>;
    }
  }

  /* What if transcription is currently turned off for a question, but user
      wants to show a transcription collected before it was turned off?
      i.e. should TRANSCRIPTION_AVAILABLE depend on question.doTranscription?
  */
  const TRANSCRIPTION_AVAILABLE =
    question.doTranscription &&
    response.features &&
    response.features.transcription;
  /* TEMPORARY: in the future, SHOW_TRANSCRIPTION will be set by user
      Could also make SHOW_TRANSCRIPTION depend on TRANSCRIPTION_AVAILABLE
      i.e. SHOW_TRANSCRIPTION = TRANSCRIPTION_AVAILABLE && true
  */
  const SHOW_TRANSCRIPTION = true;
  const IS_VIDEO = isVideoType(response.type);
  const SHOWN_DEMOGRAPHICS = response.demographics
    ? getDemographics(response.demographics)
    : [];

  const handleUpdateResponse = () => {
    if (changeReport) {
      changeReport({ suppressNotify: true }).then(() => {
        dispatch(updateResponseBuilder(response._id, { isPublic: true }));
      });
    }
  };

  return (
    <Card className={classes.root} elevation={0}>
      {hidden && (
        <div className={classes.private}>
          <Tooltip
            title="Because this response isn't public, it won't appear in the report. Only
          public responses or responses from surveys with public media will appear
          in reports."
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Chip
              size="small"
              icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
              label="Make Public"
              onClick={handleUpdateResponse}
            />
          </Tooltip>
        </div>
      )}
      <CardHeader
        title={
          <Typography component="h3" variant="h4">
            {question.text}
          </Typography>
        }
        disableTypography
        className={classes.pad1}
      />
      <CardContent className={classes.pad2}>
        {result && <div className={classes.result}>{result}</div>}
        {response.backupText && (
          <>
            {`${response.backupText}`}
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <FontAwesomeIcon
                icon={faUserEdit}
                style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
              />
              <Typography variant="overline" noWrap>
                Backup Text
              </Typography>
            </div>
          </>
        )}
        {response.uri && (
          /**
           * If there is both video and transcription,
           * layout changes to 2-column at medium breakpoint
           */
          <Grid container spacing={5}>
            <Grid
              container
              item
              xs={12}
              md={
                IS_VIDEO && SHOW_TRANSCRIPTION && TRANSCRIPTION_AVAILABLE
                  ? 5
                  : 12
              }
              justify="center"
            >
              <Grid
                item
                xs={12}
                sm={IS_VIDEO ? 6 : 12}
                md={IS_VIDEO && !SHOW_TRANSCRIPTION ? 6 : 12}
              >
                <MediaPlayer type={response.type} uri={response.uri} />
              </Grid>
            </Grid>
            {SHOW_TRANSCRIPTION && TRANSCRIPTION_AVAILABLE && (
              <Grid item xs={12} md={IS_VIDEO ? 7 : 12}>
                <Typography
                  variant="body2"
                  className={!IS_VIDEO ? classes.transcript : null}
                >
                  {`“${response.features.transcription}”`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
      {SHOWN_DEMOGRAPHICS.length > 0 ? (
        <CardContent className={`${classes.demographicsPane} ${classes.pad2}`}>
          {SHOWN_DEMOGRAPHICS.map((d, i) => (
            <span key={`${d.answer}-${i}`}>
              <Typography
                variant="subtitle2"
                className={classes.descriptor}
                display="inline"
              >
                {d.label ? `${d.label}` : null}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                className={classes.demographicsText}
                display="inline"
                component="p"
              >
                {`${d.value}${i < SHOWN_DEMOGRAPHICS.length - 1 ? `, ` : ``}`}
              </Typography>
            </span>
          ))}
        </CardContent>
      ) : null}
    </Card>
  );
};

export default ResponseCard;

import React, { Component } from "react";
import { wordCloudOptions } from "utils/utils";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";

class WordCloud extends Component {
  callbacks = this.props.interactive
    ? {
        onWordClick: (word) => {
          this.props.filterWord(word.text);
        },
      }
    : {};

  render() {
    return (
      <>
        <div style={{ width: "100%", height: "100%" }}>
          <ReactWordcloud
            options={wordCloudOptions}
            words={this.props.words}
            callbacks={this.callbacks}
          />
        </div>
      </>
    );
  }
}

export default WordCloud;

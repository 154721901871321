import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUser,
  updateUser,
  getPaymentPortalUrl,
  getCreditCheckoutId,
  getOrganization,
  postOrganizationInvite,
  deleteOrganizationInvite,
  acceptOrganizationInvite,
  updateOrganizationMember,
  removeOrganizationMember,
  cancelSubscription,
  startLoading,
  toggleUpgradePopup,
} from "redux/actions";
import { trackPage } from "analytics";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import {
  faEnvelope,
  faCreditCard,
  faTrash,
  faCheck,
  faPlusSquare,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Chip,
  Table,
  Checkbox,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import {
  validateEmail,
  notification,
  isPositiveInteger,
  formatCurrency,
  getAvatarUrl,
} from "../utils/utils";
import { isMonthly, getDiscountMultiplier } from "../utils/tiers";
import stripePromise from "../stripe";
import CancelPopup from "components/Popups/CancelPopup";
import FreeTrialAlert from "components/Pricing/FreeTrialAlert";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organization: state.organization,
    billingPortalLoading: state.loading["BillingPortal"],
    creditsCheckoutLoading: state.loading["CreditsCheckout"],
    updateUserLoading: state.loading["UpdateUser"],
    cancelLoading: state.loading["Cancel"],
    stripeCreditCheckoutId: state.stripeCreditCheckoutId,
  };
};

const mapDispatchToProps = {
  getUser,
  updateUser,
  getPaymentPortalUrl,
  getOrganization,
  getCreditCheckoutId,
  postOrganizationInvite,
  deleteOrganizationInvite,
  acceptOrganizationInvite,
  updateOrganizationMember,
  removeOrganizationMember,
  cancelSubscription,
  startLoading,
  toggleUpgradePopup,
};

class Settings extends Component {
  state = {
    email: null,
    lastName: null,
    firstName: null,
    password: "",
    inviteEmail: "",
    numCredits: 0,
  };

  componentDidMount() {
    trackPage("Profile");
    trackPage("Settings");
    this.props.getUser();
    this.props.getOrganization();
  }

  emailUpdated = (event) => {
    this.setState({ email: event.target.value });
  };

  firstNameUpdated = (event) => {
    this.setState({ firstName: event.target.value });
  };

  lastNameUpdated = (event) => {
    this.setState({ lastName: event.target.value });
  };

  passwordUpdated = (event) => {
    this.setState({ password: event.target.value });
  };

  inviteEmailUpdated = (event) => {
    this.setState({ inviteEmail: event.target.value });
  };

  inviteCheckboxUpdated = (event) => {
    this.setState({ inviteCheckbox: event.target.checked });
  };

  openPaymentPortal = () => {
    this.props.startLoading(this.props.getPaymentPortalUrl, "BillingPortal");
  };

  openUpgradePopup = () => {
    this.props.toggleUpgradePopup(true);
  };

  openCreditsCheckout = () => {
    if (!this.state.numCredits || !isPositiveInteger(this.state.numCredits)) {
      this.setState({ numCredits: " " }); // Trigger TextField error
      return;
    }

    this.props.startLoading(() => {
      this.props.getCreditCheckoutId(this.state.numCredits);
    }, "CreditsCheckout");
  };

  showCreditsUI = () => {
    if (!this.props.user) return false;
    return isMonthly(this.props.user.tier);
  };

  async componentDidUpdate() {
    if (this.props.stripeCreditCheckoutId) {
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId: this.props.stripeCreditCheckoutId,
      });
    }

    let userUpdates = {};
    if (this.state.email === null) userUpdates["email"] = this.props.user.email;
    if (this.state.firstName === null)
      userUpdates["firstName"] = this.props.user.firstName;
    if (this.state.lastName === null)
      userUpdates["lastName"] = this.props.user.lastName;
    if (!_.isEmpty(userUpdates)) this.setState({ ...userUpdates });
  }

  handleCreditsChange = (event) => {
    this.setState({ numCredits: event.target.value });
  };

  getCreditPrice = () => {
    if (
      !this.props.user ||
      !this.state.numCredits ||
      !isPositiveInteger(this.state.numCredits)
    )
      return "";

    let discountMultiplier = getDiscountMultiplier(this.props.user.discount);
    return formatCurrency(0.95 * this.state.numCredits * discountMultiplier);
  };

  confirmUpdateUser = (firebaseUser) => {
    if (this.state.password) this.updateFirebasePassword(firebaseUser);
    this.props.startLoading(() => {
      this.props.updateUser({
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      });
    }, "UpdateUser");
    this.setState({
      editingUser: false,
    });
  };

  cancelUpdateUser = (firebaseUser) => {
    this.setState({
      password: null,
      email: firebaseUser.email,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      password: "",
      editingUser: false,
    });
  };

  updateFirebasePassword = (user) => {
    user
      .updatePassword(this.state.password)
      .then(function () {
        alert("Password successfully updated.");
      })
      .catch(function (error) {
        alert("Unable to update password. " + error);
      })
      .finally(() => {
        this.setState({ password: "" });
      });
  };

  handleUpdateMember = (user, event) => {
    this.props.updateOrganizationMember(
      this.props.organization._id,
      user.userId,
      event.target.checked
    );
  };

  handleInvite = () => {
    if (!this.state.inviteEmail) {
      alert("No email provided");
      return;
    }
    if (!validateEmail(this.state.inviteEmail)) {
      alert("Invalid email provided");
      return;
    }

    if (!this.props.organization) {
      notification(
        "Not Allowed",
        "You are not part of an organization.",
        "warning"
      );
      return;
    }

    this.props.postOrganizationInvite(
      this.props.organization._id,
      this.state.inviteEmail,
      this.state.inviteCheckbox
    );
  };

  handleAcceptInvite = (organizationId) => {
    this.props.acceptOrganizationInvite(organizationId);
  };

  handleDeleteInvite = (organizationId, email) => {
    this.props.deleteOrganizationInvite(organizationId, email);
  };

  getMemberRows = () => {
    return this.props.organization.members.map((user, idx) => {
      let isAdmin = this.props.user.email === user.email;
      return (
        <tr key={`ORG-ROW-${idx}`}>
          <td>{user.email}</td>
          <td>
            {isAdmin ? (
              <Chip
                size="small"
                color="primary"
                label="Admin (You)"
                style={{ background: "#1d1c29" }}
              />
            ) : (
              <Chip size="small" color="default" label="Member" />
            )}
          </td>
          <td>
            {!isAdmin && (
              <Checkbox
                checked={user.readOnly}
                onClick={this.handleUpdateMember.bind(this, user)}
              />
            )}
          </td>
          <td>
            {!isAdmin && (
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => {
                  this.props.removeOrganizationMember(
                    this.props.organization._id,
                    user.userId
                  );
                }}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  getInviteRows = () => {
    if (!this.props.organization || !this.props.organization.invitations)
      return null;
    return this.props.organization.invitations.map((user, idx) => {
      return (
        <tr key={`INVITE-ROW-${idx}`}>
          <td>{user.email}</td>
          <td>
            <Chip
              size="small"
              color="default"
              label="Invited"
              style={{ border: "1px grey solid", background: "white" }}
            />
          </td>
          <td>
            <Checkbox
              checked={user.readOnly}
              onClick={() => {
                alert(
                  "Unable to edit a live invite. Please delete this invitation and issue another."
                );
              }}
            />
          </td>
          <td>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() =>
                this.handleDeleteInvite(this.props.organization._id, user.email)
              }
            />
          </td>
        </tr>
      );
    });
  };

  getOrganizationTable = () => {
    if (!this.props.organization || !this.props.organization.members)
      return (
        <tr>
          <td style={{ color: "gray" }}>Not an organization admin.</td>
        </tr>
      );
    return (
      <>
        {this.getMemberRows()}
        {this.getInviteRows()}
      </>
    );
  };

  getInviteTable = () => {
    if (!this.props.user || !this.props.user.invitations) return null;
    return this.props.user.invitations.map((invite, idx) => (
      <tr key={`USER-INVITE-ROW-${idx}`}>
        <td>{invite.name}</td>
        <td>
          <FontAwesomeIcon
            icon={faCheck}
            onClick={() => this.handleAcceptInvite(invite._id)}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() =>
              this.handleDeleteInvite(invite._id, this.props.user.email)
            }
          />
        </td>
      </tr>
    ));
  };

  getCreditsTable = () => {
    if (!this.props.user) return null;

    return this.props.user.usageTransactions
      .filter((t) => t.stripeInvoiceId || t.receiptUrl)
      .map((t, idx) => (
        <tr key={`CREDIT-ROW-${idx}`}>
          <td>{t.createdAt}</td>
          <td>{t.amount}</td>
          <td>
            {t.receiptUrl && (
              <a href={t.receiptUrl} target="_blank" rel="noreferrer">
                Receipt
              </a>
            )}
          </td>
        </tr>
      ));
  };

  cancelSubscription = (cancelPayload) => {
    this.props.startLoading(() => {
      this.props.cancelSubscription(cancelPayload);
    }, "Cancel");
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <div className="content">
          <CancelPopup
            open={this.state.cancelPopupOpen}
            onClose={() => {
              this.setState({ cancelPopupOpen: false });
            }}
            cancel={this.cancelSubscription}
            cancelLoading={this.props.cancelLoading}
          />
          <Grid container className="screen-margin">
            <h1 className="page-title">Settings</h1>
          </Grid>
          <Grid container className="screen-margin">
            <Grid container spacing={4}>
              {this.props.user &&
                this.props.user.tier === "FREE" &&
                this.props.user.trialExpired !== undefined && (
                  <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <FreeTrialAlert
                      user={this.props.user}
                      openUpgradePopup={this.openUpgradePopup}
                    />
                  </Grid>
                )}
              <Grid item lg={6} xs={12}>
                <div className="card padding-div-2">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}>
                      <img
                        src={getAvatarUrl(this.props.user, 200)}
                        className="profile-image"
                        alt="Profile"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={9}>
                          <h3 className="profile-title">Account Info</h3>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="profile-edit-buttons">
                            {this.props.updateUserLoading && (
                              <div style={{ margin: 6 }}>
                                <ClipLoader
                                  size={18}
                                  color={"rgba(0, 0, 0, 0.54)"}
                                  loading={this.props.updateUserLoading}
                                />
                              </div>
                            )}

                            {!this.props.updateUserLoading &&
                              !this.state.editingUser && (
                                <Tooltip title="Edit">
                                  <IconButton
                                    aria-label="Edit info"
                                    onClick={() =>
                                      this.setState({ editingUser: true })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {!this.props.updateUserLoading &&
                              this.state.editingUser && (
                                <>
                                  <Tooltip title="Cancel">
                                    <IconButton
                                      aria-label="Cancel editing info"
                                      onClick={() => {
                                        this.cancelUpdateUser(this.props.user);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Confirm">
                                    <IconButton
                                      aria-label="Confirm edits"
                                      onClick={() => {
                                        this.confirmUpdateUser(this.props.user);
                                      }}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={!this.state.editingUser}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="First Name"
                            value={this.state.firstName}
                            size="small"
                            onChange={this.firstNameUpdated}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={!this.state.editingUser}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Last Name"
                            value={this.state.lastName}
                            size="small"
                            onChange={this.lastNameUpdated}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={!this.state.editingUser}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Email"
                            value={this.state.email}
                            size="small"
                            onChange={this.emailUpdated}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={!this.state.editingUser}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Password"
                            value={this.state.password}
                            size="small"
                            onChange={this.passwordUpdated}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div className="card padding-div-2">
                  <h3 className="profile-title">Billing</h3>
                  <div
                    className="documentation-help"
                    style={{ marginBottom: 10 }}
                  >
                    <p>
                      Access invoices, reciepts, and other billing information.
                      See{" "}
                      <a
                        href="https://docs.phonic.ai/docs/how-to-access-billing-portal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        documentation
                      </a>{" "}
                      for help.
                    </p>
                  </div>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} className="profile-button-margin">
                      <Button
                        className="secondary-button-color"
                        margin="normal"
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={this.openPaymentPortal}
                      >
                        <>
                          {this.props.billingPortalLoading && (
                            <ClipLoader
                              size={14}
                              color={"white"}
                              loading={this.props.billingPortalLoading}
                            />
                          )}
                          {!this.props.billingPortalLoading && (
                            <FontAwesomeIcon icon={faCreditCard} />
                          )}
                          &nbsp;
                          {" Billing Portal"}
                        </>
                      </Button>
                    </Grid>
                    {this.props.user && isMonthly(this.props.user.tier) && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="profile-button-margin"
                      >
                        <Button
                          className="cancel-plan-button"
                          margin="normal"
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            this.setState({ cancelPopupOpen: true });
                          }}
                        >
                          Cancel Plan
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  {this.showCreditsUI() && (
                    <>
                      <h3 className="sharing-title">Add Credits</h3>
                      <div className="flex-container">
                        <TextField
                          variant="outlined"
                          margin="normal"
                          error={
                            this.state.numCredits !== "" &&
                            !isPositiveInteger(this.state.numCredits)
                          }
                          fullWidth
                          label="new credits"
                          placeholder={"0"}
                          onChange={this.handleCreditsChange}
                          size="small"
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginRight: 16,
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Total"
                          value={this.getCreditPrice()}
                          size="small"
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginRight: 16,
                          }}
                        />
                        <Button
                          className="secondary-button-color"
                          margin="normal"
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={this.openCreditsCheckout}
                        >
                          <>
                            {this.props.creditsCheckoutLoading && (
                              <ClipLoader
                                size={14}
                                color={"white"}
                                loading={this.props.creditsCheckoutLoading}
                              />
                            )}
                            {!this.props.creditsCheckoutLoading && (
                              <FontAwesomeIcon icon={faPlusSquare} />
                            )}
                            &nbsp;
                            {" Add Credits"}
                          </>
                        </Button>
                      </div>

                      <h3 className="sharing-title">Previous Credits</h3>
                      <div className="sharing-table">
                        <Table className="align-middle mb-0 table table-borderless table-hover">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>
                                Amount{" "}
                                <Tooltip
                                  title={
                                    <div className="documentation-help">
                                      <p>
                                        See how{" "}
                                        <a
                                          href="https://docs.phonic.ai/docs/credits"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          credits are allocated
                                        </a>
                                        .
                                      </p>
                                    </div>
                                  }
                                  aria-label="credits"
                                  interactive
                                >
                                  <IconButton size="small">
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                  </IconButton>
                                </Tooltip>
                              </th>
                              <th>Receipt</th>
                            </tr>
                          </thead>
                          <tbody>{this.getCreditsTable()}</tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className="card padding-div-2">
                  <h3 className="profile-title">Organization</h3>
                  <div className="documentation-help">
                    <p>
                      Share surveys, reports and collaborate with teammates. See{" "}
                      <a
                        href="https://docs.phonic.ai/organizations"
                        target="_blank"
                        rel="noreferrer"
                      >
                        documentation
                      </a>{" "}
                      for help.
                    </p>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={4} lg={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Company"
                        value={
                          this.props.organization
                            ? this.props.organization.name
                            : "Not part of an organization"
                        }
                        disabled={true}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2} lg={3}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Members"
                        value={
                          this.props.organization
                            ? this.props.organization.members
                              ? this.props.organization.members.length
                              : 1
                            : 1
                        }
                        disabled={true}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2} lg={3}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Invitations"
                        value={
                          this.props.organization
                            ? this.props.organization.invitations
                              ? this.props.organization.invitations.length
                              : 0
                            : 0
                        }
                        disabled={true}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2} lg={3}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Plan Limit"
                        value={
                          this.props.organization
                            ? this.props.organization.memberLimit || 1
                            : 1
                        }
                        disabled={true}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Invite by Email"
                        size="small"
                        onChange={this.inviteEmailUpdated}
                      />
                    </Grid>
                    <Grid item xs={1} className="flex-container flex-column">
                      <Checkbox onChange={this.inviteCheckboxUpdated} />
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          color: "gray",
                        }}
                      >
                        Read Only?
                      </span>
                    </Grid>
                    <Grid item xs={4} className="profile-button-margin">
                      <Button
                        className="secondary-button-color"
                        margin="normal"
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={this.handleInvite}
                        style={{ marginTop: 16, marginBottom: 8 }}
                      >
                        <FontAwesomeIcon icon={faEnvelope} /> &nbsp; Invite
                      </Button>
                    </Grid>
                  </Grid>
                  {this.props.organization && (
                    <>
                      <h3 className="sharing-title">Organization Members</h3>
                      <div className="sharing-table">
                        <Table className="align-middle mb-0 table table-borderless table-hover">
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Status</th>
                              <th>Read Only</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>{this.getOrganizationTable()}</tbody>
                        </Table>
                      </div>
                    </>
                  )}
                  {this.props.user.invitations && (
                    <>
                      <h3 className="sharing-title">
                        Organizations You're Invited To
                      </h3>
                      <div className="sharing-table">
                        <Table className="align-middle mb-0 table table-borderless table-hover">
                          <thead>
                            <tr>
                              <th>Organization</th>
                              <th>Accept</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>{this.getInviteTable()}</tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        );
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

import React, { Component } from "react";
import Chart from "chart.js/auto";

export default class StackedArea extends Component {
  chartRef = React.createRef();

  chart = null;

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");
    this.chart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.chartData.labels,
        datasets: this.props.chartData.data,
      },
      options: {
        plugins: {
          legend: {
            position: "right",
          },
        },

        elements: {
          point: {
            radius: 0,
          },
        },

        hover: {
          mode: "nearest",
          intersect: true,
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        scales: {
          y: {
            stacked: true,
          },
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Seconds",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                callback: function (value) {
                  return ((value / this.max) * 100).toFixed(0) + "%";
                },
              },
            },
          ],
        },
      },
    });
  }
  render() {
    return (
      <div>
        <canvas
          id="stacked-area-chart"
          className="basic-chart"
          ref={this.chartRef}
        />
      </div>
    );
  }
}

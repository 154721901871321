import React from "react";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

export default function InputAdornments(props) {
  return (
    <TextField
      label={props.label}
      id={props.id}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <div>
            <FontAwesomeIcon icon={faCopy} style={{ cursor: "pointer" }} />
            <span style={{ marginRight: "5px" }} />
          </div>
        ),
      }}
      margin="dense"
      value={props.link}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(e);
      }}
      className="surveylink"
    />
  );
}

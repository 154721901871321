import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const EditTopicPopup = ({
  open,
  closePopup,
  allTopics,
  topicAction,
  topicId,
  questionId,
}) => {
  const [topic, setTopic] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (evt) => {
    setTopic(evt.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (allTopics.some((e) => e.Text === topic)) {
      setError(true);
    } else {
      topicAction(topicId, topic, questionId);
      setTopic("");
      closePopup();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      aria-labelledby="create-topic"
      fullWidth
    >
      <DialogContent>
        <div id="form-dialog-title" style={{ marginBottom: 20 }}>
          <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
            Edit Topic Title
          </h2>
          <p>
            All future responses under this theme will be aliased to the new
            one.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            autoFocus
            id="title"
            label="Topic Name"
            required
            fullWidth
            onChange={handleChange}
            helperText={error ? "Topic name already exists" : ""}
            error={error}
            onFocus={() => setError(false)}
          />
          <span className="create-report-button">
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Rename Topic
            </Button>
          </span>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditTopicPopup;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DialogContentText } from "@material-ui/core";

class ConfirmationPopup extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div className="image-upload-container">
            <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{this.props.subtext}</DialogContentText>
              <DialogActions>
                <Button
                  className="Mui-Overrides outlined-dark"
                  variant="outlined"
                  component="label"
                  onClick={this.props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="secondary-button-color"
                  variant="contained"
                  component="label"
                  onClick={this.props.action}
                >
                  Continue
                </Button>
              </DialogActions>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default ConfirmationPopup;

import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

class AddCollectionPopup extends React.Component {
  state = {
    collection: "",
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.path) {
      this.props.handleSubmit(`${this.props.path}/${this.state.collection}`);
    } else {
      this.props.handleSubmit(this.state.collection);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            {"Add "}
            {this.props.path ? "Folder" : "Collection"}
            <div className="x-button">
              <IconButton
                aria-label="close"
                onClick={this.props.onClose}
                style={{ float: "right" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </h2>
          {this.props.path && (
            <p style={{ marginBottom: 20 }}>
              Creating new folder{" "}
              <b>
                {this.props.path}
                {"/"}
                {this.state.collection}
              </b>
            </p>
          )}
          <form onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              autoFocus
              id="collection-name"
              type="name"
              label="Name"
              required
              placeholder="Collection name..."
              fullWidth
              autoComplete="off"
              onChange={(e) => {
                this.setState({ collection: e.target.value });
              }}
            />

            <div className="create-report-button">
              <Button
                style={{ marginLeft: 8 }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.collection === ""}
              >
                Add Collection
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}
export default AddCollectionPopup;

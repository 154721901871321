import React from "react";
import FileGroup from "./FileGroup";
import AddCollectionButton from "./AddCollectionButton";
class FileBrowser extends React.Component {
  state = { searchText: "" };
  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };
  searchFilter = (file) => {
    if (!file) return false;
    return (
      (file.path + file.name)
        .toLowerCase()
        .includes(this.state.searchText.toLowerCase()) ||
      file.children.some((c) => this.searchFilter(c))
    );
  };
  render() {
    return (
      <div className="file-browser">
        <div className="small-search-container conversations-search">
          <input
            type="text"
            placeholder="Search..."
            onChange={this.handleSearch}
          />
        </div>
        <div className="files-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="collection-title-container"
              role="button"
              tabIndex={0}
            >
              <span className="collection-title">{`Collections [${
                Object.keys(this.props.files).length
              }]`}</span>
            </div>
          </div>
          <AddCollectionButton
            handleClick={this.props.openNewCollectionPopup}
          />
        </div>
        <div className="display-block">
          {Object.values(this.props.files)
            .filter(this.searchFilter)
            .map((f) => (
              <FileGroup
                {...this.props}
                {...f}
                key={f.name}
                path={f.name}
                depth={1}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default FileBrowser;

import * as RecordRTC from "recordrtc";
const MicRecorder = require("mic-recorder-to-mp3");

export default class Recorder {
  constructor(format) {
    if (format === "mp3") {
      this.recorder = new MP3Recorder();
    } else {
      // Default to wav
      this.recorder = new WAVRecorder();
    }
  }

  start = () => {
    return this.recorder.start();
  };
  stop = () => {
    return this.recorder.stop();
  };
}

export class MP3Recorder {
  constructor() {
    this.recorder = new MicRecorder({
      bitRate: 128,
    });
  }

  start = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .start()
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const file = new File(buffer, "stimuli.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });
          resolve(file);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}

export class WAVRecorder {
  start = () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(async (stream) => {
          this.recorder = RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            recorderType: RecordRTC.StereoAudioRecorder, // force for all browsers
            numberOfAudioChannels: 2,
          });
          await this.recorder.startRecording();
          resolve();
        })
        .catch((e) => reject(e));
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      try {
        this.recorder.stopRecording(() => {
          let blob = this.recorder.getBlob();
          const file = new File([blob], "stimuli.wav", {
            type: blob.type,
            lastModified: Date.now(),
          });
          resolve(file);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}

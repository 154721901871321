import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faBookmark as faBookmarkSolid,
  faUserFriends,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkOutlined } from "@fortawesome/free-regular-svg-icons";
import Pagination from "@material-ui/lab/Pagination";
import {
  getSurvey,
  getSurveyExport,
  getUser,
  getSessions,
  getSession,
  getTopics,
  updateSurvey,
  updateSession,
  updateSessionLocal,
  setPageTitle,
  startSurveyMediaDownload,
  generateGSheet,
} from "redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { track, trackPage } from "analytics";
import {
  Button,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import SessionView from "components/SessionView";
import ConfirmationPopup from "components/Popups/ConfirmationPopup";
import EmailNotificationsPopup from "components/Popups/EmailNotificationsPopup";
import {
  getPage,
  stringifyQuery,
  copyToClipboard,
  notification,
  truncateStringWithEllipsis,
  blockFreeTier,
  surveyHasMedia,
  parseQuery,
  initialParams,
} from "../../utils/utils";
import Skeleton from "@material-ui/lab/Skeleton";
import Share from "./Share";
import ExportPopup from "components/Popups/ExportPopup";
import MediaDownloadPopup from "components/Popups/MediaDownloadPopup";
import GSheetsExportPopup from "components/Popups/GSheetsExportPopup";
import Completion from "./Completion";
import Question from "./Question";
import ResultsSidebarTile from "components/Results/ResultsSidebarTile";
import { rainbow } from "utils/brandPalette";
import { getBestSessionTitles } from "utils/utils";
import { getReadableSessionId } from "utils/utils";
import FilterPopover from "components/Popovers/FilterPopover";
import { getBlankDisplayAndFilter } from "utils/defaults";
import { ClipLoader } from "react-spinners";
import { AutoSizer, List } from "react-virtualized";

const INFINITE_SCROLL_PAGE_SIZE = 100;

const mapStateToProps = (state, ownProps) => {
  return {
    survey: state.surveys
      ? state.surveys[ownProps.match.params.surveyId]
      : null,
    user: state.user,
    sessions: state.sessions
      ? state.sessions[ownProps.match.params.surveyId]
      : null,
    // Needed for things like `completionTimes`
    currentSurvey: state.currentSurvey,
    exportLoading: state.loading["SurveyExport"],
    sessionLoading: state.loading["SessionView"],
    topics: state.topics,
  };
};

const mapDispatchToProps = {
  getSurvey,
  getSurveyExport,
  getUser,
  updateSurvey,
  getSessions,
  getSession,
  getTopics,
  updateSession,
  updateSessionLocal,
  setPageTitle,
  startSurveyMediaDownload,
  generateGSheet,
};

const SkeletonSessions = () => {
  return (
    <div className="dummy-results-tile">
      <Skeleton variant="text" style={{ width: "100%" }} />
      <Skeleton variant="text" style={{ width: "50%", marginBottom: 30 }} />
      <Skeleton variant="text" style={{ width: "100%" }} />
      <Skeleton variant="text" style={{ width: "50%" }} />
    </div>
  );
};

class Questions extends Component {
  state = {
    surveyId: undefined,
    confirmPublic: undefined,
    sessionOpened: false,
    numPerPage: 20,
    optionsPopoverEl: undefined,
    shownLanguages: initialParams(parseQuery(this.props.location.search).lang),
    dates: {
      startdate: "",
      enddate: "",
    },
    showPreview: false,
    exportPopupOpen: false,
    mediaDownloadPopupOpen: false,
    gSheetsExportPopup: false,
    // Filtering and sorting parameters
    ...getBlankDisplayAndFilter(),
    sentimentIdx: 0,
    shownSessions: INFINITE_SCROLL_PAGE_SIZE,
  };

  getTab() {
    if (this.props.history.location.pathname.indexOf("respondents") !== -1)
      return "respondents";
    return this.props.match.params.tab
      ? this.props.match.params.tab
      : "questions";
  }

  /**
   * Fetch session and survey data from the Phonic API. Called upon mount and update with an new surveyId.
   */
  fetchDataAndUpdateUrl = () => {
    this.props.getSurvey(this.props.match.params.surveyId, {
      includeCompletionTimes: true,
    });
    this.props.getSessions(
      this.props.match.params.surveyId,
      this.props.location.search
    );
    var queryParams = parseQuery(this.props.location.search);
    this.setState({
      surveyId: this.props.match.params.surveyId,
      page: getPage(this.props.location.search),
      dates: {
        startdate: queryParams.startdate ? queryParams.startdate : "",
        enddate: queryParams.enddate ? queryParams.enddate : "",
      },
    });
  };

  componentDidMount() {
    trackPage("Questions");
    this.fetchDataAndUpdateUrl();
  }

  componentDidUpdate() {
    // Covers edge case where survey is switched via search bar.
    if (this.state.surveyId !== this.props.match.params.surveyId) {
      this.fetchDataAndUpdateUrl();
    }
    // sessionOpened and sessionOpenedIdx both being null indicates the session was just closed.
    if (
      this.props.sessions &&
      this.props.sessions.length > 0 &&
      this.state.sessionOpened !== null &&
      this.state.sessionOpenedIdx !== null
    ) {
      let sessionToOpenIdx = 0;
      if (this.props.match.params.sessionObjectId) {
        sessionToOpenIdx = this.getSessionIdx(
          this.props.match.params.sessionObjectId
        );
        if (sessionToOpenIdx === -1) return;
      }
      this.openSession(sessionToOpenIdx);
    }
    if (
      this.props.survey &&
      this.getTab() === "questions" &&
      !this.props.match.params.questionId
    )
      this.openQuestion(this.props.survey.questions[0].questionId);
    this.props.setPageTitle(
      <div className="breadcrumbs-title">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => this.props.history.push("/surveys")}
          >
            Surveys
          </Link>
          {this.props.survey && (
            <Link color="inherit">
              {truncateStringWithEllipsis(this.props.survey.name, 36)}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  getSessionIdx(sessionObjectId) {
    for (let i = 0; i < this.props.sessions.length; i++) {
      if (this.props.sessions[i]._id === sessionObjectId) return i;
    }
    return -1;
  }

  changePage = (e, value) => {
    this.props.history.push({
      search: stringifyQuery({
        ...parseQuery(this.props.location.search),
        page: value,
      }),
    });
    this.setState({ page: value });
  };

  getShownSessions = () => {
    if (!this.props.sessions) return [];
    return this.props.sessions.slice(0, this.state.shownSessions);
  };

  getNextSessions = () => {
    this.setState({
      shownSessions: this.state.shownSessions + INFINITE_SCROLL_PAGE_SIZE,
    });
  };

  routeToQuestion(question) {
    if (question.type === "DISPLAY") return;
    this.props.history.push({
      pathname: `/surveys/${this.props.match.params.surveyId}/questions/${question.questionId}`,
    });
  }

  openQuestion = (questionId) => {
    this.setState({ ...getBlankDisplayAndFilter() });
    this.props.history.push({
      pathname: `/surveys/${this.props.match.params.surveyId}/questions/${questionId}`,
      search: this.props.location.search,
    });
    this.props.getTopics(questionId);
  };
  getSurveyQuestion = (questionId) => {
    if (!this.props.survey || !this.props.survey.questions) return null;
    return this.props.survey.questions.find((q) => q.questionId === questionId);
  };

  openSession = (sessionIdx) => {
    if (
      this.state.sessionOpened === true &&
      this.state.sessionOpenedIdx === sessionIdx
    )
      return;

    this.setState({ sessionOpened: true, sessionOpenedIdx: sessionIdx }, () => {
      this.props.getSession(
        this.props.match.params.surveyId,
        this.props.sessions[sessionIdx]._id
      );
    });
    if (
      this.getTab() === "respondents" &&
      this.props.match.params.surveyId !==
        this.props.sessions[sessionIdx].sessionId
    ) {
      this.props.history.push({
        pathname: `/surveys/${this.props.match.params.surveyId}/respondents/${this.props.sessions[sessionIdx]._id}`,
        search: this.props.location.search,
      });
    }
  };

  closeSession = () => {
    this.setState({ sessionOpened: false, sessionOpenedIdx: null });
    this.props.history.push({
      pathname: `/surveys/${this.props.match.params.surveyId}/respondents`,
      search: this.props.location.search,
    });
  };
  prevSession = () => {
    if (this.state.sessionOpenedIdx === 0) return;
    if (this.state.sessionOpenedIdx % this.state.numPerPage === 0) {
      this.changePage(null, this.state.page - 1);
    }
    this.openSession(this.state.sessionOpenedIdx - 1);
  };
  nextSession = () => {
    if (this.state.sessionOpenedIdx === this.props.sessions.length - 1) return;
    if ((this.state.sessionOpenedIdx + 1) % this.state.numPerPage === 0) {
      this.changePage(null, this.state.page + 1);
    }
    this.openSession(this.state.sessionOpenedIdx + 1);
  };
  getRowClass = (i) => {
    if (
      i + (this.state.page - 1) * this.state.numPerPage ===
      this.state.sessionOpenedIdx
    )
      return "selected-session-row";
    return "";
  };

  linkClick = (event) => {
    event.stopPropagation();
    copyToClipboard(`https://survey.phonic.ai/${this.state.surveyId}`);
    notification(
      "Link Copied to Clipboard.",
      "You may now share this survey with respondents.",
      "success"
    );
  };

  getDemographicHeaders = () => {
    if (!this.props.survey || !this.props.survey.demographics) return null;
    return (
      <>
        {(this.props.survey.demographics.firstName ||
          this.props.survey.demographics.lastName) && <th>Name</th>}
        {this.props.survey.demographics.email && <th>Email</th>}
        {this.props.survey.demographics.age && <th>Age</th>}
        {this.props.survey.demographics.gender && <th>Gender</th>}
        {this.props.survey.demographics.hHIncome && <th>Household Income</th>}
        {this.props.survey.demographics.location && <th>Location</th>}
      </>
    );
  };

  exportSurvey = (exportParams) => {
    // Reject if the user is not premium
    if (blockFreeTier(this.props.user)) return;
    this.props
      .getSurveyExport(
        this.props.match.params.surveyId,
        undefined,
        exportParams
      )
      .then((res) => {
        try {
          var hiddenElement = document.createElement("a");
          var blob = new Blob([res], {
            type:
              exportParams.format === "csv" ? "text/csv" : "application/zip",
          });
          var url = window.URL.createObjectURL(blob);
          hiddenElement.href = url;

          hiddenElement.target = "_blank";
          hiddenElement.download = `phonic-${
            this.props.match.params.surveyId
          }.${exportParams.format === "csv" ? "csv" : "zip"}`;
          hiddenElement.click();
        } catch (e) {
          alert("Unable to Export. Try again later.");
        }
      });
  };

  changeTabs = (e, v) => {
    track(`Viewed Survey Tab ${v}`);
    this.props.history.push({
      pathname: `/surveys/${this.props.match.params.surveyId}/${v}`,
      search: this.props.location.search,
    });
  };

  saveTriggers = (triggers) => {
    this.props.updateSurvey(this.state.surveyId, {
      triggers: triggers,
    });
  };

  getBookmarkIcon = (session) => {
    return (
      <FontAwesomeIcon
        onClick={(e) => {
          e.stopPropagation();
          this.props.updateSession(session.sessionId, {
            bookmarked: session.bookmarked ? !session.bookmarked : true,
          });
          this.props.updateSessionLocal(session.sessionId, session.surveyId, {
            bookmarked: session.bookmarked ? !session.bookmarked : true,
          });
        }}
        icon={session.bookmarked ? faBookmarkSolid : faBookmarkOutlined}
      />
    );
  };

  showPagination = () => {
    if (!this.props.sessions) return false;
    return this.props.sessions.length / this.state.numPerPage > 1;
  };

  getPagination = () => {
    if (!this.props.sessions || !this.state.page) return undefined;
    return (
      <div>
        {this.showPagination() && (
          <Pagination
            count={Math.ceil(
              this.props.sessions.length / this.state.numPerPage
            )}
            onChange={this.changePage}
            page={this.state.page}
            size="small"
          />
        )}
      </div>
    );
  };

  getContentLoader = () => {
    return (
      <div className="full-width flex-container">
        <ClipLoader size={24} color={"black"} />
      </div>
    );
  };

  openOptions = (e) => {
    this.setState({
      optionsPopoverEl: e.target,
    });
  };
  closeOptions = () => {
    this.setState({
      optionsPopoverEl: undefined,
    });
  };
  updateDisplayOptions = (options) =>
    this.setState({
      displayOptions: {
        ...this.state.displayOptions,
        ...options,
      },
    });
  updateFilters = (filters) =>
    this.setState({
      filters: { ...this.state.filters, ...filters },
    });

  sessionRowRenderer = ({ key, index, style }) => {
    const s = this.props.sessions[index];
    return (
      <div key={key} style={style}>
        <ResultsSidebarTile
          idx={index + 1}
          {...getBestSessionTitles(s)}
          subText={getReadableSessionId(s.sessionId)}
          onClick={() => this.openSession(index)}
          iconsOverride={[faUserFriends]}
          colorOverride={rainbow.gray}
          selected={s.sessionId == this.props.match.params.sessionId}
          icon={this.getBookmarkIcon(s)}
        />
      </div>
    );
  };
  questionRowRenderer = ({ key, index, style }) => {
    const q = this.props.survey.questions[index];
    return (
      <div key={key} style={style}>
        <ResultsSidebarTile
          idx={index + 1}
          type={q.type}
          text={q.text}
          subText={q.subText}
          onClick={() => this.openQuestion(q.questionId)}
          selected={q.questionId == this.props.match.params.questionId}
        />
      </div>
    );
  };

  rowRenderer = ({ key, index, style }) => {
    return (
      <div key={key} style={style}>
        {index}
      </div>
    );
  };

  render() {
    const params = parseQuery(this.props.location.search);
    const question = this.getSurveyQuestion(this.props.match.params.questionId);
    return (
      <div id="results-screen">
        <Helmet>
          <title>
            {this.props.survey ? this.props.survey.name : "Phonic Dashboard"}
          </title>
        </Helmet>
        <ConfirmationPopup
          open={this.state.confirmPublic !== undefined}
          action={() => {
            track("Made Audio Data Public");
            this.props.updateSurvey(this.state.surveyId, {
              audioPublic: this.state.confirmPublic,
            });
            this.setState({ confirmPublic: undefined });
          }}
          onClose={() => {
            this.setState({ confirmPublic: undefined });
          }}
          title={`Make your audio data ${
            this.state.confirmPublic ? "public" : "private"
          }?`}
          subtext={`You will ${
            this.state.confirmPublic ? "now" : "no longer"
          } be able to share play links freely.`}
        />
        <ExportPopup
          open={this.state.exportPopupOpen}
          onClose={() => this.setState({ exportPopupOpen: false })}
          export={this.exportSurvey}
        />
        <MediaDownloadPopup
          open={this.state.mediaDownloadPopupOpen}
          user={this.props.user}
          onClose={() => this.setState({ mediaDownloadPopupOpen: false })}
          surveyId={this.state.surveyId}
          startSurveyMediaDownload={this.props.startSurveyMediaDownload}
        />
        <GSheetsExportPopup
          open={this.state.gSheetsExportPopup}
          user={this.props.user}
          onClose={() => this.setState({ gSheetsExportPopup: false })}
          surveyId={this.state.surveyId}
          generateGSheet={this.props.generateGSheet}
        />
        <EmailNotificationsPopup
          open={this.state.emailNotifcationPopup}
          onClose={() => this.setState({ emailNotifcationPopup: false })}
          survey={this.props.survey}
          saveTriggers={this.saveTriggers}
        />
        <div id="results-tabs-container">
          <Tabs
            id="results-tabs"
            className="create-survey-tabs"
            value={this.getTab()}
            onChange={this.changeTabs}
            TabIndicatorProps={{ style: { background: "#51a871" } }}
            style={{ marginBottom: 0 }}
          >
            <Tab label="Respondents" value="respondents" />
            <Tab label="Questions" value="questions" />
            <Tab label="Insights" value="insights" />
            <Tab label="Share" value="share" />
          </Tabs>
          <div className="action-buttons">
            {this.getTab() === "questions" && question && (
              // Question Filters
              <>
                <Button
                  id="filter-button"
                  className="secondary-button-color mr-10"
                  variant="contained"
                  component="label"
                  onClick={(e) =>
                    this.setState({ filterPopAnchor: e.currentTarget })
                  }
                  style={{ marginRight: 10 }}
                >
                  <FontAwesomeIcon icon={faFilter} />
                  &nbsp;Filters
                </Button>
                <FilterPopover
                  anchorEl={this.state.filterPopAnchor}
                  open={Boolean(this.state.filterPopAnchor)}
                  handleClose={() =>
                    this.setState({ filterPopAnchor: undefined })
                  }
                  questionInfo={question}
                  updateFilters={this.updateFilters}
                  updateDisplayOptions={this.updateDisplayOptions}
                  filters={this.state.filters}
                  displayOptions={this.state.displayOptions}
                  changeDate={this.changeDate}
                  initialDatesPresent={Boolean(
                    params.startdate || params.enddate
                  )}
                  submitLanguages={this.submitLanguages}
                  initialLanguages={parseQuery(this.props.location.search).lang}
                  submitOptions={this.submitOptions}
                  initialOptions={parseQuery(this.props.location.search)}
                  urlParams={this.props.urlParams}
                />
              </>
            )}
            <Button
              className="secondary-button-color"
              variant="contained"
              component="label"
              onClick={this.openOptions}
              disabled={this.props.exportLoading}
            >
              {this.props.exportLoading ? (
                <span>
                  Downloading &nbsp;
                  <CircularProgress size={14} style={{ color: "white" }} />
                </span>
              ) : (
                <span>
                  Options&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              )}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.optionsPopoverEl}
              open={Boolean(this.state.optionsPopoverEl)}
              onClose={this.closeOptions}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ marginTop: 60 }}
            >
              <MenuItem
                onClick={() => {
                  this.props.history.push(
                    `/create_survey/${this.props.match.params.surveyId}`
                  );
                }}
              >
                Edit Survey
              </MenuItem>
              <MenuItem
                disabled={
                  !this.props.user ||
                  this.props.user.tier === "FREE" ||
                  !surveyHasMedia(
                    this.props.currentSurvey &&
                      this.props.currentSurvey.questions
                  )
                }
                onClick={() => {
                  this.closeOptions();
                  this.setState({ mediaDownloadPopupOpen: true });
                }}
              >
                Download Media
              </MenuItem>
              <MenuItem
                disabled={!this.props.user || this.props.user.tier === "FREE"}
                onClick={() => {
                  this.closeOptions();
                  this.setState({ exportPopupOpen: true });
                }}
              >
                Export Survey
              </MenuItem>
              {this.props.survey &&
                this.props.survey.continuousGSheet &&
                this.props.survey.continuousGSheetUrl && (
                  <MenuItem
                    disabled={
                      !this.props.user || this.props.user.tier !== "ENTERPRISE"
                    }
                    onClick={() => {
                      window.open(
                        this.props.survey.continuousGSheetUrl,
                        "_blank"
                      );
                    }}
                  >
                    Open Google Sheet
                  </MenuItem>
                )}
              <MenuItem
                disabled={
                  !this.props.user ||
                  this.props.user.tier !== "ENTERPRISE" ||
                  !this.props.survey
                }
                onClick={() => {
                  track("Toggled Continuous GSheets");
                  if (!this.props.survey.continuousGSheet) {
                    this.setState({ gSheetsExportPopup: true });
                  } else {
                    notification(
                      "Stopping GSheet Export.",
                      "The Google Sheet associated with this survey will no longer update.",
                      "success"
                    );
                    this.props.updateSurvey(this.state.surveyId, {
                      continuousGSheet: false,
                    });
                  }

                  this.setState({
                    optionsPopoverEl: undefined,
                  });
                }}
              >
                {this.props.survey && this.props.survey.continuousGSheet
                  ? "Stop Export to Google Sheets"
                  : "Live Export to Google Sheets"}
              </MenuItem>
              <MenuItem
                disabled={!this.props.user || this.props.user.tier === "FREE"}
                onClick={() => this.setState({ emailNotifcationPopup: true })}
              >
                Email Notifications
              </MenuItem>
              <MenuItem
                disabled={!this.props.user || this.props.user.tier === "FREE"}
                onClick={() => {
                  if (blockFreeTier(this.props.user)) return;
                  this.setState({
                    confirmPublic: !this.props.survey.audioPublic,
                    optionsPopoverEl: undefined,
                  });
                }}
              >
                {this.props.survey && this.props.survey.audioPublic === true
                  ? "Make Media Private"
                  : "Make Media Public"}
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div id="results-screen-inner">
          {(this.getTab() === "questions" ||
            this.getTab() === "respondents") && (
            <>
              <div id="left-results-pane">
                {this.getTab() === "questions" && this.props.survey && (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        height={height}
                        rowCount={this.props.survey.questions.length}
                        rowHeight={80}
                        rowRenderer={this.questionRowRenderer}
                        width={width}
                        data={this.props.match.params.questionId}
                      />
                    )}
                  </AutoSizer>
                )}
                {this.getTab() === "respondents" &&
                  (!this.props.sessions ? (
                    <SkeletonSessions />
                  ) : this.props.sessions.length === 0 ? (
                    <div className="no-items-container">No Respondents</div>
                  ) : (
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          rowCount={this.props.sessions.length}
                          rowHeight={80}
                          rowRenderer={this.sessionRowRenderer}
                          width={width}
                          sessionId={this.props.match.params.sessionId}
                          sessions={this.props.sessions}
                        />
                      )}
                    </AutoSizer>
                  ))}
              </div>
              <div id="right-results-pane">
                {this.getTab() === "questions" &&
                  this.props.match.params.questionId && (
                    <Question
                      survey={this.props.survey}
                      question={question}
                      filters={this.state.filters}
                      displayOptions={this.state.displayOptions}
                      updateFilters={this.updateFilters}
                      topics={this.props.topics}
                      searchParams={params}
                    />
                  )}
                {this.getTab() === "respondents" && (
                  <>
                    {this.state.sessionOpened && this.props.sessions ? (
                      <SessionView
                        session={
                          this.props.sessions[this.state.sessionOpenedIdx]
                        }
                        sessionIdx={this.state.sessionOpenedIdx}
                        survey={this.props.survey}
                        close={this.closeSession}
                        nextSession={this.nextSession}
                        prevSession={this.prevSession}
                        paginationShown={this.showPagination()}
                        loading={this.props.sessionLoading}
                      />
                    ) : (
                      <div
                        className="no-items-container"
                        style={{ margin: "-30px" }}
                      >
                        No Session Selected
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {this.getTab() === "insights" && (
            <>
              {this.props.survey && (
                <Completion
                  survey={this.props.survey}
                  sessions={this.props.sessions}
                />
              )}
            </>
          )}

          {this.getTab() === "share" && (
            <Share surveyId={this.state.surveyId} linkClick={this.linkClick} />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Questions)
);

import { Grid } from "@material-ui/core";
import React from "react";
import PropCheckbox from "components/SurveyCreation/PropCheckbox";
import { autocompleteDemographics } from "utils/Recruit/recruitDemographics";

export default function FilteringPane(props) {
  const onPropToggle = (type, checked) => {
    props.modifyAudience({ [type]: checked ? [] : undefined });
  };
  return (
    <Grid id="demographics-filtering-pane" item xs={2}>
      <h3>Default</h3>
      <PropCheckbox
        label={{ text: "Gender", placement: "right" }}
        value={Boolean(props.audience["gender"])}
        disabled={true}
      />
      <PropCheckbox
        label={{ text: "Age Range", placement: "right" }}
        value={Boolean(props.audience["age"])}
        disabled={true}
      />
      <hr />
      <h3>Demographics</h3>
      {autocompleteDemographics.map((d, idx) => {
        return (
          <PropCheckbox
            key={`DEMOGRAPHIC-${idx}`}
            label={{ text: d.title, placement: "right" }}
            value={Boolean(props.audience[d.optionType])}
            update={(e) => onPropToggle(d.optionType, e.target.checked)}
            disabled={d.disabled}
          />
        );
      })}
    </Grid>
  );
}

import React from "react";
import ResponseCard from "components/Report/ResponseCard";
import GraphCard from "components/Report/GraphCard";
import MarkdownEditable from "components/Report/Markdown/MarkdownEditable";
import Markdown from "components/Report/Markdown/Markdown";
import BlockMenu from "components/Report/BlockMenu";
import ResponseNotFound from "components/Report/ResponseNotFound";

const ContentBlock = ({
  content,
  editable,
  index,
  editMarkdown,
  deleteBlock,
  changeReport,
}) => {
  let contentJSX = null;

  switch (content.type) {
    case "MARKDOWN":
      if (editable) {
        contentJSX = (
          <MarkdownEditable
            placeholder="*Content is empty*"
            index={index}
            editMarkdown={editMarkdown}
          >
            {content.markdown}
          </MarkdownEditable>
        );
      } else {
        contentJSX = <Markdown>{content.markdown}</Markdown>;
      }
      break;
    case "RESPONSE":
      if (content.question && content.response) {
        contentJSX = (
          <ResponseCard
            question={content.question}
            response={content.response}
            hidden={content.hidden}
            changeReport={changeReport}
          />
        );
      } else if (editable) {
        contentJSX = (
          <ResponseNotFound deleteBlock={() => deleteBlock(index)} />
        );
      }
      break;
    case "GRAPH":
      contentJSX = (
        <GraphCard
          graphType={content.graphType}
          graphData={content.graphData}
          title={content.title}
          questionText={content.questionText}
        />
      );
      break;
    default:
    // Swallow unsupported block type
  }

  if (editable) {
    contentJSX = (
      <>
        <BlockMenu deleteBlock={deleteBlock} index={index} />
        {contentJSX}
      </>
    );
  }

  // Don't render grid if unsupported content type
  return contentJSX ? (
    <div className="content-block-wrapper">
      <div className="position-relative">{contentJSX}</div>
    </div>
  ) : null;
};

export default ContentBlock;

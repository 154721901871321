import React, { useState } from "react";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "components/Popups/DeletePopup";
import { deleteReport } from "redux/actions";

const mapDispatchToProps = {
  deleteReport,
};

const ReportPopupMenu = ({ reportId, reportName, deleteReport }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const handleClick = (evt) => {
    evt.stopPropagation();
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt) => {
    evt.stopPropagation();
    setAnchorEl(null);
  };

  const openReport = (evt) => {
    evt.stopPropagation();
    window.open(`${window.location.origin}/reports/${reportId}`, "_blank");
  };

  const popupClose = () => {
    setPopupOpen(false);
  };

  const handleDeleteAlert = (evt) => {
    evt.stopPropagation();
    setPopupOpen(true);
    setAnchorEl(null);
  };

  const confirmDeleteReport = () => {
    deleteReport(reportId);
    setPopupOpen(false);
  };

  return (
    <>
      <DeletePopup
        open={popupOpen}
        deleteFunction={confirmDeleteReport}
        onClose={popupClose}
        title={`Please confirm you'd like to delete ${reportName} permanently.`}
        subTitle="This action cannot be undone."
        buttonText={`Delete report`}
      />
      <div onClick={handleClick} className="rounded-icon-container">
        <FontAwesomeIcon
          icon={faEllipsisV}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="survey-menu-icon"
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openReport}>Open Report</MenuItem>
        <MenuItem onClick={handleDeleteAlert}>Delete Report</MenuItem>
      </Menu>
    </>
  );
};

export default connect(null, mapDispatchToProps)(ReportPopupMenu);

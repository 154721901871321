// Segment Analytics tracking

// Track identity event (login)
export function trackIdentify(user) {
  window.analytics.identify(user.uid, {
    displayName: user.displayName,
    email: user.email,
  });

  window.profitwell("start", { user_email: user.email });
}

export function trackPage(pageName) {
  window.analytics.page(pageName);
}

export function track(eventName, metadata) {
  window.analytics.track(eventName, metadata);
}

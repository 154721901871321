import React from "react";
import { isAudioType, isVideoType } from "utils/utils";
import Waveform from "components/Waveform";

const MediaPlayer = ({ type, uri, waveProps }) => {
  if (!type || !uri) return null;

  let media = null;

  if (isAudioType(type)) {
    media = (
      <div className="audio-player">
        <Waveform height={50} src={uri} {...waveProps} fluid />
      </div>
    );
  } else if (isVideoType(type)) {
    media = (
      <video
        controls
        controlsList="nodownload"
        style={{ width: "100%", borderRadius: 4 }}
      >
        <source src={uri} />
        Your browser does not support the video tag.
      </video>
    );
  }

  return media;
};

export default MediaPlayer;

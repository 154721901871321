import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import StyledToggleButtonGroup from "./StyledToggleButtonGroup";

export default function ToggleSwitch(props) {
  const handleClickItem = (_, autoRefreshOn) => {
    if (autoRefreshOn !== null) {
      props.onChange(autoRefreshOn);
    }
  };

  return (
    <div className="auto-refresh-toggle">
      <span className="auto-refresh-toggle-label">Auto-Refresh</span>
      <StyledToggleButtonGroup
        size="small"
        exclusive
        value={props.autoRefresh}
        onChange={handleClickItem}
      >
        <ToggleButton value={true} aria-label="auto-refresh on" disableRipple>
          On
        </ToggleButton>
        <ToggleButton value={false} aria-label="auto-refresh off" disableRipple>
          Off
        </ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
}

import React, { Component } from "react";
import Recorder from "utils/audioRecorder";
import Grid from "@material-ui/core/Grid";
import { secondsToString } from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import RecordButton from "../Buttons/RecordButton";
import Waveform from "../Waveform";

export default class AudioRecorder extends Component {
  state = {
    isHovered: true,
    isRecording: false,
    timeElapsed: 0,
    somethingRecorded: false,
  };

  componentDidMount() {
    this.recorder = new Recorder();
  }

  getRecorderText = () => {
    return (
      <p className="record-time-text">
        {secondsToString(this.state.timeElapsed)}
      </p>
    );
  };

  getRecordButtonText = () => {
    if (this.state.isRecording) return "Stop Recording";
    if (!this.state.somethingRecorded)
      return (
        <>
          <FontAwesomeIcon icon={faMicrophone} />
          <span style={{ marginLeft: 10 }}>{"Record"}</span>
        </>
      );
    return "Re-record";
  };

  toggleAudioRecording = () => {
    if (this.state.isRecording) {
      // Stop Recording
      clearInterval(this.micTimer);

      this.recorder
        .stop()
        .then((file) => {
          this.setState(
            {
              somethingRecorded: true,
              isRecording: false,
              file: file,
              uri: URL.createObjectURL(file),
            },
            () => this.props.onSubmit(file)
          );
        })
        .catch((e) => {
          alert("We could not record your message.");
          console.error(e);
        });
    } else {
      this.recorder
        .start()
        .then(() => {
          this.setState({
            startTime: Date.now(),
            timeElapsed: 0,
            somethingRecorded: false,
          });
          this.micTimer = setInterval(
            () =>
              this.setState({
                timeElapsed: this.state.timeElapsed + 1,
              }),
            1000
          );
          this.setState({ isRecording: true });
        })
        .catch((e) => {
          console.error(e);
          alert("Could not start recording.");
        });
    }
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ height: 200 }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <br />
          {this.getRecorderText()}
          <div>
            <RecordButton onClick={this.toggleAudioRecording}>
              {this.getRecordButtonText()}
            </RecordButton>
          </div>
        </Grid>
        <Grid item xs={10} sm={8} md={6}>
          {this.state.uri && this.state.file && (
            <Waveform
              key={this.state.uri}
              src={this.state.uri}
              height={50}
              smaller={true}
              fluid
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="prompt-container-indash">
      <div>
        <h1>
          <b>404</b>
        </h1>
        <h3>We can't find what you're looking for.</h3>
        <p>
          <Link to="/surveys">Click here to go back to your surveys.</Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;

import {
  faCog,
  faCommentAlt,
  faFileExport,
  faMicrophone,
  faPause,
  faPlay,
  faShareAlt,
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import ClipTrimmer from "./ClipTrimmer";
import ClipTracker from "./ClipTracker";
import ClipLoader from "react-spinners/ClipLoader";
import { isAudioType } from "utils/utils";
import { getSentenceAtTime } from "utils/utils";
import { withRouter } from "react-router";
import { Tooltip } from "@material-ui/core";
import { notification } from "utils/utils";
import { isTitleType } from "utils/utils";
import PreviewTitleSlideController from "./PreviewTitleSlideController";
import clsx from "clsx";
class ShowreelPreview extends Component {
  state = { trim: {}, currentTime: 0, durations: {} };

  componentDidUpdate(prevProps) {
    if (prevProps.playing !== this.props.playing) {
      if (this.props.playing) this.play();
      else this.pause();
    }
  }

  onClipEnd = () => {
    if (
      !this.props.showreel ||
      !this.props.showreel.content ||
      this.props.clipIdx >= this.props.showreel.content.length - 1
    ) {
      this.pause();
    } else {
      this.nextClip();
    }
  };

  play = () => {
    if (this.videoPreview) {
      this.videoPreview.play();
      this.props.setPlaying(true);
      if (this.props.trimming) this.props.setTrimming(false);
    }
  };
  pause = () => {
    if (this.videoPreview) this.videoPreview.pause();
    this.props.setPlaying(false);
  };
  fullscreen = () => {
    this.videoPreview.requestFullscreen();
  };
  seek = _.debounce((seconds) => {
    this.videoPreview.currentTime = Math.min(
      seconds,
      this.getCurrentDuration() - 0.01
    );
  }, 50);
  getCurrentTime = () => {
    if (isTitleType(this.getCurrentBlock().type)) return this.state.currentTime;
    return this.videoPreview.currentTime;
  };

  titleScreenUpdate = (increment) => {
    if (this.state.currentTime + increment >= this.getCurrentDuration()) {
      this.onClipEnd();
    } else
      this.setState(
        { currentTime: this.state.currentTime + increment },
        this.props.setClipTime(this.state.currentTime)
      );
  };

  videoPlayingUpdate = () => {
    // Bubble time out to parent
    this.props.setClipTime(this.getCurrentTime());

    // Check end time of clip
    const currBlock = this.getCurrentBlock();

    if (
      currBlock &&
      currBlock.endTimeSec &&
      this.getCurrentTime() > currBlock.endTimeSec
    ) {
      if (this.props.clipIdx < this.props.showreel.content.length - 1)
        this.nextClip();
      else this.pause();
    } else if (!this.state.tracking) {
      this.setState({
        currentTime: this.videoPreview.currentTime,
      });
    }
  };

  nextClip = () => {
    if (!this.props.showreel || !this.props.showreel.content) return;
    if (this.state.tracking) this.setState({ tracking: false });
    if (this.props.clipIdx < this.props.showreel.content.length) {
      const nextClip = this.props.showreel.content[this.props.clipIdx];
      const startTime = nextClip.startTimeSec ? nextClip.startTimeSec : 0;
      this.props.setClipTime(startTime, () => {
        this.props.setClipIdx(this.props.clipIdx + 1, () => {
          this.videoPreview.currentTime = startTime;
        });
      });
    }
  };
  prevClip = () => {
    if (!this.props.showreel || !this.props.showreel.content) return;
    this.props.setClipIdx(Math.max(this.props.clipIdx - 1, 0));
  };
  commitTrimChange = (event, newValue) => {
    const block = this.getCurrentBlock();
    if (block.startTimeSec && block.endTimeSec) {
      // Check which trimmer value has changed
      if (
        (newValue[0] === block.startTimeSec ||
          newValue[0] === block.endTimeSec) &&
        (newValue[1] !== block.startTimeSec || newValue[1] !== block.endTimeSec)
      ) {
        this.seek(newValue[1]);
      } else {
        this.seek(newValue[0]);
      }
    }

    this.props.setContent(this.props.clipIdx, {
      startTimeSec: newValue[0],
      endTimeSec: newValue[1],
    });
  };
  handleTrimChange = (_, newValue) => {
    this.setState({
      trim: { ...this.state.trim, [this.getCurrentBlockId()]: newValue },
    });
  };

  handleTrackerChange = (event, newValue) => {
    this.setState({ currentTime: newValue }, () => this.seek(newValue));
  };
  commitTrackerChange = (event, newValue) => {
    this.setState({ tracking: false });
    this.seek(newValue);
  };
  loadedNewSource = () => {
    const currentBlock = this.getCurrentBlock();
    if (currentBlock.startTimeSec) {
      this.seek(currentBlock.startTimeSec);
    }
    this.setState((prevState) => ({
      durations: {
        ...prevState.durations,
        [this.getCurrentBlockId()]: this.videoPreview.duration,
      },
    }));
  };

  // Helper functions for accessing clip data
  getCurrentBlock = () => {
    return this.props.showreel.content[this.props.clipIdx];
  };
  getCurrentResponse = () => {
    return this.props.showreel.content[this.props.clipIdx].response;
  };
  getCurrentDuration = () => {
    if (this.getCurrentBlock() && this.getCurrentBlock().durationSec)
      return this.getCurrentBlock().durationSec;
    return this.state.durations[this.getCurrentBlockId()];
  };
  getCurrentBlockId = () => {
    if (!this.props.showreel || !this.props.showreel.content) return undefined;
    const block = this.props.showreel.content[this.props.clipIdx];
    if (block) return block.blockId;
    return undefined;
  };
  getCurrentStartEnd = () => {
    const clip = this.props.showreel.content[this.props.clipIdx];
    if (!clip || !clip.startTimeSec || !clip.endTimeSec) return undefined;
    return [clip.startTimeSec, clip.endTimeSec];
  };
  getDemographicsJSX = () => {
    if (!this.getCurrentBlock() || !this.getCurrentBlock().response)
      return null;
    const demographics = this.getCurrentResponse().demographics;
    if (!demographics) return null;
    const age = demographics.age;
    const gender = demographics.gender;
    const hHIncome = demographics.hHIncome;
    const location = demographics.location;
    const firstName = demographics.firstName;
    const lastName = demographics.lastName;
    return (
      <>
        {(firstName !== undefined || lastName !== undefined) && (
          <p>
            <b>Name</b>: {firstName} {lastName}
          </p>
        )}
        {age !== undefined && (
          <p>
            <b>Age</b>: {age}
          </p>
        )}
        {age !== undefined && (
          <p>
            <b>Gender</b>: {gender}
          </p>
        )}
        {hHIncome !== undefined && (
          <p>
            <b>HH Income</b>: {hHIncome}
          </p>
        )}
        {location !== undefined && (
          <p>
            <b>Location</b>: {location.display}
          </p>
        )}
      </>
    );
  };

  getCurrentTrim = () => {
    // 1. If the local trim is present, return this as the value
    // 2. If the current clip has start and end times, return them
    // 3. If neither are found, return [0, clipDuration]
    if (this.state.trim[this.getCurrentBlockId()] !== undefined)
      return this.state.trim[this.getCurrentBlockId()];
    else if (this.getCurrentStartEnd()) return this.getCurrentStartEnd();
    return [0, this.getCurrentDuration()];
  };

  render() {
    return (
      <>
        {this.props.showreel &&
          this.props.showreel.content &&
          this.props.showreel.content.length > 0 &&
          this.props.showreel.content[this.props.clipIdx] !== undefined && (
            <>
              {isAudioType(this.getCurrentBlock().type) && (
                <div className="microphone-overlay flex-container">
                  <FontAwesomeIcon icon={faMicrophone} />
                </div>
              )}
              {isTitleType(this.getCurrentBlock().type) && (
                <div
                  className={clsx(
                    "title-overlay",
                    `h-${this.getCurrentBlock().horizontalAlign}`,
                    `v-${this.getCurrentBlock().verticalAlign}`
                  )}
                  style={{
                    backgroundColor: this.getCurrentBlock().backgroundColor,
                    backgroundImage: `url(${this.getCurrentBlock().backgroundImageUrl})`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div
                    className={"title-overlay-text"}
                    style={{
                      color: this.getCurrentBlock().fontColor,
                    }}
                  >
                    <h3 style={{ fontFamily: "Arial", margin: "10px 0" }}>
                      {this.getCurrentBlock().title}
                    </h3>
                    <p style={{ fontFamily: "Arial", margin: 0 }}>
                      {this.getCurrentBlock().subTitle}
                    </p>
                  </div>
                </div>
              )}
              {this.props.showreel.addSubtitles &&
                this.getCurrentBlock().response &&
                this.getCurrentBlock().response.features &&
                this.getCurrentBlock().response.features
                  .transcription_items && (
                  <div className="transcription-overlay flex-container">
                    <span className="shadow-text">
                      {getSentenceAtTime(
                        this.getCurrentBlock().response.features
                          .transcription_items,
                        this.state.currentTime
                      )}
                    </span>
                  </div>
                )}
              <video
                id="SHOWREEL-PREVIEW-VIDEO"
                ref={(component) => {
                  this.videoPreview = component;
                }}
                key={`CLIP-${this.props.showreel.content[this.props.clipIdx].blockId
                  }`}
                className="showreels-preview-video"
                controls={false}
                onEnded={this.onClipEnd}
                autoPlay={this.props.playing}
                onLoadedMetadata={this.loadedNewSource}
                onTimeUpdate={this.videoPlayingUpdate}
              >
                {this.props.showreel.content[this.props.clipIdx].response && (
                  <source
                    src={
                      this.props.showreel.content[this.props.clipIdx].response
                        .uri
                    }
                  />
                )}
                Your browser does not support the video tag.
              </video>
              {isTitleType(this.getCurrentBlock().type) && (
                <PreviewTitleSlideController
                  playing={this.props.playing}
                  currentTime={this.state.currentTime}
                  resetCurrentTime={() => this.setState({ currentTime: 0 })}
                  onTimeUpdate={this.titleScreenUpdate}
                  duration={this.getCurrentBlock().duration}
                />
              )}
            </>
          )}
        {this.props.showreel.addWatermark && (
          <div className="showreel-watermark">
            {this.props.showreel.topLeftImageUrl ? (
              <img src={this.props.showreel.topLeftImageUrl} />
            ) : (
              <img
                src={require("assets/img/powered-by-phonic-white.png")}
                className="phonic-watermark"
              />
            )}
          </div>
        )}
        {this.props.showreel.showDemographics && (
          <div className="showreel-demographics">
            {this.getDemographicsJSX()}
          </div>
        )}
        {this.props.clipIdx !== undefined &&
          this.getCurrentDuration() &&
          !this.props.trimming && (
            <div className="showreel-preview-trimming">
              <ClipTracker
                min={0}
                max={this.getCurrentDuration()}
                value={this.state.currentTime}
                handleChange={this.handleTrackerChange}
                commitTrackerChange={this.commitTrackerChange}
                handleMouseDown={() => this.setState({ tracking: true })}
              />
            </div>
          )}
        {this.props.trimming && this.state.durations[this.getCurrentBlockId()] && (
          <div className="showreel-preview-trimming">
            <ClipTrimmer
              min={0}
              max={this.getCurrentDuration()}
              value={this.getCurrentTrim()}
              handleChange={this.handleTrimChange}
              commitTrimChange={this.commitTrimChange}
            />
          </div>
        )}
        <div className="showreel-preview-controls flex-container">
          <div
            className="showreel-preview-control-button flex-container"
            onClick={this.prevClip}
          >
            <FontAwesomeIcon icon={faStepBackward} />
          </div>
          <div
            className="showreel-preview-control-button flex-container"
            onClick={() => {
              if (this.props.playing) {
                this.pause();
              } else {
                this.play();
              }
            }}
          >
            {!this.props.playing ? (
              <FontAwesomeIcon icon={faPlay} />
            ) : (
              <FontAwesomeIcon icon={faPause} />
            )}
          </div>
          <div
            className="showreel-preview-control-button flex-container"
            onClick={this.nextClip}
          >
            <FontAwesomeIcon icon={faStepForward} />
          </div>
          {/* Left flaoting container for exporting */}
          <div className="showreel-preview-control-button-absolute left smaller flex-container">
            <Tooltip title="Showreel Settings">
              <div
                className="showreel-preview-control-button flex-container"
                onClick={() => {
                  this.props.openSettingsPopup(true);
                }}
              >
                <FontAwesomeIcon icon={faCog} />
              </div>
            </Tooltip>
            {this.props.showreel.exportJobStatus !== "PENDING" && (
              <Tooltip title="Export Showreel">
                <div
                  className="showreel-preview-control-button flex-container"
                  onClick={() => {
                    if (!this.props.user || this.props.user.tier === "FREE") {
                      notification(
                        "Please upgrade your account.",
                        "Showreel exporting is only available with premium plans",
                        "warning"
                      );
                      return;
                    }
                    this.props.exportShowreel(this.props.showreel._id);
                  }}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </div>
              </Tooltip>
            )}
            {this.props.showreel.exportJobStatus === "COMPLETED" &&
              this.props.showreel.uri && (
                <Tooltip title="Open Showreel">
                  <div
                    className="showreel-preview-control-button flex-container"
                    onClick={() => {
                      if (!this.props.showreel.public) {
                        this.props.openPublicPopup(true);
                        return;
                      }
                      if (this.props.showreel.uri)
                        window.open(
                          `/showreels/${this.props.showreel._id}/share`,
                          "_blank"
                        );
                    }}
                  >
                    <FontAwesomeIcon icon={faShareAlt} />
                  </div>
                </Tooltip>
              )}
            {this.props.showreel.exportJobStatus === "PENDING" && (
              <div className="flex-container">
                <ClipLoader size={18} color={"black"} loading={true} />{" "}
                <span style={{ fontSize: 10, marginLeft: 10 }}>
                  {this.props.showreel.exportJobProgress.toFixed(2)}%
                </span>
              </div>
            )}
          </div>
          {/* Right floating container for fullscreen */}
          <div
            className="showreel-preview-control-button-absolute right smaller flex-container"
            onClick={this.props.openSubtitlesPopup}
          >
            <div className="showreel-preview-control-button flex-container">
              <FontAwesomeIcon icon={faCommentAlt} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ShowreelPreview);

import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { addSurvey, startLoading, getTemplates } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TemplateTile from "components/Tiles/TemplateTile";
import TemplateOptionTile from "components/Tiles/TemplateOptionTile";
import { ScaleLoader } from "react-spinners";
import { getTypeJSXIconSquare } from "utils/utils";

function TemplateConfirmationView({
  template,
  createSurveyFromTemplate,
  template: { name, templateCategory, templateDescription, questions },
}) {
  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <Grid container spacing={2} className="">
        <Grid item xs={12} sm={4} className="">
          <h3>{name}</h3>
          <h5 className="template-category">{templateCategory}</h5>
          <h5 className="descriptor-text">Description</h5>
          <div id="template-summary">
            <Typography gutterBottom className="">
              {templateDescription}
            </Typography>
          </div>
          <h5 className="descriptor-text">Question Overview</h5>
          <div id="question-overview">
            {questions.map(({ text, type, questionId }) => {
              return (
                <div key={questionId} className="question-overview-element">
                  <div className="question-overview-icon">
                    <div>{getTypeJSXIconSquare(type)}</div>
                  </div>
                  <p>{text}</p>
                </div>
              );
            })}
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => createSurveyFromTemplate(template["_id"])}
            fullWidth
            style={{ marginTop: 20 }}
          >
            Use Template
          </Button>
        </Grid>
        <Grid item xs={12} sm={8} id="preview-container">
          <iframe
            title="Template Preview"
            id="preview"
            src={`https://survey.phonic.ai/${template._id}?preview=true`}
            allow="microphone;camera"
            className=""
          ></iframe>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Templates({
  templateSurveySwitch,
  isMainPage,
  turnOffMainView,
  searchKey,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [categorizedTemplates, setCategorizedTemplates] = useState({});
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const groupTemplatesByCategory = (templatesArr) => {
    const categorized = {};
    for (let template of templatesArr) {
      if (categorized[template.templateCategory]) {
        categorized[template.templateCategory].push(template);
      } else {
        categorized[template.templateCategory] = [template];
      }
    }
    setCategorizedTemplates(categorized);
  };

  if (state.templates && searchKey !== null && searchKey !== state.searchKey) {
    const templatesArr = state.templates.filter((template) => {
      let hayStack = "";
      if (template.name) hayStack += template.name;
      if (template.templateDescription)
        hayStack += template.templateDescription;
      if (template.templateCategory) hayStack += template.templateCategory;
      // Address issue where app goes stale
      if (hayStack === undefined || searchKey === undefined) return true;
      return hayStack.toLowerCase().includes(searchKey.toLowerCase());
    });

    groupTemplatesByCategory(templatesArr);
    state.searchKey = searchKey;
  }

  const createSurveyFromTemplate = (templateId) => {
    //uses ID to create survey from template
    dispatch(
      startLoading(() => dispatch(addSurvey({ templateId })), "CreateSurvey")
    );
  };

  const getTemplateDetails = (template) => {
    // sets a template to be feed into TemplateConfirmationView and shuts off mainView (this.state.popupOptions.mainView) in CreateSurvey
    turnOffMainView();
    setSelectedTemplate(template);
  };

  let areTemplatesAvailable = Object.keys(categorizedTemplates).length > 0;
  let templatesLoading = state.loading["GetTemplates"];

  useEffect(() => {
    // setTemplates
    dispatch(startLoading(() => dispatch(getTemplates()), "GetTemplates"));
  }, [dispatch]);

  useEffect(() => {
    // sets templates in local state after the payload is set in the reducer's state
    if (Object.keys(categorizedTemplates).length === 0 && state.templates) {
      groupTemplatesByCategory(state.templates);
    }
  }, [state.templates, categorizedTemplates]);

  useEffect(() => {
    // when a survey is created, reroutes only after the id is set to the reducer
    if (state.createSurveyId) {
      history.push(`/create_survey/${state.createSurveyId}`);
    }
  }, [state.createSurveyId, history]);

  return (
    <>
      {selectedTemplate && !isMainPage ? (
        <TemplateConfirmationView
          template={selectedTemplate}
          createSurveyFromTemplate={createSurveyFromTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      ) : (
        <>
          <div className="template-category-wrapper">
            <h6 className="">Quick Start</h6>
          </div>
          <Grid container spacing={2} className="">
            <TemplateOptionTile
              createSurveyFromTemplate={getTemplateDetails}
              title="Start From Scratch"
              type="NEW"
              action={() => templateSurveySwitch("NEW")}
            />
            <TemplateOptionTile
              title="Continue From Draft"
              createSurveyFromTemplate={getTemplateDetails}
              type="DRAFT"
              action={() => templateSurveySwitch("DRAFTS")}
            />
          </Grid>

          {templatesLoading && (
            <div
              style={{
                padding: 12,
                width: "100%",
                textAlign: "center",
              }}
            >
              <ScaleLoader
                style={{ width: "40px" }}
                size={"10px"}
                color={"#9BA5BA"}
                loading={templatesLoading}
              />
              <i>Loading Templates</i>
            </div>
          )}
          {areTemplatesAvailable &&
            Object.keys(categorizedTemplates).map((category) => {
              const filteredCategory = categorizedTemplates[category];
              return filteredCategory.length > 0 ? (
                <div key={category} className="template-list">
                  <div className="template-category-wrapper">
                    <h6 className="">{category}</h6>
                  </div>
                  <Grid
                    container
                    alignContent="stretch"
                    spacing={2}
                    className=""
                  >
                    {filteredCategory.map((template) => {
                      return (
                        <TemplateTile
                          key={template["_id"]}
                          action={getTemplateDetails}
                          name={template.name}
                          image={template.templateImageUrl}
                          template={template}
                          description={template.templateDescription}
                        />
                      );
                    })}
                  </Grid>
                </div>
              ) : null;
            })}
          {!areTemplatesAvailable && !templatesLoading && (
            <div>
              <p className="no-results-text">There are no results...</p>
            </div>
          )}
        </>
      )}
    </>
  );
}

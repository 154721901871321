// Lightweight fetch abstraction
const fetchWithAuth = (baseUrl, token, path, method, data) => {
  const options = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      "Content-Type": "application/json",
    },
  };

  if (data) options["body"] = JSON.stringify(data);
  return fetch(`${baseUrl}${path}`, options);
};

export default fetchWithAuth;

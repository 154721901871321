import React from "react";

import { TextField, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";

export default class FileDemographic extends React.Component {
  state = { editing: false, newValue: null };

  componentDidMount() {
    this.setState({ newValue: this.props.value });
  }

  render() {
    return (
      <div className="file-demographics">
        <TextField
          disabled={!this.state.editing}
          value={this.state.newValue}
          variant="outlined"
          margin="dense"
          className="translation-flex"
          label={this.props.label}
          key="value"
          onChange={(e) => {
            this.setState({ newValue: e.target.value });
          }}
        />

        <div className="file-demographics-edit-button">
          {this.state.editing ? (
            <>
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="Cancel editing info"
                  onClick={() => {
                    this.setState({
                      editing: false,
                      newValue: this.props.value,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Confirm">
                <IconButton
                  aria-label="Confirm edits"
                  onClick={() => {
                    this.setState({ editing: false });
                    this.props.save({
                      [this.props._key]: this.state.newValue,
                    });
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Edit">
              <IconButton
                aria-label="Edit"
                onClick={() => this.setState({ editing: true })}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Chart from "chart.js/auto";
import { getChartColors } from "utils/utils";

export default class LineGraph extends Component {
  chartRef = React.createRef();

  pieChart = null;

  clickSegment = (e) => {
    if (this.props.interactive) {
      const elem = this.pieChart.getElementsAtEventForMode(
        e,
        "nearest",
        { intersect: true },
        false
      );
      if (elem[0]) {
        const segment = this.props.chartData.labels[elem[0].index];
        this.props.filterSentiment(segment);
      }
    } else return;
  };

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");
    this.pieChart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: this.props.chartData.labels,
        datasets: [
          {
            data: this.props.chartData.data,
            backgroundColor: this.props.backgroundColor
              ? this.props.backgroundColor
              : getChartColors(this.props.chartData.data.length),
            borderColor: this.props.borderColor,
            borderWidth: 2,
          },
        ],
      },
      options: {
        aspectRatio: 2,
        plugins: {
          legend: {
            position: "right",
            display: this.props.displayLegend,
          },
        },
        hover: {
          onHover: (_, items) => {
            let el = this.chartRef.current;
            el.style.cursor = items[0] ? "pointer" : "default";
          },
        },
        onClick: this.clickSegment,
      },
    });
  }
  render() {
    return (
      <div>
        <canvas id="pie-chart" className="basic-chart" ref={this.chartRef} />
      </div>
    );
  }
}

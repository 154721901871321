import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import Loading from "views/Loading";
import { getShowreelFile } from "redux/actions";
import { Helmet } from "react-helmet";
import PoweredBy from "components/PoweredBy";
import AdminNavbar from "components/Navbars/AdminNavbar";

const mapStateToProps = (state) => {
  return {
    showreel: state.currentShowreel,
  };
};
const mapDispatchToProps = {
  getShowreelFile,
};

class Showreel extends Component {
  componentDidMount() {
    this.props.getShowreelFile(this.props.match.params.showreelId);
  }
  render() {
    return (
      <div>
        <AdminNavbar />
        <div id="showreels-share">
          <Helmet>
            <title>
              {this.props.showreel
                ? this.props.showreel.name
                : "Phonic Showreel"}
            </title>
          </Helmet>

          {!this.props.showreel ? (
            <Loading />
          ) : (
            <>
              <Container component="article" maxWidth="md">
                <div className="inner-container">
                  <div>
                    <div className="showreel-header">
                      <h1>{this.props.showreel.name}</h1>
                    </div>
                    <video controls={true}>
                      <source src={this.props.showreel.uri}></source>
                    </video>
                    <div className="flex-container" style={{ marginTop: 20 }}>
                      <PoweredBy />
                    </div>
                  </div>
                </div>
              </Container>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Showreel);

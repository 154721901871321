import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  IconButton,
  ListItemText,
  ListItem,
  List,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { addContentToReport, getReports } from "redux/actions";

const mapStateToProps = (state) => {
  return {
    reports: state.reports,
  };
};

const mapDispatchToProps = {
  getReports,
  addContentToReport,
};

const AddToReportPopup = ({
  open,
  onClose,
  reports,
  addContentToReport,
  getReports,
  blockInfo,
}) => {
  useEffect(() => {
    if (open) getReports();
  }, [open]);

  const handleItemClick = (reportId, evt) => {
    evt.stopPropagation();
    addContentToReport(reportId, blockInfo);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullwidth
        aria-labelledby="add-to-report"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <div id="form-dialog-title" style={{ marginBottom: 20 }}>
            <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
              Add Response to Report
            </h2>
            <p>This response will appear at the end of your selected report.</p>
          </div>
          <div className="med-scroller">
            <List aria-label="list of reports">
              {reports && reports.length === 0 ? (
                <ListItem>
                  <ListItemText primary="You have no reports." />
                </ListItem>
              ) : (
                reports &&
                reports.map((report) => (
                  <ListItem
                    button
                    component="a"
                    key={report._id}
                    onClick={(evt) => handleItemClick(report._id, evt)}
                  >
                    <ListItemText primary={`${report.name}`} />
                  </ListItem>
                ))
              )}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToReportPopup);

import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="space-evenly">
          <div className="icon-big text-center icon-warning">
            {this.props.bigIcon}
          </div>
          <div className="numbers">
            <p>{this.props.statsText}</p>
            {this.props.statsValue}
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCard;

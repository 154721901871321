import React from "react";
import { connect } from "react-redux";
import { addContentToShowreel, getShowreels } from "redux/actions";
import {
  Dialog,
  DialogContent,
  IconButton,
  ListItemText,
  ListItem,
  List,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getObjectId } from "utils/utils";

const mapStateToProps = (state) => {
  return {
    showreels: state.showreels,
    loading: state.loading["AddToShowreelPopup"],
  };
};

const mapDispatchToProps = {
  addContentToShowreel,
  getShowreels,
};

class AddToShowreelPopup extends React.Component {
  handleItemClick = (evt, showreelId, response) => {
    evt.stopPropagation();
    const newBlock = {
      type: response.type,
      blockId: getObjectId(),
      responseId: response._id,
    };
    this.props.addContentToShowreel(showreelId, newBlock);
    this.props.onClose();
  };

  componentDidMount() {
    this.props.getShowreels();
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose} fullWidth>
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <div id="form-dialog-title" style={{ marginBottom: 20 }}>
            <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
              Add Response to Showreel
            </h2>
            <p>
              This response will appear at the end of your selected showreel.
            </p>
          </div>
          <div className="med-scroller">
            <List>
              {this.props.showreels &&
                (this.props.showreels.length === 0 ? (
                  <ListItem>
                    <ListItemText primary="You have no showreels." />
                  </ListItem>
                ) : (
                  this.props.showreels.map((showreel) => (
                    <ListItem
                      button
                      component="a"
                      key={showreel._id}
                      onClick={(evt) =>
                        this.handleItemClick(
                          evt,
                          showreel._id,
                          this.props.response
                        )
                      }
                    >
                      <ListItemText primary={`${showreel.name}`} />
                    </ListItem>
                  ))
                ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToShowreelPopup);

import React from "react";
import { getTimeStringFromSeconds } from "utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";

const PARENT_TIME_REFRESH_RATE = 0.25;

var WaveSurfer = require("wavesurfer.js");
var RegionsPlugin = require("wavesurfer.js/dist/plugin/wavesurfer.regions.min.js");

export default class Waveform extends React.Component {
  state = {
    currentTime: "00:00",
    parentTime: 0,
    playing: false,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.waveRef = React.createRef();
  }

  componentDidMount() {
    this.wavesurfer = WaveSurfer.create({
      container: this.waveRef.current,
      waveColor: "#62cb88",
      progressColor: "#448e5f",
      barWidth: 2,
      barRadius: 3,
      height: this.props.height,
      barMinHeight: 2,
      cursorWidth: 1,
      plugins: [
        RegionsPlugin.create({
          container: "#wave-timeline",
        }),
      ],
    });
    // Once the audio is loaded
    this.wavesurfer.load(this.props.src);
    this.setState({ wavesurfer: this.wavesurfer });
    this.wavesurfer.on("ready", () => {
      this.setState({ loading: false });
      this.setState({
        currentTime: getTimeStringFromSeconds(
          this.wavesurfer.backend.getDuration()
        ),
      });
    });
    // As the audio plays
    this.wavesurfer.on("audioprocess", () => {
      const time = this.wavesurfer.getCurrentTime();
      if (
        this.props.interactiveTranscription &&
        Math.abs(time - this.state.parentTime) > PARENT_TIME_REFRESH_RATE
      ) {
        this.props.updatePlaybackResponse(time);
        this.setState({ parentTime: time });
      }
      this.setState({
        currentTime: getTimeStringFromSeconds(Math.round(time)),
      });
    });
    // When the audio is finished playing
    this.wavesurfer.on("finish", () => {
      this.wavesurfer.seekTo(0);
      this.setState({ playing: false });
      if (this.props.onEnded) this.props.onEnded();
    });
  }
  componentWillUnmount() {
    this.wavesurfer.destroy();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.playTime !== this.props.playTime) {
      if (this.wavesurfer) {
        this.wavesurfer.play(this.props.playTime);
        this.setState({
          playing: true,
        });
      }
    }
  }

  seek = (time) => {
    if (isFinite(time)) {
      this.wavesurfer.play(time);
      this.setState({ playing: true });
    }
  };

  getPlayPauseText() {
    if (this.state.playing) return <FontAwesomeIcon icon={faPauseCircle} />;
    return <FontAwesomeIcon icon={faPlayCircle} />;
  }

  toggle = (e) => {
    e.stopPropagation();
    if (this.state.playing) {
      this.state.wavesurfer.pause();
    } else {
      this.state.wavesurfer.play();
    }
    this.setState({ playing: !this.state.playing });
  };

  render() {
    if (this.props.simpleWave) {
      return (
        <div className="waveform">
          <div ref={this.waveRef} className="wave"></div>
        </div>
      );
    }
    if (this.props.smaller)
      return (
        <div className="audio-player-outer">
          <div className="play-button-container">
            <span
              onClick={(e) => this.toggle(e)}
              className={"playpause-button-smaller"}
            >
              {this.getPlayPauseText()}
            </span>
          </div>
          <div className="waveform-inner-container">
            <div className="waveform">
              <div ref={this.waveRef} className="wave"></div>
            </div>
          </div>
          <div className="audio-player-time">
            <span>{this.state.currentTime}</span>
          </div>
        </div>
      );
    return (
      <div className={this.props.fluid ? "sound-max-width" : null}>
        <div className="play-button-container large">
          <span onClick={(e) => this.toggle(e)} className={"playpause-button"}>
            {this.getPlayPauseText()}
          </span>
        </div>
        <div className="waveform-inner-container large">
          <div className="waveform">
            <div
              ref={this.waveRef}
              onClick={(e) => e.stopPropagation()}
              className="wave"
            ></div>
          </div>
        </div>
        <div className="audio-player-time large">
          <span>{this.state.currentTime}</span>
        </div>
      </div>
    );
  }
}

Waveform.defaultProps = {
  src: "",
};

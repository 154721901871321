import React, { forwardRef, useRef } from "react";
import clsx from "clsx";
import Waveform from "components/Waveform";
import VideoPlayer from "components/VideoPlayer";
import useOnScreen from "hooks/useOnScreen";
import { isAudioType, isVideoType } from "utils/utils";

const UploadedMedia = forwardRef(
  ({ type, uri, skipTime, onTimeUpdate }, ref) => {
    const containerRef = useRef();
    const [mainMediaVisible, mediaHeight] = useOnScreen(containerRef);

    return (
      <div ref={containerRef} style={{ height: mediaHeight }}>
        <div className={clsx(!mainMediaVisible && "floating-media")}>
          {isAudioType(type) && (
            <Waveform
              ref={ref}
              interactiveTranscription
              updatePlaybackResponse={onTimeUpdate}
              src={uri}
              playTime={skipTime || 0}
              height={50}
            />
          )}
          {isVideoType(type) && (
            <div className="flex-container">
              <VideoPlayer
                ref={ref}
                uri={uri}
                onTimeUpdate={onTimeUpdate}
                size="full-width"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
UploadedMedia.displayName = "UploadedMedia";
export default UploadedMedia;

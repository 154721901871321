import { fromJS } from "immutable";
import _ from "lodash";
import { interweave } from "utils/utils";
import {
  pushItemOntoArray,
  removeItemFromArray,
  mergeTrees,
} from "utils/utils";

function convertTimeStampToSeconds(utterance) {
  const parts = utterance.timestamp.split(":");
  var seconds = 0;
  for (var i = 0; i < parts.length; i++) {
    seconds += Math.pow(60, parts.length - 1 - i) * parseInt(parts[i]);
  }
  utterance.seconds = seconds;
}

const backendReducer = (state = [], action) => {
  var surveys;
  switch (action.type) {
    case "RETURN_USER":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case "RETURN_SURVEYS":
      surveys = {};
      for (var i = 0; i < action.payload.surveys.length; i++) {
        surveys[action.payload.surveys[i]._id] = action.payload.surveys[i];
      }
      return {
        ...state,
        surveys: surveys,
        numResponses: action.payload.total_responses,
        numSurveys: action.payload.total_surveys,
        monthlyResponses: action.payload.monthly_responses,
        monthlyAllowance: action.payload.monthly_allowance,
      };
    case "RETURN_CURRENT_SURVEY":
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.payload.currentSurvey._id]: action.payload.currentSurvey,
        },
        currentSurvey: action.payload.currentSurvey,
        estimatedCompletionTimeMinutes:
          action.payload.estimatedCompletionTimeMinutes,
      };
    case "UPDATE_CURRENT_SURVEY":
      return {
        ...state,
        currentSurvey: action.payload,
      };
    case "UPDATE_SURVEY_PROP":
      surveys = JSON.parse(JSON.stringify(state.surveys));
      surveys[action.payload.surveyId] = {
        ...surveys[action.payload.surveyId],
        ...action.payload.data,
      };
      return {
        ...state,
        surveys,
      };
    case "UPDATE_DURATION_ESTIMATE":
      return {
        ...state,
        estimatedCompletionTimeMinutes:
          action.payload.estimatedCompletionTimeMinutes,
      };
    case "CLEAR_SURVEY_DURATION_ESTIMATE":
      return {
        ...state,
        estimatedCompletionTimeMinutes: undefined,
      };
    case "SET_SURVEY_STATE":
      surveys = {};
      Object.assign(surveys, state.surveys);
      surveys[action.payload.surveyId].state = action.payload.state;
      return {
        ...state,
        surveys,
      };

    case "DELETE_SURVEY":
      surveys = {};
      Object.assign(surveys, state.surveys);
      delete surveys[action.payload.surveyId];
      return {
        ...state,
        surveys: surveys,
      };
    case "CLEAR_CREATE_SURVEY_ID":
      return {
        ...state,
        createSurveyId: undefined,
      };
    case "CLEAR_CREATE_SURVEY":
      return {
        ...state,
        currentSurvey: undefined,
      };
    case "DELETE_DRAFT":
      var drafts = {};
      Object.assign(drafts, state.drafts);
      delete drafts[action.payload.surveyId];
      return {
        ...state,
        drafts: drafts,
      };
    case "APPEND_DRAFT":
      var drafts = JSON.parse(JSON.stringify(state.drafts));
      drafts[action.payload._id] = action.payload;
      return { ...state, drafts };
    case "RETURN_DRAFTS":
      var drafts = {};
      for (var i = 0; i < action.payload.length; i++) {
        drafts[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        drafts: drafts,
      };
    case "RETURN_FOCUS_GROUPS":
      var groups = {};
      for (var i = 0; i < action.payload.length; i++) {
        groups[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        focusGroups: groups,
      };
    case "RETURN_ORGANIZATION":
      return {
        ...state,
        organization: { ...state.organization, ...action.payload },
      };
    case "RETURN_FOCUS_GROUP_CONVERSATIONS":
      // Dict of arrays, keyed by focusGroupId
      var c = { ...state.converations };
      if (!action.payload) return { ...state };
      for (var i = 0; i < action.payload.length; i++) {
        var focusGroupId = action.payload[i].focusGroupId;

        // Convert timestamp to seconds int.
        for (var utterance in action.payload[i].conversation) {
          convertTimeStampToSeconds(action.payload[i].conversation[utterance]);
        }

        if (!(focusGroupId in c)) {
          c[focusGroupId] = [];
        }
        c[focusGroupId].push(action.payload[i]);
      }
      return {
        ...state,
        conversations: c,
      };
    case "RETURN_RESPONSES":
      if (action.payload)
        return {
          ...state,
          responses: {
            ...state.responses,
            [action.questionId]: action.payload.responses,
          },
          questionNumResponses: {
            [action.questionId]: action.payload.numResponses,
          },
          languages: action.payload.languages
            ? {
                ...state.languages,
                [action.questionId]: action.payload.languages,
              }
            : { ...state.languages },
          urlParams: action.payload.urlParams
            ? {
                ...state.urlParams,
                [action.questionId]: action.payload.urlParams,
              }
            : { ...state.urlParams },
        };
    case "RETURN_TOPICS":
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.questionId]: action.payload.topics,
        },
      };
    case "RETURN_RESPONSE":
      // The update response endpoint doesn't generate a URI
      // so this is needed to not overwrite the existing URI.
      return {
        ...state,
        response: action.payload.uri
          ? {
              ...action.payload,
            }
          : {
              ...state.response,
              ...action.payload,
            },
      };
    case "UPDATE_RESPONSE":
      let responses = _.cloneDeep(state.responses);
      let questionResponses = responses[action.payload.questionId];
      for (let i = 0; i < questionResponses.length; ++i) {
        if (questionResponses[i]._id === action.payload.responseId) {
          questionResponses[i] = {
            ...questionResponses[i],
            ...action.payload.responseData,
          };
        }
      }
      responses[action.payload.questionId] = questionResponses;
      return {
        ...state,
        responses: responses,
      };
    case "UPDATE_SESSION":
      let surveySessions = _.cloneDeep(state.sessions[action.surveyId]);
      for (let i = 0; i < surveySessions.length; ++i) {
        if (surveySessions[i].sessionId === action.sessionId) {
          surveySessions[i] = { ...surveySessions[i], ...action.payload };
        }
      }
      return {
        ...state,
        sessions: { ...state.sessions, [action.surveyId]: surveySessions },
      };
    case "RETURN_SESSIONS":
      return {
        ...state,
        sessions: { ...state.sessions, [action.surveyId]: action.payload },
      };
    case "RETURN_SESSION":
      let sessions = _.cloneDeep(state.sessions[action.surveyId]);
      for (let i = 0; i < sessions.length; ++i) {
        if (sessions[i]._id === action.sessionId) sessions[i] = action.payload;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [action.surveyId]: sessions,
        },
      };
    case "DELETE_SESSION":
      let sessionIdx = null;
      for (let n = 0; n < state.sessions[action.surveyId].length; n++) {
        if (state.sessions[action.surveyId][n]._id === action.sessionId) {
          sessionIdx = n;
        }
      }
      if (sessionIdx === null) return state;
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [action.surveyId]: [
            ...state.sessions[action.surveyId].slice(0, sessionIdx), // Everything before sIdx
            ...state.sessions[action.surveyId].slice(sessionIdx + 1), // Everything after sIdx
          ],
        },
      };
    case "RETURN_UTTERANCE":
      return {
        ...state,
        utterance: { ...state.utterance, [action.utteranceId]: action.payload },
      };
    case "RETURN_FULL_AUDIO":
      return {
        ...state,
        fullAudio: {
          ...state.fullAudio,
          [action.conversationId]: action.payload,
        },
      };
    case "ADD_AUTH_TOKEN":
      return {
        ...state,
        authToken: action.payload,
      };
    case "SEARCH_TEXT_CHANGED":
      return { ...state, searchText: action.payload };
    case "RETURN_SURVEY_ID":
      return { ...state, createSurveyId: action.payload.surveyId };
    case "TOGGLE_UPGRADE_POPUP":
      return { ...state, upgradePopupOpen: action.payload };
    case "RETURN_UPGRADE_CHECKOUT_ID":
      return { ...state, stripeCheckoutId: action.payload.id };
    case "RETURN_CREDIT_CHECKOUT_ID":
      return { ...state, stripeCreditCheckoutId: action.payload.id };
    case "CONSENT_UPLOAD":
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          consentPDFUri: action.payload.public_url,
        },
      };
    case "WELCOME_IMAGE_UPLOAD":
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          welcomeImageUrl: action.payload.public_url,
        },
      };
    case "TOP_LEFT_IMAGE_UPLOAD":
      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          topLeftImageUrl: action.payload.public_url,
        },
      };
    case "UPDATE_QUESTION_STIMULI":
      if (!state.currentSurvey) alert("No survey loaded.");
      // On error reset Loader
      if (!action.payload.public_url) {
        return { ...state, loadingQuestionMedia: undefined };
      }
      var questions = state.currentSurvey.questions;
      questions[action.payload.questionIdx].stimuli = {
        type: action.payload.stimuliType,
        url: action.payload.public_url,
        ...action.payload.mediaProps,
      };
      return {
        ...state,
        loadingQuestionMedia: undefined,
        currentSurvey: {
          ...state.currentSurvey,
          questions: questions,
        },
      };
    case "QUESTION_MEDIA_LOADING":
      return {
        ...state,
        loadingQuestionMedia: action.payload.questionIdx,
      };
    case "UNSHARE_SURVEY": {
      const surveyId = action.payload.surveyId;
      for (var idx = 0; idx < state.user.shared[surveyId].length; ++idx) {
        if ((state.user.shared[surveyId][idx].userId = action.payload.userId))
          break;
      }
      return {
        ...state,
        user: {
          ...state.user,
          shared: {
            ...state.user.shared,
            [surveyId]: removeItemFromArray(state.user.shared[surveyId], idx),
          },
        },
      };
    }
    case "SHARE_SURVEY": {
      const surveyId = action.payload.surveyId;
      return {
        ...state,
        user: {
          ...state.user,
          shared: {
            ...state.user.shared,
            [surveyId]: pushItemOntoArray(state.user.shared[surveyId], {
              userId: action.payload.userId,
              email: action.payload.email,
            }),
          },
        },
      };
    }
    case "RETURN_REPORT":
      return {
        ...state,
        report: {
          _id: action.payload._id,
          name: action.payload.name,
          contents: action.payload.contents,
          imageUrl: action.payload.imageUrl,
          createdAt: action.payload.createdAt,
        },
      };
    case "RETURN_REPORTS":
      return {
        ...state,
        reports: action.payload,
      };
    case "REPORT_ERROR":
      return {
        ...state,
        reportError: true,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: action.payload,
        },
      };
    case "RETURN_BOOKMARKS":
      return {
        ...state,
        bookmarks: {
          responses: action.payload.bookmarks,
          surveys: action.payload.surveys,
        },
      };
    case "UPDATE_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.payload.title,
      };
    case "EMAIL_VERIFIED":
      return { ...state, emailVerified: action.payload };
    case "RETURN_TEMPLATES":
      return {
        ...state,
        templates: action.payload,
      };
    case "SET_MODEL_SAMPLE":
      return {
        ...state,
        modelSample: action.payload,
      };
    case "CLEAR_MODEL_SAMPLE":
      return { ...state, modelSample: undefined };
    case "RETURN_JOBS":
      const questionId = action.payload.questionId;
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [questionId]: action.payload.jobs,
        },
      };
    case "APPLY_TOPIC":
      const currentResponses = _.cloneDeep(
        state.responses[action.payload.questionId]
      );
      for (let i = 0; i < currentResponses.length; i++) {
        if (currentResponses[i]._id === action.payload.responseId) {
          currentResponses[i] = {
            ...currentResponses[i],
            topics: [...(currentResponses[i].topics || []), action.payload],
          };
          break;
        }
      }
      const currentTopics = _.cloneDeep(
        state.topics[action.payload.questionId]
      );
      for (let i = 0; i < currentTopics.length; i++) {
        if (currentTopics[i]._id === action.topicId) {
          currentTopics[i] = {
            ...currentTopics[i],
            mentions: [
              ...currentTopics[i].mentions,
              { responseId: action.payload.responseId },
            ],
          };
          break;
        }
      }
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.questionId]: currentResponses,
        },
        topics: {
          ...state.topics,
          [action.payload.questionId]: currentTopics,
        },
      };
    case "UNAPPLY_TOPIC": {
      // Add brackets for case-scope variable
      const currentResponses = _.cloneDeep(state.responses[action.questionId]);
      for (let i = 0; i < currentResponses.length; i++) {
        if (currentResponses[i]._id === action.payload.responseId) {
          currentResponses[i] = {
            ...currentResponses[i],
            topics: currentResponses[i].topics.filter(
              (t) => t._id !== action.payload.topicId
            ),
          };
          break;
        }
      }
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.questionId]: currentResponses,
        },
      };
    }
    case "EDIT_TOPIC": {
      const newTopics = state.topics[action.questionId].map((t) =>
        t._id === action.payload._id ? { ...t, Text: action.payload.Text } : t
      );
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.questionId]: newTopics,
        },
        topicsDirtied: true,
      };
    }
    case "CREATE_TOPIC":
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.questionId]: [
            ...state.topics[action.questionId],
            action.payload,
          ],
        },
      };
    case "DELETE_TOPIC":
      const newTopics = state.topics[action.questionId].filter(
        (t) => t._id !== action.deletedId
      );
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.questionId]: newTopics,
        },
        topicsDirtied: true,
      };
    case "SET_TOPICS_DIRTIED":
      return {
        ...state,
        topicsDirtied: action.payload,
      };
    case "SET_SHOWREELS":
      return {
        ...state,
        showreels: action.payload,
      };
    case "SET_CURRENT_SHOWREEL":
      return {
        ...state,
        currentShowreel: {
          ...state.currentShowreel,
          ...action.payload,
        },
      };
    case "CLEAR_CURRENT_SHOWREEL":
      return {
        ...state,
        currentShowreel: undefined,
      };
    case "REMOVE_FEEDBACK_NOTIF":
      if (!state.user || !state.user.feedback) return state;
      let feedback = state.user.feedback;
      _.remove(feedback, { type: action.payload.type });
      return {
        ...state,
        user: {
          ...state.user,
          feedback: feedback,
        },
      };

    case "SET_PANEL_REQUESTS":
      return {
        ...state,
        panelRequests: action.payload,
      };
    case "SET_CURRENT_PANEL_REQUEST":
      return {
        ...state,
        currentPanelRequest: action.payload,
      };
    case "UPDATE_CURRENT_PANEL_REQUEST":
      return {
        ...state,
        currentPanelRequest: {
          ...state.currentPanelRequest,
          ...action.payload,
        },
      };
    case "ADD_FILES":
      return { ...state, files: action.payload };
    case "UPDATE_FILE": {
      // Return state as it is if path is undefined
      if (!action.payload.path) return state;

      // Using Immutable JS to update state.files

      // Add children to filePath array because children are stored under
      // children key
      const filePath = interweave(action.payload.path.split("/"), "children");

      let files = fromJS(state.files);
      let file = files.getIn(filePath);
      let updatedFile = null;

      // Update features if present
      if ("features" in action.payload.updates) {
        updatedFile = file.mergeIn(
          ["features"],
          action.payload.updates.features
        );
      }

      // Delete features from updates and make updates to file
      delete action.payload.updates.features;
      updatedFile = updatedFile.merge(action.payload.updates);

      // Put updated file back in files
      let updatedFiles = files.setIn(filePath, updatedFile);

      return {
        ...state,
        files: updatedFiles.toJS(),
      };
    }
    case "MERGE_FILES":
      return { ...state, files: mergeTrees(state.files, action.payload) };
    case "SET_FILE_UPLOAD_PROGRESS":
      return {
        ...state,
        fileUploadProgress: { ...state.fileUploadProgress, ...action.payload },
      };
    default:
      return state;
  }
};

export default backendReducer;

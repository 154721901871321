import React, { useState } from "react";
import { connect } from "react-redux";
import { applyTopic, unapplyTopic, createTopic } from "redux/actions";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ApplyTopicPopup from "components/Popups/ApplyTopicPopup";
import uniqBy from "lodash/uniqBy";

const mapDispatchToProps = { applyTopic, unapplyTopic, createTopic };

const TopicsList = ({
  topics,
  allTopics,
  responseId,
  questionId,
  surveyId,
  canEdit,
  applyTopic,
  unapplyTopic,
  createTopic,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = (evt) => {
    evt.stopPropagation();
    setPopupOpen(true);
  };

  const applyTopicToResponse = (topicId) => {
    applyTopic(topicId, responseId);
  };

  const handleRemoveTopic = (topicId) => {
    unapplyTopic(topicId, responseId, questionId);
  };

  const createNewTopic = (name, responseId) => {
    createTopic(surveyId, questionId, name, responseId);
  };

  const deDupedTopics = uniqBy(topics, "Text");

  return (
    <div className="chip-container" style={{ marginTop: "5px" }}>
      {Array.isArray(topics)
        ? deDupedTopics.map((t, i) => (
            <Chip
              label={t.Text}
              key={`${t.Text}-${i}`}
              variant="outlined"
              size="small"
              style={{ margin: "5px 5px 5px 0" }}
              onDelete={canEdit ? () => handleRemoveTopic(t._id) : null}
            />
          ))
        : null}
      {canEdit ? (
        <Tooltip title="Add Topic">
          <IconButton aria-label="add" onClick={openPopup}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <ApplyTopicPopup
        open={popupOpen}
        closePopup={() => setPopupOpen(false)}
        topics={topics}
        allTopics={allTopics}
        applyTopicToResponse={applyTopicToResponse}
        createNewTopic={createNewTopic}
        responseId={responseId}
      />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(TopicsList);

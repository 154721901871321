import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import PremiumPopup from "../Popups/PremiumPopup";
import {
  getUser,
  getSurveys,
  getDrafts,
  searchTextChanged,
  toggleUpgradePopup,
} from "redux/actions";
import {
  Button,
  Chip,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import SearchBar from "../SearchBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCog,
  faDoorOpen,
  faPoll,
  faBook,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getAvatarUrl } from "utils/utils";
import clsx from "clsx";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    surveys: state.surveys,
    drafts: state.drafts,
    pageTitle: state.pageTitle,
  };
};

const mapDispatchToProps = {
  searchTextChanged,
  getUser,
  getSurveys,
  getDrafts,
  toggleUpgradePopup,
};

class AdminNavbarLinks extends Component {
  state = {
    searchOpen: false,
    searchAnchor: undefined,
    searchText: "",
    accountMenuAnchor: undefined,
  };

  constructor(props) {
    super(props);
    if (!this.props.surveys) this.props.getSurveys();
    if (!this.props.drafts) this.props.getDrafts();
  }

  redirect = (path) => {
    this.props.history.push(path);
  };
  signOut = () => {
    firebase
      .app()
      .auth()
      .signOut()
      .then(() => {
        if (this.props.user && this.props.user.organization)
          this.props.history.push(`/${this.props.user.organization}/signin`);
        this.props.history.push("/signin");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleSearchClose = () => {
    this.setState({
      searchOpen: false,
    });
  };

  searchNavChanged = (event) => {
    // If the surveys haven't been fetched yet, get them
    if (!this.props.surveys) this.props.getSurveys();
    // If the drafts haven't been fetched yet, get them
    if (!this.props.drafts) this.props.getDrafts();
    this.setState({
      searchOpen: true,
      searchAnchor: event.currentTarget,
      searchText: event.target.value,
    });
  };
  searchNavClicked = (e) => {
    // If the surveys haven't been fetched yet, get them
    if (!this.props.surveys) this.props.getSurveys();
    // If the drafts haven't been fetched yet, get them
    if (!this.props.drafts) this.props.getDrafts();
    this.setState({
      searchOpen: true,
      searchAnchor: e.currentTarget,
    });
  };

  openUpgradePopup = () => {
    this.props.toggleUpgradePopup(true);
  };

  getTierJSX = () => {
    if (!this.props.user || !this.props.user.tier) return;
    if (this.props.user.tier === "FREE") {
      const chip = (
        <Chip
          size="small"
          label={"Free Trial"}
          onClick={this.openUpgradePopup}
          className={clsx("tier-container", "free")}
        />
      );
      if (this.props.user.trialDaysLeft >= 0) {
        return (
          <Tooltip
            title={`${this.props.user.trialDaysLeft} day${
              this.props.user.trialDaysLeft !== 1 ? "s" : ""
            } left in trial`}
          >
            {chip}
          </Tooltip>
        );
      } else {
        return chip;
      }
    } else if (this.props.user.tier === "PREMIUM") {
      return (
        <Chip
          size="small"
          label={"Premium"}
          onClick={this.openUpgradePopup}
          className={clsx("tier-container", "premium")}
        />
      );
    } else if (this.props.user.tier === "ENTERPRISE") {
      return (
        <Chip
          size="small"
          label={"Business"}
          onClick={this.openUpgradePopup}
          className={clsx("tier-container", "premium")}
        />
      );
    }
  };

  render() {
    return (
      <div className="navbar-container navbar-links">
        <PremiumPopup navigate={this.redirect} />
        <div className="navbar-container-left">{this.props.pageTitle}</div>
        <div className="navbar-container-center"></div>
        <div className="navbar-container-right">
          {this.props.user && !this.props.user.readOnly && (
            <div className="navbar-search-content">
              <div className="search-container">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  className="navbar-search"
                  placeholder="Search Surveys..."
                  onChange={this.searchNavChanged}
                  onClick={this.searchNavClicked}
                />
              </div>
              <SearchBar
                anchorEl={this.state.searchAnchor}
                open={this.state.searchOpen}
                handleClose={this.handleSearchClose}
                surveys={this.props.surveys}
                drafts={this.props.drafts}
                searchText={this.state.searchText}
              />
            </div>
          )}
          {this.props.user && !this.props.user.readOnly && (
            <>{this.getTierJSX()}</>
          )}
        </div>
        <div>
          {this.props.user ? (
            <>
              <button
                onClick={(e) => {
                  this.setState({ accountMenuAnchor: e.currentTarget });
                }}
                className="navbar-account"
              >
                <img
                  className="thumbnail-image"
                  src={getAvatarUrl(this.props.user, 64)}
                  alt="profile"
                />
                <span className="avatar-text">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </button>

              <Menu
                anchorEl={this.state.accountMenuAnchor}
                open={Boolean(this.state.accountMenuAnchor)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClick={() => this.setState({ accountMenuAnchor: undefined })}
              >
                <MenuItem disabled>
                  <span>{this.props.user.email}</span>
                </MenuItem>
                <hr style={{ margin: 4 }} />
                {this.props.user &&
                  this.props.user.trialDaysLeft !== undefined && (
                    <>
                      <MenuItem onClick={this.openUpgradePopup}>
                        {this.props.user.trialDaysLeft !== 0 && (
                          <CircularProgress
                            variant="determinate"
                            className="mr-1"
                            size={18}
                            value={
                              this.props.user.trialDaysLeft === 0
                                ? 100
                                : (100 * this.props.user.trialDaysLeft) / 14
                            }
                          />
                        )}
                        {`${this.props.user.trialDaysLeft} day${
                          this.props.user.trialDaysLeft !== 1 ? "s" : ""
                        } left in trial`}
                      </MenuItem>
                      <hr style={{ margin: 4 }} />
                    </>
                  )}
                <MenuItem onClick={() => this.redirect("/settings")}>
                  <FontAwesomeIcon icon={faCog} />
                  &nbsp;&nbsp;Settings
                </MenuItem>
                <MenuItem onClick={() => this.redirect("/support")}>
                  <FontAwesomeIcon icon={faUser} />
                  &nbsp;&nbsp;Support
                </MenuItem>
                <MenuItem onClick={() => this.redirect("/surveys")}>
                  <FontAwesomeIcon icon={faPoll} />
                  &nbsp;&nbsp;Surveys
                </MenuItem>
                {this.props.user && !this.props.user.readOnly && (
                  <MenuItem
                    onClick={() => {
                      window.location = "https://docs.phonic.ai";
                    }}
                  >
                    <FontAwesomeIcon icon={faBook} />
                    &nbsp;&nbsp;Documentation
                  </MenuItem>
                )}
                <MenuItem onClick={this.signOut}>
                  <FontAwesomeIcon icon={faDoorOpen} />
                  &nbsp;&nbsp;Sign Out
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="contained"
              className="usage"
              onClick={() => {
                this.redirect("/signin");
              }}
            >
              Sign In
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminNavbarLinks));

import React, { useState } from "react";
import { Dialog, DialogContent, TextField, Button } from "@material-ui/core";

const NewTopicPopup = ({ open, closePopup, allTopics, createNewTopic }) => {
  const [topic, setTopic] = useState("");
  const [error, setError] = useState({ invalid: false, generic: false });

  const handleChange = (evt) => {
    setTopic(evt.target.value);
    if (/[*{}[\]/\\()+^]/gi.test(evt.target.value)) {
      setError({ ...error, invalid: true });
    } else {
      setError({ ...error, invalid: false });
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const topicName = topic.trim();
    if (allTopics.some((e) => e.Text === topicName)) {
      setError({ ...error, generic: true });
    } else if (!error.invalid && !error.generic) {
      createNewTopic(topicName);
      setTopic("");
      closePopup();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      aria-labelledby="create-topic"
      fullWidth
    >
      <DialogContent>
        <div id="form-dialog-title" style={{ marginBottom: 20 }}>
          <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
            Create New Topic
          </h2>
          <p>Expand the topic-set for this survey.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            autoFocus
            id="title"
            label="Topic Name"
            required
            fullWidth
            onChange={handleChange}
            helperText={
              error.generic
                ? "Topic name already exists"
                : error.invalid
                ? "Topic name cannot include the characters: * { } [ ] / \\ ( ) ^ +"
                : ""
            }
            error={error.generic || error.invalid}
            onFocus={() => setError({ ...error, generic: false })}
          />
          <span className="create-report-button">
            <Button
              variant="contained"
              type="submit"
              disabled={topic.trim() === "" || error.invalid || error.generic}
              color="primary"
            >
              Create Topic
            </Button>
          </span>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default NewTopicPopup;

import React from "react";
import { Button, Grid, MenuItem, Paper, Select } from "@material-ui/core";
import { isAudioType, isFileType, isVideoType } from "utils/utils";
import { ClipLoader } from "react-spinners";

export default function PricingPane(props) {
  const getSurveyProperties = () => {
    if (!props.surveys || !props.panelRequest) return {};
    const survey = props.surveys[props.panelRequest.surveyId];
    if (!survey) return {};
    let counts = {
      basic: 0,
      audio: 0,
      video: 0,
      file: 0,
    };
    survey.questions.forEach((q) => {
      if (isAudioType(q.type)) {
        counts.audio++;
      } else if (isVideoType(q.type)) {
        counts.video++;
      } else if (isFileType(q.type)) {
        counts.file++;
      } else {
        counts.basic++;
      }
    });
    return { counts: counts };
  };

  const surveyProps = getSurveyProperties();
  return (
    <Grid id="pricing-pane" item xs={3}>
      <Paper className="recruit-paper">
        <h3>Survey</h3>
        {!props.loadingSurveys || props.surveys ? (
          <Select
            fullWidth
            variant="outlined"
            value={
              props.panelRequest.surveyId !== undefined
                ? props.panelRequest.surveyId
                : "None Selected"
            }
            onChange={(e) => {
              if (e.target.value === "None Selected") return;
              props.modifyPanelRequest({ surveyId: e.target.value });
            }}
          >
            <MenuItem value="None Selected">Select Survey</MenuItem>
            {props.surveys &&
              Object.values(props.surveys).map((s) => {
                return (
                  <MenuItem key={`SURVEY-${s._id}`} value={s._id}>
                    {s.name}
                  </MenuItem>
                );
              })}
          </Select>
        ) : (
          <div
            className="flex-container justify-left"
            style={{ color: "gray" }}
          >
            <ClipLoader size={"16px"} color={"gray"} loading={true} />
            &nbsp;
            <span>Finding available surveys</span>
          </div>
        )}
        {surveyProps.counts && (
          <>
            <h3 style={{ marginTop: 20, marginBottom: 5 }}>Question Types</h3>
            <ul>
              <li>Basic: {surveyProps.counts.basic}</li>
              <li>Audio: {surveyProps.counts.audio}</li>
              <li>Video: {surveyProps.counts.video}</li>
              <li>File: {surveyProps.counts.file}</li>
            </ul>
          </>
        )}
        <h3 style={{ marginTop: 20 }}>Subtotals</h3>
        {props.panelRequest.audiencePricing && (
          <table>
            {props.panelRequest.audiencePricing.map((ap, idx) => {
              return (
                <tr key={`AUDIENCE-PRICING-${idx}`}>
                  <td>{ap.name}: </td>
                  <td>
                    <span style={{ marginLeft: 10 }}>
                      ${parseFloat(ap.price).toFixed(2)}
                    </span>
                  </td>
                </tr>
              );
            })}
          </table>
        )}

        <h3 style={{ marginTop: 20 }}>Total</h3>
        <Grid container>
          <Grid item xs={6} className="flex-container justify-left">
            {!props.loadingNewPrice ? (
              <span>
                {props.panelRequest.pricing ? (
                  <span>
                    <span className="dollar-sign">$</span>
                    <span className="total-price">{`${parseFloat(
                      props.panelRequest.pricing
                    ).toFixed(2)}`}</span>
                  </span>
                ) : (
                  " — "
                )}
              </span>
            ) : (
              <ClipLoader size={14} color={"gray"} />
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className="secondary-button-color"
              style={{ float: "right" }}
              onClick={props.setProceedPopupOpen}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

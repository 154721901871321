import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

const getPercent = (value) => {
  if (typeof value !== "number") return 0;
  return Number((value * 100).toFixed(1));
};

const SENTIMENT_ORDER = ["Positive", "Negative", "Mixed", "Neutral"];

const SentimentBar = ({ score }) => {
  if (!score) return null;

  const tooltipJSX = (
    <>
      {SENTIMENT_ORDER.map((key, i) => (
        <div className="sentiment-label" key={`label-${i}`}>
          <span className={`key sentiment ${key.toLowerCase()}`} />
          <p>{key}</p>
          <p>{getPercent(score[key])}%</p>
        </div>
      ))}
    </>
  );

  return (
    <Tooltip title={tooltipJSX} placement="bottom">
      <div className="stacked-bar">
        {SENTIMENT_ORDER.map((key, i) => (
          <div
            className={`segment sentiment ${key.toLowerCase()}`}
            style={{ width: `${getPercent(score[key])}%` }}
            aria-valuenow={getPercent(score[key])}
            aria-valuemin="0"
            aria-valuemax="100"
            key={`SEGMENT-${i}`}
          />
        ))}
      </div>
    </Tooltip>
  );
};

export default SentimentBar;

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

const mapStateToProps = (state) => {
  return { user: state.user };
};

function SidebarToggle({ mobileSidebarToggle }) {
  return (
    <div
      className="sidebar-toggle navbar-container-right"
      onClick={mobileSidebarToggle}
    >
      <IconButton aria-label="toggle nav" component="span">
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
    </div>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  render() {
    return (
      <div id="app-navbar">
        <AdminNavbarLinks />
        <SidebarToggle mobileSidebarToggle={this.mobileSidebarToggle} />
      </div>
    );
  }
}

export default connect(mapStateToProps, undefined)(withRouter(Header));

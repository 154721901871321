import React from "react";
import { useRemirrorContext } from "@remirror/react";
import { ComponentsTheme } from "@remirror/theme";
import clsx from "clsx";

const EditingComponent = ({ className }) => {
  return (
    <div
      className={clsx(ComponentsTheme.EDITOR_WRAPPER, className)}
      {...useRemirrorContext().getRootProps()}
    />
  );
};

export default EditingComponent;

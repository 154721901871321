import React from "react";
import Typography from "@material-ui/core/Typography";

const Image = ({ src, alt, ...props }) => (
  <img src={src} alt={alt} {...props} style={{ maxWidth: "100%" }} />
);

// Make markdown links open in new tab
const MarkdownLink = ({ href, children, ...props }) => (
  <a href={href} {...props} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h2",
      },
    },
    h2: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h3",
      },
    },
    h3: {
      component: Typography,
      props: {
        variant: "h4",
      },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h5",
      },
    },
    h5: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h6",
      },
    },
    h6: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h6",
      },
    },
    img: { component: Image },
    a: { component: MarkdownLink },
  },
};

import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import MediaPlayer from "components/MediaPlayer";

const ResponseRow = ({ response, isChecked, handleToggle, questionNum }) => {
  if (!response) return null;

  let data = [];

  // Checkbox
  data.push(
    <TableCell padding="checkbox" key={Math.random()}>
      <Checkbox
        checkedIcon={<CheckBoxOutlinedIcon />}
        color="default"
        name={`${response._id}`}
        checked={isChecked}
        onChange={() => handleToggle(response._id)}
      />
    </TableCell>
  );

  // Audio & Video Data
  if (response.uri) {
    data.push(
      <TableCell key={Math.random()}>
        <MediaPlayer
          type={response.type}
          uri={response.uri}
          waveProps={{ smaller: true }}
        />
      </TableCell>
    );
  }
  if (response.features && response.features.transcription) {
    data.push(
      <TableCell key={Math.random()}>
        {response.features.transcription.length > 50
          ? `“${response.features.transcription}...”`
          : response.features.transcription}
      </TableCell>
    );
  }

  // Selection or Text/Number
  if (response.data) {
    let result = null;

    if (Array.isArray(response.data)) {
      result = response.data.map((data, idx) => {
        return data ? (
          <li className="options-text" key={Math.random()}>
            {response.question.options[idx]}
          </li>
        ) : null;
      });
    } else {
      result = response.data;
    }

    data.push(
      <TableCell key={Math.random()} colSpan={data.length}>
        {result}
      </TableCell>
    );
  }

  // Question Number
  data.push(
    <TableCell key={Math.random()}>{`Question ${questionNum}`}</TableCell>
  );

  return (
    <React.Fragment key={Math.random()}>
      <TableRow hover className="table-row">{data}</TableRow>
    </React.Fragment>
  );
};

export default React.memo(ResponseRow);

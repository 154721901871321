import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { initialParams } from "utils/utils";
import { transcriptionLanguages } from "utils/data";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& label.Mui-focused": {
      color: "#507b82",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#507b82",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "1.25rem",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "unset",
    },
    "& .MuiChip-root": {
      backgroundColor: "#EAEAEA",
    },
  },
}));

const PickGeneric = ({ param, options, submitOptions, initialOptions }) => {
  const classes = useStyles();
  const [items, setItems] = useState(initialParams(initialOptions));

  if (!Array.isArray(options) || options.length < 2) {
    return null;
  }

  const handleChange = (_, values) => {
    setItems(values);
    submitOptions({ [param]: values });
  };

  return (
    <Autocomplete
      multiple
      id="lang-tags"
      options={options}
      getOptionLabel={(item) =>
        transcriptionLanguages[item] ? transcriptionLanguages[item] : item
      }
      onChange={handleChange}
      value={items}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={`Filter by ${param}`}
          className={classes.inputRoot}
        />
      )}
    />
  );
};

export default PickGeneric;

import React from "react";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router";
import { Chip } from "@material-ui/core";

function SearchBar(props) {
  const filterSurveys = (needle, surveys) => {
    var searchResults = [];
    if (surveys) {
      searchResults = Object.entries(surveys).filter((s) => {
        if (
          s[1] &&
          needle &&
          (s[1].name.toLowerCase().includes(needle.toLowerCase()) ||
            s[1].tag.toLowerCase().includes(needle.toLowerCase()))
        )
          return true;
        return false;
      });
    }
    return searchResults;
  };

  const routeToSurvey = (state, id) => {
    if (state === "DRAFT") {
      props.history.push(`/create_survey/${id}`);
      props.handleClose();
      return;
    }
    props.history.push(`/surveys/${id}`);
    props.handleClose();
  };

  const mapSurveys = () => {
    var s = [];
    if (props.surveys) s = { ...props.surveys };
    if (props.drafts) s = { ...s, ...props.drafts };
    var surveys = filterSurveys(props.searchText, s);

    if (!surveys || surveys.length === 0)
      return (
        <div className="search-result final">
          <div>
            <h2 className="search-result">No results found</h2>
          </div>
        </div>
      );
    return surveys.map((s, idx) => {
      return (
        <div
          className={
            idx !== surveys.length - 1 ? "search-result" : "search-result final"
          }
          onClick={() => routeToSurvey(s[1].state, s[0])}
          key={`SEARCHBAR-RESULT-${s[0]}`}
        >
          <div>
            <h2 className="search-result">{s[1].name}</h2>
            <Chip
              label={s[1].state}
              size="small"
              className={`${s[1].state === "PENDING" && "pending"} ${
                s[1].state === "COMPLETED" && "completed"
              }`}
            />
            {s[1].tag.length > 0 && (
              <Chip
                label={s[1].tag}
                size="small"
                icon={<LoyaltyIcon className="search" />}
              />
            )}
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: 20, maxHeight: 600 }}
      >
        {mapSurveys()}
      </Popover>
    </div>
  );
}

export default withRouter(SearchBar);

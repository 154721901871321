import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";


function ImageUploadForm({ onSubmit, handleClose }) {
  const [file, setFile] = useState(undefined);
  const [tempUrl, setTempUrl] = useState(undefined);

  const fileInputChange = (event) => {
    let _file = event.target.files[0];
    setFile(_file);
    setTempUrl(URL.createObjectURL(_file));
  };

  const handleSubmit = () => {
    if (!file) {
      alert("You have not uploaded a file.");
      return;
    }
    onSubmit(file);
    handleClose();
  };

  return (
    <>
      <form method="post" action="#" id="#">
        <div className="form-group files">
          <input
            type="file"
            className="form-control"
            multiple=""
            accept="image/*"
            onChange={fileInputChange}
          />
        </div>
      </form>
      {
        tempUrl && (
          <div style={{ height: "10em", justifyContent: "center", display: "flex" }}>
            <img
              src={tempUrl}
              className="img-thumbnail"
              alt="welcome preview"
            />
          </div>
        )
      }
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          component="label"
          onClick={handleSubmit}
          color="primary"
        >
          Upload
        </Button>
      </DialogActions>
    </>
  )
}

function RemoveImageForm({ imageUrl, onImageDelete }) {
  return (
    <>
      <img
        src={imageUrl}
        className="img-thumbnail"
        alt="welcome preview"
      />
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          component="label"
          onClick={onImageDelete}
          color="primary"
        >
          Remove
        </Button>
      </DialogActions>
    </>
  )
}

class ShowreelsImageUpload extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="image-upload-container">
          <DialogTitle id="form-dialog-title">
            {this.props.dialogTitle || "Upload"}
          </DialogTitle>
          <DialogContent>
            {!this.props.currentImage && (
              <ImageUploadForm
                onSubmit={this.props.onSubmit}
                handleClose={this.handleClose}
              />
            )}
            {this.props.currentImage && (
              <RemoveImageForm
                imageUrl={this.props.currentImage}
                onImageDelete={this.props.onImageDelete}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

export default ShowreelsImageUpload;

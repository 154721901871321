import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "components/Header";
import Footer from "components/Footer/Footer";
import ContentBlock from "components/Report/ContentBlock";
import Loading from "views/Loading";
import ReportError from "views/ReportError";
import { getReport } from "redux/actions";
import reportTheme from "reportTheme";
import { Helmet } from "react-helmet";
import PoweredByPhonic from "components/PoweredBy"

const mapStateToProps = (state) => {
  return {
    report: state.report,
    reportError: state.reportError,
  };
};
// Object shorthand for mapDispatchToProps
const mapDispatchToProps = {
  getReport,
};

const Report = ({ match, report, reportError, getReport }) => {
  useEffect(() => {
    getReport(match.params.reportId);
  }, [getReport, match.params.reportId]);

  if (reportError) {
    return <ReportError />;
  }

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Helmet>
        <title>{report ? report.name : "Phonic Report"}</title>
      </Helmet>
      <ThemeProvider theme={reportTheme}>
        <CssBaseline />
        {!report && !reportError && <Loading />}
        {report && (
          <div className="report-wrapper">
            <div className="report-header-padding">
              <Header imageUrl={report.imageUrl} />
            </div>
            <Container component="article" maxWidth="md">
              <Typography component="h1" variant="h2" gutterBottom>
                {report.name}
              </Typography>
              <Grid container direction="column">
                {report.contents &&
                  report.contents.map((item) => (
                    <ContentBlock content={item} key={item.blockId} />
                  ))}
              </Grid>
            </Container>
            <Footer>
              <PoweredByPhonic />
            </Footer>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);

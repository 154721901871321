import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Component } from "react";
import { removeItemFromArray } from "utils/utils";
import { supportedLanguages } from "utils/data";

class LogicItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logic: {
        conditionSubject: this.props.logic.conditionSubject,
        conditionAssign: this.props.logic.conditionAssign,
        keyword: this.props.logic.keyword,
        operand: this.props.logic.operand,
      },
    };
  }

  updateLogicLocal = (logic) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      logic: {
        ...this.state.logic,
        ...logic,
      },
      updateTimeout: setTimeout(() => {
        this.props.updateLogic(this.state.logic);
      }, 250),
    });
  };

  render() {
    return (
      <div id="logic-container">
        <div className="remove-button">
          <FontAwesomeIcon icon={faTimes} onClick={this.props.deleteLogic} />
        </div>
        <div className="logic-line">
          <div className="logic-text">If</div>
          <Select
            value={this.props.logic.subject}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) =>
              this.props.updateLogic({ subject: e.target.value })
            }
          >
            <MenuItem value="CONDITION">Condition</MenuItem>
            <MenuItem value="ANSWER">Answer</MenuItem>
          </Select>
          {this.props.logic.subject === "CONDITION" && (
            <>
              <div className="logic-text">:</div>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.conditionSubject}
                placeholder="Condition"
                onChange={(e) =>
                  this.updateLogicLocal({ conditionSubject: e.target.value })
                }
              />
            </>
          )}
          <Select
            value={this.props.logic.operator}
            variant="outlined"
            margin="dense"
            className="logic-short"
            onChange={(e) =>
              this.props.updateLogic({ operator: e.target.value })
            }
          >
            <MenuItem value="IS">Is</MenuItem>
            <MenuItem value="IS NOT">Is not</MenuItem>
            <MenuItem value="CONTAINS">Contains</MenuItem>
            <MenuItem value="DOES NOT CONTAIN">Does not contain</MenuItem>
          </Select>
          <TextField
            variant="outlined"
            margin="dense"
            className="logic-long"
            value={this.state.logic.keyword}
            placeholder="Keyword (s)"
            onChange={(e) => this.updateLogicLocal({ keyword: e.target.value })}
          />
        </div>
        <div className="logic-line">
          <div className="logic-text">Then</div>
          <Select
            value={this.props.logic.action}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) => this.props.updateLogic({ action: e.target.value })}
          >
            <MenuItem value="SKIP TO">Skip to</MenuItem>
            <MenuItem value="ASSIGN CONDITION">Set condition</MenuItem>
            <MenuItem value="SET LANGUAGE">Set language to </MenuItem>
          </Select>
          {this.props.logic.action === "SKIP TO" && (
            <Select
              value={this.props.logic.operand}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) =>
                this.props.updateLogic({ operand: e.target.value })
              }
            >
              <MenuItem value={0}>Next Question</MenuItem>
              {this.props.questions.map((q, idx) => {
                return (
                  <MenuItem key={`LOGIC-SKIP-${idx}`} value={q.questionId}>
                    Question {idx + 1}
                  </MenuItem>
                );
              })}
              <MenuItem value={-1}>End Survey</MenuItem>
              <MenuItem value={-2}>Screen Out</MenuItem>
            </Select>
          )}
          {this.props.logic.action === "ASSIGN CONDITION" && (
            <>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.operand}
                placeholder="Condition"
                onChange={(e) =>
                  this.updateLogicLocal({ operand: e.target.value })
                }
              />
              <div className="logic-text">=</div>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.conditionAssign}
                placeholder="Value"
                onChange={(e) =>
                  this.updateLogicLocal({ conditionAssign: e.target.value })
                }
              />
            </>
          )}
          {this.props.logic.action === "SET LANGUAGE" && (
            <Select
              value={this.state.logic.conditionAssign}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) => {
                this.updateLogicLocal({
                  operand: "lang",
                  conditionAssign: e.target.value,
                });
              }}
            >
              {supportedLanguages.map((l, idx) => {
                return (
                  <MenuItem key={`LANGUAGE-LOGIC-${idx}`} value={l.code}>
                    {l.text}
                  </MenuItem>
                );
              })}
              <MenuItem value={-1}>End Survey</MenuItem>
              <MenuItem value={-2}>Screen Out</MenuItem>
            </Select>
          )}
        </div>
        {this.props.logic.subject === "CONDITION" && (
          <div className="logic-line">
            <div className="logic-text alternate">Run</div>
            <Select
              value={this.props.logic.execute}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) =>
                this.props.updateLogic({ execute: e.target.value })
              }
            >
              <MenuItem value="BEFORE">Before Question</MenuItem>
              <MenuItem value="AFTER">After Question</MenuItem>
            </Select>
          </div>
        )}
        {this.props.logic.action === "ASSIGN CONDITION" &&
          this.state.logic.operand === "lang" && (
            <div className="logic-line">
              <Alert severity="info" className="survey-info-alert logic-alert">
                The <b>lang</b> condition will set the survey and transcription
                language.
              </Alert>
            </div>
          )}
      </div>
    );
  }
}

class Logic extends Component {
  updateLogic = (idx, logic) => {
    let updatedLogic = this.props.qinfo.logic;
    updatedLogic[idx] = { ...updatedLogic[idx], ...logic };
    this.props.changeQInfo("logic", updatedLogic);
  };
  deleteLogic = (idx) => {
    this.props.changeQInfo(
      "logic",
      removeItemFromArray(this.props.qinfo.logic, idx)
    );
  };
  render() {
    return (
      <>
        {this.props.qinfo &&
          this.props.qinfo.logic &&
          this.props.qinfo.logic.map((l, idx) => {
            return (
              <LogicItem
                key={`LOGIC-${idx}`}
                updateLogic={(logic) => this.updateLogic(idx, logic)}
                deleteLogic={() => this.deleteLogic(idx)}
                logic={l}
                questions={this.props.questions}
              />
            );
          })}
      </>
    );
  }
}

export default Logic;

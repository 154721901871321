import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router";

class NewShowreelTile extends React.Component {
  render() {
    return (
      <Grid
        item
        onClick={this.props.routeToNew}
        className="main-survey-tile main-survey-new"
      >
        <div style={{ display: "inline-block", textAlign: "center" }}>
          <p
            style={{
              fontSize: 26,
              fontWeight: 700,
              marginBottom: -5,
              marginTop: -10,
            }}
          >
            +
          </p>
          <h3 style={{ fontSize: 22 }}>New Showreel</h3>
        </div>
      </Grid>
    );
  }
}

export default withRouter(NewShowreelTile);

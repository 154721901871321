import React from "react";
import ReactMarkdown from "markdown-to-jsx";
import { options } from "./options";
const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const Markdown = (props) => {
  /* Sanitizing turns > into &gt; 
  Change it back to be recognized by markdown */
  const clean = DOMPurify.sanitize(props.children).replaceAll(/&gt;/g, ">");

  return <ReactMarkdown options={options}>{clean}</ReactMarkdown>;
};

export default Markdown;

import { Grid, Chip } from "@material-ui/core";
import ShowreelPopupMenu from "components/Showreels/ShowreelPopupMenu";
import LoaderTile from "components/Tiles/LoaderTile";
import NewShowreelTile from "components/Tiles/NewShowreelTile";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getShowreels, setPageTitle } from "../redux/actions";

const mapStateToProps = (state) => {
  return {
    showreels: state.showreels,
    loading: state.loading.MyShowreels,
  };
};

const mapDispatchToProps = { getShowreels, setPageTitle };

class MyShowreels extends Component {
  componentDidMount() {
    this.props.getShowreels();
    this.props.setPageTitle();
  }

  routeToEdit = (showreelId) => {
    this.props.history.push(`showreels/${showreelId}/create`);
  };

  render() {
    const { showreels, loading } = this.props;

    return (
      <div className="content">
        <Helmet>
          <title>Showreels</title>
        </Helmet>
        <Grid container className="screen-margin" spacing={2}>
          <Grid item>
            <h1 className="page-title">
              Showreels
              <Chip size="small" label="BETA" className="beta" />
            </h1>
          </Grid>
        </Grid>
        <Grid container>
          <NewShowreelTile
            routeToNew={() => this.props.history.push("/showreels/create")}
          />
          {!showreels && loading && <LoaderTile />}
          {showreels &&
            showreels.map((showreel) => (
              <Grid
                key={showreel._id}
                item
                onClick={() => this.routeToEdit(showreel._id)}
                className="main-survey-tile"
              >
                <div className="top-status-background report-banner" />
                <div className="top-status report-banner">Showreel</div>
                <div className="top-hamburger">
                  <ShowreelPopupMenu showreel={showreel} />
                </div>
                <h3>
                  {showreel.name.length > 40
                    ? `${showreel.name.substring(0, 40)}...`
                    : showreel.name}
                </h3>
                <p className="bottom-date">{showreel.createdAt}</p>
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyShowreels);

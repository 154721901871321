import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getTypeJSXIcon } from "utils/utils";
import { Input } from "@material-ui/core";

export default class WelcomeScreens extends Component {
  state = {
    optionsAnchorEl: undefined,
    info: { title: "", subTitle: "" },
    updateTimeout: 0,
  };

  componentDidMount() {
    this.setState({
      info: {
        title: this.props.info.title,
        subTitle: this.props.info.subTitle,
      },
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.info !== this.props.info) {
      this.setState({
        info: {
          title: this.props.info.title,
          subTitle: this.props.info.subTitle,
        },
      });
    }
  }

  updateParent = () => {
    this.props.changeInfo(
      {
        ...this.state.info,
      },
      true
    );
  };

  // Update the local state and set a timeout to update the parent too
  updateWithDebounce = (prop, value) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      info: {
        ...this.state.info,
        [prop]: value,
      },
      updateTimeout: setTimeout(() => {
        this.updateParent();
      }, 500),
    });
  };

  getQuestionMenu = () => {
    return (
      <span className="x-button-bottom">
        <div
          className="icon-container"
          onClick={() => {
            this.props.changeInfo(
              {
                removeWelcomeScreen: true,
              },
              true
            );
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </span>
    );
  };

  changeTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };
  changeSubTitle = (e) => {
    this.setState({
      subTitle: e.target.value,
    });
  };

  render() {
    return (
      <div className="builder-question">
        <div
          className="condensed"
          onMouseEnter={() => this.setState({ showQuestionMenu: true })}
          onMouseLeave={() => this.setState({ showQuestionMenu: false })}
          onClick={() => this.props.modFunctions.previewQuestion(undefined)}
        >
          {this.state.showQuestionMenu && this.getQuestionMenu()}
          <div className="type-section">
            {getTypeJSXIcon("WELCOME", undefined)}
          </div>
          <div className="info-section">
            <Input
              multiline
              autoFocus
              disableUnderline
              className="Mui-Overrides text"
              placeholder="Welcome Text..."
              value={this.state.info.title}
              onChange={(e) => this.updateWithDebounce("title", e.target.value)}
            />
            <Input
              multiline
              autoFocus
              disableUnderline
              className="Mui-Overrides sub text"
              placeholder="Welcome Subtext..."
              value={this.state.info.subTitle}
              onChange={(e) =>
                this.updateWithDebounce("subTitle", e.target.value)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import PropCheckbox from "components/SurveyCreation/PropCheckbox";

const CurrentStimuli = ({ stimuli, changeStimuliAutoplay, closePopup }) => {
  const [autoPlay, setAutoPlay] = useState(
    stimuli ? Boolean(stimuli.autoPlay) : false
  );

  return (
    <>
      <div className="transcription-options">
        <PropCheckbox
          label={{
            text: "Autoplay Stimuli",
            placement: "right",
          }}
          update={(e) => setAutoPlay(e.target.checked)}
          value={autoPlay}
        />
      </div>
      <div className="dialog-button-wrapper">
        <Button
          variant="contained"
          component="label"
          onClick={() => changeStimuliAutoplay(autoPlay)}
          color="primary"
        >
          Save changes
        </Button>
      </div>
      <div className="cancel-button dialog-button-wrapper">
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CurrentStimuli;

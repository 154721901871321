import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import PieChart from "components/Graphs/PieChart";
import BarChart from "components/Graphs/BarChart";
import ReactWordcloud from "react-wordcloud";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { wordCloudOptions } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    backgroundColor: "#f9f9f9",
    borderRadius: 0,
  },
  pad1: {
    paddingTop: "25px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  pad2: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "75px",
      paddingRight: "75px",
    },
  },
}));

const GraphCard = ({ graphType, graphData, title, questionText }) => {
  const classes = useStyles();

  if (!graphType || !graphData) return null;

  let graph = null;

  switch (graphType) {
    case "PIE":
      graph = (
        <PieChart
          chartData={{ data: graphData.data, labels: graphData.labels }}
        />
      );
      break;
    case "BAR":
      graph = (
        <BarChart
          chartData={{ data: graphData.data, labels: graphData.labels }}
        />
      );
      break;
    case "WORD_CLOUD":
      // Div is necessary or else wordcloud infinitely stretches page
      graph = (
        <div>
          <ReactWordcloud options={wordCloudOptions} words={graphData.data} />
        </div>
      );
      break;
    default:
      break;
  }

  return (
    graph && (
      <Card className={classes.root} elevation={0}>
        <CardHeader
          title={
            <Typography component="h3" variant="h4">
              {questionText}
            </Typography>
          }
          disableTypography
          className={classes.pad1}
        />
        <CardContent className={classes.pad2}>
          <Grid container justify="center">
            <Grid item xs={12} sm={9}>
              <div className="graph-title">
                <span>{title}</span>
              </div>
              {graph}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  );
};

export default GraphCard;

import React, { Component, useState } from "react";
import clsx from "clsx";
import {
  faCheck,
  faCut,
  faEdit,
  faTrash,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
import {
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  FormatColorText,
  FormatColorFill,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Waveform from "components/Waveform";
import { Draggable } from "react-beautiful-dnd";
import {
  isAudioType,
  isVideoType,
  truncateStringWithEllipsis,
  isTitleType,
} from "utils/utils";
import { TextField, Popover } from "@material-ui/core";
import { SketchPicker } from "react-color";
import ShowreelsImageUpload from "components/Popups/ShowreelsImageUpload";

const HORIZONTAL_OPTIONS = ["left", "center", "right"];
const VERTICAL_OPTIONS = ["top", "center", "bottom"];
const PICKER_TYPE = {
  TEXT: "Text",
  BACKGROUND: "Background",
};

function TimelineBlock({
  blockContent,
  idx,
  onClick,
  selected,
  playing,
  removeContent,
  setTrimming,
  setDuration,
  setContent,
  showreelId,
  uploadShowreelFile,
}) {
  const [mouseHover, setMouseHover] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(undefined);
  const [subTitle, setSubTitle] = useState(undefined);
  const [colorPickerEl, setColorPickerEl] = useState(undefined);
  const [pickerType, setPickerType] = useState(undefined);
  const [openUploadImage, setOpenUploadImage] = useState(false);

  const handleClickUploadImage = () => {
    setOpenUploadImage(true);
  };

  const getPickerColor = () => {
    let color = "black";
    if (pickerType == PICKER_TYPE.TEXT) {
      color = blockContent.fontColor || "white";
    } else if (pickerType == PICKER_TYPE.BACKGROUND) {
      color = blockContent.backgroundColor || "black";
    }
    return color;
  };

  const handleColorPickerComplete = (color) => {
    if (pickerType == PICKER_TYPE.TEXT) {
      setContent(idx, { fontColor: color.hex });
    } else if (pickerType == PICKER_TYPE.BACKGROUND) {
      setContent(idx, { backgroundColor: color.hex });
    }
  };

  const getHorizontalAlign = (align) => {
    return HORIZONTAL_OPTIONS[(HORIZONTAL_OPTIONS.indexOf(align) + 1) % 3];
  };

  const getVerticalAlign = (align) => {
    return VERTICAL_OPTIONS[(VERTICAL_OPTIONS.indexOf(align) + 1) % 3];
  };

  const submitTitleImage = (file) => {
    let uploadTitleImage = (data) => {
      setContent(idx, { backgroundImageUrl: data.public_url });
    };
    uploadShowreelFile(showreelId, file, uploadTitleImage);
  };

  const handleImageDelete = () => {
    setContent(idx, { backgroundImageUrl: null });
  };

  return (
    <Draggable
      draggableId={blockContent.blockId}
      index={idx}
      isDropDisabled={true}
      key={`TIMELINE-${blockContent.blockId}`}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style }}
          className="showreels-timeline-block"
          onClick={onClick}
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => setMouseHover(false)}
        >
          <div className="showreels-timeline-tile-layover">
            {mouseHover && !isTitleType(blockContent.type) && (
              <div className="tray-top-right tray-top-timeline flex-container">
                <div className="tray-icon mr-1">
                  <FontAwesomeIcon icon={faCut} onClick={setTrimming} />
                </div>
                <div className="tray-icon" onClick={removeContent}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            )}
            {mouseHover && isTitleType(blockContent.type) && (
              <div className="tray-top-left tray-top-timeline flex-container">
                <div
                  className="tray-icon"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setContent(idx, {
                      horizontalAlign: getHorizontalAlign(
                        blockContent.horizontalAlign
                      ),
                    });
                  }}
                >
                  {blockContent.horizontalAlign === "left" && (
                    <FontAwesomeIcon icon={faAlignLeft} />
                  )}
                  {blockContent.horizontalAlign === "center" && (
                    <FontAwesomeIcon icon={faAlignCenter} />
                  )}
                  {blockContent.horizontalAlign === "right" && (
                    <FontAwesomeIcon icon={faAlignRight} />
                  )}
                </div>
                <div
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setContent(idx, {
                      verticalAlign: getVerticalAlign(
                        blockContent.verticalAlign
                      ),
                    });
                  }}
                >
                  {blockContent.verticalAlign === "bottom" && (
                    <VerticalAlignBottom className="tray-icon" />
                  )}
                  {blockContent.verticalAlign === "center" && (
                    <VerticalAlignCenter className="tray-icon" />
                  )}
                  {blockContent.verticalAlign === "top" && (
                    <VerticalAlignTop className="tray-icon" />
                  )}
                </div>
                <FormatColorText
                  className="tray-icon"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={(event) => {
                    setColorPickerEl(event.currentTarget);
                    setPickerType(PICKER_TYPE.TEXT);
                  }}
                />
                <FormatColorFill
                  className="tray-icon"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={(event) => {
                    setColorPickerEl(event.currentTarget);
                    setPickerType(PICKER_TYPE.BACKGROUND);
                  }}
                />
                <Popover
                  open={Boolean(colorPickerEl)}
                  anchorEl={colorPickerEl}
                  onClose={() => {
                    setColorPickerEl(undefined);
                    setPickerType(undefined);
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      "vertical-align": "middle",
                      "text-align": "center",
                    }}
                  >
                    Select {pickerType} Color
                  </div>
                  <SketchPicker
                    color={getPickerColor()}
                    onChangeComplete={handleColorPickerComplete}
                  />
                </Popover>
                {!isTitleType(blockContent.type) && (
                  <div className="tray-icon" style={{ marginRight: 10 }}>
                    <FontAwesomeIcon icon={faCut} onClick={setTrimming} />
                  </div>
                )}
                {isTitleType(blockContent.type) && (
                  <div className="tray-icon" style={{ marginRight: 10 }}>
                    {!editingTitle ? (
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => {
                          setTitle(blockContent.title);
                          setSubTitle(blockContent.subTitle);
                          setEditingTitle(true);
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCheck}
                        onClick={() => {
                          setContent(idx, { title: title, subTitle: subTitle });
                          setEditingTitle(false);
                        }}
                      />
                    )}
                  </div>
                )}
                <div
                  className="tray-icon"
                  onClick={handleClickUploadImage}
                  style={{ marginRight: 10 }}
                >
                  <FontAwesomeIcon icon={faImages} />
                </div>
                <div className="tray-icon" onClick={removeContent}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            )}
          </div>
          {isVideoType(blockContent.type) && (
            <video
              className={`showreels-timeline-content ${
                selected ? "highlighted" : playing ? "dimmed" : ""
              }`}
              id={`TIMELINE-${blockContent.blockId}`}
              controls={false}
              onLoadedMetadata={(e) => {
                let duration = e.currentTarget.duration;
                if (blockContent.endTimeSec)
                  duration -= duration - blockContent.endTimeSec;
                if (blockContent.startTimeSec)
                  duration -= blockContent.startTimeSec;
                setDuration(blockContent.blockId, duration);
              }}
            >
              <source src={blockContent.response.uri} />
              Your browser does not support the video tag.
            </video>
          )}
          {isAudioType(blockContent.type) && blockContent.response.uri && (
            <div
              className={clsx(
                "showreels-timeline-content",
                "audio",
                "flex-container",
                "flex-column",
                "flex-start",
                "showreels-timeline-audio-container",
                selected ? "highlighted" : playing ? "dimmed" : ""
              )}
            >
              <div className="full-width">
                <Waveform
                  src={blockContent.response.uri}
                  height={50}
                  simpleWave
                />
              </div>
              {blockContent.response.demographics &&
                (blockContent.response.demographics.firstName ||
                  blockContent.response.demographics.lastName) && (
                  <div>
                    <p>
                      {blockContent.response.demographics.firstName}{" "}
                      {blockContent.response.demographics.lastName}
                    </p>
                  </div>
                )}
              {blockContent.response.demographics &&
                blockContent.response.demographics.email && (
                  <div>
                    <p>{blockContent.response.demographics.email}</p>
                  </div>
                )}
              {blockContent.response.features &&
                blockContent.response.features.transcription && (
                  <div>
                    <p>
                      {truncateStringWithEllipsis(
                        blockContent.response.features.transcription,
                        38
                      )}
                    </p>
                  </div>
                )}
            </div>
          )}
          {isTitleType(blockContent.type) && (
            <div
              className={clsx(
                "title-slide",
                "showreels-timeline-content",
                selected ? "highlighted" : playing ? "dimmed" : ""
              )}
              id={`TIMELINE-${blockContent.blockId}`}
              style={{
                color: blockContent.fontColor,
                backgroundColor: blockContent.backgroundColor,
                backgroundImage: `url(${blockContent.backgroundImageUrl})`,
                backgroundSize: "100% 100%",
              }}
            >
              <div style={{ display: "inline-block" }}>
                {!editingTitle ? (
                  <h2>{blockContent.title}</h2>
                ) : (
                  <TextField
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ zIndex: 1000, marginBottom: 10, width: 170 }}
                    margin="dense"
                    fullWidth
                  />
                )}
                {!editingTitle ? (
                  <h3>{blockContent.subTitle}</h3>
                ) : (
                  <TextField
                    value={subTitle}
                    onChange={(e) => setSubTitle(e.target.value)}
                    style={{ zIndex: 1000, width: 170 }}
                    margin="dense"
                    fullWidth
                  />
                )}
              </div>
              <ShowreelsImageUpload
                dialogTitle="Upload Title Background"
                open={openUploadImage}
                onSubmit={submitTitleImage}
                onClose={() => setOpenUploadImage(false)}
                currentImage={blockContent.backgroundImageUrl}
                onImageDelete={handleImageDelete}
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

function BlankTimelineBlock() {
  return (
    <div className="showreels-timeline-placeholder-content flex-container">
      drag and drop content from the file explorer to get started...
    </div>
  );
}

export default class Timeline extends Component {
  render() {
    if (
      !this.props.showreel ||
      !this.props.showreel.content ||
      this.props.showreel.content.length === 0
    ) {
      return <BlankTimelineBlock />;
    }
    return (
      <>
        {this.props.showreel &&
          this.props.showreel.content &&
          this.props.showreel.content.map((c, idx) => {
            return (
              <TimelineBlock
                key={`TIMELINE-BLOCK-${c.blockId}`}
                blockContent={c}
                idx={idx}
                onClick={() => {
                  this.props.setClipIdx(idx);
                }}
                selected={this.props.clipIdx === idx}
                playing={this.props.playing}
                removeContent={(e) => {
                  e.stopPropagation();
                  this.props.removeContent(idx);
                }}
                setTrimming={(e) => {
                  e.stopPropagation();
                  this.props.setTrimming(true, idx);
                }}
                setDuration={this.props.setDuration}
                setContent={this.props.setContent}
                showreelId={this.props.showreel._id}
                uploadShowreelFile={this.props.uploadShowreelFile}
              />
            );
          })}
      </>
    );
  }
}

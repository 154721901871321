import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileBrowser from "../components/Files/FileBrowser";
import FileUploadPopup from "../components/Popups/FileUploadPopup";
import FileViewer from "../components/Files/FileViewer";
import AddCollectionPopup from "../components/Popups/AddCollectionPopup";
import AddCollectionButton from "../components/Files/AddCollectionButton";
import { Grid } from "@material-ui/core";
import { trackPage } from "../analytics";
import { Helmet } from "react-helmet";
import {
  setPageTitle,
  getFiles,
  addFile,
  addFolder,
  uploadFile,
  updateFile,
  updateFiles,
  deleteFiles,
} from "redux/actions";

const mapStateToProps = (state) => {
  return {
    files: state.files,
    loading: state.loading.MyFiles,
    fileUploadProgress: state.fileUploadProgress,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  getFiles,
  addFile,
  addFolder,
  uploadFile,
  updateFile,
  updateFiles,
  deleteFiles,
};

class Files extends Component {
  state = { fileUploadPopupOpen: false, collectionPopupOpen: false };

  componentDidMount() {
    trackPage("Files");
    this.props.getFiles();
    this.props.setPageTitle();
  }

  openNewCollectionPopup = () => {
    this.setState({ collectionPopupOpen: true, selectedFile: null });
  };
  closeCollectionPopup = () => {
    this.setState({ collectionPopupOpen: false });
  };
  handleCollectionSubmit = (collectionName) => {
    this.props.addFile({ type: "FOLDER", path: collectionName });
    this.closeCollectionPopup();
  };
  closeFileUploadPopup = () => {
    this.setState({ fileUploadPopupOpen: false });
  };
  handleSelectClick = (file) => {
    this.props.history.push(`/files/${file.path}`);
    this.setState({ selectedFile: file });
  };

  addFile = () => {
    this.setState({ fileUploadPopupOpen: true });
  };
  addFolder = (file) => {
    this.setState({ collectionPopupOpen: true });
  };
  deleteFolder = (file) => {
    this.props.deleteFiles({ path: file.path, collection: file.collection });
  };
  updateFile = (fileId, updates) => {
    this.setState({ selectedFile: { ...this.state.selectedFile, ...updates } });
    this.props.updateFile(fileId, updates);
  };
  updateFiles = (collectionId, updates) => {
    this.props.updateFiles(collectionId, updates);
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Conversations</title>
        </Helmet>
        <div className="files-content">
          <FileUploadPopup
            open={this.state.fileUploadPopupOpen}
            selectedFile={this.state.selectedFile}
            uploadFile={this.props.uploadFile}
            onClose={this.closeFileUploadPopup}
          />
          <AddCollectionPopup
            path={this.state.selectedFile ? this.state.selectedFile.path : null}
            open={this.state.collectionPopupOpen}
            handleSubmit={this.handleCollectionSubmit}
            onClose={this.closeCollectionPopup}
          />
          <Grid container style={{ height: "calc(100vh - 55px)" }}>
            <Grid item xs={12} sm={3} md={2}>
              <div className="file-browser-container">
                <FileBrowser
                  className="file-browser"
                  addFile={this.addFile}
                  addFolder={this.addFolder}
                  deleteFolder={this.deleteFolder}
                  updateFiles={this.updateFiles}
                  files={this.props.files}
                  handleSelectClick={this.handleSelectClick}
                  openNewCollectionPopup={this.openNewCollectionPopup}
                  selectedFile={
                    this.state.selectedFile
                      ? this.state.selectedFile.path
                      : null
                  }
                  urlPath={this.props.match.params.path}
                  fileUploadProgress={this.props.fileUploadProgress}
                />
                <div className="add-new-file-browser">
                  <AddCollectionButton
                    handleClick={this.openNewCollectionPopup}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="collection-title-container">
                      <span>Add New</span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              style={{ height: "calc(100vh - 55px)", overflow: "scroll" }}
            >
              <div id="file-viewer">
                <FileViewer
                  key={
                    this.state.selectedFile
                      ? this.state.selectedFile.name
                      : "EMPTY-VIEWER"
                  }
                  file={this.state.selectedFile}
                  updateFile={this.updateFile}
                  handleSelectClick={this.handleSelectClick}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Files));

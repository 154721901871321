import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginTop: theme.spacing(1),
  },
  label: {
    color: "#507b82",
    "&.Mui-focused": {
      color: "#507b82",
    },
  },
  shrink: {
    fontSize: "1.25rem",
  },
  select: {
    "&:after": {
      borderColor: "#507b82",
    },
  },
}));

const COMPLETION_OPTIONS = [
  { value: "FINISHED", label: "Finished survey" },
  { value: "IN_PROGRESS", label: "In progress" },
  { value: "SCREENED_OUT", label: "Screened out" },
];

const PickCompletionStatus = ({ change, initialState }) => {
  const classes = useStyles();

  const [item, setItem] = useState(initialState ? " " : "");

  const handleSelectChange = (evt) => {
    setItem(evt.target.value);
    change("completion_status", evt.target.value);
  };
  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="date-sort"
        shrink
        classes={{ root: classes.label, shrink: classes.shrink }}
      >
        Filter by progress
      </InputLabel>
      <Select
        id="completion-sort"
        labelId="completion-sort-label"
        value={item}
        onChange={handleSelectChange}
        displayEmpty
        className={classes.select}
      >
        <MenuItem value="">
          <em>Show all responses</em>
        </MenuItem>
        <MenuItem value=" " disabled />
        {COMPLETION_OPTIONS.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PickCompletionStatus;

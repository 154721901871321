import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Markdown from "./Markdown";

const MarkdownEditable = ({ placeholder, children, index, editMarkdown }) => {
  const [markdown, setMarkdown] = useState(children);
  const [isEditing, setIsEditing] = useState(false);

  const exitEditor = () => {
    setIsEditing(false);
    editMarkdown(markdown, index);
  };

  const handleKeyDown = (evt) => {
    const exitKeys = { Escape: true, Tab: true };

    if (exitKeys[evt.key]) {
      exitEditor();
    }
  };

  const handleInput = (evt) => {
    setMarkdown(evt.target.value);
  };

  return (
    <Box
      py={2}
      pl={1}
      pr={5}
      className={isEditing ? null : "markdown-editable"}
    >
      {!isEditing ? (
        <div onClick={() => setIsEditing(true)}>
          <span className={markdown ? null : "empty"}>
            <Markdown>{!markdown.trim() ? placeholder : markdown}</Markdown>
          </span>
        </div>
      ) : (
        <div onBlur={exitEditor} onKeyDown={handleKeyDown}>
          <TextareaAutosize
            aria-label="edit markdown"
            rowsMin={4}
            placeholder="Write in markdown"
            value={markdown}
            onInput={handleInput}
            className="styled-textarea"
            autoFocus
          />
        </div>
      )}
    </Box>
  );
};

export default MarkdownEditable;

import React, { Component } from "react";
import { Checkbox, Grid } from "@material-ui/core";

class PersonalInfo extends Component {
  render() {
    return (
      <div>
        <h3 className="survey-info-titles" style={{ marginBottom: 20 }}>
          Personal Information
        </h3>

        {/* SURVEY START */}
        <h5>Beginning of Survey</h5>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.firstName}
                onChange={(e) => this.props.changeDemo(e, "firstName")}
              />
              <h3 className="demographic">First Name</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.firstNameOptional}
                disabled={!this.props.info.demographics.firstName}
                onChange={(e) => this.props.changeDemo(e, "firstNameOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.lastName}
                onChange={(e) => this.props.changeDemo(e, "lastName")}
              />
              <h3 className="demographic">Last Name</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.lastNameOptional}
                disabled={!this.props.info.demographics.lastName}
                onChange={(e) => this.props.changeDemo(e, "lastNameOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.email}
                onChange={(e) => this.props.changeDemo(e, "email")}
              />
              <h3 className="demographic">Email</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.emailOptional}
                disabled={!this.props.info.demographics.email}
                onChange={(e) => this.props.changeDemo(e, "emailOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.mergeDuplicateEmails}
                disabled={!this.props.info.demographics.email}
                onChange={(e) =>
                  this.props.changeInfo({
                    mergeDuplicateEmails: e.target.checked,
                  })
                }
              />
              <h3 className="demographic">Merge Duplicates</h3>
            </div>
          </Grid>
        </Grid>

        {/* SURVEY END */}
        <Grid container>
          <Grid item xs={4}>
            <h5>End of Survey</h5>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.age}
                onChange={(e) => this.props.changeDemo(e, "age")}
              />
              <h3 className="demographic">Age</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.ageOptional}
                disabled={!this.props.info.demographics.age}
                onChange={(e) => this.props.changeDemo(e, "ageOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.gender}
                onChange={(e) => this.props.changeDemo(e, "gender")}
              />
              <h3 className="demographic">Gender</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.genderOptional}
                disabled={!this.props.info.demographics.gender}
                onChange={(e) => this.props.changeDemo(e, "genderOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.hHIncome}
                onChange={(e) => this.props.changeDemo(e, "hHIncome")}
              />
              <h3 className="demographic">Household Income</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.hHIncomeOptional}
                disabled={!this.props.info.demographics.hHIncome}
                onChange={(e) => this.props.changeDemo(e, "hHIncomeOptional")}
              />
              <h3 className="demographic">Optional</h3>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <div className="checkbox-container">
              <Checkbox
                checked={this.props.info.demographics.location}
                onChange={(e) => this.props.changeDemo(e, "location")}
              />
              <h3 className="demographic">Location</h3>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PersonalInfo;

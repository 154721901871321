import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loading = () => (
  <div style={{ margin: "0 auto", width: 50, marginTop: "35vh" }}>
    <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
  </div>
);

export default Loading;

export const tierUsage = {
  FREE: {
    responses: 25,
    surveys: 3,
  },
  PREMIUM: {
    responses: 200,
    surveys: -1,
  },
  ENTERPRISE: {
    responses: 1000,
    surveys: -1,
  },
};

export function getFeatures(tier) {
  switch (tier) {
    case "FREE":
      return {
        name: "Free",
        showTime: true,
        featureList: [
          "3 surveys",
          "10 questions / survey",
          "25 survey responses",
          "200 transcription minutes",
          "Sentiment analysis",
          "MP3 / MP4 recording formats",
          "Phonic Showreels",
          "Phonic Reports",
          "Phonic Recruit",
          "Phonic Recruit",
        ],
      };
    case "PREMIUM":
      return {
        name: "Premium",
        showTime: true,
        featureList: [
          "20 Surveys / mo",
          "Unlimited questions",
          "200 survey responses / mo",
          "500 transcription minutes / mo",
          "Response translation",
          "Survey localization",
          "Sentiment analysis",
          "MP3/MP4/WAV recording formats",
          "Survey export in CSV or TXT",
          "Bulk file download",
          "Skip logic & randomization",
          "Survey conditions",
          "Custom branding",
          "Phonic showreels",
          "Showreel sharing and export",
          "Phonic Reports",
          "Phonic Recruit",
          "Embedded feedback",
          "Survey integrations",
        ],
      };
    case "ENTERPRISE":
      return {
        name: "Business",
        showTime: false,
        featureList: [
          "Unlimited surveys / mo",
          "1000+ survey responses / mo",
          "1000+ transcription minutes / mo",
          "All premium features",
          "All speech analytics",
          "PII Redaction",
          "Sharing and collaboration",
          "Platform whitelabel",
          "Google sheets integration",
          "Phonic Conversations",
          "Media dropbox",
          "Prioritized support",
        ],
      };
    default:
      return {};
  }
}

export function getPrice(tier, billingPeriod, discount) {
  if (tier === "FREE") return 0;
  else if (tier === "PREMIUM") {
    let price;
    if (billingPeriod === "annually") price = 79;
    else if (billingPeriod === "monthly") price = 129;
    price *= getDiscountMultiplier(discount);
    return parseInt(price);
  } else if (tier === "ENTERPRISE") {
    return "Contact Us";
  }
  return null;
}

export function getTierUsage(tier) {
  if (tierUsage[tier]) return tierUsage[tier];
  return tierUsage.FREE;
}

export function isMonthly(tier) {
  if (tier === "PREMIUM" || tier === "ENTERPRISE") return true;
  return false;
}

export function getDiscountMultiplier(discount) {
  if (discount === "ACADEMIC") return 0.3;
  if (discount === "NONPROFIT") return 0.75;
  if (discount === "FRIENDS") return 0.8;
  return 1.0;
}

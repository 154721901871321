import React from "react";

class FileUpload extends React.Component {
  render() {
    return (
      <div>
        <form method="post" action="#" id="#">
          <div className="form-group files">
            <input
              type="file"
              className="form-control"
              {...this.props.inputProps}
              onChange={this.props.onChange}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default FileUpload;

import React from "react"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    DialogContentText
} from "@material-ui/core";
import RandomConditions from "../RandomConditions"

class RandomConditionsPopup extends React.Component {
    state = {
        randomConditions: [],
        errors: null
    }
    componentDidMount() {
        if (this.props.randomConditionsInfo && this.props.randomConditionsInfo.length > 0) {
            this.setState({ randomConditions: this.props.randomConditionsInfo })
        } else {
            this.setState({ randomConditions: [{ condition: "", values: [{ value: '', weight: 0 }] }] })
        }
    }
    handleClose = () => {
        this.setState({ randomConditions: [] });
        this.props.onClose();
    }
    handleSave = () => {
        this.setState({ errors: null })
        const randomConditionsWithValuesParsed = [...this.state.randomConditions]
        const areFieldsComplete = this.state.randomConditions.every(({ condition, values }, conditionIndex) => {
            return condition !== "" && values.every(({ value, weight }, valueIndex) => {
                randomConditionsWithValuesParsed[conditionIndex].values[valueIndex] = { value, weight: parseFloat(weight) }
                return value !== '' && weight !== '' && /^-?.?[0-9]\d*(\.\d+)?$/.test(weight)
            })
        })
        if (areFieldsComplete) {
            this.props.onSubmit(randomConditionsWithValuesParsed);
            this.handleClose();
        } else {
            this.setState({ errors: "Please complete all fields properly before saving" })
        }
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>Random Condition Assignment</DialogTitle>
                    <DialogContent>
                        {this.state.errors && (<DialogContentText style={{ color: "red" }} className="dialog-text">
                            {this.state.errors}
                        </DialogContentText>)}
                        <RandomConditions
                            randomConditions={this.state.randomConditions}
                            changeRandomConditionInfo={(r) => {
                                this.setState({ randomConditions: r })
                            }}
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button
                            color="primary"
                            variant="contained"
                            component="label"
                            onClick={this.handleSave}
                        >
                            Save
                  </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default RandomConditionsPopup
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, Checkbox, IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles(() => ({
  iconButton: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

const TopicFilter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="filter-popover">
          <h3>Filter Topics</h3>
          <div className="section">
            <div className="checkbox-container">
              <Checkbox
                checked={props.topicFilters.MANUAL}
                onChange={(e) =>
                  props.updateTopicFilters("MANUAL", e.target.checked)
                }
              />
              <p>Manually Applied Topics</p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                checked={props.topicFilters.CLASSIFICATION}
                onChange={(e) =>
                  props.updateTopicFilters("CLASSIFICATION", e.target.checked)
                }
              />
              <p>Auto-Coded Topics</p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                checked={props.topicFilters.AUTO}
                onChange={(e) =>
                  props.updateTopicFilters("AUTO", e.target.checked)
                }
              />
              <p>Key Words & Phrases</p>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default TopicFilter;

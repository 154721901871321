export const rainbow = {
  green: "#22CE81",
  red: "#EE9077",
  yellow: "#F7EE71",
  blue: "#85A0D6",
  violet: "#c07ec2",
  cyan: "#77D8E6",
  orange: "#F9B972",
  gray: "#B3B3B3",
};

import React, { useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropCheckbox from "components/SurveyCreation/PropCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ShowreelsImageUpload from "./ShowreelsImageUpload";

export default function ShowreelsSettingsPopup({
  open,
  onClose,
  showreel,
  setShowreelInfo,
  submitTopLeftImage,
  user,
  loadingShowreelImage,
  saveShowreel,
}) {
  const [state, setstate] = useState({});

  const handleClose = () => {
    saveShowreel();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <ShowreelsImageUpload
        dialogTitle="Upload Watermark"
        open={state.imageUploadOpen}
        onSubmit={submitTopLeftImage}
        onClose={() => setstate({ ...state, imageUploadOpen: false })}
      />
      <div className="x-button">
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="popup-container">
        <h2>Showreel Settings</h2>
        <Typography variant="body1" style={{ marginBottom: 20 }}>
          <div className="flex-container justify-left">
            Showreel Name:&nbsp;
            {state.editingName ? (
              <>
                <TextField
                  style={{ marginRight: 10 }}
                  onChange={(e) => {
                    setstate({ ...state, name: e.target.value });
                  }}
                  value={state.name}
                />
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faSave}
                    onClick={() => {
                      setShowreelInfo("name", state.name, true);
                      setstate({ ...state, editingName: false });
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <b>{showreel.name}</b>{" "}
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() =>
                      setstate({
                        ...state,
                        editingName: true,
                        name: showreel.name,
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Typography>
        <div className="prop-checkbox-container">
          <PropCheckbox
            label={{
              text: "Include Subtitles",
              placement: "right",
            }}
            update={(e) =>
              setShowreelInfo("addSubtitles", e.target.checked, true)
            }
            value={Boolean(showreel.addSubtitles)}
          />
        </div>
        <div className="prop-checkbox-container">
          <PropCheckbox
            label={{
              text: "Show Demographics",
              placement: "right",
            }}
            update={(e) =>
              setShowreelInfo("showDemographics", e.target.checked, true)
            }
            value={Boolean(showreel.showDemographics)}
            disabled={!user || user.tier === "FREE"}
          />
        </div>
        <div className="prop-checkbox-container">
          <PropCheckbox
            label={{
              text: "Remove Watermark",
              placement: "right",
            }}
            update={(e) =>
              setShowreelInfo("addWatermark", !e.target.checked, true)
            }
            value={!Boolean(showreel.addWatermark)}
            disabled={!user || user.tier === "FREE"}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            opacity: user.tier === "FREE" || !showreel.addWatermark ? 0.5 : 1.0,
          }}
        >
          <Typography variant="body1">Custom Watermark</Typography>
          <div className="image-preview">
            {showreel.topLeftImageUrl && (
              <FontAwesomeIcon
                icon={faTimes}
                className="image-preview-x"
                onClick={() => setShowreelInfo("topLeftImageUrl", null, true)}
              />
            )}
            {showreel.topLeftImageUrl ? (
              <img
                src={showreel.topLeftImageUrl}
                className="custom-image-preview"
                alt="welcome preview"
              />
            ) : (
              <span>No Image</span>
            )}
          </div>
          <Button
            onClick={() => {
              if (user.tier !== "FREE" && showreel.addWatermark)
                setstate({ ...state, imageUploadOpen: true });
            }}
            variant="contained"
            style={{ width: 150 }}
            className={`bottom-button secondary-button-color`}
          >
            {loadingShowreelImage ? (
              <CircularProgress size={22} style={{ color: "white" }} />
            ) : (
              "Upload Image"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

import { useEffect, useState } from "react";

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const [targetHeight, setTargetHeight] = useState("unset");

  useEffect(() => {
    const targetRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
        setTargetHeight(
          entry.isIntersecting ? "unset" : entry.boundingClientRect.height
        );
      },
      { threshold: 0 }
    );

    if (targetRef) observer.observe(targetRef);

    return () => observer.unobserve(targetRef);
  }, [ref]);

  return [isIntersecting, targetHeight];
};

export default useOnScreen;

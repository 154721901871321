import { getNElements, conversationColors } from "./utils";

/* Transcription Utils */

export const nodeType = {
  PRONUNCIATION: "pronunciation",
  PUNCTUATION: "punctuation",
  PARAGRAPH: "paragraph",
};

export const ZERO_WIDTH_CHAR = "\u200B";

const getWordsWithinTimesFromItems = (startTime, endTime, items) => {
  // TODO: Make this faster
  return items.filter((item, idx) => {
    if (
      parseFloat(item.start_time) >= startTime &&
      parseFloat(item.end_time) <= endTime
    )
      return true;
    if (
      item.type === nodeType.PUNCTUATION &&
      idx > 0 &&
      items[idx - 1] &&
      parseFloat(items[idx - 1].start_time) >= startTime &&
      parseFloat(items[idx - 1].end_time) <= endTime
    )
      return true;
    return false;
  });
};

function* naturalNumbers() {
  let num = 0;
  while (true) {
    yield num;
    num += 1;
  }
}

const getSegments = (speakers, items, numSpeakers) => {
  const speakerColors = getNElements(
    conversationColors,
    numSpeakers > 1 ? numSpeakers : 1
  );
  return (
    speakers &&
    speakers.segments
      .map((speaker) => {
        const transcriptionItems = getWordsWithinTimesFromItems(
          speaker.start_time,
          speaker.end_time,
          items
        );
        return !transcriptionItems || transcriptionItems.length === 0
          ? null
          : {
              startTime: speaker.start_time,
              endTime: speaker.end_time,
              speaker: parseInt(speaker.speaker_label.split("_")[1]) + 1,
              speakerColor:
                speakerColors[parseInt(speaker.speaker_label.split("_")[1])],
              items: transcriptionItems,
            };
      })
      .filter((segment) => segment !== null)
  );
};

export const conversationToEditorValue = (items, speakers, numSpeakers) => {
  const segments = getSegments(speakers, items, numSpeakers);

  const masterIndex = naturalNumbers();

  const getNodeFromTranscriptionItem = (acc, item, localIdx) => {
    const spaceBefore = item.type === nodeType.PRONUNCIATION && localIdx !== 0;
    const itemNode = {
      type: "item",
      attrs: {
        type: item.type,
        spaceBefore,
        startTime: item.start_time || null,
        endTime: item.end_time || null,
        transcriptionIdx: masterIndex.next().value,
      },
      content: [
        {
          type: "text",
          text:
            item.alternatives && item.alternatives.length
              ? item.alternatives[0].content
              : "",
        },
      ],
    };

    return (
      (spaceBefore
        ? acc.push({ type: "space" }, itemNode)
        : acc.push(itemNode)) && acc
    );
  };

  const paragraphNode = (segment) => ({
    type: nodeType.PARAGRAPH,
    content: segment.items.reduce(getNodeFromTranscriptionItem, []),
    startTime: segment.startTime || 0,
    endTime: segment.endTime,
    speaker: segment.speaker,
    speakerColor: segment.speakerColor,
  });

  return segments
    ? segments.map((segment) => paragraphNode(segment))
    : [
        {
          type: nodeType.PARAGRAPH,
          content: items.reduce(getNodeFromTranscriptionItem, []),
        },
      ];
};

export function updateTranscriptionItems(editorContent, transcriptionItems) {
  /**
   * Update transcription items given value of edited Prose Mirror document
   * and old transcription items.
   * @param {Array} editorContent: Prose Mirror document content
   * @param {Array} transcriptionItems: old transcription items
   * @returns {Array} new transcription items
   */
  // Flatten editor content to array of transcription items
  const editedItems = editorContent.reduce((acc, paragraph) => {
    return acc.concat(
      paragraph.content.filter((node) => node.type !== "space" && node.content)
    );
  }, []);

  // Build new transcription items by comparing old and edited transcription items
  const newTranscriptionItems = [];

  // Pointers to old and edited transcription items
  let transcriptionIdx = 0;
  let editorIdx = 0;
  // Iterate through transcription items and editor content in parallel
  while (
    transcriptionIdx < transcriptionItems.length &&
    editorIdx < editedItems.length
  ) {
    const transcriptionItem = transcriptionItems[transcriptionIdx];

    const editedItem = editedItems[editorIdx];
    const { content, attrs } = editedItem;
    const [{ text: editedText }] = content;
    const { transcriptionIdx: editedTranscriptionIdx } = attrs;

    /**
     * If we find the transcription item in the editor content, create a new
     * item with updated text and push it to the new transcription items list.
     * Move the pointers to the next transcription item and editor item.
     */
    if (transcriptionIdx === editedTranscriptionIdx) {
      let newTranscriptionItem = transcriptionItem;
      const [alternative] = transcriptionItem.alternatives || [
        { content: "", confidence: 1.0 },
      ];
      if (editedText !== alternative.content) {
        newTranscriptionItem = {
          ...transcriptionItem,
          alternatives: [
            {
              ...alternative,
              content: editedText,
            },
          ],
        };
      }
      newTranscriptionItems.push(newTranscriptionItem);
      transcriptionIdx++;
      editorIdx++;
    } else if (transcriptionIdx < editedTranscriptionIdx) {
      /**
       * If transcriptionIdx < editorTranscriptionIdx, the item was deleted.
       * Update the last item in the new transcription items list with the
       * deleted item's end time. Move to the next transcription item.
       */
      const prevItem = newTranscriptionItems.pop();
      newTranscriptionItems.push({
        ...prevItem,
        endTime: transcriptionItem.endTime,
      });
      transcriptionIdx++;
    } else if (!editedTranscriptionIdx) {
      // If this happens, push the editor item to the new transcription items
      editorIdx++;
    }
  }
  return newTranscriptionItems;
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  label: {
    color: "#757575",
  },
});

const TextButton = (props) => {
  const classes = useStyles();
  return (
    <Button classes={{ root: classes.root, label: classes.label }} {...props} />
  );
};

export default TextButton;

import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { createReport } from "redux/actions";
import { ClipLoader } from "react-spinners";

const mapStateToProps = (state) => {
  return {
    redirectTo: state.redirectTo,
    loading: state.loading ? state.loading.CreateReport : false,
  };
};

const mapDispatchToProps = { createReport };

const CreateReportPopup = ({ handleClose, createReport, history, loading }) => {
  const [title, setTitle] = useState("");

  const handleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const report = {
      name: title,
      contents: [],
    };
    createReport(report, history);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="create-new-report"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <span className="create-report-title">Create Report</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-text">
          Give your report a name.
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            autoFocus
            id="title"
            label="Report Title"
            required
            fullWidth
            onChange={handleChange}
          />
          <span className="create-report-button">
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {!loading ? (
                "Create Report"
              ) : (
                <ClipLoader size={21} color={"white"} />
              )}
            </Button>
          </span>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateReportPopup)
);

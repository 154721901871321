import TypeTile from "components/TypeTile";
import React from "react";
import clsx from "clsx";
import { removeHtmlTags } from "utils/utils";
import { Tooltip } from "@material-ui/core";

function ResultsSidebarTile({
  idx,
  type,
  text,
  subText,
  onClick,
  selected,
  iconsOverride,
  colorOverride,
  icon,
}) {
  const stText = removeHtmlTags(text);
  const stSubText = removeHtmlTags(subText);
  return (
    <Tooltip
      title={
        stText || stSubText ? (
          <div className="tooltip-title">
            <h2>{stText}</h2>
            <h3>{stSubText}</h3>
          </div>
        ) : (
          "..."
        )
      }
      placement="right"
      arrow
    >
      <div
        className={clsx("results-tile", selected && "selected")}
        onClick={onClick}
      >
        <div className="results-tile-left">
          <TypeTile
            type={type}
            num={idx}
            shape="rect"
            size="small"
            iconsOverride={iconsOverride}
            colorOverride={colorOverride}
          />
        </div>
        <div className="results-tile-right">
          <span className="results-tile-text">{stText}</span>
          <br />
          <span className="results-tile-subtext">{stSubText}</span>
        </div>
        <div className="results-tile-icon">{icon}</div>
      </div>
    </Tooltip>
  );
}

export default ResultsSidebarTile;

import React, { useState } from "react";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "components/Popups/DeletePopup";
import { deleteShowreel } from "redux/actions";

const mapDispatchToProps = {
  deleteShowreel,
};

const ShowreelPopupMenu = ({ showreel, deleteShowreel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const handleClick = (evt) => {
    evt.stopPropagation();
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt) => {
    evt.stopPropagation();
    setAnchorEl(null);
  };

  const popupClose = () => {
    setPopupOpen(false);
  };

  const handleDeleteAlert = (evt) => {
    evt.stopPropagation();
    setPopupOpen(true);
    setAnchorEl(null);
  };

  const confirmDeleteShowreel = () => {
    deleteShowreel(showreel._id);
    setPopupOpen(false);
  };

  const openShowreelExport = (evt) => {
    evt.stopPropagation();
    window.open(`/showreels/${showreel._id}/share`, "_blank");
    setPopupOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <DeletePopup
        open={popupOpen}
        deleteFunction={confirmDeleteShowreel}
        onClose={popupClose}
        title={`Please confirm you'd like to delete ${showreel.name} permanently.`}
        subTitle="This action cannot be undone."
        buttonText={`Delete showreel`}
        deleteId={showreel._id}
      />
      <div onClick={handleClick} className="rounded-icon-container">
        <FontAwesomeIcon
          icon={faEllipsisV}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="survey-menu-icon"
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={openShowreelExport}
          disabled={!showreel.path || !showreel.public}
        >
          Open Showreel Export
        </MenuItem>
        <MenuItem onClick={handleDeleteAlert}>Delete Showreel</MenuItem>
      </Menu>
    </>
  );
};

export default connect(null, mapDispatchToProps)(ShowreelPopupMenu);

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export default class PropCheckbox extends React.Component {
  render() {
    const checkBoxClass = this.props.disabled ? "disabled-checkbox" : "";
    return (
      <div className="checkbox-with-label">
        {this.props.label.placement === "left" && (
          <p className="selection-header exclusive">{this.props.label.text}</p>
        )}
        <Checkbox
          checked={this.props.value}
          onChange={this.props.update}
          disabled={this.props.disabled}
          className={`${checkBoxClass}`}
        />
        {this.props.label.placement === "right" && (
          <p className="selection-header exclusive">{this.props.label.text}</p>
        )}
      </div>
    );
  }
}

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Translation from "./../SurveyCreation/Translation";

class TranslationPopup extends React.Component {
  state = {
    file: null,
    translations: [],
    fileTranslations: [],
  };

  componentDidMount() {
    this.setState({
      translations: this.props.translationInfo,
    });
  }

  handleClose = () => {
    this.setState({ translations: [] });
    this.props.onClose();
  };

  handleFileAdd = () => {
    this.setState({
      translations: this.state.translations.concat(this.state.fileTranslations),
    });
  };

  handleSave = () => {
    this.props.onSubmit(this.state.translations);
    this.handleClose();
  };

  fileInputChange = (event) => {
    this.setState({ file: event.target.files[0] });
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event) => {
      let json = JSON.parse(event.target.result);
      for (const t in json) {
        this.state.fileTranslations.push({ text: t, ...json[t] });
      }
      this.setState({});
    };
    reader.readAsText(file);
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>Edit Translations</DialogTitle>
          <div className="image-upload-container">
            <DialogContent>
              <DialogContentText className="dialog-text">
                <div className="info-text">
                  <p>
                    Either upload a JSON file or add the translations below.{" "}
                    <a
                      href="https://www.loom.com/share/0f8359d7d28f4b6b9ea4a61964ad2d66"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more.
                    </a>
                  </p>
                </div>
              </DialogContentText>
              <input
                type="file"
                className="form-control"
                multiple=""
                accept="application/json"
                onChange={this.fileInputChange}
              />
              {this.state.file && (
                <div className="translation-file-line">
                  <div className="translation-file-text">
                    Found {this.state.fileTranslations.length} translations -
                    add them? Any duplicates will be overwritten.
                  </div>
                  <div className="translation-file-button">
                    <Button
                      className="gray-outlined-button"
                      variant="outlined"
                      component="label"
                      onClick={this.handleFileAdd}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </DialogContent>
          </div>
          <DialogContent>
            <Translation
              translationInfo={this.state.translations}
              changeTranslationInfo={(t) => {
                this.setState({ translations: t });
              }}
              textEditable={true}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              className="secondary-button-color"
              variant="contained"
              component="label"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default TranslationPopup;

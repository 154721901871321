import React, { useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, TextField } from "@material-ui/core";

const AddAudienceButton = ({ onClick }) => {
  return (
    <div className="add-audience-button flex-container" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
  );
};

const AudienceTile = ({
  selected,
  name,
  setName,
  limit,
  setLimit,
  onClick,
  remove,
}) => {
  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);

  return (
    <div
      className={clsx("audience-tile", selected && "selected")}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <div className="x-button tight small">
          {!editing ? (
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={{ marginRight: 10 }}
              className="hover-button"
              onClick={() => setEditing(true)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheck}
              style={{ marginRight: 10 }}
              className="hover-button"
              onClick={() => setEditing(false)}
            />
          )}
          <FontAwesomeIcon
            icon={faTrash}
            onClick={remove}
            className="hover-button"
          />
        </div>
      )}
      {!editing ? (
        <h3>{name}</h3>
      ) : (
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Audience Name"
          value={name}
          onChange={setName}
          style={{ marginBottom: 15, marginTop: 10 }}
        />
      )}
      <TextField
        fullWidth
        variant="outlined"
        label="Completes"
        value={limit}
        onChange={setLimit}
      />
    </div>
  );
};

export default function FilteringPane(props) {
  return (
    <Grid id="audience-pane" item xs={2}>
      {props.audiences.map((audience, idx) => {
        return (
          <AudienceTile
            key={`AUDIENCE-${idx}`}
            idx={idx}
            selected={idx === props.selectedAudience}
            name={audience.name}
            limit={audience.limit}
            setLimit={(e) => {
              let value = e.target.value;
              if (e.target.value === "") value = 0;
              else if (isNaN(value)) return;
              props.modifyAudience(idx, {
                limit: parseInt(value),
              });
            }}
            setName={(e) => {
              props.modifyAudience(idx, {
                name: e.target.value,
              });
            }}
            onClick={() => props.selectAudience(idx)}
            remove={(e) => {
              e.stopPropagation();
              props.removeAudience(idx);
            }}
          />
        );
      })}
      <AddAudienceButton onClick={props.addAudience} />
    </Grid>
  );
}

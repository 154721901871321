import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Steps from "components/Report/Steps";
import ResponseRow from "components/Report/ResponseRow";
import { getStateJSX } from "utils/utils";
import { getBookmarks, addManyContents } from "redux/actions";

const mapStateToProps = (state) => {
  return {
    bookmarks: state.bookmarks,
  };
};

const mapDispatchToProps = {
  getBookmarks,
  addManyContents,
};

const STEPS = ["Select Survey", "Saved Responses"];

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#f9f9f9",
    boxShadow: "none",
  },
}));

const AddBookmarkPopup = ({
  open,
  setBookmarkBrowser,
  reportId,
  bookmarks,
  getBookmarks,
  addManyContents,
  changeReport,
}) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getBookmarks();
  }, [getBookmarks]);

  useEffect(() => {
    if (!bookmarks) return;

    // Make object: { id: false, ... }
    setSelected(
      bookmarks.responses.reduce((obj, curr) => {
        obj[curr._id] = false;
        return obj;
      }, {})
    );
  }, [bookmarks]);

  const handleNext = (survey) => {
    setStep(step + 1);
    setCurrentSurvey(survey);
  };

  const handleBack = () => {
    setStep(step - 1);
    setCurrentSurvey(null);
  };

  const handleToggle = useCallback((responseId) => {
    setSelected((s) => ({ ...s, [responseId]: !s[responseId] }));
  }, []);

  const handlePaste = () => {
    const bulkData = bookmarks.responses
      .filter((response) => selected[response._id])
      .map((response) => ({ responseId: response._id, type: "RESPONSE" }));

    changeReport({ suppressNotify: true })
      .then(() => {
        addManyContents(reportId, bulkData);
      })
      .then(() => {
        setSelected(
          bookmarks.responses.reduce((obj, curr) => {
            obj[curr._id] = false;
            return obj;
          }, {})
        );
      });

    setBookmarkBrowser(false);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <Steps
              step={step}
              name={currentSurvey.name}
              handleBack={handleBack}
            />
            {/* Saved Responses */}
            <TableContainer component={Paper} className={classes.paper}>
              <Table aria-label="response table" size="small">
                <TableBody>
                  {bookmarks
                    ? bookmarks.responses
                        .filter(
                          (response) => response.surveyId === currentSurvey._id
                        )
                        .map((response) => {
                          return (
                            <ResponseRow
                              key={response._id}
                              response={response}
                              isChecked={selected[response._id]}
                              handleToggle={handleToggle}
                              questionNum={response.question.questionNum}
                            />
                          );
                        })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      case 0:
      default:
        return (
          <TableContainer component={Paper} className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>State</TableCell>
                </TableRow>
              </TableHead>
              <tbody>
                {!bookmarks && (
                  <TableRow className="table-row">
                    <TableCell colspan={2}>
                      <CircularProgress size={14} />
                    </TableCell>
                  </TableRow>
                )}
                {bookmarks && bookmarks.surveys.length === 0 ? (
                  <TableRow className="table-row">
                    <TableCell colSpan={2}>
                      <i>You don't have any bookmarks.</i>
                    </TableCell>
                  </TableRow>
                ) : (
                  bookmarks &&
                  bookmarks.surveys.map((survey) => (
                    <TableRow
                      key={survey._id}
                      onClick={() => handleNext(survey)}
                      hover
                      className="table-row"
                    >
                      <TableCell>{survey.name}</TableCell>
                      <TableCell>{getStateJSX(survey)}</TableCell>
                    </TableRow>
                  ))
                )}
              </tbody>
            </Table>
          </TableContainer>
        );
    }
  };

  const selectedJSX = (selected, responses) => {
    if (!selected) return;

    const rows = responses.filter((res) => selected[res._id]);

    if (rows.length === 0) {
      return (
        <TableRow className="table-row">
          <TableCell>
            <i>You haven't selected any bookmarks.</i>
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((res) => (
      <ResponseRow
        key={res._id}
        response={res}
        isChecked={selected[res._id]}
        handleToggle={handleToggle}
        questionNum={res.question.questionNum}
      />
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setBookmarkBrowser(false)}
      aria-labelledby="add-to-report"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>
        <span className="bookmark-dialog-title">{STEPS[step]}</span>
        <span>
          <h3 className="sharing-title">Showing bookmarked responses</h3>
        </span>
      </DialogTitle>
      <DialogContent>{getStepContent(step)}</DialogContent>
      <DialogTitle>
        <span className="bookmark-dialog-title">Selected Data</span>
      </DialogTitle>
      <DialogContent>
        {/* Selected Data */}
        <TableContainer component={Paper} className={classes.paper}>
          <Table aria-label="selected table" size="small">
            <TableBody>
              {bookmarks && selectedJSX(selected, bookmarks.responses)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogContent style={{ marginBottom: 8 }}>
        <Button
          variant="contained"
          className="deploy-button"
          onClick={handlePaste}
          color="primary"
        >
          Paste Data
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBookmarkPopup);

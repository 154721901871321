import { Grid } from "@material-ui/core";
import React from "react";
import {
  autocompleteDemographics,
  selectionDemographics,
} from "utils/Recruit/recruitDemographics";
import AutocompletePicker from "./Pickers/AutocompletePicker";
import SelectionPicker from "./Pickers/SelectionPicker";

export default function FilteringPane(props) {
  return (
    <Grid id="options-pane" item xs={5}>
      {selectionDemographics.map((d, idx) => {
        if (props.audience[d.optionType] === undefined) return null;
        return (
          <SelectionPicker
            {...d}
            key={`SELECTION-PICKER-${idx}`}
            audience={props.audience}
            modifyAudience={props.modifyAudience}
          />
        );
      })}
      {autocompleteDemographics.map((d, idx) => {
        if (props.audience[d.optionType] === undefined) return null;
        return (
          <AutocompletePicker
            {...d}
            key={`AUTOCOMPLETE-PICKER-${idx}`}
            audience={props.audience}
            modifyAudience={props.modifyAudience}
          />
        );
      })}
    </Grid>
  );
}

import {
  faVolumeUp,
  faHandPointer,
  faPenSquare,
  faDesktop,
  faVideo,
  faCalculator,
  faCalendar,
  faChalkboard,
  faFile,
  faArrowsAltH,
  faCaretSquareDown,
  faCommentDots,
  faShapes,
  faTh,
  faSortNumericDown,
} from "@fortawesome/free-solid-svg-icons";
import { rainbow } from "./brandPalette";

export const questionTypes = [
  {
    key: "WELCOME",
    icons: [faShapes],
    name: "Welcome Screen",
    category: "WELCOME",
    color: rainbow.gray,
  },
  {
    key: "TEXT",
    icons: [faPenSquare],
    name: "Open Text",
    description: "type in a textbox",
    subText: "Text Response",
    category: "BASIC",
    color: rainbow.blue,
  },
  {
    key: "NUMBER",
    icons: [faCalculator],
    name: "Number",
    description: "restrict input to only numbers",
    subText: "Enter a Number",
    category: "BASIC",
    color: rainbow.blue,
  },
  {
    key: "DATE",
    icons: [faCalendar],
    name: "Date",
    description: "input a date",
    subText: "Enter a Date",
    category: "BASIC",
    color: rainbow.blue,
  },
  {
    key: "AUDIO",
    icons: [faVolumeUp],
    name: "Audio",
    description: "speak into a microphone",
    subText: "Audio Response",
    category: "AUDIO",
    color: rainbow.green,
  },
  {
    key: "VIDEO",
    icons: [faVideo],
    name: "Video",
    description: "record webcam video",
    subText: "Video Response",
    category: "VIDEO",
    color: rainbow.red,
  },
  {
    key: "SCREEN",
    icons: [faDesktop],
    name: "Screen",
    description: "record screen video",
    subText: "Screen Recording",
    category: "VIDEO",
    color: "#a66da8",
  },
  {
    key: "THINKALOUD",
    icons: [faCommentDots],
    name: "Think-Aloud",
    description: "spoken response that starts recording immediately",
    subText: "The microphone is already recording. Speak your thoughts freely",
    color: "#956297",
  },
  {
    key: "AUDIO_SELECTION",
    icons: [faVolumeUp, faHandPointer],
    name: "Audio + Selection",
    description:
      "make a selection and use the microphone to explain your choice.",
    subText: "Make a selection and use the microphone to explain your choice",
    category: "AUDIO",
    color: rainbow.green,
  },
  {
    key: "AUDIO_TEXT",
    icons: [faVolumeUp, faPenSquare],
    name: "Audio + Text",
    description: "speak into a microphone and type in a textbox",
    subText: "Type a response and use the microphone to explain your choice",
    category: "AUDIO",
    color: rainbow.green,
  },
  {
    key: "AUDIO_SLIDER",
    icons: [faVolumeUp, faArrowsAltH],
    name: "Audio + Slider",
    description: "speak into a microphone and drag a slider",
    subText:
      "Provide a slider rating and use the microphone to explain your choice",
    category: "AUDIO",
    color: rainbow.green,
  },
  {
    key: "SLIDER",
    icons: [faArrowsAltH],
    name: "Slider",
    description: "drag a slider",
    subText: "Slider Response",
    category: "BASIC",
    color: rainbow.blue,
  },
  {
    key: "FILE",
    icons: [faFile],
    name: "File Upload",
    description: "upload a file",
    subText: "File Upload",
    category: "BASIC",
    color: rainbow.blue,
  },
  {
    key: "SELECTION",
    icons: [faHandPointer],
    name: "Selection",
    description: "choose from a set of options",
    subText: "Selection Response",
    category: "BASIC",
    color: rainbow.orange,
  },
  {
    key: "DROPDOWN",
    icons: [faCaretSquareDown],
    name: "Dropdown",
    description: "select from a set of options in a dropdown",
    subText: "Selection Response",
    category: "BASIC",
    color: rainbow.orange,
  },
  {
    key: "LIKERT",
    icons: [faTh],
    name: "Likert",
    description: "rank items on a custom scale",
    subText: "On a scale of 1 to 5, rate the following",
    category: "BASIC",
    color: rainbow.orange,
  },
  {
    key: "RANKING",
    icons: [faSortNumericDown],
    name: "Ranking",
    description: "Rank items in the order you see fit",
    subText: "Ranking",
    category: "BASIC",
    color: rainbow.orange,
  },
  {
    key: "DISPLAY",
    icons: [faChalkboard],
    name: "Display Only",
    description: "no response, just titles and media",
    subText: "Display Subtext...",
    category: "BASIC",
    color: rainbow.gray,
  },
  {
    key: "AUDIO_RANKING",
    icons: [faVolumeUp, faSortNumericDown],
    name: "Audio + Ranking",
    description:
      "Rank items in the order you see fit and use the microphone to explain your choice",
    subText:
      "Rank items in the order you see fit and use the microphone to explain your choice",
    category: "AUDIO",
    color: rainbow.green,
  },
];

export function getQuestionType(type) {
  for (var i = 0; i < questionTypes.length; ++i) {
    if (questionTypes[i].key === type) return questionTypes[i];
  }
  return null;
}

import React from "react";
import { Grid } from "@material-ui/core";
import {
  getSelectionData,
  getAverageSentimentPieChartData,
  getSliderNumberChartData,
  getLikertChartData,
  getAverageRank,
  getEmotionBarChartData,
  getWordCloudData,
  getTopicChartData,
} from "utils/graphData";
import { getQuestionType } from "utils/questionTypes";
import {
  getChartColors,
  changeOpacity,
  isOpenEndType,
  isAudioOrVideoType,
  getTransparentChartColors,
} from "utils/utils";
import { isTextType } from "utils/utils";
import ResultsChart from "components/Graphs/ResultsChart";

class QuestionAnalytics extends React.Component {
  state = { addedStopWords: [] };

  getBackgroundAndBorder = () => {
    return {
      backgroundColor: changeOpacity(
        getQuestionType(this.props.question.type).color,
        0.4
      ),
      borderColor: getQuestionType(this.props.question.type).color,
    };
  };

  getLikertCharts = () => {
    let labels = ["1", "2", "3", "4", "5"];
    if (
      this.props.question.useCustomLikertOptions &&
      this.props.question.customLikertOptions
    )
      labels = this.props.question.customLikertOptions;
    if (this.props.question.type !== "LIKERT") return null;
    return this.props.question.options.map((option, optionIdx) => {
      return (
        <ResultsChart
          key={`LIKERT-CHART-${this.props.question.questionId}-${optionIdx}`}
          title={option}
          loading={Boolean(!this.props.responses)}
          loadingColor={getQuestionType(this.props.question.type).color}
          chartType={"BAR"}
          conditions={true}
          chartDataFn={() =>
            getLikertChartData(this.props.responses, optionIdx, labels)
          }
          {...this.getBackgroundAndBorder()}
          question={this.props.question}
          openReportsPopup={this.props.openReportsPopup}
        />
      );
    });
  };

  addStopWord = () => {
    this.setState((prevState) => ({
      newStopWord: "",
      addedStopWords: [...prevState.addedStopWords, prevState.newStopWord],
    }));
  };

  render() {
    const { responses, question, entities } = this.props;
    return (
      <Grid id="question-analytics" container spacing={2}>
        {/* SELECTION CHART */}
        <ResultsChart
          key={`SELECTION-CHART-${question.questionId}`}
          title={"Selections"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"BAR"}
          conditions={
            question.type.includes("SELECTION") ||
            question.type.includes("DROPDOWN")
          }
          chartDataFn={() => getSelectionData(responses, question.options)}
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
        {/* EMOTION CHART */}
        <ResultsChart
          key={`EMOTION-CHART-${question.questionId}`}
          title={"Emotions"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"BAR"}
          conditions={question.doEmotion && isOpenEndType(question.type)}
          chartDataFn={() => getEmotionBarChartData(responses)}
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
        {/* SLIDER CHART */}
        <ResultsChart
          key={`SLIDER-CHART-${question.questionId}`}
          title={"Slider Responses"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"BAR"}
          conditions={
            question.type.includes("NUMBER") || question.type.includes("SLIDER")
          }
          chartDataFn={() => getSliderNumberChartData(responses)}
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
        {/* Likert Charts */}
        {this.getLikertCharts()}
        {/* Ranking Chart */}
        <ResultsChart
          key={`RANKING-CHART-${question.questionId}`}
          title={"Ranking Positions"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"BAR"}
          conditions={question.type.includes("RANKING")}
          chartDataFn={() => getAverageRank(responses, question.options)}
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
        {/* Sentiment Chart */}
        <ResultsChart
          key={`SENTIMENT-CHART-${question.questionId}`}
          title={"Sentiment"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"PIE"}
          conditions={
            question &&
            question.doSentiment &&
            isOpenEndType(question.type) &&
            !isTextType(question.type)
          }
          chartDataFn={() => getAverageSentimentPieChartData(responses)}
          backgroundColor={getTransparentChartColors(4, 0.4)}
          borderColor={getChartColors(4)}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
          additionalChartProps={{
            interactive: true,
            filterSentiment: (sentiment) =>
              this.props.updateFilters({ sentiment: sentiment }),
          }}
        />
        {/* Topic Graph */}
        <ResultsChart
          key={`TOPICS-CHART-${question.questionId}`}
          title={"Topics"}
          loading={Boolean(!responses || !entities)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"BAR"}
          conditions={entities && entities.length > 0}
          chartDataFn={() => getTopicChartData(this.props.topics)}
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
        {/* Word Cloud */}
        <ResultsChart
          key={`WORD-CLOUD-CHART-${question.questionId}`}
          title={"Word Cloud"}
          loading={Boolean(!responses)}
          loadingColor={getQuestionType(question.type).color}
          chartType={"WORD_CLOUD"}
          conditions={isAudioOrVideoType(question.type)}
          chartDataFn={() =>
            getWordCloudData(responses, this.state.addedStopWords)
          }
          {...this.getBackgroundAndBorder()}
          question={question}
          openReportsPopup={this.props.openReportsPopup}
        />
      </Grid>
    );
  }
}

export default QuestionAnalytics;

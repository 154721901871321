import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { strFromDate } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginTop: theme.spacing(1),
  },
  label: {
    color: "#507b82",
    "&.Mui-focused": {
      color: "#507b82",
    },
  },
  shrink: {
    fontSize: "1.25rem",
  },
  select: {
    "&:after": {
      borderColor: "#507b82",
    },
  },
}));

const DATE_RANGES = [
  { value: "1", label: "Past 1 day" },
  { value: "7", label: "Past 7 days" },
  { value: "30", label: "Past 30 days" },
];

const PickDate = ({ changeDate, initialDatesPresent }) => {
  const classes = useStyles();

  const [item, setItem] = useState(initialDatesPresent ? " " : "");

  const handleSelectChange = (evt) => {
    setItem(evt.target.value);

    let value = evt.target.value;
    if (value !== "") {
      const d = new Date();
      d.setDate(d.getDate() - parseInt(value, 10));
      value = strFromDate(d);
    }
    changeDate("startdate", value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="date-sort"
        shrink
        classes={{ root: classes.label, shrink: classes.shrink }}
      >
        Select date range
      </InputLabel>
      <Select
        id="date-sort"
        labelId="date-sort-label"
        value={item}
        onChange={handleSelectChange}
        displayEmpty
        className={classes.select}
      >
        <MenuItem value="">
          <em>Show all dates</em>
        </MenuItem>
        <MenuItem value=" " disabled />
        {DATE_RANGES.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PickDate;

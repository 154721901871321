import React from "react";
import clsx from "clsx";
import SplitChip from "components/SplitChip";
import {
  Button,
  Grid,
  Card,
  Checkbox,
  IconButton,
  TextField,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import Edit from "@material-ui/icons/Edit";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import SentimentBar from "components/SentimentBar";
import SelectSort from "components/Topics/SelectSort";
import TopicFilter from "components/Topics/TopicFilter";
import { OfflineBolt } from "@material-ui/icons";
import NewTopicTile from "components/Tiles/NewTopicTile";
import NewTopicPopup from "components/Popups/NewTopicPopup";
import DeletePopup from "components/Popups/DeletePopup";
import EditTopicPopup from "components/Popups/EditTopicPopup";
import MergeTopicPopup from "components/Popups/MergeTopicPopup";
import { connect } from "react-redux";
import {
  createTopic,
  deleteTopic,
  mergeTopic,
  aliasTopic,
  deleteTopics,
} from "redux/actions";
import { cloneDeep, isEqual } from "lodash";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassificationPopup from "components/Popups/ClassificationPopup";
import { withRouter } from "react-router";
import { track } from "analytics";
import { parseQuery } from "utils/utils";
import { removeItemFromArray } from "utils/utils";
import { stringifyQuery } from "utils/utils";

const TOPIC_SOURCE_TO_USER_READABLE_MAP = {
  AUTO: "Keyword",
  CLASSIFICATION: "Auto-Coding",
  MANUAL: "Manual",
};

const mapDispatchToProps = {
  createTopic,
  deleteTopic,
  deleteTopics,
  mergeTopic,
  aliasTopic,
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    numResponses:
      state.questionNumResponses &&
      state.questionNumResponses[ownProps.question._id],
    loading: state.loading["GeneralResponse"],
  };
};

class TopicsView extends React.Component {
  state = {
    topicPopupOpen: false,
    mergeTopicPopupOpen: false,
    mergingThemes: false,
    entities: [],
    shownEntities: [],
    selectedTopics: [],
    selectMultiple: false,
    topicFilters: {
      AUTO: false,
      MANUAL: true,
      CLASSIFICATION: true,
    },
  };

  componentDidMount() {
    this.setState({
      entities: cloneDeep(this.props.entities),
    });
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.entities, this.props.entities)) {
      this.setState({ entities: cloneDeep(this.props.entities) });
    }
  }

  /**
   * List of existing helper functions from GeneralResponse.jsx.
   */

  clickDeleteTopic = () => {
    this.props.deleteTopic(
      this.state.currentTopicId,
      this.props.match.params.questionId
    );
    this.handleDeleteTopic(this.state.currentTopicName);
  };

  createNewTopic = (name, responseId) => {
    this.props.createTopic(
      this.props.match.params.surveyId,
      this.props.match.params.questionId,
      name,
      responseId
    );
  };
  clickEditTopic = (evt, topicId) => {
    evt.stopPropagation();
    this.setState({ editTopicPopupOpen: true, currentTopicId: topicId });
  };
  toggleMultiSelect = () => {
    if (this.state.selectMultiple) {
      this.setState({ selectMultiple: false });
    } else {
      this.setState({ selectMultiple: true, selectedTopics: [] });
    }
  };
  clickTopic = (name, id) => {
    track("[Question Viewer] Selected Topic");
    if (this.state.selectMultiple) {
      this.toggleTopic(name, id);
    } else {
      this.filterOneTopic(name);
    }
  };
  toggleTopic = (name, id) => {
    const topicIds = this.state.selectedTopics.map((t) => t._id);
    const idx = topicIds.indexOf(id);
    if (idx !== -1) {
      this.setState({
        selectedTopics: removeItemFromArray(this.state.selectedTopics, idx),
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        selectedTopics: [...prevState.selectedTopics, { Text: name, _id: id }],
      }));
    }
  };
  filterOneTopic = (name) => {
    const query = parseQuery(this.props.location.search);
    delete query.page;
    this.setState({ shownEntities: [name] }, () => {
      this.props.history.push({
        search: stringifyQuery({ ...query, entity: this.state.shownEntities }),
      });
      this.props.toggleTopics();
    });
  };
  viewResponses = () => {
    if (this.state.selectMultiple) {
      const topics = this.state.selectedTopics.map((t) => t.Text);
      const { page, ...query } = parseQuery(this.props.location.search);
      this.props.history.push({
        search: stringifyQuery({
          ...query,
          entity: topics,
        }),
      });
      this.props.toggleTopics();
    }
  };

  handleSortChange = (evt) => {
    switch (evt.target.value) {
      case "POSITIVE":
        this.setState({
          entities: this.state.entities.sort((a, b) =>
            a.sentiment && b.sentiment
              ? b.sentiment.Positive - a.sentiment.Positive
              : 0
          ),
        });
        break;
      case "NEGATIVE":
        this.setState({
          entities: this.state.entities.sort((a, b) =>
            a.sentiment && b.sentiment
              ? b.sentiment.Negative - a.sentiment.Negative
              : 0
          ),
        });
        break;
      case "CONTROVERSIAL":
        this.setState({
          entities: this.state.entities.sort((a, b) => {
            if (!a.sentiment || !b.sentiment) return 0;
            const c = Math.abs(a.sentiment.Positive - a.sentiment.Negative);
            const d = Math.abs(b.sentiment.Positive - b.sentiment.Negative);
            return c - d;
          }),
        });
        break;
      case "MENTIONS":
        this.setState({
          entities: this.state.entities.sort((a, b) => {
            if (!a.mentions || !b.mentions) return 0;
            return b.mentions.length - a.mentions.length;
          }),
        });
        break;
      case "":
      default:
        this.setState({ entities: cloneDeep(this.props.entities) });
    }
  };
  handleDeleteTopic = (name) => {
    const { page, ...query } = parseQuery(this.props.location.search);
    const idx = this.state.shownEntities.indexOf(name);

    if (idx !== -1) {
      this.setState(
        {
          shownEntities: removeItemFromArray(this.state.shownEntities, idx),
        },
        () =>
          this.props.history.push({
            search: stringifyQuery({
              ...query,
              entity: this.state.shownEntities,
            }),
          })
      );
    }
  };
  updateTopicFilters = (prop, value) =>
    this.setState({
      topicFilters: {
        ...this.state.topicFilters,
        [prop]: value,
      },
    });

  topicFilter = (topic) => {
    if (topic.source === "MANUAL" && this.state.topicFilters.MANUAL)
      return true;
    if (topic.source === "AUTO" && this.state.topicFilters.AUTO) return true;
    if (
      topic.source === "CLASSIFICATION" &&
      this.state.topicFilters.CLASSIFICATION
    )
      return true;
    return false;
  };

  topicSearchFilter = (topic) => {
    if (!this.state.topicSearchText) return true;
    return (
      topic.Text &&
      topic.Text.toLowerCase().includes(
        this.state.topicSearchText.toLowerCase()
      )
    );
  };

  render() {
    if (!this.state.entities) return null;
    return (
      <>
        <MergeTopicPopup
          open={this.state.mergeTopicPopupOpen}
          closePopup={() => {
            this.setState({
              mergeTopicPopupOpen: false,
              selectedTopics: [],
              mergingThemes: false,
            });
          }}
          selectedTopics={this.state.selectedTopics}
          mergeTopics={this.props.mergeTopic}
          questionId={this.props.match.params.questionId}
        />
        <NewTopicPopup
          open={this.state.topicPopupOpen}
          closePopup={() => this.setState({ topicPopupOpen: false })}
          allTopics={this.props.entities}
          createNewTopic={this.createNewTopic}
        />
        <ClassificationPopup
          open={this.state.modelPickerOpen}
          surveyId={this.props.match.params.surveyId}
          questionId={this.props.question.questionId}
          onClose={() => this.setState({ modelPickerOpen: false })}
          getRandomResponse={this.props.getRandomResponse}
        />
        <Grid
          item
          container
          xs={12}
          display="flex"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <SelectSort handleChange={this.handleSortChange} />

            <TopicFilter
              topicFilters={this.state.topicFilters}
              updateTopicFilters={this.updateTopicFilters}
            />

            <Button
              variant="contained"
              className="secondary-button-color"
              onClick={this.toggleMultiSelect}
              startIcon={
                this.state.selectMultiple ? <DonutLargeIcon /> : <CheckIcon />
              }
              size="large"
            >
              {this.state.selectMultiple ? "Cancel" : "Select"}
            </Button>
          </Grid>
          {this.state.selectMultiple && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  className="secondary-button-color"
                  onClick={this.viewResponses}
                  startIcon={<DoneAllIcon />}
                  size="large"
                >
                  View
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ mergeTopicPopupOpen: true })}
                  startIcon={<MergeTypeIcon />}
                  size="large"
                  disabled={this.state.selectedTopics.length < 2}
                  className={clsx(
                    "secondary-button-color",
                    this.state.selectedTopics.length < 2 && "gray-button"
                  )}
                >
                  Merge
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    this.props.deleteTopics(
                      this.state.selectedTopics.map((topic) => topic._id),
                      this.props.match.params.questionId
                    )
                  }
                  startIcon={<DeleteIcon />}
                  size="large"
                  disabled={this.state.selectedTopics.length < 1}
                  className={clsx(
                    "secondary-button-color",
                    this.state.selectedTopics.length < 1 && "gray-button"
                  )}
                >
                  Delete
                </Button>
              </Grid>
            </>
          )}
          <Grid
            item
            align="center"
            style={{ marginLeft: "auto", order: 2, marginRight: -16 }}
          >
            <Button
              variant="outlined"
              className="phonic-outlined-button mr-10"
              onClick={() =>
                this.setState({
                  modelPickerOpen: true,
                })
              }
              startIcon={<OfflineBolt />}
            >
              Auto-Coding
            </Button>
            <Button
              variant="outlined"
              className="phonic-outlined-button mr-10"
              onClick={this.props.toggleTopics}
            >
              <FontAwesomeIcon icon={faUsers} />
              &nbsp;Show Responses
            </Button>
            <TextField
              variant="outlined"
              margin="dense"
              placeholder="Search Topics..."
              onChange={this.searchTopicsChanged}
              style={{ marginTop: 0, marginBottom: 0 }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <NewTopicTile
            handleClick={() => this.setState({ topicPopupOpen: true })}
          />

          {this.state.entities
            .filter(this.topicFilter)
            .filter(this.topicSearchFilter)
            .map((e, idx) => (
              <Grid key={`ENTITIES-${idx}`} item>
                <Card
                  elevation={
                    this.state.selectedTopics
                      .map((t) => t.Text)
                      .indexOf(e.Text) !== -1
                      ? 2
                      : 1
                  }
                  onClick={() => this.clickTopic(e.Text, e._id)}
                  className={`topic-card${
                    this.state.shownEntities.indexOf(e.Text) !== -1 &&
                    !this.state.mergingThemes
                      ? " pressed"
                      : ""
                  }`}
                >
                  {this.state.selectMultiple ? (
                    <div className="absolute-top-right">
                      <Checkbox
                        checked={
                          this.state.selectedTopics
                            .map((t) => t.Text)
                            .indexOf(e.Text) !== -1
                        }
                        onClick={(evt) => evt.stopPropagation()}
                        onChange={() => this.clickTopic(e.Text, e._id)}
                        disableRipple
                      />
                    </div>
                  ) : null}
                  {!this.state.selectMultiple && (
                    <div className="hover-wrapper">
                      <div className="absolute-bottom-right">
                        <IconButton
                          aria-label="edit"
                          onClick={(evt) => this.clickEditTopic(evt, e._id)}
                          style={{ marginLeft: "40px" }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.setState({
                              deletePopupOpen: true,
                              currentTopicId: e._id,
                              currentTopicName: e.Text,
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                  {this.state.mergingThemes ? (
                    <div className="absolute-top-right">
                      <Checkbox
                        checked={
                          this.state.selectedTopics
                            .map((e) => e._id)
                            .indexOf(e._id) !== -1
                        }
                        onClick={(evt) => evt.stopPropagation()}
                        onChange={() => this.collectTopic(e.Text, e._id)}
                        disableRipple
                      />
                    </div>
                  ) : null}
                  <h5 className="theme-name">{e.Text}</h5>
                  <p className="entity-type">
                    {e.Type ? e.Type.replace("_", " ") : null}
                  </p>
                  <SentimentBar score={e.sentiment} />
                  <div className="flex-container justify-left flex-wrap split-chip-margin">
                    <SplitChip left="mentions" right={e.mentions.length} />
                    <SplitChip
                      left="source"
                      right={TOPIC_SOURCE_TO_USER_READABLE_MAP[e.source]}
                    />
                  </div>
                </Card>
              </Grid>
            ))}
          <EditTopicPopup
            open={this.state.editTopicPopupOpen}
            closePopup={() =>
              this.setState({
                editTopicPopupOpen: false,
                currentTopicId: null,
              })
            }
            allTopics={this.props.entities}
            topicAction={this.props.aliasTopic}
            topicId={this.state.currentTopicId}
            questionId={this.props.match.params.questionId}
          />
          <DeletePopup
            open={this.state.deletePopupOpen}
            onClose={() =>
              this.setState({
                deletePopupOpen: false,
                currentTopicId: null,
                currentTopicName: null,
              })
            }
            title="Delete this topic?"
            subTitle="This topic will be removed from its responses."
            deleteFunction={this.clickDeleteTopic}
            buttonText="Delete"
          />
        </Grid>
        {this.state.entities && this.state.entities.length < 1 && (
          <i className="my-2 mx-auto">There are no topics.</i>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopicsView)
);

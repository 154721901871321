import React, { Component, createRef } from "react";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import FileDemographic from "./FileDemographic";
import ConversationEditor from "./ConversationEditor";
import FolderTile from "./FolderTile";
import { Alert, AlertTitle } from "@material-ui/lab";
import { truncateStringWithEllipsis, getDemographics } from "utils/utils";
import _ from "lodash";
import { readableDate } from "utils/utils";
import SplitChip from "components/SplitChip";
import UploadedMedia from "./UploadedMedia";

class FileViewer extends Component {
  state = { playbackTime: 0 };

  mediaPlayerRef = createRef();

  getTitle = () => {
    if (!this.props.file) return "Conversations";
    return truncateStringWithEllipsis(this.props.file.name, 40);
  };
  updatePlaybackTime = (time) => {
    this.setState({ playbackTime: time });
  };
  seekTime = (time) => {
    if (this.mediaPlayerRef.current) {
      this.mediaPlayerRef.current.seek(parseFloat(time));
    }
  };

  showDemographics = () => {
    return this.props.file.type !== "FOLDER";
  };

  saveDemographic = (demProps) => {
    if (demProps.name) {
      var splitName = demProps.name.split(" ");
      if (splitName.length === 1) {
        demProps.firstName = splitName[0];
        demProps.lastName = "";
      } else {
        demProps.firstName = splitName[0];
        demProps.lastName = _.tail(splitName).join(" "); // All other space-seperated names are combined into lastName
      }
      delete demProps.name;
    }
    if (this.props.file._id) {
      this.props.updateFile(
        this.props.file._id, 
        {
          demographics: { ...this.props.file.demographics, ...demProps },
        }, 
        this.props.file.path
      );
    } else {
      alert("Unable to update demographics. Please refresh and try again.");
    }
  };

  saveTranscription = (transcriptionItems) => {
    this.props.updateFile(
      this.props.file._id,
      {
        transcription_items: transcriptionItems,
      },
      this.props.file.path
    );
  };

  getTimeStamps = () => {
    let ti = this.props.file.features.transcription_items;
    if (!ti || ti.length === 0) return { startTime: 0 };
    return {
      startTime: ti[0] && ti[0].start_time ? ti[0].start_time : 0,
      endTime:
        ti[ti.length - 1] && ti[ti.length - 1].end_time
          ? ti[ti.length - 1].end_time
          : undefined,
    };
  };

  toggleEditingDemographics = () => {
    this.setState({ editingDemographics: !this.state.editingDemographics });
  };

  render() {
    var childFolders = [],
      childFiles = [];

    let fileChildren = null;

    if (this.props.file && this.props.file.children) {
      fileChildren = Object.values(this.props.file.children);
      childFolders = fileChildren.filter((c) => c.type === "FOLDER");
      childFiles = fileChildren.filter((c) => c.type !== "FOLDER");
    }

    const demographics =
      (this.props.file &&
        this.props.file.demographics &&
        getDemographics(this.props.file.demographics, true, true)) ||
      [];

    return (
      <div>
        <h1 className="file-title">{this.getTitle()}</h1>
        {this.props.file && (
          <h3 className="file-title">
            {readableDate(this.props.file.createdAt)}
          </h3>
        )}
        {!this.props.file && (
          <div className="neutral-alert">
            <i>No file selected</i>
          </div>
        )}

        {this.props.file &&
          this.props.file.type === "FOLDER" &&
          fileChildren &&
          fileChildren.length === 0 && (
            <div className="neutral-alert">
              <i>This folder is empty</i>
            </div>
          )}

        {this.props.file && this.props.file.type === "FOLDER" && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3>
                Folders
                <span className="file-viewer-count">
                  [{childFolders.length}]
                </span>
              </h3>
            </Grid>
            {childFolders.map((c, idx) => {
              const children = Object.values(c.children);
              return (
                <Grid item key={`FOLDER-TILE-${idx}`}>
                  <FolderTile
                    onClick={() => {
                      this.props.handleSelectClick(c);
                    }}
                    title={c.name}
                    date={c.createdAt}
                    comment={c.path}
                    numFolders={
                      children.filter((f) => f.type === "FOLDER").length
                    }
                    numFiles={
                      children.filter((f) => f.type !== "FOLDER").length
                    }
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <h3>
                Files
                <span className="file-viewer-count">[{childFiles.length}]</span>
              </h3>
            </Grid>
            {childFiles.map((c, idx) => {
              const children = Object.values(c.children);
              return (
                <Grid item key={`FILE-TILE-${idx}`}>
                  <FolderTile
                    onClick={() => {
                      this.props.handleSelectClick(c);
                    }}
                    title={c.name}
                    date={c.createdAt}
                    comment={c.path}
                    numFolders={
                      children.filter((f) => f.type === "FOLDER").length
                    }
                    numFiles={
                      children.filter((f) => f.type !== "FOLDER").length
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}

        {this.props.file && (
          <Grid container spacing={3}>
            <Grid item xs={8}>
              {this.props.file.status === "PENDING" ? (
                <Alert severity="info">
                  <AlertTitle>Upload in progress</AlertTitle>
                  Please check back later.
                </Alert>
              ) : (
                this.props.file.uri &&
                this.props.file.type && (
                  <UploadedMedia
                    ref={this.mediaPlayerRef}
                    type={this.props.file.type}
                    uri={this.props.file.uri}
                    onTimeUpdate={this.updatePlaybackTime}
                  />
                )
              )}
            </Grid>
            <Grid item xs={4}>
              {this.showDemographics() && (
                <div className="demographics-wrapper">
                  <h4 className="mt-1">
                    About{" "}
                    <FontAwesomeIcon
                      className="edit-icon"
                      icon={this.state.editingDemographics ? faCheck : faEdit}
                      onClick={this.toggleEditingDemographics}
                    />
                  </h4>
                  {this.state.editingDemographics
                    ? getDemographics(
                        this.props.file.demographics,
                        true,
                        false
                      ).map((d, i) => (
                        <FileDemographic
                          {...d}
                          key={`FILE-DEMOGRAPHIC-${i}`}
                          save={this.saveDemographic}
                        />
                      ))
                    : demographics.length > 0
                    ? demographics.map((d, i) => (
                        <SplitChip
                          key={`FILE-DEMOGRAPHIC-${i}`}
                          left={d.label}
                          right={d.value}
                          color="#595c62"
                        />
                      ))
                    : "No speaker info available."}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              {this.props.file.features &&
                this.props.file.features.transcription_items &&
                this.props.file.features.transcription_items.length > 0 && (
                  <ConversationEditor
                    transcriptionItems={
                      this.props.file.features.transcription_items
                    }
                    speakerLabels={this.props.file.features.speaker_labels}
                    numSpeakers={this.props.file.numSpeakers}
                    time={this.state.playbackTime}
                    onWordClick={this.seekTime}
                    saveTranscription={this.saveTranscription}
                  />
                )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default FileViewer;

import React from "react";
import { Grid, LinearProgress, Button } from "@material-ui/core";

export default class UsageTile extends React.Component {
  render() {
    // This is the number shown in the linear progress bar
    const shownNumSurveys =
      this.props.surveyLimit !== -1 ? this.props.numSurveys : 0;
    // This determines if the survey limit is shown
    const showSurveyLimit = this.props.surveyLimit !== -1;
    return (
      <Grid
        item
        onClick={this.routeToCreation}
        className="main-survey-tile usage"
      >
        <div style={{ display: "inline-block" }}>
          <h3 style={{ fontSize: 20 }}>{`${
            this.props.monthly ? "Monthly Usage" : "Free Tier Usage"
          }`}</h3>
          <p
            className={`usage ${
              this.props.surveyLimit !== -1 &&
              shownNumSurveys >= this.props.surveyLimit
                ? "over"
                : ""
            }`}
          >
            <b>Surveys</b>
          </p>
          <LinearProgress
            variant="determinate"
            value={Math.min(
              (shownNumSurveys * 100) / this.props.surveyLimit,
              100
            )}
            className={`usage ${
              shownNumSurveys >= this.props.surveyLimit ? "over" : ""
            }`}
          />
          <p
            className={`usage ${
              this.props.surveyLimit !== -1 &&
              this.props.shownNumSurveys >= this.props.surveyLimit
                ? "over"
                : ""
            }`}
          >
            {this.props.numSurveys}
            {`${showSurveyLimit ? `/${this.props.surveyLimit} ` : " "}`}
            created
          </p>
          <p
            className={`usage ${
              this.props.numResponses >= this.props.responseLimit ? "over" : ""
            }`}
          >
            <b>Responses {this.props.monthly && "This Month"}</b>
          </p>
          <LinearProgress
            variant="determinate"
            value={Math.min(
              (this.props.numResponses / this.props.responseLimit) * 100,
              100
            )}
            className={`usage ${
              this.props.numResponses >= this.props.responseLimit ? "over" : ""
            }`}
          />
          <p
            className={`usage ${
              this.props.numResponses >= this.props.responseLimit ? "over" : ""
            }`}
          >
            {Math.max(0, this.props.numResponses)}/{this.props.responseLimit}{" "}
            received
          </p>
          {!this.props.monthly && (
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              className="usage"
              onClick={this.props.openUpgradePopup}
            >
              Upgrade
            </Button>
          )}
        </div>
      </Grid>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, Grid } from "@material-ui/core";
import { trackPage } from "../analytics";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import { setPageTitle } from "redux/actions";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const FEEDBACK_SURVEY_URL = "https://survey.phonic.ai/5f62e72601792c3849faa1f3";

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(setPageTitle(title)),
  };
};

class Support extends Component {
  componentDidMount() {
    trackPage("Support");
    this.props.setPageTitle();
  }

  state = {
    expanded: null,
  };

  handleChange = (panel) => {
    if (panel === this.state.expanded) {
      // Collapse all divs.
      this.setState({ expanded: null });
    } else {
      this.setState({ expanded: panel });
    }
  };

  supportQuestions = [
    {
      question: "What types of questions are best for audio responses?",
      answer:
        'We\'ve found that open-ended questions generally yield great audio responses, for example "How does this slogan make you feel?" Questions with non-trivial answers are also great candidates for audio surveys. Listening to the respondent pause and sort through their thoughts can be very revealing.',
    },
    {
      question: "How long should my survey be?",
      answer:
        "Survey length can vary depending on subject, but typical responses vary from 10 seconds to 2 minutes in length. We recommend surveys with less than 10 questions.",
    },

    {
      question: "Can Phonic find people to answer my survey?",
      answer:
        "Phonic integrates with existing Panel providers such as Amazon Mechanical Turk and Prolific. Use the completion link in settings to insert an MTurk code or Prolific completion link to your survey.",
    },
    {
      question:
        "Can I download the audio recordings once the survey is completed?",
      answer:
        "Premium users can download all audio and metadata in CSV format.",
    },
    {
      question: "Can I track different groups of people or run an A/B test?",
      answer:
        "At the moment Phonic does not support tracking multiple cohorts. If this is something that would be useful to you, please reach out to our team.",
    },
    {
      question:
        "How soon after a response is collected will it be available in my dashboard?",
      answer:
        "All responses, transcripts, and analytics should appear within a few seconds of the respondent completing the survey.",
    },
    {
      question: "What do I get with Premium?",
      answer:
        "Free users are limited to 3 surveys with 10 questions each and 25 total survey responses.  Premium users can create unlimited surveys per month with unlimited questions and 200 total survey responses per month. Premium users can also export their data to CSV, download raw audio files, access integrations, and get richer data analytics.",
    },
  ];

  render() {
    return (
      <>
        <Helmet>
          <title>Support</title>
        </Helmet>
        <div className="content">
          <div className="screen-margin support-content-wrapper">
            <Paper>
              <div className="support-container">
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <h3 className="support-header">Need some help?</h3>
                </Grid>
                <Grid xs={12}>
                  <p className="support-text">
                    If you can't find what you're looking for in our Frequently
                    Asked Questions or Knowledge Base, don't hesitate to reach
                    out!
                  </p>
                </Grid>
                <Grid xs={12}>
                  <p className="support-text-box">
                    email us at: <b>contact@phonic.ai</b>
                  </p>
                </Grid>
              </div>
            </Paper>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper style={{ marginTop: 15 }}>
                  <div className="support-container">
                    <h3 className="support-header">
                      Frequently Asked Questions
                    </h3>
                  </div>

                  {this.supportQuestions.map((element, idx) => {
                    return (
                      <Accordion
                        key={`FAQ-${idx}`}
                        square
                        expanded={this.state.expanded === idx}
                        onClick={this.handleChange.bind(this, idx)}
                      >
                        <AccordionDetails
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <h2 className="audience-header">
                            {element.question}
                          </h2>
                        </AccordionDetails>
                        <AccordionDetails>
                          <p className="faq-response">{element.answer}</p>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper>
                      <div
                        style={{ marginTop: 15 }}
                        className="support-container"
                      >
                        <Grid>
                          <h3 className="support-header">Knowledge Base</h3>
                        </Grid>
                        <Grid>
                          <p className="support-text">
                            Supporting documentation to help you find solutions
                            and learn more about the platform
                          </p>
                        </Grid>
                        <Grid>
                          <div>
                            <Button
                              className="knowledgebase-button secondary-button-color"
                              variant="contained"
                              href="https://docs.phonic.ai/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Let's Go!
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <div
                        style={{ marginTop: 15 }}
                        className="support-container"
                      >
                        <Grid>
                          <h3 className="support-header">Feedback</h3>
                        </Grid>
                        <Grid>
                          <p className="support-text">
                            Is Phonic missing some features? Let us know what
                            you think of the platform the best way possible.
                          </p>
                        </Grid>
                        <Grid>
                          <div>
                            <Button
                              className="knowledgebase-button secondary-button-color"
                              variant="contained"
                              href={FEEDBACK_SURVEY_URL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Let's go!
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(Support);

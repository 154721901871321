import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeItemFromArray } from "utils/utils";

class TriggerItem extends React.Component {
  updateTrigger = (t) => {
    this.setState(t);
    this.props.updateTrigger(t);
  };

  render() {
    return (
      <div id="logic-container">
        <div className="remove-button">
          <FontAwesomeIcon icon={faTimes} onClick={this.props.deleteTrigger} />
        </div>
        <div className="logic-line">
          <div className="logic-text">When</div>
          <Select
            value={this.props.event}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) => this.updateTrigger({ event: e.target.value })}
          >
            <MenuItem value="SURVEY_COMPLETED">A survey is completed</MenuItem>
          </Select>
        </div>
        <div className="logic-line">
          <div className="logic-text">Then</div>
          <Select
            value={this.props.type}
            variant="outlined"
            margin="dense"
            className="logic-short"
            onChange={(e) => this.updateTrigger({ type: e.target.value })}
          >
            <MenuItem value="email">Send an email to</MenuItem>
          </Select>
          {this.props.type === "email" && (
            <TextField
              variant="outlined"
              margin="dense"
              className="logic-long"
              value={this.props.email}
              placeholder="ada@email.com"
              onChange={(e) => this.updateTrigger({ email: e.target.value })}
            />
          )}
        </div>
      </div>
    );
  }
}

const DEFAULT_TRIGGER_OBJ = { type: "email", event: "SURVEY_COMPLETED" };
class EmailNotificationsPopup extends React.Component {
  state = { triggers: [], populated: false };
  componentDidUpdate() {
    if (
      this.props.survey &&
      this.props.survey.triggers &&
      this.state.populated === false
    )
      this.setState({ triggers: this.props.survey.triggers, populated: true });
  }
  updateTrigger = (update, idx) => {
    const newTriggers = Object.assign([...this.state.triggers], {
      [idx]: {
        ...this.state.triggers[idx],
        ...update,
      },
    });
    this.setState({ triggers: newTriggers });
  };
  deleteTrigger = (idx) => {
    this.setState({
      triggers: removeItemFromArray(this.state.triggers, idx),
    });
  };
  cancel = () => {
    if (this.props.survey && this.props.survey.triggers)
      this.setState({ triggers: this.props.survey.triggers });
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullwidth
        aria-labelledby="add-to-report"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container">
          <div id="form-dialog-title" style={{ marginBottom: 20 }}>
            <h2 className="dialog-title-small" style={{ marginBottom: 10 }}>
              Email Notifications
            </h2>
            <p>
              Automatically recieve an email when someone starts or completes a
              survey.
            </p>
          </div>
          {this.state.triggers.map((t, idx) => (
            <TriggerItem
              key={`TRIGGER-ITEM-${idx}`}
              {...t}
              updateTrigger={(update) => {
                this.updateTrigger(update, idx);
              }}
              deleteTrigger={() => {
                this.deleteTrigger(idx);
              }}
            />
          ))}
          <DialogActions>
            <Button
              className="secondary-button-color"
              variant="contained"
              onClick={() => {
                this.setState({
                  triggers: [
                    ...this.state.triggers,
                    { ...DEFAULT_TRIGGER_OBJ },
                  ],
                });
              }}
            >
              + Add Notification
            </Button>
            <div className="flex-grow-basis-1" />
            <Button
              variant="outlined"
              component="label"
              onClick={this.cancel}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                this.props.saveTriggers(this.state.triggers);
                this.props.onClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default EmailNotificationsPopup;

import React from "react";
import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PanelFeedback from "components/Feedback/PanelFeedback";
import { submitFeedback } from "redux/actions";
import { connect } from "react-redux";
import PanelTutorial from "components/Feedback/PanelTutorial";

const mapDispatchToProps = {
  submitFeedback,
};

class FeedbackPopup extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.loading === true && this.props.loading === false)
      this.props.onClose();
  }
  handleClose = () => {
    this.props.submitFeedback({ type: this.props.feedbackNotif.type });
    this.props.onClose();
  };
  getFeedbackForm = () => {
    switch (this.props.feedbackNotif.type) {
      case "PANEL":
        return (
          <PanelFeedback
            submitFeedback={this.props.submitFeedback}
            loading={this.props.loading}
          />
        );
      case "PANEL_TUTORIAL":
        return (
          <PanelTutorial
            proceed={this.handleClose}
            loading={this.props.loading}
          />
        );
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        fullWidth
        maxWidth={this.props.maxWidth ? this.props.maxWidth : "sm"}
        className="popup-container"
      >
        <div className="x-button">
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {this.props.feedbackNotif && this.getFeedbackForm()}
      </Dialog>
    );
  }
}
export default connect(undefined, mapDispatchToProps)(FeedbackPopup);

import React from "react";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";

class UpgradeThankYou extends React.Component {
  routeToSurveys = () => {
    this.props.history.push("/surveys");
  };

  render() {
    return (
      <div
        component={"main"}
        style={{ backgroundColor: "#66C9B1", height: "100vh" }}
      >
        <Container maxWidth={"sm"} style={{ paddingTop: "12vh" }}>
          <Paper style={{ padding: 36 }}>
            <Typography variant="h5" color="textPrimary" align="center">
              <span role="img" aria-label="Party">
                🎉{" "}
              </span>
              Thanks for Upgrading
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center">
              Enjoy access to our Premium tier.
            </Typography>
            <div
              style={{
                marginTop: "24px",
                marginBottom: "24px",
                display: "block",
                textAlign: "center",
              }}
            >
              <img
                src={require("../assets/img/upgrade-thank-you.svg")}
                style={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  width: "200px",
                }}
                alt="thank you for upgrading"
              />
            </div>
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              <Button variant="contained" onClick={this.routeToSurveys}>
                Return To Dashboard
                <span style={{ width: 4 }} />
                <ArrowForwardIcon />
              </Button>
            </div>
          </Paper>
          <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://phonic.ai/">
                Phonic Inc.
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }
}

export default withRouter(UpgradeThankYou);

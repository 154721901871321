import React from "react";
import _ from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRight from "@material-ui/icons/ArrowRight";

import PropCheckbox from "components/SurveyCreation/PropCheckbox";
import { supportedLanguages } from "utils/data";
import { removeItemFromArray } from "utils/utils";

import FileUpload from "../Files/FileUpload";

class FileUploadPopup extends React.Component {
  state = {
    type: "VIDEO",
    doTranscription: true,
    transcriptionLanguage: "en-US",
    numSpeakers: 1,
    files: [],
  };

  getAcceptedFiles() {
    if (this.state.type === "VIDEO") return "video/*";
    else if (this.state.type === "AUDIO")
      return "audio/mp3,audio/*;capture=microphone";
    return "*";
  }

  fileInputChanged = (input) => {
    var newFiles = [];
    // Files use special collections that are most easily iterated over with lodash
    _.forEach(input.target.files, (f) => {
      newFiles.push(f);
    });
    this.setState({ files: [...this.state.files, ...newFiles] });
  };

  removeFile = (fileIdx) => {
    this.setState({ files: removeItemFromArray(this.state.files, fileIdx) });
  };

  uploadFile = (file) => {
    this.props.uploadFile(
      {
        type: this.state.type,
        fileName: file.name,
        path: this.props.selectedFile.path + "/" + file.name,
        collection: this.props.selectedFile.collection,
        doTranscription: this.state.doTranscription,
        transcriptionLanguage: this.state.transcriptionLanguage,
        numSpeakers: this.state.numSpeakers,
      },
      file
    );
  };

  uploadAllFiles = () => {
    this.state.files.forEach((f) => {
      this.uploadFile(f);
    });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({files: []});
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="x-button">
          <IconButton aria-label="close" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="popup-container small">
          <h2 className="dialog-title-small" style={{ marginBottom: 20 }}>
            Upload Conversations
          </h2>
          {this.props.selectedFile && (
            <p style={{ marginBottom: 20 }}>
              Adding new files to<b> /{this.props.selectedFile.path}</b>
            </p>
          )}
          <Grid container>
            <Grid item xs={8} style={{ paddingRight: 8 }}>
              <Typography variant={"body1"}>Conversation Type</Typography>
              <Select
                fullWidth
                variant="outlined"
                value={this.state.type}
                onChange={(e) => this.setState({ type: e.target.value })}
              >
                <MenuItem value={"AUDIO"}>Audio</MenuItem>
                <MenuItem value={"VIDEO"}>Video</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Number of Speakers</Typography>
              <Select
                fullWidth
                variant="outlined"
                value={this.state.numSpeakers}
                onChange={(e) => this.setState({ numSpeakers: e.target.value })}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                  <MenuItem key={`NUM-SPEAKERS-${i}`} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex", margin: "10px 0" }}>
            <PropCheckbox
              label={{
                text: "Enable Computer Transcription",
                placement: "right",
              }}
              update={(e) =>
                this.setState({ doTranscription: e.target.checked })
              }
              value={this.state.doTranscription}
            />
            <Select
              variant="outlined"
              margin="dense"
              value={this.state.transcriptionLanguage}
              className="language-selector"
              disabled={!this.state.doTranscription}
              onChange={(e) =>
                this.setState({ transcriptionLanguage: e.target.value })
              }
            >
              {supportedLanguages.map((l) => {
                return (
                  <MenuItem key={l.code} value={l.code}>
                    {l.text}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <FileUpload
            className="file-upload-popup"
            onChange={this.fileInputChanged}
            inputProps={{ multiple: "true", accept: this.getAcceptedFiles() }}
          />
          {this.state.files.length > 0 && (
            <List subheader={<ListSubheader>Uploads</ListSubheader>}>
              {this.state.files.map((f, i) => {
                return (
                  <ListItem
                    key={`FILE-UPLOAD-${i}`}
                    role={undefined}
                    dense
                    button
                  >
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary={f.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="remove-file"
                        onClick={() => {
                          this.removeFile(i);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          )}
          <DialogActions>
            <Button
              variant="outlined"
              component="label"
              onClick={this.handleClose}
              className="phonic-outlined-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={this.state.files.length === 0}
              component="label"
              onClick={this.uploadAllFiles}
              color="primary"
            >
              Upload
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}
export default FileUploadPopup;

import React from "react"
import {
    TextField,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"
import {
    faTimes,
    faPlusSquare,
    faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { insertItemIntoArray, removeItemFromArray } from "utils/utils";

const styles = () => ({
    numericInput: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
})


class RandomConditions extends React.Component {
    state = {
        randomConditions: [],
    }

    componentDidMount() {
        this.setState({ randomConditions: this.props.randomConditions })
    }
    updateCondition = (conditionIndex, newCondition) => {
        const newConditions = [...this.state.randomConditions]
        newConditions[conditionIndex] = newCondition
        this.setState({ randomConditions: newConditions })
        this.props.changeRandomConditionInfo(newConditions)
    }
    propagateStateToParent = () => {
        this.props.changeRandomConditionInfo(
            this.state.randomConditions
        )
    }
    deleteCondition = (conditionIndex) => {
        this.setState(
            {
                randomConditions: removeItemFromArray(this.state.randomConditions, conditionIndex),
            },
            this.propagateStateToParent
        );
    }
    addCondition = () => {
        const newCondition = { condition: '', values: [{ value: '', weight: 0 }] }
        this.setState({ randomConditions: [...this.state.randomConditions, newCondition] }, this.propagateStateToParent)
    }
    updateQuestionPropWithTimedUpdate = (conditions) => {
        if (this.state.updateTimeout) {
            clearTimeout(this.state.updateTimeout);
        }
        this.setState({
            randomConditions: conditions,
            updateTimeout: setTimeout(() => {
                this.props.changeRandomConditionInfo(conditions);
            }, 500),
        });
    };

    render() {
        const { classes } = this.props
        return (
            <>
                {this.state.randomConditions &&
                    this.state.randomConditions.map((condition, conditionIndex) => {
                        return (
                            <div key={`CONDITION_${conditionIndex}`} id="translation-container">
                                <div className="remove-button">
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => this.deleteCondition(conditionIndex)}
                                    />
                                </div>
                                <div className="translation-line">
                                    <TextField
                                        value={condition.condition}
                                        variant="outlined"
                                        margin="dense"
                                        className="translation-long"
                                        placeholder="Condition"
                                        onChange={(e) => {
                                            const newCondition = { ...condition }
                                            newCondition.condition = e.target.value
                                            this.updateCondition(conditionIndex, newCondition)
                                        }}
                                    />
                                </div>
                                {condition.values.map(({ value, weight }, valueIndex) => (
                                    <div
                                        key={`VALUE_${valueIndex}`}
                                        className="translation-line"
                                    >
                                        <TextField
                                            value={value}
                                            variant="outlined"
                                            margin="dense"
                                            className="translation-flex"
                                            placeholder="Value"
                                            key="value"
                                            onChange={(e) => {
                                                const newCondition = { ...condition };
                                                newCondition.values[valueIndex].value = e.target.value;
                                                this.updateCondition(conditionIndex, newCondition);
                                            }}
                                        />
                                        <TextField
                                            value={weight}
                                            variant="outlined"
                                            margin="dense"
                                            className={`${classes.numericInput} translation-flex`}
                                            placeholder="Weight"
                                            key="weight"
                                            type="number"
                                            onChange={(e) => {
                                                const newCondition = { ...condition };
                                                newCondition.values[valueIndex].weight = e.target.value
                                                this.updateCondition(conditionIndex, newCondition);
                                            }}
                                        />
                                        <div className="selection-options-updown add-translation-lang">
                                            <FontAwesomeIcon
                                                icon={faPlusSquare}
                                                className="add-response"
                                                onClick={() => {
                                                    this.updateCondition(conditionIndex, {
                                                        ...condition,
                                                        values: [...insertItemIntoArray(
                                                            condition.values,
                                                            { value: "", weight: 0 },
                                                            valueIndex + 1
                                                        )]
                                                    });
                                                }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faMinusSquare}
                                                className="add-response"
                                                onClick={() => {
                                                    if (condition.values.length <= 1) return; // Need at least 1 translation
                                                    this.updateCondition(conditionIndex, {
                                                        ...condition,
                                                        values: removeItemFromArray(
                                                            condition.values,
                                                            valueIndex
                                                        ),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}

                            </div>

                        );
                    })}
                <Button
                    className="gray-outlined-button"
                    style={{ width: "100%" }}
                    variant="outlined"
                    onClick={this.addCondition}
                >
                    + Add Condition
        </Button>
            </>
        )
    }
}

export default withStyles(styles)(RandomConditions)

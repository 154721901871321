import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function SquareChip({ text, onDelete }) {
  return (
    <div className="phonic-square-chip">
      <div className="phonic-square-chip-text">{text}</div>
      <div className="delete-icon" onClick={onDelete}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
}

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Logic from "components/SurveyCreation/Logic";
import { Button, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { getBlankLogicBlock } from "utils/defaults";
import { isSelectionNatureStrict } from "utils/utils";

class CreateLogicPopup extends React.Component {
  state = {
    showSkipLogic: false,
  };
  addLogic = () => {
    this.props.changeQInfo(
      "logic",
      this.props.qinfo.logic
        ? this.props.qinfo.logic.concat([getBlankLogicBlock()])
        : [getBlankLogicBlock()]
    );
  };
  toggleOptionSkipLogic = () => {
    this.setState({
      showSkipLogic: !this.state.showSkipLogic,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="popup-container">
          <DialogTitle id="form-dialog-title">
            <h2>Question Logic</h2>{" "}
            <p>
              Add conditional logic to your survey. See{" "}
              <a
                href="https://docs.phonic.ai/docs/skip-logic"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </a>{" "}
              for help.
            </p>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 30 }}>
            <>
              {!this.state.showSkipLogic ? (
                <>
                  <Logic
                    questions={this.props.questions}
                    qinfo={this.props.qinfo}
                    changeQInfo={this.props.changeQInfo}
                  />
                  <Button
                    className="secondary-button-color"
                    variant="contained"
                    onClick={this.addLogic}
                    style={{ marginRight: 10 }}
                  >
                    + Add Logic
                  </Button>
                </>
              ) : (
                <div style={{ marginBottom: 20 }}>
                  {this.props.qinfo.options.map((option, idx) => {
                    return (
                      <Grid
                        key={`LOGIC-SKIP-OPTION-${idx}`}
                        container
                        spacing={2}
                      >
                        <Grid item xs={8}>
                          <TextField
                            variant="filled"
                            value={option.text}
                            disabled
                            margin="dense"
                            style={{
                              width: "100%",
                              margin: 0,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            variant="outlined"
                            margin="dense"
                            className="option-fullwidth"
                            value={option.skip}
                            onChange={(e) =>
                              this.props.changeQuestionOption(
                                idx,
                                "skip",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem key={"QUESTION-" + idx} value={0}>
                              Next Question
                            </MenuItem>
                            <MenuItem key={"QUESTION-" + idx} value={-1}>
                              End Survey
                            </MenuItem>
                            {this.props.questions.map((q, idx) => {
                              if (idx <= this.props.idx) return null;
                              return (
                                <MenuItem
                                  key={"QUESTION-" + idx}
                                  value={q.questionId}
                                >
                                  Q{idx + 1}. {q.text}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )}
              {isSelectionNatureStrict(this.props.qinfo.type) && (
                <Button
                  variant="outlined"
                  onClick={this.toggleOptionSkipLogic}
                  className="Mui-Overrides font-dark"
                >
                  {!this.state.showSkipLogic ? (
                    <span>Selection Logic</span>
                  ) : (
                    <span>Conditions</span>
                  )}
                </Button>
              )}
              <Button
                className="Mui-Overrides font-dark"
                variant="outlined"
                style={{ float: "right" }}
                onClick={this.props.handleClose}
              >
                Save
              </Button>
            </>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
export default CreateLogicPopup;

import React, { useState } from "react";

import { Button, Dialog, DialogContent, TextField } from "@material-ui/core";
import { ClipLoader } from "react-spinners";

export default function CreatePanelRequestPopup({
  open,
  onClose,
  loading,
  createPanelRequest,
}) {
  const [name, setName] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    createPanelRequest(name);
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent className="popup-container">
        <h2>New Recruit Campaign</h2>
        <p className="dialog-text">
          Give your recruit request a name for future reference.
        </p>
        <form onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            autoFocus
            id="name"
            type="name"
            required
            placeholder="Young Canadian Chess Players..."
            fullWidth
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ marginBottom: 16, marginTop: 8, float: "right" }}
          >
            {!loading ? (
              "Let's Recruit!"
            ) : (
              <ClipLoader size={21} color={"white"} />
            )}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

import React, { useCallback } from "react";
import { PositionerPortal, useExtension, usePositioner } from "@remirror/react";
import { EventsExtension } from "remirror/extensions";
import {
  Positioner,
  blockNodePositioner,
} from "@remirror/extension-positioner";
import { nodeType } from "utils/transcription";

const CURSOR_PADDING = 1;

const getPlaybackNode = (doc, time) => {
  let playbackNode = undefined;
  let previousNode = undefined;

  doc.descendants((node, pos) => {
    // Stop looking through nodes when playback node is found
    if (playbackNode) return false;

    // If node is pronunciation item, check if it is the playback node
    if (node.attrs.type === nodeType.PRONUNCIATION) {
      if (
        time >= parseFloat(node.attrs.startTime) &&
        time < parseFloat(node.attrs.endTime)
      ) {
        playbackNode = { node, pos };
        /**
         * If time is between previous node and current node, set playback node
         * to previous node. Merged items in the editor do not have start and
         * end times stitched together correctly (whereas updated
         * transcription items do), so this gives the illusion of correct
         * cursor placement until refresh. This avoids the expensive
         * calculation of the editor value with each transcription update.
         */
      } else if (
        previousNode &&
        time >= parseFloat(previousNode.node.attrs.endTime) &&
        time < parseFloat(node.attrs.startTime)
      ) {
        playbackNode = previousNode;
      } else {
        previousNode = { node, pos };
      }
    }
  });

  return playbackNode;
};

const timePositioner = (time) =>
  blockNodePositioner.clone(() => ({
    hasChanged: () => true,
    getActive: (props) => {
      const playbackNode = getPlaybackNode(props.state.doc, time);
      return playbackNode ? [playbackNode] : Positioner.EMPTY;
    },
  }));

const Cursor = ({ time, onWordClick }) => {
  const {
    ref,
    width,
    height,
    x: left,
    y: top,
    active,
  } = usePositioner(timePositioner(time), [time]);

  const handleClick = useCallback(
    (_, data) => {
      const item = data.getNode("item");
      if (item && onWordClick) {
        onWordClick(item.node.attrs.startTime);
      }
      return true;
    },
    [onWordClick]
  );

  useExtension(
    EventsExtension,
    ({ addHandler }) => addHandler("click", handleClick),
    [handleClick]
  );

  return (
    <PositionerPortal>
      <span
        ref={ref}
        className="floating-cursor"
        style={{
          width: width + CURSOR_PADDING * 2,
          height: height + CURSOR_PADDING * 2,
          top: top - CURSOR_PADDING,
          left: left - CURSOR_PADDING,
        }}
      />
    </PositionerPortal>
  );
};

export default Cursor;

import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const BlockMenu = ({ deleteBlock, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (index) => {
    deleteBlock(index);
    setAnchorEl(null);
  };

  return (
    <>
      <span className="top-hamburger">
        <div onClick={handleClick} className="rounded-icon-container">
          <FontAwesomeIcon
            icon={faEllipsisV}
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="survey-menu-icon"
          />
        </div>
      </span>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDelete(index)}>Delete Block</MenuItem>
      </Menu>
    </>
  );
};

export default BlockMenu;

import React, { Component } from "react";
import { connect } from "react-redux";
import { track, trackPage } from "analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";
import Waveform from "components/Waveform";
import { Grid, Paper, Button, Link, Breadcrumbs } from "@material-ui/core";
import { ScaleLoader } from "react-spinners";
import {
  getResponse,
  updateResponse,
  getUser,
  getSurveys,
  setPageTitle,
} from "../redux/actions";
import {
  isAudioType,
  isVideoType,
  hasImageExtension,
  isAudioOrVideoType,
  getDemographicsBlock,
  truncateStringWithEllipsis,
} from "utils/utils";
import PieChart from "../components/Graphs/PieChart";
import StackedArea from "../components/Graphs/StackedArea";
import RadarChart from "../components/Graphs/RadarChart";
import {
  getAverageSentimentPieChartData,
  getEmotionLineChartData,
  getAverageEmotionChartData,
} from "../utils/graphData";
import VideoPlayer from "../components/VideoPlayer";
import ConversationEditor from "components/Files/ConversationEditor";
import PlainTextEditor from "components/PlainTextEditor";

const getResponseMap = (state, ownProps) => {
  var s = {};
  if (state.surveys) {
    s.survey = state.surveys[ownProps.match.params.surveyId];
    if (s.survey && s.survey.questions) {
      for (let i = 0; i < s.survey.questions.length; ++i) {
        const q = s.survey.questions[i];
        if (q.questionId === ownProps.match.params.questionId) {
          s.question = q;
          s.questionIdx = i;
          break;
        }
      }
    }
  }
  if (state.user) s.user = state.user;
  return s;
};

const mapStateToProps = (state, ownProps) => {
  return {
    response: state.response,
    user: state.user,
    authToken: state.authToken,
    ...getResponseMap(state, ownProps),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getResponse: (id) => dispatch(getResponse(id)),
    getSurveys: () => dispatch(getSurveys()),
    getUser: () => dispatch(getUser()),
    updateResponse: (responseId, responseData) =>
      dispatch(updateResponse(responseId, responseData)),
    setPageTitle: (title) => dispatch(setPageTitle(title)),
  };
};

class PlayAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptSignIn: false,
      editingTranscription: false,
      editingTranslation: false,
      playbackTime: 0,
      localTranscriptionItems: undefined,
      localTranslation: "",
    };
    this.props.getSurveys();
    this.props.getResponse(this.props.match.params.responseId);
    this.props.getUser();
  }

  componentDidMount() {
    trackPage("Viewed Response", this.props.match.params.responseId);
    setTimeout(() => {
      this.setState({ promptSignIn: true });
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    // Fetch responses again once the auth token is fetched
    if (prevProps.authToken !== this.props.authToken) {
      this.props.getResponse(this.props.match.params.responseId);
      this.props.getUser();
    }
    this.props.setPageTitle(this.getBreadCrumbs());
  }

  canEdit = () => {
    if (!this.props.user || this.props.user.readOnly || !this.props.response) {
      return false;
    }
    const surveyId = this.props.response.surveyId;
    for (let i = 0; i < this.props.user.surveys.length; i++) {
      if (this.props.user.surveys[i] === surveyId) return true;
    }
    if (Array.isArray(this.props.user.sharedSurveys)) {
      for (let i = 0; i < this.props.user.sharedSurveys.length; i++) {
        if (
          this.props.user.sharedSurveys[i].surveyId === surveyId &&
          this.props.user.sharedSurveys[i].permissions.write === true
        ) {
          return true;
        }
      }
    }
    return false;
  };

  translationExists = () => {
    if (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.translations &&
      this.props.response.features.translations["en"]
    ) {
      return true;
    }
    return false;
  };

  downloadAudio = () => {
    if (
      !this.props.user ||
      (this.props.user.tier !== "PREMIUM" &&
        this.props.user.tier !== "ENTERPRISE")
    ) {
      alert("Please upgrade to Premium to use this feature.");
      return;
    }
    if (!this.props.response || !this.props.response.uri) {
      alert("Audio not available. Please try again.");
      return;
    }
    track("Audio Downloaded");
    let url = this.props.response.uri;
    let a = document.createElement("a");
    a.href = url;
    a.download =
      this.props.response._id +
      (isAudioType(this.props.response.type)
        ? this.props.response.audioPath.split(".").slice(-1).pop()
        : isVideoType(this.props.response.type)
        ? this.props.response.videoPath.split(".").slice(-1).pop()
        : this.props.response.filePath.split(".").slice(-1).pop());
    a.click();
  };

  editTranscription = () => {
    this.setState({
      editingTranscription: true,
      localTranscriptionItems: this.props.response.features.transcription_items,
    });
  };

  editTranslation = () => {
    this.setState({
      editingTranslation: true,
      localTranslation: this.props.response.features.translations["en"],
    });
  };

  debounceAction = (action) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      updateTimeout: setTimeout(() => {
        action();
      }, 500),
    });
  };

  saveTranscription = () => {
    const key = Array.isArray(this.state.localTranscriptionItems)
      ? "transcription_items"
      : typeof this.state.localTranscriptionItems === "string"
      ? "transcription"
      : null;

    if (key) {
      this.props.updateResponse(this.props.response._id, {
        [key]: this.state.localTranscriptionItems,
      });
    }

    this.setState({ editingTranscription: false });
  };

  saveTranslation = () => {
    this.props.updateResponse(this.props.response._id, {
      translations: { en: this.state.localTranslation },
    });
    this.setState({ editingTranslation: false });
  };

  updateLocalTranscriptionItems = (updatedTranscriptionItems) => {
    this.setState({ localTranscriptionItems: updatedTranscriptionItems });
  };

  updateLocalTranslation = (updatedTranslation) => {
    this.setState({ localTranslation: updatedTranslation });
  };

  getFeaturesJSON = () => {
    var json = Object.assign({}, this.props.response.features); // Copy object
    delete json.transcription;
    return json;
  };

  updatePlaybackResponse = (time) => {
    this.setState({ playbackTime: time });
  };

  getMedia = () => {
    if (!this.props.response || !this.props.response.uri)
      return "No Media Found.";
    return (
      <>
        {isAudioType(this.props.response.type) && (
          <Waveform
            interactiveTranscription={true}
            updatePlaybackResponse={(time) => this.updatePlaybackResponse(time)}
            src={this.props.response.uri}
            playTime={this.state.skipTime ? this.state.skipTime : 0}
            height={50}
            key={`PlayAudio-Waveform-${this.props.match.params.responseId}-${this.props.response.uri}`}
          />
        )}
        {isVideoType(this.props.response.type) && (
          <div className="flex-container">
            <VideoPlayer
              uri={this.props.response.uri}
              responseId={this.props.response._id}
              onTimeUpdate={(time) => this.updatePlaybackResponse(time)}
              skipTime={this.state.skipTime ? this.state.skipTime : 0}
            />
          </div>
        )}
        {this.props.response.type === "FILE" &&
          (hasImageExtension(this.props.response.filePath.split(".")[1]) ? (
            <div className="flex-container full-width">
              <img
                src={this.props.response.uri}
                style={{ maxWidth: "100%", maxHeight: 600 }}
                alt="preview"
              ></img>
            </div>
          ) : (
            <div>No preview available. Please download the file to view.</div>
          ))}
      </>
    );
  };

  showEmotionCharts = () => {
    return (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.Faces
    );
  };

  showEmotionWarnings = () => {
    return (
      this.props.response &&
      this.props.response.features &&
      this.props.response.features.FaceAttributes &&
      this.props.response.features.FaceAttributes.Warnings &&
      this.props.response.features.FaceAttributes.Warnings.length > 0
    );
  };

  onWordClick = (e, item) => {
    e.stopPropagation();
    this.setState({
      skipTime: parseFloat(item.start_time),
    });
  };

  seekTime = (newTime) => {
    this.setState({ skipTime: parseFloat(newTime) });
  };

  getBreadCrumbs = () => {
    if (
      this.props.match.params.surveyId &&
      this.props.match.params.questionId &&
      this.props.survey
    ) {
      return (
        <div className="breadcrumbs-title">
          <Breadcrumbs aria-label="breadcrumb">
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() => this.props.history.push("/surveys")}
              >
                Surveys
              </Link>
            )}
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() =>
                  this.props.history.push(
                    `/surveys/${this.props.match.params.surveyId}`
                  )
                }
              >
                {truncateStringWithEllipsis(this.props.survey.name, 36)}
              </Link>
            )}
            {this.props.survey && (
              <Link
                color="inherit"
                onClick={() =>
                  this.props.history.push(
                    `/surveys/${this.props.match.params.surveyId}/questions/${this.props.match.params.questionId}`
                  )
                }
              >{`Question ${this.props.questionIdx + 1}`}</Link>
            )}
            {this.props.survey && <Link color="inherit">{"Response"}</Link>}
          </Breadcrumbs>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="content">
        <Grid container className="screen-margin" spacing={2}>
          {this.props.response && this.props.response.error === undefined ? (
            <>
              <Grid item xs={8}>
                <h1 className="page-title">Response</h1>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    float: "right",
                    marginRight: "10px",
                    lineHeight: "48px",
                  }}
                >
                  {this.props.response && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => {
                        this.downloadAudio();
                      }}
                      className="secondary-button-color"
                      disabled={
                        this.props.user
                          ? this.props.user.tier === "FREE" ||
                            this.props.user.readOnly
                          : true
                      }
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; File
                    </Button>
                  )}
                </div>
              </Grid>
              <Paper style={{ width: "100%" }}>
                <Grid container id="play-information" spacing={2}>
                  <Grid item xs={12} md={8}>
                    <h3>Media</h3>
                    <div className="media">{this.getMedia()}</div>
                    {isAudioOrVideoType(this.props.response.type) && (
                      <>
                        <h3>Transcription</h3>
                        {this.canEdit() && (
                          <>
                            {this.state.editingTranscription ? (
                              <Tooltip
                                title="Save Transcription"
                                placement="right"
                              >
                                <IconButton
                                  aria-label="save"
                                  onClick={this.saveTranscription}
                                >
                                  <Check />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Edit Transcription"
                                placement="right"
                              >
                                <IconButton
                                  aria-label="edit"
                                  onClick={this.editTranscription}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {this.props.response.features &&
                        this.props.response.features.transcription_items ? (
                          <ConversationEditor
                            key={this.props.response._id}
                            transcriptionItems={
                              this.props.response.features.transcription_items
                            }
                            time={this.state.playbackTime}
                            onWordClick={this.seekTime}
                            saveTranscription={
                              this.updateLocalTranscriptionItems
                            }
                            readOnly={!this.state.editingTranscription}
                          />
                        ) : (
                          <PlainTextEditor
                            key={this.props.response._id}
                            transcriptionString={
                              this.props.response.features.transcription
                            }
                            saveTranscription={
                              this.updateLocalTranscriptionItems
                            }
                            readOnly={!this.state.editingTranscription}
                          />
                        )}
                      </>
                    )}
                    {this.canEdit() && this.translationExists() && (
                      <>
                        {this.state.editingTranslation ? (
                          <Tooltip title="Save Translation" placement="right">
                            <IconButton
                              aria-label="save"
                              onClick={this.saveTranslation}
                            >
                              <Check />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Edit Translation">
                            <IconButton
                              aria-label="edit"
                              onClick={this.editTranslation}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {this.translationExists() &&
                      Object.values(
                        this.props.response.features.translations
                      ).map((translation) => (
                        <PlainTextEditor
                          key={this.props.response._id}
                          transcriptionString={translation}
                          saveTranscription={this.updateLocalTranslation}
                          readOnly={!this.state.editingTranslation}
                          className="font-bold"
                        />
                      ))}
                    {this.props.response && this.props.response.data && (
                      <div className="mt-10">
                        <h3>Other Response Data</h3>
                        <pre>
                          {JSON.stringify(
                            this.props.response.data,
                            undefined,
                            2
                          )}
                        </pre>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <h3>Information</h3>
                    {getDemographicsBlock(this.props.response)}
                    {this.props.response.features &&
                      this.props.response.features.sentiment && (
                        <PieChart
                          key={`PIE-${this.props.response._id}`}
                          chartData={getAverageSentimentPieChartData([
                            this.props.response,
                          ])}
                        />
                      )}
                  </Grid>
                  {this.showEmotionCharts() && (
                    <>
                      <Grid item xs={12}>
                        <h3>Facial Expressions</h3>
                        {this.showEmotionWarnings() && (
                          <Grid item xs={12} md={6}>
                            <Alert severity="warning">
                              Some issues were detected with the video that may
                              reduce accuracy:
                              {this.props.response.features.FaceAttributes.Warnings.map(
                                (warningCode, i) => {
                                  if (
                                    warningCode.type ===
                                    "DOMINANT_FACE_WAS_NOT_FOUND"
                                  )
                                    return (
                                      <li key={warningCode}>
                                        There were multiple faces detected
                                      </li>
                                    );
                                  else if (warningCode.type === "FACE_MISSING")
                                    return (
                                      <li key={warningCode}>
                                        The person leaves the video frame
                                      </li>
                                    );
                                  return (
                                    <li key={`${warningCode}-${i}`}>
                                      Unspecified warning
                                    </li>
                                  );
                                }
                              )}
                            </Alert>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <StackedArea
                          chartData={getEmotionLineChartData(
                            this.props.response.features.Faces
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <RadarChart
                          chartData={getAverageEmotionChartData(
                            this.props.response.features.Faces
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </>
          ) : (
            <div className="prompt-container-indash">
              {!this.state.promptSignIn ? (
                <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
              ) : (
                <div>
                  <h3>You don't have access to this response.</h3>
                  <p>
                    <a href="/signin">Click here to sign in.</a>
                  </p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayAudio);

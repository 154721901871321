import React from "react";
import Editor from "./Editor";

const PlainTextEditor = ({
  transcriptionString,
  readOnly,
  saveTranscription,
  className,
}) => {
  const updateTranscription = (doc) => {
    const content = doc.textContent;

    if (saveTranscription) {
      saveTranscription(content);
    }
  };
  return (
    <Editor
      initialValue={transcriptionString}
      readOnly={readOnly}
      updateTranscription={updateTranscription}
      className={className}
    />
  );
};

export default PlainTextEditor;

import {
  faHome,
  faPollH,
  faVideo,
  faComments,
  faHeading,
  faFolder,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs, Link } from "@material-ui/core";
import Waveform from "components/Waveform";
import React, { Component } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { ClipLoader, ScaleLoader } from "react-spinners";
import { getSurveys, getResponses, getFiles } from "redux/actions";
import { getQuestionType } from "utils/questionTypes";
import {
  isAudioOrVideoType,
  truncateStringWithEllipsis,
  isAudioType,
  isVideoType,
  pushItemOntoArray,
} from "utils/utils";
import VerticalAlignCenter from "@material-ui/icons/VerticalAlignCenter";
import { isTitleOrFolderType } from "utils/utils";
import { getBlankTitleBlock } from "utils/defaults";

const INFINITE_SCROLL_PAGE_SIZE = 20;

const mapStateToProps = (state) => {
  return {
    surveys: state.surveys,
    responses: state.responses,
    files: state.files,
    user: state.user,
  };
};

const mapDispatchToProps = {
  getSurveys,
  getResponses,
  getFiles,
};

const getItemStyle = (style, snapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
};

function ContentViewerListItem({ name, action, icon, isMedia }) {
  return (
    <div>
      <div
        className={`showreels-content-list-item ${isMedia ? "" : "non-media"}`}
        onClick={() => {
          if (!isMedia) return;
          action();
        }}
      >
        <FontAwesomeIcon icon={icon} /> {truncateStringWithEllipsis(name, 50)}
      </div>
      <hr />
    </div>
  );
}

function ContentViewerTileMedia({ data, clone }) {
  if (isVideoType(data.type))
    return (
      <>
        <div className="showreels-content-tile-layover"></div>
        {!clone ? (
          <video
            className="showreels-content-video"
            key={`VIDEO-${data._id}`}
            id={`VIDEO-${data._id}`}
            controls={false}
          >
            <source src={data.uri} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="showreels-content-video flex-container clone">
            <FontAwesomeIcon icon={faVideo} />
          </div>
        )}
      </>
    );
  if (isAudioType(data.type) && data.uri)
    return (
      <div className="showreels-content-audio-container">
        <div className="showreels-content-audio">
          <Waveform src={data.uri} height={50} smaller />
        </div>
        {data.demographics && (
          <>
            {(data.demographics.firstName || data.demographics.lastName) && (
              <div>
                <p>
                  <b>Name</b>: {data.demographics.firstName}{" "}
                  {data.demographics.lastName}
                </p>
              </div>
            )}
            {data.demographics.email && (
              <div>
                <p>
                  <b>Email</b>: {data.demographics.email}
                </p>
              </div>
            )}
            {data.features && data.features.transcription && (
              <div>
                <p>
                  <b>Transcript</b>:{" "}
                  {truncateStringWithEllipsis(data.features.transcription)}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    );
  if (isTitleOrFolderType(data.type))
    return (
      <div className="content-medium flex-container flex-column">
        <VerticalAlignCenter style={{ marginBottom: 5 }} />
        Center
      </div>
    );
  return null;
}

function ContentViewerTile({
  idx,
  data,
  draggableId,
  contentDragStart,
  content,
  contentClone,
}) {
  if (data.type === "media" && !data.uri) return null;
  return (
    <Draggable draggableId={draggableId} index={idx} isDropDisabled={true}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(provided.draggableProps.style, snapshot)}
            className="showreels-content-tile"
            onMouseDown={() => contentDragStart(data)}
          >
            {content}
          </div>
          {snapshot.isDragging && (
            <div className="showreels-content-tile">{contentClone}</div>
          )}
          {provided.placeholder}
        </>
      )}
    </Draggable>
  );
}

class ContentViewer extends Component {
  state = {
    screen: "mediums",
    responses: undefined,
    currentFiles: null,
    fileLinkedList: null,
  };
  componentDidMount() {
    this.props.getSurveys();
    this.props.getFiles();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.responses !== this.props.responses &&
      this.props.responses !== undefined &&
      this.state.question &&
      this.state.question.questionId
    ) {
      this.setState({
        numResponsesToShow: INFINITE_SCROLL_PAGE_SIZE,
        responses: this.props.responses[this.state.question.questionId].slice(
          0,
          INFINITE_SCROLL_PAGE_SIZE
        ),
      });
    }
    if (!this.state.currentFiles && this.props.files) {
      this.setState({ currentFiles: this.props.files, fileLinkedList: [] });
    }
  }
  getNextResponses = () => {
    setTimeout(() => {
      const numResponsesToShow =
        this.state.numResponsesToShow + INFINITE_SCROLL_PAGE_SIZE;
      const responses = this.props.responses[this.state.question.questionId];
      this.setState({
        numResponsesToShow: numResponsesToShow,
        responses: responses.slice(0, numResponsesToShow),
      });
    }, 200);
  };

  getBreadcrumbs = () => {
    return (
      <div className="showreels-content-breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link
            color="inherit"
            onClick={() => {
              this.setState({
                screen: "mediums",
                survey: undefined,
                question: undefined,
              });
            }}
          >
            <FontAwesomeIcon icon={faHome} />
          </Link>
          {this.state.screen === "titles" && (
            <Link color="inherit">Titles</Link>
          )}
          {(this.state.screen === "surveys" || Boolean(this.state.survey)) && (
            <Link
              color="inherit"
              onClick={() => {
                this.setState({ screen: "surveys", question: undefined });
              }}
            >
              Surveys
            </Link>
          )}
          {this.state.survey && (
            <Link
              color="inherit"
              onClick={() => {
                this.setState({ screen: "questions", question: undefined });
              }}
            >
              {truncateStringWithEllipsis(this.state.survey.name)}
            </Link>
          )}
          {this.state.question && (
            <Link
              color="inherit"
              onClick={() => {
                this.setState({ screen: "responses" });
              }}
            >
              {truncateStringWithEllipsis(this.state.question.text)}
            </Link>
          )}
          {this.state.screen === "files" && (
            <Link
              color="inherit"
              onClick={() => {
                this.setState({
                  screen: "files",
                  currentFiles: this.props.files,
                  fileLinkedList: [],
                });
              }}
            >
              Conversations
            </Link>
          )}
          {this.state.screen === "files" &&
            this.state.fileLinkedList &&
            this.state.fileLinkedList.map((f, idx) => {
              return (
                <Link
                  key={`FILE-${idx}`}
                  onClick={() => {
                    this.setState({
                      currentFiles: f.children,
                      fileLinkedList: this.state.fileLinkedList.slice(
                        0,
                        idx + 1
                      ),
                    });
                  }}
                >
                  {f.name}
                </Link>
              );
            })}
        </Breadcrumbs>
      </div>
    );
  };

  getContentLoader = () => {
    return (
      <div className="full-width flex-container">
        <ClipLoader size={24} color={"black"} />
      </div>
    );
  };

  getContent = () => {
    if (this.state.screen === "mediums") {
      return (
        <div id="content-scroller" className="showreels-content-tile-container">
          <div
            className="content-medium flex-container flex-column"
            onClick={() => {
              this.setState({
                screen: "surveys",
                survey: undefined,
                question: undefined,
              });
            }}
          >
            <span className="content-medium-logo">
              <FontAwesomeIcon icon={faPollH} />
            </span>
            Surveys
          </div>
          {this.props.user && this.props.user.showConversations && (
            <div
              className="content-medium flex-container flex-column"
              onClick={() => {
                this.setState({
                  screen: "files",
                  currentFiles: this.props.files,
                });
              }}
            >
              <span className="content-medium-logo">
                <FontAwesomeIcon icon={faComments} />
              </span>
              Conversations
            </div>
          )}
          <div
            className="content-medium flex-container flex-column"
            onClick={() => {
              this.setState({
                screen: "titles",
              });
            }}
          >
            <span className="content-medium-logo">
              <FontAwesomeIcon icon={faHeading} />
            </span>
            Titles
          </div>
        </div>
      );
    } else if (this.state.screen === "files") {
      if (this.state.currentFiles) {
        return (
          <>
            <div>
              {this.state.currentFiles
                .filter((f) => {
                  return f.type === "FOLDER";
                })
                .map((f, idx) => {
                  return (
                    <ContentViewerListItem
                      key={`FILE-LIST-${f.path}`}
                      name={f.name}
                      action={() => {
                        this.setState({
                          currentFiles: f.children,
                          fileLinkedList: pushItemOntoArray(
                            this.state.fileLinkedList,
                            f
                          ),
                        });
                      }}
                      icon={f.type === "FOLDER" ? faFolder : faFile}
                      isMedia={true}
                    />
                  );
                })}
            </div>
            <div className="showreels-content-tile-container">
              {this.state.currentFiles
                .filter((f) => {
                  return f.type !== "FOLDER";
                })
                .map((f, idx) => {
                  return (
                    <ContentViewerTile
                      key={`CONTENT-TILE-${f._id}`}
                      data={{ fileId: f._id, type: f.type, response: f }}
                      draggableId={f._id}
                      idx={idx}
                      contentDragStart={this.props.contentDragStart}
                      content={<ContentViewerTileMedia data={f} />}
                      contentClone={<ContentViewerTileMedia data={f} clone />}
                    />
                  );
                })}
            </div>
          </>
        );
      }
    } else if (this.state.screen === "titles") {
      return (
        <div id="content-scroller" className="showreels-content-tile-container">
          <ContentViewerTile
            idx={0}
            data={getBlankTitleBlock()}
            draggableId="TITLE-CENTER"
            contentDragStart={this.props.contentDragStart}
            content={
              <div className="content-medium flex-container flex-column">
                <VerticalAlignCenter style={{ marginBottom: 5 }} />
                Center
              </div>
            }
            contentClone={
              <div className="content-medium flex-container flex-column">
                <VerticalAlignCenter style={{ marginBottom: 5 }} />
                Center
              </div>
            }
          />
        </div>
      );
    } else if (this.state.screen === "responses") {
      if (
        this.state.responses &&
        this.state.question &&
        this.state.question.questionId &&
        this.props.responses[this.state.question.questionId] &&
        this.props.responses[this.state.question.questionId].length > 0
      ) {
        return (
          <InfiniteScroll
            dataLength={this.state.responses.length}
            next={this.getNextResponses}
            hasMore={
              this.state.responses.length <
              this.props.responses[this.state.question.questionId].length
            }
            loader={this.getContentLoader()}
            className="showreels-content-tile-container"
            scrollableTarget="showreels-content"
            style={{ overflow: "hidden" }}
          >
            {this.state.responses.map((r, idx) => {
              return (
                <ContentViewerTile
                  idx={idx}
                  key={`CONTENT-TILE-${r._id}`}
                  data={{ responseId: r._id, type: r.type, response: r }}
                  draggableId={r._id}
                  contentDragStart={this.props.contentDragStart}
                  content={<ContentViewerTileMedia data={r} />}
                  contentClone={<ContentViewerTileMedia data={r} clone />}
                />
              );
            })}
          </InfiniteScroll>
        );
      } else if (
        this.props.responses &&
        this.props.responses[this.state.question.questionId] &&
        this.props.responses[this.state.question.questionId].length === 0
      ) {
        return <p style={{ marginTop: 20 }}>No responses found...</p>;
      } else {
        return <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />;
      }
    } else if (this.state.screen === "questions") {
      return (
        <div id="content-scroller">
          {Object.values(
            this.props.surveys[this.state.survey._id].questions
          ).map((q) => {
            return (
              <ContentViewerListItem
                key={`QUESTION-LIST-${q.questionId}`}
                name={q.text}
                action={() => {
                  this.setState({ question: q, screen: "responses" }, () => {
                    this.props.getResponses(
                      this.state.survey._id,
                      q.questionId
                    );
                  });
                }}
                icon={getQuestionType(q.type).icons[0]}
                isMedia={isAudioOrVideoType(q.type)}
              />
            );
          })}
        </div>
      );
    } else {
      return this.props.surveys ? (
        <div id="content-scroller">
          {Object.values(this.props.surveys).map((s) => {
            return (
              <ContentViewerListItem
                key={`SURVEY-LIST-${s._id}`}
                name={s.name}
                action={() => {
                  this.setState({ survey: s, screen: "questions" });
                }}
                icon={faPollH}
                isMedia={true}
              />
            );
          })}
        </div>
      ) : (
        <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
      );
    }
  };

  render() {
    return (
      <Droppable droppableId="CONTENT" direction="horizontal">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {this.getBreadcrumbs()}
            {this.getContent()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentViewer);

import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { matchPath } from "react-router";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import routes from "routes.js";
import { addAuthToken } from "redux/actions";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import ReactNotification from "react-notifications-component";
import SignInPrompt from "layouts/SignInPrompt";
import firebase from "firebase/app";
import NotFound from "layouts/NotFound";
import theme from "../theme";

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAuthToken: () => dispatch(addAuthToken()),
  };
};

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "black",
      fixedClasses: "dropdown show-dropdown open",
    };
  }

  // Each route can either be authenticated or not
  getRoutes = (routes) => {
    let availableRoutes = routes;
    const premium =
      this.props.user &&
      (this.props.user.tier === "PREMIUM" ||
        this.props.user.tier === "ENTERPRISE");

    if (!premium) {
      availableRoutes = availableRoutes.filter((route) => !route.premium);
    }

    return availableRoutes.map((prop) => {
      return (
        <Route
          exact={
            !prop.matchNonExactly
          } /* The default is to be exact, unless otherwise specified */
          path={prop.path}
          render={(props) => <prop.component {...props} />}
          key={prop.key}
        />
      );
    });
  };

  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  componentDidMount() {
    firebase
      .app()
      .auth()
      .onAuthStateChanged((user) => {
        if (user) this.props.addAuthToken();
      });

    // Refresh auth token every 45 minutes.
    var authIntervalId = setInterval(() => {
      this.props.addAuthToken();
    }, 45 * 60 * 1000);
    this.setState({ authIntervalId: authIntervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.authIntervalId);
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  isRouteAuthenticated = () => {
    var authenticated = true;
    routes.forEach((r) => {
      if (
        matchPath(this.props.location.pathname, r.path) &&
        r.authenticated === false
      )
        authenticated = false;
    });
    return authenticated;
  };

  render() {
    // Auth token must be loaded before anything else loads, since many auth requests depend on it.
    if (
      this.isRouteAuthenticated() &&
      (!this.props.authToken || !this.props.isSignedIn || !this.props.user)
    ) {
      return <SignInPrompt {...this.props} />;
    }

    const freeRoutes = routes.filter((route) => !route.premium);
    const premium =
      this.props.user &&
      (this.props.user.tier === "PREMIUM" ||
        this.props.user.tier === "ENTERPRISE");

    return (
      <ThemeProvider theme={theme}>
        <div className="wrapper">
          <ReactNotification />
          {(!this.props.user || !this.props.user.readOnly) && (
            <Sidebar
              {...this.props}
              routes={premium ? routes : freeRoutes}
              image={this.state.image}
              color={this.state.color}
              hasImage={this.state.hasImage}
            />
          )}

          <div
            id="main-panel"
            className={`main-panel ${
              this.props.user && this.props.user.readOnly ? "readonly" : ""
            }`}
          >
            <AdminNavbar {...this.props} />
            <Switch>
              {this.getRoutes(routes)}
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));

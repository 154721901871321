import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ThemeProvider } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import LinkTextField from "components/Text/LinkTextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ReportError from "views/ReportError";
import Loading from "views/Loading";
import Header from "components/Header";
import Footer from "components/Footer/Footer";
import Editable from "components/Report/Editable";
import ContentBlock from "components/Report/ContentBlock";
import NewBlockPopup from "components/Popups/NewBlockPopup";
import AddBookmarkPopup from "components/Popups/AddBookmarkPopup";
import PoweredByPhonic from "components/PoweredBy";
import {
  startLoading,
  getReportBuilder,
  updateReport,
  setPageTitle,
} from "redux/actions";
import {
  copyToClipboard,
  notification,
  reorder,
  getObjectId,
  truncateStringWithEllipsis,
} from "utils/utils";
import reportTheme from "reportTheme";

const mapStateToProps = (state) => {
  return {
    report: state.report,
    reportError: state.reportError,
    loading: state.loading["BuildReport"],
  };
};

const mapDispatchToProps = {
  startLoading,
  getReportBuilder,
  updateReport,
  setPageTitle,
};

const EditableBlock = React.memo(function EditableBlock({
  item,
  i,
  editMarkdown,
  deleteBlock,
  changeReport,
}) {
  return (
    <ContentBlock
      content={item}
      editable
      index={i}
      editMarkdown={editMarkdown}
      deleteBlock={deleteBlock}
      changeReport={changeReport}
    />
  );
});

class BuildReport extends Component {
  state = {
    title: null,
    blocks: [],
    imageUrl: null,
    popupOpen: false,
    bookmarkBrowserOpen: false,
  };

  componentDidMount() {
    this.props.startLoading(
      () => this.props.getReportBuilder(this.props.match.params.reportId),
      "BuildReport"
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report) {
      this.setState({
        title: this.props.report.name,
        blocks: this.props.report.contents,
        imageUrl: this.props.report.imageUrl,
      });
    }

    this.props.setPageTitle(
      <div className="breadcrumbs-title">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => this.props.history.push("/reports")}
          >
            Reports
          </Link>
          {this.state.title && (
            <Link color="inherit">
              {truncateStringWithEllipsis(this.state.title, 36)}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  editMarkdown = (markdown, index) => {
    this.setState((prevState) => {
      let newBlocks = [...prevState.blocks];
      newBlocks[index] = { ...newBlocks[index], markdown };

      return {
        ...prevState,
        blocks: newBlocks,
      };
    });
  };

  deleteBlock = (index) => {
    this.setState((prevState) => {
      const newBlocks = [
        ...prevState.blocks.slice(0, index),
        ...prevState.blocks.slice(index + 1),
      ];

      return {
        ...prevState,
        blocks: newBlocks,
      };
    });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  setBookmarkBrowser = (open) => {
    this.setState({ bookmarkBrowserOpen: open });
  };

  addMarkdown = () => {
    this.setState((prevState) => ({
      ...prevState,
      blocks: [
        ...prevState.blocks,
        {
          type: "MARKDOWN",
          markdown: "",
          blockId: getObjectId(),
        },
      ],
      popupOpen: false,
    }));
  };

  changeReport = (options) => {
    return this.props
      .updateReport(
        this.props.match.params.reportId,
        {
          prop: "name",
          data: this.state.title,
        },
        this.state.blocks
      )
      .then(() => {
        if (!options || !options.suppressNotify) {
          notification(
            "Successfully edited report.",
            "Report updated.",
            "success"
          );
        }
      })
      .catch(() => {
        notification("Unable to edit report.", "Please try again.", "warning");
      });
  };

  onDragEnd = (result) => {
    if (!result.destination) return;

    const newBlocks = reorder(
      this.state.blocks,
      result.source.index,
      result.destination.index
    );
    this.setState({ blocks: newBlocks });
  };

  linkClick = (evt) => {
    evt.stopPropagation();
    copyToClipboard(
      `${window.location.origin}/reports/${this.props.match.params.reportId}`
    );
    notification(
      "Link Copied to Clipboard.",
      "You may now share this report.",
      "success"
    );
  };

  render() {
    const { match, reportError, loading } = this.props;

    return (
      <>
        <Helmet>
          <title>Create Report</title>
        </Helmet>
        <div className="content create-report-wrapper">
          {/* HEADER */}
          <div className="b-row">
            <div className="b-col-6">
              <h1 className="page-title">Build Report</h1>
            </div>
            <div className="b-col-6">
              <span style={{ float: "right" }}>
                <LinkTextField
                  id={match.params.reportId}
                  label="Report Link"
                  link={`${window.location.origin}/reports/${match.params.reportId}`}
                  onClick={(evt) => this.linkClick(evt, match.params.reportId)}
                />
              </span>
            </div>
          </div>
          <NewBlockPopup
            open={this.state.popupOpen}
            closePopup={this.closePopup}
            setBookmarkBrowser={this.setBookmarkBrowser}
            addMarkdown={this.addMarkdown}
          />
          <AddBookmarkPopup
            open={this.state.bookmarkBrowserOpen}
            setBookmarkBrowser={this.setBookmarkBrowser}
            reportId={match.params.reportId}
            changeReport={this.changeReport}
          />
          {/* REPORT */}
          <ThemeProvider theme={reportTheme}>
            <CssBaseline />
            {reportError && <ReportError />}
            <div className="report-wrapper">
              <div className="report-header-padding">
                <Header imageUrl={this.state.imageUrl} />
              </div>
              {!reportError && loading ? (
                <Loading />
              ) : (
                <>
                  <Container component="article" maxWidth="md">
                    <Typography component="h1" variant="h2" gutterBottom>
                      <Editable
                        text={this.state.title}
                        placeholder={
                          this.state.title === null ? "" : "No title"
                        }
                      >
                        <TextField
                          id="title"
                          label="Report name"
                          placeholder="Write a title"
                          variant="outlined"
                          value={this.state.title}
                          onChange={(evt) =>
                            this.setState({ title: evt.target.value })
                          }
                          fullWidth
                          autoFocus
                        />
                      </Editable>
                    </Typography>
                    {/* CONTENTS */}
                    <Grid container direction="column" spacing={2}>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.blocks.map((item, i) => (
                                <Draggable
                                  draggableId={item.blockId}
                                  index={i}
                                  key={item.blockId}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        backgroundColor: snapshot.isDragging
                                          ? "#BCCBDC"
                                          : "inherit",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Grid item>
                                        <EditableBlock
                                          item={item}
                                          i={i}
                                          editMarkdown={this.editMarkdown}
                                          deleteBlock={this.deleteBlock}
                                          changeReport={this.changeReport}
                                        />
                                      </Grid>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Grid>
                  </Container>
                  <Footer>
                    <PoweredByPhonic />
                  </Footer>
                </>
              )}
            </div>
          </ThemeProvider>
          {/* BUTTON BAR */}
          <div className="create-survey-buttons-container flex-between">
            <span
              className="add-survey-data flex-container"
              onClick={() => this.setState({ popupOpen: true })}
            >
              <AddBoxIcon
                fontSize="large"
                style={{ color: "#62cb88", marginRight: "15px" }}
              />
              Add Survey Data
              <br />
              Data, responses, graphs
            </span>
            <Button
              variant="contained"
              className="deploy-button"
              onClick={this.changeReport}
              color="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildReport);

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router-dom";
import { questionTypes } from "utils/questionTypes";
import TypeTile from "components/TypeTile";

class NewQuestionPopup extends React.Component {
  state = {
    selected: undefined,
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <div id="question-picker-dialog">
            <h2 className="title">Select a question type</h2>

            <div id="question-types" style={{ marginBottom: 20 }}>
              {["WELCOME", "BASIC", "AUDIO", "VIDEO"].map((c) => {
                return (
                  <>
                    <div>
                      {questionTypes
                        .filter((e) => e.category === c)
                        .map((type, idx) => {
                          if (
                            type.key === "WELCOME" &&
                            this.props.info.removeWelcomeScreen === false
                          )
                            return null;
                          return (
                            <div
                              key={`QTYPE-${idx}`}
                              className="tile-section"
                              onClick={() => {
                                if (type.key === "WELCOME") {
                                  this.props.changeInfo(
                                    {
                                      removeWelcomeScreen: false,
                                    },
                                    true
                                  );
                                  this.props.modFunctions.previewQuestion(
                                    undefined
                                  );
                                  this.props.handleClose();
                                  return;
                                }
                                this.props.addQuestion(
                                  this.props.idx,
                                  type.key
                                );
                                this.props.handleClose();
                              }}
                            >
                              <div className="mr-10">
                                <TypeTile type={type.key} shape="square" />
                              </div>
                              <div className="info">
                                <p className="type-header">{type.name}</p>
                                <p className="type-body">{type.description}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(NewQuestionPopup);

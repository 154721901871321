import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
  orgLogo: {
    height: "60px",
    margin: "0 10px 0 0",
  },
}));

const Header = ({ imageUrl }) => {
  const classes = useStyles();

  return imageUrl ? (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <img
          src={imageUrl}
          alt="organization logo"
          className={classes.orgLogo}
        />
      </Grid>
    </Grid>
  ) : null;
};

export default Header;

import { PlainExtension } from "@remirror/core";

class PlainTextCopyExtension extends PlainExtension {
  get name() {
    return "plainTextCopy";
  }

  createPlugin() {
    const transformPastedHTML = (html) => {
      const tmp = document.createElement("div");
      tmp.innerHTML = html;
      const stripped = tmp.textContent || tmp.innerText || "";
      return stripped;
    };

    return {
      props: {
        transformPastedHTML,
      },
    };
  }
}

export default PlainTextCopyExtension;

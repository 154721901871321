import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Chip,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PersonalInfo from "../../components/SurveyCreation/PersonalInfo";
import { localizationLanguages } from "../../utils/data";
import ConsentPopup from "../../components/Popups/ConsentPopup";
import TranslationPopup from "../../components/Popups/TranslationPopup";
import RandomConditionsPopup from "../../components/Popups/RandomConditionsPopup";
import { uploadSurveyImage } from "redux/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import SurveyHtmlHeadPopup from "components/Popups/SurveyHtmlHeadPopup";
import { blockFreeTierNotification } from "utils/utils";

const mapDispatchToProps = (dispatch) => {
  return {
    uploadSurveyImage: (surveyId, file, action) =>
      dispatch(uploadSurveyImage(surveyId, file, action)),
  };
};

class Settings extends Component {
  state = {
    info: {},
  };
  componentDidUpdate(prevProps) {
    if (prevProps.info !== this.props.info) {
      if (prevProps.info.consentPDFUri !== this.props.info.consentPDFUri)
        this.setState({ consentLoading: false });
    }
  }

  /**
   * Function used to update survey properties in a debounced manner.
   * @param {*} updates Dictionary containing updates to me made to the main survey object.
   */
  updateSurveyWithTimedUpdate = (updates) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      info: {
        ...this.state.info,
        ...updates,
      },
      updateTimeout: setTimeout(() => {
        this.props.changeInfo(updates, true);
      }, 500),
    });
  };
  checkStateInfoThenProps = (key) => {
    return this.state.info[key] ? this.state.info[key] : this.props.info[key];
  };

  clearConsentPDF = () => {
    this.props.changeInfo(
      {
        consentPDFUri: undefined,
      },
      true
    );
  };
  toggleConsentPopup = (value) => {
    this.setState({
      consentPopupOpen: value,
    });
  };
  toggleTranslationPopup = (value) => {
    this.setState({
      translationPopupOpen: value,
    });
  };
  toggleHtmlHeadPopup = (value) => {
    this.setState({
      htmlHeadPopupOpen: value,
    });
  };
  toggleRandomConditionsPopup = (value) => {
    this.setState({
      randomConditionsPopupOpen: value,
    });
  };
  enableRandomConditions = (value) => {
    this.props.changeInfo(
      {
        enableRandomConditions: value,
      },
      true
    );
  };
  submitConsentPDF = (file) => {
    this.props.pushSurveyUpdates();
    this.props.uploadSurveyImage(this.props.surveyId, file, "CONSENT_UPLOAD");
    this.setState({ consentLoading: false });
  };
  changeTranslations = (translations) => {
    this.props.changeInfo(
      {
        translations: translations,
      },
      true
    );
  };
  changeRandomConditions = (conditions) => {
    this.props.changeInfo(
      {
        randomConditions: conditions,
        enableRandomConditions: this.props.info.enableRandomConditions
          ? true
          : false,
      },
      true
    );
  };

  render() {
    return (
      <div className="restricted-container">
        <ConsentPopup
          open={this.state.consentPopupOpen}
          onSubmit={this.submitConsentPDF}
          setLoading={() => this.setState({ consentLoading: true })}
          onClose={() => this.toggleConsentPopup(false)}
        />
        {this.state.translationPopupOpen && (
          <TranslationPopup
            open={this.state.translationPopupOpen}
            onSubmit={this.changeTranslations}
            onClose={() => this.toggleTranslationPopup(false)}
            translationInfo={this.props.info.translations}
          />
        )}
        {this.state.randomConditionsPopupOpen && (
          <RandomConditionsPopup
            open={this.state.randomConditionsPopupOpen}
            onClose={() => this.toggleRandomConditionsPopup(false)}
            randomConditionsInfo={this.props.info.randomConditions}
            onSubmit={this.changeRandomConditions}
          />
        )}
        <SurveyHtmlHeadPopup
          open={this.state.htmlHeadPopupOpen}
          onClose={() => this.toggleHtmlHeadPopup(false)}
          onSave={(htmlHead) => this.props.changeInfo({ htmlHead: htmlHead })}
          htmlHead={this.props.info.htmlHead}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Survey Name</h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Survey Name"
                  value={this.checkStateInfoThenProps("name")}
                  onChange={(e) =>
                    this.updateSurveyWithTimedUpdate({ name: e.target.value })
                  }
                />
              </div>
            </Paper>

            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Instructions</h3>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      multiline
                      fullWidth
                      maxRows={25}
                      label="Instructions"
                      value={this.checkStateInfoThenProps("summary")}
                      onChange={(e) =>
                        this.updateSurveyWithTimedUpdate({
                          summary: e.target.value,
                        })
                      }
                      className="instructions"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <PersonalInfo
                  info={this.props.info}
                  changeDemo={this.props.changeDemo}
                  changeInfo={this.props.changeInfo}
                />
              </div>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper>
                  <div className="info-container">
                    <h3 className="survey-info-titles">Completion</h3>
                    <p className="survey-info-tip">
                      Direct respondents to a link at the end of your survey.
                    </p>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Title"
                          value={this.checkStateInfoThenProps("finishedTitle")}
                          onChange={(e) =>
                            this.props.changeInfo(
                              {
                                finishedTitle: e.target.value,
                              },
                              true
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Subtitle"
                          value={this.checkStateInfoThenProps(
                            "finishedSubTitle"
                          )}
                          onChange={(e) =>
                            this.props.changeInfo(
                              { finishedSubTitle: e.target.value },
                              true
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Completion URL"
                          value={this.checkStateInfoThenProps("completionLink")}
                          onChange={(e) =>
                            this.updateSurveyWithTimedUpdate(
                              { completionLink: e.target.value },
                              true
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <div className="checkbox-container">
                      <div>
                        <Checkbox
                          color={"#595c62"}
                          checked={this.props.info.redirectToCompletionLink}
                          onChange={(e) =>
                            this.props.changeInfo({
                              redirectToCompletionLink: e.target.checked,
                            })
                          }
                        />
                      </div>
                      <div>
                        <h3 className="demographic">Redirect Automatically</h3>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper>
                  <div className="info-container">
                    <h3 className="survey-info-titles">Screen Out</h3>
                    <p className="survey-info-tip">
                      Direct respondents to a link if screened out of your
                      survey.
                    </p>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Title"
                          value={this.checkStateInfoThenProps("screenOutTitle")}
                          onChange={(e) =>
                            this.updateSurveyWithTimedUpdate({
                              screenOutTitle: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Subtitle"
                          value={this.checkStateInfoThenProps(
                            "screenOutSubTitle"
                          )}
                          onChange={(e) =>
                            this.updateSurveyWithTimedUpdate({
                              screenOutSubTitle: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Screen Out URL"
                          value={this.checkStateInfoThenProps("screenOutLink")}
                          onChange={(e) =>
                            this.updateSurveyWithTimedUpdate({
                              screenOutLink: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <div className="checkbox-container">
                      <div>
                        <Checkbox
                          color={"#595c62"}
                          checked={this.props.info.redirectToScreenOutLink}
                          onChange={(e) =>
                            this.props.changeInfo({
                              redirectToScreenOutLink: e.target.checked,
                            })
                          }
                        />
                      </div>
                      <div>
                        <h3 className="demographic">Redirect Automatically</h3>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Additional Options</h3>
                <div className="checkbox-container">
                  <div>
                    <Checkbox
                      color={"#595c62"}
                      checked={this.props.info.persistResponses}
                      onChange={(e) =>
                        this.props.changeInfo(
                          { persistResponses: e.target.checked },
                          true
                        )
                      }
                    />
                  </div>
                  <div>
                    <h3 className="demographic">
                      Allow respondents to continue later
                    </h3>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div>
                    <Checkbox
                      color={"#595c62"}
                      checked={this.props.info.hidePreviousButton}
                      onChange={(e) =>
                        this.props.changeInfo(
                          { hidePreviousButton: e.target.checked },
                          true
                        )
                      }
                    />
                  </div>
                  <div>
                    <h3 className="demographic">Hide previous button</h3>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div>
                    <Checkbox
                      color={"#595c62"}
                      checked={this.props.info.hideSupportText}
                      onChange={(e) =>
                        this.props.changeInfo(
                          {
                            hideSupportText: e.target.checked,
                          },
                          true
                        )
                      }
                    />
                  </div>
                  <div>
                    <h3 className="demographic">Hide bottom support text</h3>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div>
                    <Checkbox
                      color={"#595c62"}
                      checked={this.props.info.useNativeCamera}
                      onChange={(e) =>
                        this.props.changeInfo(
                          {
                            useNativeCamera: e.target.checked,
                          },
                          true
                        )
                      }
                    />
                  </div>
                  <div>
                    <h3 className="demographic">
                      Use native camera on mobile (when possible)
                    </h3>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Microphone Check</h3>
                <div className="checkbox-container">
                  <div>
                    <Checkbox
                      color={"#595c62"}
                      checked={this.props.info.includeMicCheck}
                      onChange={(e) =>
                        this.props.changeInfo(
                          {
                            includeMicCheck: e.target.checked,
                          },
                          true
                        )
                      }
                    />
                  </div>
                  <div>
                    <h3 className="demographic">Include a microphone check</h3>
                  </div>
                </div>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Survey Language</h3>
                <p className="survey-info-tip">
                  Change the language of buttons and instructions.
                </p>
                {this.props.info.defaultLanguage !== "en" && (
                  <Alert severity="warning" className="survey-info-alert">
                    This does <b>not</b> set transcription language, which is
                    done in question settings.
                  </Alert>
                )}
                <Select
                  fullWidth
                  variant="outlined"
                  value={
                    this.props.info.defaultLanguage
                      ? this.props.info.defaultLanguage
                      : "en"
                  }
                  onChange={(e) =>
                    this.props.changeInfo(
                      { defaultLanguage: e.target.value },
                      true
                    )
                  }
                >
                  {localizationLanguages.map((l) => {
                    return (
                      <MenuItem key={l.code} value={l.code}>
                        {l.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button
                  variant="contained"
                  className="secondary-button-color"
                  onClick={() => {
                    if (this.props.tier !== "ENTERPRISE") {
                      alert(
                        "Translation editing is limited to Enterprise users."
                      );
                      return;
                    }
                    this.toggleTranslationPopup(true);
                  }}
                  style={{ marginTop: 12 }}
                >
                  Edit Translations
                </Button>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Consent Page</h3>
                <p className="survey-info-tip" style={{ marginBottom: 10 }}>
                  Include a PDF document to explain survey terms. This is placed
                  at the beginning of your survey.
                </p>

                {!this.props.info.consentPDFUri ? (
                  <Button
                    variant="contained"
                    className="secondary-button-color"
                    onClick={() => this.toggleConsentPopup(true)}
                  >
                    {this.state.consentLoading ? (
                      <CircularProgress size={22} style={{ color: "white" }} />
                    ) : (
                      "Upload PDF"
                    )}
                  </Button>
                ) : (
                  <Chip
                    icon={<PictureAsPdfIcon />}
                    label={"Consent PDF"}
                    onDelete={this.clearConsentPDF}
                    color="primary"
                    onClick={() => {
                      window.open(this.props.info.consentPDFUri, "_blank");
                    }}
                  />
                )}
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Audio Format</h3>
                <p className="survey-info-tip">
                  Change the file format of your audio recording.
                </p>
                <Select
                  fullWidth
                  variant="outlined"
                  value={
                    this.props.info.audioRecordingFormat
                      ? this.props.info.audioRecordingFormat
                      : "wav"
                  }
                  onChange={(e) =>
                    this.props.changeInfo({
                      audioRecordingFormat: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"wav"}>.WAV</MenuItem>
                  <MenuItem value={"mp3"}>.MP3</MenuItem>
                </Select>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Analytics Integrations</h3>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      key="GOOGLE-ANALYTICS"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      label="Google Analytics ID"
                      value={this.checkStateInfoThenProps("googleTrackingID")}
                      onChange={(e) =>
                        this.updateSurveyWithTimedUpdate({
                          googleTrackingID: e.target.value,
                        })
                      }
                      disabled={this.props.tier === "FREE"}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      key="FACEBOOK-ANALYTICS"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      label="Facebook Pixel ID"
                      value={this.checkStateInfoThenProps("facebookPixelID")}
                      onChange={(e) =>
                        this.updateSurveyWithTimedUpdate({
                          facebookPixelID: e.target.value,
                        })
                      }
                      disabled={this.props.tier === "FREE"}
                      onBlur={this.updateParent}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Event Bindings</h3>
                <p className="survey-info-tip mb-10">
                  Write Javascript which is bound to event listeners throughout
                  your survey.
                </p>
                <Button
                  variant="contained"
                  className="secondary-button-color"
                  onClick={() => {
                    if (this.props.tier === "FREE") {
                      blockFreeTierNotification();
                    }
                    this.toggleHtmlHeadPopup(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCode} />
                  &nbsp;Open Editor
                </Button>
              </div>
            </Paper>
            <Paper>
              <div className="info-container">
                <h3 className="survey-info-titles">Random Conditions</h3>
                <p className="survey-info-tip">
                  Randomize initial conditions with specific probabilities.
                </p>
                <div className="random-checkbox-container">
                  <Checkbox
                    color={"#595c62"}
                    checked={
                      this.props.info.enableRandomConditions ? true : false
                    }
                    value={this.props.info.enableRandomConditions}
                    onChange={(e) => {
                      this.enableRandomConditions(e.target.checked);
                    }}
                    name="enableRandomConditions"
                  />
                  <p className="survey-info-tip">Enable Random Conditions</p>
                </div>
                <Button
                  variant="contained"
                  className="secondary-button-color"
                  onClick={() => this.toggleRandomConditionsPopup(true)}
                >
                  Edit Conditions
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(Settings);

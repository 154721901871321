import { loadStripe } from "@stripe/stripe-js";

let key = "";
if (process.env.REACT_APP_STRIPE_ENV === "TEST") {
  key = "pk_test_NcQs0DKhz26RewYRM1x7VWpH00dwt2zRVi";
} else {
  // Default to live credentials.
  key = "pk_live_Ql9K5PmzEghTzOLr44fCyQXI00OHBd2I5F";
}
export default loadStripe(key);

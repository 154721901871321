import React from "react";
import clsx from "clsx";
import { Checkbox, withStyles } from "@material-ui/core";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#13c552",
    "&$checked": {
      color: "#13c552",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BoundedCheckbox = ({ checked, text, onChange }) => {
  return (
    <div
      className={clsx("bounded-checkbox", checked && "checked")}
      onClick={() => onChange(!checked)}
    >
      <CustomColorCheckbox checked={checked} /> {text}
    </div>
  );
};

export default BoundedCheckbox;

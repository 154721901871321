import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#81d59f",
      main: "#62cb88",
      dark: "#448e5f",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
  },
  shape: {
    borderRadius: 8,
  },
  // Override MUI classes
  overrides: {
    MuiButton: {
      root: {
        fontSize: "1rem",
        textTransform: "capitalize",
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "#595c62",
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: "#22ce81",
      },
      clickableColorPrimary: {
        "&:hover": {
          backgroundColor: "#22ce81",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
      },
    },
  },
  // Override MUI component props
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default responsiveFontSizes(theme);

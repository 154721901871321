import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function ConfirmEditButton(props) {
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="file-button-wrapper"
        onClick={props.handleClick}
      >
        <svg
          viewBox="0 0 62 62"
          className="file-button plus"
          style={{ enableBackground: "new 0 0 60.364 60.364" }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </svg>
      </div>
    </>
  );
}
